import { CustomButton } from 'components/Button/CustomButton'
import CustomSpinner from 'components/Misc/CustomSpinner'
import { MainContext } from 'context/mainContext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, CustomInput, Form, FormGroup, Input, Label, Modal, Row, Table } from 'reactstrap'
import { evaThemeLighter } from 'services/EVAResponseService';
import { registerDashboardTemplate } from 'services/mongoDB';
import { dashboardTemplate } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import DashboardTemplateItem from './DashboardTemplateItem';
import DashboardTemplateItemModal from './DashboardTemplateItemModal';
import PanelCard from 'components/Cards/PanelCard';
import { useHistory } from "react-router";
import DashboardTemplateKPI from './DashboardTemplateKPI';
import { submitEvaQueryHandler } from 'services/DashboardService';
import { forEach, wrap } from 'lodash';
import { generateUUID } from 'services/EVAService';
import ManageDashboardDnD from '../manageDashboard/ManageDashboardDnD';
import EVAVisualEditDashboard from '../manageDashboard/EVAVisualEditDashboard';
import { userDashboardTemplate } from 'services/mongoDB';
import { userDashboardTemplateByUser } from 'services/mongoDB';
import { getCustomGroups } from 'services/mongoDB';
import Select from 'react-select';
import { registerGroupNotification } from 'services/mongoDB';
import SendMultiDeviceNotification from 'services/NotificationService';
import SendExpoNotifications from 'services/ExpoNotificationService';
import { mongoFetchToken } from 'services/mongoDB';
import { etaniDashboardTemplates } from 'services/mongoDB';
// import socket from 'services/MainSocket';
// import socket from 'chat-components/utils/socket';

function DashboardTemplate({ ...props }) 
{
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    // const [templateList, setTemplateList] = useState(null)
    const [selectedTemplateItem, setSelectedTemplateItem] = useState([]);
    const [newDashboardName, setNewDashboardName] = useState(null);
    const [tabKey, setKey] = useState(null);
    const [dashboardType, setDashboardType] = useState('New');
    const [KPITabKey, setKPITabKey] = useState(null);
    const [templates, setTemplates] = useState([])
    const [integration, setIntegration] = useState([])
    const [categories, setCategories] = useState([])
    const [sqlExampleResult, setSQLExampleResult] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [onEditVisual, setOnEditVisual] = useState(false)
    const [onEditItem, setOneditItem] = useState(null)
    const [onEditItemIndex, setOneditItemIndex] = useState(null)
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [userTemplatesList, setUserTemplateList] = useState([])
    const [selectedExistingDashboard, setSelectedExistingDashboard] = useState(null)
    const [pintoDashboard, setPintoDashboard] = useState('personal');
    const [selectedGroupId, setSelectedGroupId] = useState([]);
    const [groupList, setGroupList] = useState([])
    const [customGroupList, setCustomGroupList] = useState(null)
    const [clientUsers, setClientUsers] = useState([]);
    const [selectGroupUsers, setSelectGroupUsers] = useState(null)
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState(null)
    const [etaniTempaltes, setEtaniTemplates] = useState([])
    const [selectedEtaniTempaltes, setSelectedEtaniTemplates] = useState(null)
    const {
        bgColor,
        textColor,
        selectedClientID,
        userDetails,
        firmDetails,
        getDetails,
        isTemplateEdit,
        selectedTemplatetoEdit, 
        selectedClient,
        selectClientIntegration
      } = useContext(MainContext);

    const theme = { PrimaryColor: bgColor, TextColor: textColor }
    const history = useHistory();
    const isInitialMount = useRef(true)

    useEffect(()=> {
        const loadTemplates = async () => {
            // const template = await dashboardTemplate()
            // console.log(firmDetails)
            try{
                const res = await submitEvaQueryHandler(`SELECT * FROM _eva_sql_templates WHERE template IS NOT null ORDER BY intergration_id`, selectedClient, firmDetails, userDetails);
                if(res === undefined || !res) history.push(`/client/customDashboard`)

                const filteredDashboards = res.filter(dashboard => selectClientIntegration.includes(dashboard.integration));
                // console.log(filteredDashboards);

                getTemplates(filteredDashboards)
                const groupedData = groupBy(filteredDashboards, 'template', 'integration', 'category');
                const transformedData = Object.entries(groupedData).map(([key, value]) => {
                    const [template, integration, category] = key.split('-');
                    return {
                        template,
                        integration,
                        category,
                        details: value.map(item => ({
                            display_name: item.display_name,
                            query: item.query,
                            visual_type: item.visual_type,
                            visual_data: parseVisualData(item.visual_data) 
                        }))
                    };
                });
                // console.log(selectClientIntegration)
                // console.log(transformedData)
                const etaniTemplate = await etaniDashboardTemplates()
                setEtaniTemplates(etaniTemplate)
                setSQLExampleResult(transformedData);
                setIsLoading(false)
            }catch(error)
            {
                console.log(error)
                setIsLoading(false)
            }

            // 
        }
        loadTemplates()
    }, [])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            history.push(`/client/customDashboard`);
        }
    }, [selectedClientID, history]);

    useEffect(() => {
        loadUserTemplate()
        loadClientUser(selectedClient?.id)
    },[selectedClientID, selectedClient])
    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    const loadClientUser = async (selectedClientID) => {
        if(!selectedClientID || selectedClientID === undefined) return

        await fetch(`/api/user/getAllRecords/${selectedClientID}`)
        .then((res) => res.json())
        .then((data) => {
            if (data && !data.error) {
                const users = []
                data.map((user) => users.push(user.Email));
                setClientUsers(users);
            } else {
                setIsLoading(false)
                return;
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const loadUserTemplate = async () => 
    {
        if (Object.keys(userDetails).length === 0) {
            return false;
        }

        const param = {
            clientId: selectedClientID,
            user: userDetails.User.Email
        }
        const res = await userDashboardTemplateByUser(param)
        console.log(res)

        const publicTemplate = res.filter((item) => item.userAccessSetting?.accessType === 'public'
            && (item.userAccessSetting?.clientOption === "All Client" || item.userAccessSetting?.selectedClient?.some(client => client.ClientID === selectedClientID))
            && item.businessId === firmDetails.AccountingFirmID
        )

        console.log(publicTemplate)
        setUserTemplateList(publicTemplate)
    }
    const fetchClientGroups = async () => {
        if(pintoDashboard === 'personal') return
        const res = await getCustomGroups({ ClientId: selectedClientID, email: userDetails.User.Email || '' })
        setCustomGroupList(res)

        const users = []
        selectedClient && selectedClient.User && selectedClient.User.map(item => users.push(item.Email))
        const groups = [{
            "_id": selectedClient.ClientID,
            "Users": users,
            "GroupName": selectedClient.Name,
            "ClientId": selectedClient.ClientID
        }]
        const newRes = groups.map(item => { return { value: item._id, label: item.GroupName } }).concat(res.map(item => {return { value: item._id, label: item.GroupName }}))
        setGroupList(newRes);
    };
    const parseVisualData = (visualData) => {
        try {
            const data = JSON.parse(visualData) 
            return data
        }catch(error){
            return []
        }
    }
    const groupBy = (array, ...keys) => {
        return array.reduce((acc, obj) => {
            const key = keys.map(key => obj[key]).join('-');
            (acc[key] = acc[key] || []).push(obj);
            return acc;
        }, {});
    };
    const getTemplates = (res) => {
        const templates = []
        const integration = []
        const categories = []
        const newData = []
        // console.log(res)
        res !== undefined && res.length > 0 && res.forEach(item => {
            if (!templates.includes(item.template)) templates.push(item.template)
            if (!integration.includes(item.integration)) integration.push(item.integration)
            if (!categories.includes(item.category)) categories.push(item.category)
        })

        if(!tabKey) setKey(integration[0])

        setTemplates(templates)
        setIntegration(integration)
        setCategories(categories)
    }
    useEffect(() => {
        const loadTemplates = async () => {
           
            if(isTemplateEdit)

            {
                let templatetoEdit = selectedTemplatetoEdit ? selectedTemplatetoEdit : sessionStorage.getItem('selectedTemplatetoEdit')
                setPintoDashboard(selectedTemplatetoEdit.isPersonalDashboard ? 'personal' : 'group')
                // console.log(templatetoEdit)
                // console.log(selectedTemplatetoEdit)
                
                console.log(selectedTemplatetoEdit?.selectedGroupId?.groupsId)
                console.log(groupList)
                const groupsIds = selectedTemplatetoEdit?.selectedGroupId?.groupsId.map(item => {
                    return groupList.find(group => group.value === item);
                }).filter(group => group !== undefined);
        
                setDefaultSelectedGroup(groupsIds);
                
                console.log(groupsIds);
                // setDefaultSelectedGroup(groupsIds)

                const selectedTemplate = templatetoEdit
                handleSelectGroupChange(groupsIds)
                setNewDashboardName(selectedTemplate.name)
                setSelectedTemplateItem(selectedTemplate.dashboardTemplate)
            }else{
                return
            }
        }

        loadTemplates()
    },[groupList])
    useEffect(() => {
        
        if(selectedTemplateItem.length === 0) return

        const newItem = { ...selectedTemplateItem };


        if(selectedTemplateItem.length === 1) {
            setKPITabKey(selectedTemplateItem[0].name) 
            return
        }
        if(!KPITabKey) {
            setKPITabKey(selectedTemplateItem[0].name) 
            return
        }

        const index = selectedTemplateItem.findIndex((prevItem) => prevItem.name === KPITabKey.name);
        if (index < 0 && selectedTemplateItem.length > 0) setKPITabKey(selectedTemplateItem[0].name)

    },[selectedTemplateItem])

    const handleNewDashboardNameChange = (event) => {
        setNewDashboardName(event.target.value);
    };
    const handleSelectItem = async (item) => {

        item.uid = await generateUUID();
        setSelectedTemplateItem((prevItems) => {
            const index = prevItems.findIndex((prevItem) => prevItem.name === item.name);
            if (index > -1) {
                const updatedItems = [...prevItems];
                updatedItems.splice(index, 1);
                console.log(updatedItems)
                return updatedItems;
            } else {
                return [...prevItems, item];
            }
        });
    }
    const handleSave = async () => 
    {
        if(selectedTemplateItem.length > 0 && newDashboardName)
        {
            let param = {
                name: newDashboardName,
                clientId: selectedClientID,
                businessId: firmDetails.AccountingFirmID,
                groupId: selectedClientID,
                user: userDetails.User.Email,
                isPersonalDashboard: pintoDashboard === 'personal',
                selectedGroupId: { groupsId: selectedGroupId },
                dashboardTemplate: selectedTemplateItem.map(item => ({
                    ...item,
                    visual: item.tempVisual
                }))
            };
            
            param.dashboardTemplate.forEach(item => {
                delete item.tempVisual;
            });
            
         
            // console.log("param: ", param)
            if(isTemplateEdit)
            {
                param._id= selectedTemplatetoEdit._id
                registerDashboardTemplate(param)
                if(pintoDashboard !== 'personal')
                    sendNotificationPerGroup(isTemplateEdit, newDashboardName)
                const alertMessage = `You have successfully update ${newDashboardName}`
                handleSuccessUpdate(alertMessage)
            }else{
                registerDashboardTemplate(param)
                const alertMessage = `You have successfully add ${newDashboardName}`
                if(pintoDashboard !== 'personal')
                    sendNotificationPerGroup(isTemplateEdit, newDashboardName)
                handleSuccessUpdate(alertMessage)
            }
        }else{
            if (!selectedTemplateItem && selectedTemplateItem.length === 0) warningAlert("You need to add at least one item!");
            if (!newDashboardName) warningAlert("The dashboard name is invalid!");
        }
    }

    const handleImportTemplate = async () => 
    {
        let param = {
            name: newDashboardName,
            clientId: selectedClientID,
            businessId: firmDetails.AccountingFirmID,
            groupId: selectedClientID,
            user: userDetails.User.Email,
            dashboardTemplate: selectedTemplateItem.map(item => ({
                ...item,
                visual: item.tempVisual
            })),
            importedTemplate: true
        };
        
        param.dashboardTemplate.forEach(item => {
            delete item.tempVisual;
        });

        try{
            registerDashboardTemplate(param)
            const alertMessage = `You have successfully add ${newDashboardName}`
            handleSuccessUpdate(alertMessage)
        } catch(error) {
            const alertMessage = `An error occured while saving the template`
            warningAlert(alertMessage)
        }
    }
     const warningAlert = (message) => {
        setAlert(
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Warning"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
        >
            {message}
        </ReactBSAlert>
        );
    };
    const handleSuccessUpdate = (msg, data) => {
        setAlert(
            <ReactBSAlert
                succes
                title="Success"
                onConfirm={async () => {
                    setAlert(null)
                    history.push(`/client/customDashboard`);
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
    const handleSelectExistingTemplate =  useCallback( (item) => {
        setNewDashboardName(item.name)
        setSelectedExistingDashboard(item._id)
        setSelectedTemplateItem([]);

        setTimeout(() => {
            setSelectedTemplateItem(item.dashboardTemplate);
        }, 200);
    }, []);
    const handleSelectTemplate =  async(item, selectedTemplate) => {
        setSelectedTemplate(null);
        setSelectedTemplateItem([]);

        setTimeout(async () => {
            if (selectedTemplate === item) {
                setSelectedTemplate(null);
                setSelectedTemplateItem([]);
            } else {
                const newSelectedTemplateItem = [];
                const filterByTemplate = sqlExampleResult.filter((e) => e.template === item);
        
                for (const e of filterByTemplate) {
                    if (e.template === item) {
                        const details = e.details;
                        for (const detail of details) {
                            const uid = await generateUUID();
                            newSelectedTemplateItem.push({ 
                                name: detail.display_name, 
                                query: detail.query, uid, 
                                visual_type: detail.visual_type, 
                                visual_data: detail.visual_data,
                                integration: item.integration
                            });
                        }
                    }
                }
                setSelectedTemplate(item);
                setSelectedTemplateItem(newSelectedTemplateItem);
            }
        }, 300);
    };
    const handleSelectEtaniTemplate =  async(selectedTemplate) => {
        if(selectedTemplate._id === selectedEtaniTempaltes?._id){
            setSelectedTemplateItem([])
            setNewDashboardName(null)
            setSelectedEtaniTemplates(null)
        }else{
            setSelectedTemplateItem([])
            setNewDashboardName(null)
            setSelectedEtaniTemplates(null)
            setTimeout(() => {
                setNewDashboardName(selectedTemplate.name)
                setSelectedTemplateItem(selectedTemplate.dashboardTemplate)
                setSelectedEtaniTemplates(selectedTemplate)
            }, 100);
        }
    };
    const handleSelectCategory = useCallback(async (checked, value) => {
        let newItems = [...selectedTemplateItem];
    
        for (const e of value.details) {
            const exists = selectedTemplateItem.some(item => item.name === e.display_name);
            console.log(exists);
    
            if (exists && !checked) {
                // Remove item if it exists and should be unchecked
                newItems = newItems.filter(item => item.name !== e.display_name);
            } else if (!exists && checked) {
                // Add item if it does not exist and should be checked
                const newItem = {
                    uid: await generateUUID(),
                    name: e.display_name,
                    query: e.query,
                    visual_type: e.visual_type,
                    visual_data: e.visual_data,
                    integration: value.integration
                };
                newItems.push(newItem);
            }
        }
        setSelectedTemplateItem(newItems);
    }, [selectedTemplateItem]);
    
    const handleSelectDashboardType = useCallback((value) => {
        setNewDashboardName(null)
        setSelectedExistingDashboard(null)
        setSelectedTemplateItem([]);
        setDashboardType(value)
    }, []);
    const handleTabSelect = useCallback((value) => {
        setKey(value)
    }, []);
    const handleOnEditVisual = (index) => {
        setOnEditVisual(!onEditVisual)
        setOneditItem(selectedTemplateItem[index])
        setOneditItemIndex(index)
    }
    const handleCloseEditVisual = () => {
        setOnEditVisual(false)
        setNewUpdateCount(prevCount => prevCount + 1);
    }
    useEffect(() => {
    },[newUpdateCount])
    const handleSelectPinToDashboard = async (event) => {
        // setDashboardType('new');
        setPintoDashboard(event.target.value);
    };
    useEffect(() => {
        fetchClientGroups()
    },[pintoDashboard])

    const handleSelectGroupChange = async (value) => {
        console.log(value)
        const selectedGroupId = value && value.length > 0 && value.map((item) => item.value)
        console.log(selectedGroupId)
        setSelectedGroupId(selectedGroupId);
    };
    
    
    const sendNotificationPerGroup = (isTemplateEdit, newDashboardName) => {
        if(selectedGroupId.includes(selectedClientID))
        {
            const usersSet = new Set();
            clientUsers.forEach(user => { if(user !== userDetails.User.Email) usersSet.add(user)});
            const msg = isTemplateEdit ? 
            `${userDetails.User.FirstName} ${userDetails.User.LastName} updated the ${newDashboardName} dashboard in ${selectedClient.Name} group` : 
            `${userDetails.User.FirstName} ${userDetails.User.LastName} posted new dashboard in ${selectedClient.Name} group`

            sendNotificationHandler(usersSet, msg, selectedClient.Name)
        }

        customGroupList && customGroupList.forEach(group => {
            const usersSet = new Set();
            if (selectedGroupId.includes(group._id) && group.UserList && group.UserList.users) {
                group.UserList.users.forEach(user => { if(user !== userDetails.User.Email) usersSet.add(user)});
                const msg = isTemplateEdit ? 
                `${userDetails.User.FirstName} ${userDetails.User.LastName} updated the ${newDashboardName} dashboard in ${group.GroupName}` :
                `${userDetails.User.FirstName} ${userDetails.User.LastName} posted a dashboard in ${group.GroupName}`

                sendNotificationHandler(usersSet, msg, group.GroupName)
            }
        });
    }

    const sendNotificationHandler = async (users, msg, groupName) => {
        sendNotification(users, msg)
        const ClientID = props.selectedClientID
        const notification = {
          title: `new notification from the "${groupName}" group`,
          body:  msg,
          sound: 'default',
          badge: '1',
        };
        
        const clientToken = await mongoFetchToken({ clientid : ClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = clientToken.tokens
        .filter(token => {
            return users.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userDetails.User.Email.toLowerCase();
        })
        .map(token => token.token);

        if (filteredTokens.length === 0) return
    
        const message = {
          tokens: filteredTokens,
          data: { ClientID: ClientID, Name: selectedClient.Name ,  type: 'custom group', Id: selectedClient.id},
          notification: notification,
        };

        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };

    const sendNotification = async (selectedUsers, message) => {
        console.log(selectedUsers)
        for (const email of selectedUsers) {
    
          if(email !== userDetails.User.Email)
          {
            const notifications = {
              uid: await generateUUID(),
              id: selectedClientID,
              clientId: selectedClientID,
              message: message,
              createdAt: new Date(),
              sender: userDetails.User.Email,
              isReport: true,
              email: email,
              user: email,
              url: '',
              groupId: selectedClientID,
            };
            // await socket.emit("newClientNotification", notifications);
            registerGroupNotification(notifications)
          }
        }
    };
    return (
        <Container className="mt-6 fadeIn" fluid>
        {alert}
            <div className="nav-wrapper">
                <PanelCard headerName="Dashboard Templates">
                    {isLoading ? <CustomSpinner />
                    : 
                    <Form>
                        <FormGroup>
                            {sqlExampleResult && sqlExampleResult.length > 0 ?
                                <div className='templateDashboardTabView'>
                                    <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
                                            <>
                                                <a 
                                                    id={1} 
                                                    href="#" role="tab" 
                                                    key={1}
                                                    style={dashboardType === 'New' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
                                                    className={`nav-item nav-link ${dashboardType === 'New' ? "active" : ""}`}
                                                    onClick={() => handleSelectDashboardType('New')}>
                                                        New Dashboard
                                                </a>
                                                <a 
                                                    id={2} 
                                                    href="#" role="tab" 
                                                    key={2}
                                                    style={dashboardType === 'Existing' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
                                                    className={`nav-item nav-link ${dashboardType === 'Existing' ? "active" : ""}`}
                                                    onClick={() => handleSelectDashboardType('Existing')}>
                                                        Public Dashboard
                                                </a>
                                            </>
                                    </div>
                                    {dashboardType === 'Existing' ? 
                                        <div style={{ padding: '20px 15px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10 }}>
                                            {/* USER DASHBOARD */}
                                            <div className='templateContainer' style={{ border: 'unset' }}>
                                                <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Dashboards</Label>
                                                {userTemplatesList.length > 0 ? 
                                                    <div className='templateContent'>
                                                            {userTemplatesList.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div  key={index} style={{ background: selectedExistingDashboard === item._id && bgColor, color: selectedExistingDashboard === item._id && textColor }}
                                                                        onClick={e => handleSelectExistingTemplate(item)}>
                                                                        {item.name}
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                    </div>
                                                    :
                                                    <>
                                                        <div className='noTemplateFound'>
                                                            No public dashboard template found!
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className="modal-footer">
                                                <CustomButton
                                                    type="button"
                                                    onClick={() => handleImportTemplate()}
                                                    outlineStyle={false}
                                                    title="Save Dashboard"
                                                />
                                                <CustomButton
                                                    onClick={() => {
                                                        return history.push(`/client/customDashboard`);
                                                    }}
                                                    outlineStyle={true}
                                                    data-dismiss="modal"
                                                    // className="ml-auto"
                                                    type="button"
                                                    color="link"
                                                    title="Close"
                                                /> 
                                            
                                            </div>
                                        </div>
                                    :
                                        <div style={{ padding: '20px 15px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10 }}>
                                        
                                                <Row>
                                                    <Col xl='4' md='6' sm='12'>
                                                        <FormGroup>
                                                            <Label 
                                                                htmlFor="newDashboardName" 
                                                                style={{ 
                                                                    fontWeight: 'bold', 
                                                                    fontSize: 14, 
                                                                    marginBottom: 7
                                                                }}>Dashboard Name</Label>
                                                                <Input
                                                                    key={1}
                                                                    type="text"
                                                                    id="newDashboardName"
                                                                    placeholder="Dashboard Name"
                                                                    value={newDashboardName}
                                                                    onChange={handleNewDashboardNameChange}
                                                                />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl='3' md='6' sm='12'>
                                                        <FormGroup tag="fieldset">
                                                            <Label 
                                                                htmlFor="personalDashboard" 
                                                                style={{ 
                                                                    fontWeight: 'bold', 
                                                                    fontSize: 14, 
                                                                    marginBottom: 7
                                                                }}>Dashboard Location</Label>
                                                            <CustomInput
                                                                type="radio"
                                                                id="personalDashboard"
                                                                name="pinTodashboardType"
                                                                value="personal"
                                                                label="Personal Dashboard"
                                                                checked={pintoDashboard === 'personal'}
                                                                onChange={handleSelectPinToDashboard}
                                                            />
                                                            <CustomInput
                                                                type="radio"
                                                                id="otherDashboard"
                                                                name="pinTodashboardType"
                                                                value="group"
                                                                label="Group Dashboard"
                                                                checked={pintoDashboard === 'group'}
                                                                onChange={handleSelectPinToDashboard}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl='4' md='6' sm='12'>
                                                        {pintoDashboard === 'group' && (
                                                            <FormGroup>
                                                                <Label 
                                                                htmlFor="personalDashboard" 
                                                                style={{ 
                                                                    fontWeight: 'bold', 
                                                                    fontSize: 14, 
                                                                    marginBottom: 7
                                                                }}>Group List</Label>
                                                                <Select 
                                                                // defaultValue={[groupList[2], groupList[3]]}
                                                                    defaultValue={defaultSelectedGroup}
                                                                    isMulti
                                                                    name="colors"
                                                                    options={groupList}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    onChange={handleSelectGroupChange}
                                                                />
                                                                {/* <Input type="select" id="selectedGroupId" value={selectedGroupId} onChange={handleSelectGroupChange}>
                                                                <option value="">Select Group</option>
                                                                {groupList.map(group => (
                                                                    <option key={group._id} value={group._id}>
                                                                    {group.GroupName}
                                                                    </option>
                                                                ))}
                                                                </Input> */}
                                                            </FormGroup>
                                                        )}
                                                    </Col>
                                                </Row>

                                                {/* TEMPLATES */}
                                                <div className='templateContainer' style={{ border: 'unset' }}>
                                                    <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Templates</Label>
                                                    <div className='templateContent'>
                                                        {/* {templates && templates.map((item, index) => (
                                                            <div 
                                                                key={index} 
                                                                style={{ background: selectedTemplate === item ? bgColor : undefined, color: selectedTemplate === item ? textColor : undefined }}
                                                                onClick={e => handleSelectTemplate(item, selectedTemplate)}
                                                            >
                                                                {item} Template
                                                            </div>
                                                        ))} */}
                                                        {etaniTempaltes.length > 0 && etaniTempaltes.map((template, index) => (
                                                            <div 
                                                                key={index} 
                                                                style={{ background: selectedEtaniTempaltes?._id === template._id ? bgColor : undefined, color: selectedEtaniTempaltes?._id === template._id ? textColor : undefined }}
                                                                onClick={e => handleSelectEtaniTemplate(template)}
                                                            >
                                                                {template.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                {/* INTEGRATION */}
                                                <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
                                                    {integration && integration.map((item, index) => (
                                                        <>
                                                            <a 
                                                                id={item.id} 
                                                                href="#" role="tab" 
                                                                key={index}
                                                                style={tabKey === item ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
                                                                className={`nav-item nav-link ${tabKey === item ? "active" : ""}`}
                                                                onClick={() => handleTabSelect(item)}>
                                                                    {item} Integration
                                                            </a>
                                                        </>
                                                    ))}
                                                </div>
                                                {/* KPI */}
                                                <div className='nav-child' style={{ padding: 10, border: '1px solid #e0e0e0', borderRadius: 5, display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}>
                                                    {sqlExampleResult && sqlExampleResult.map((item, index) => (
                                                        item.integration === tabKey &&
                                                        <div key={index} 
                                                            // style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10}}
                                                            style={{ height: 'max-content'}}
                                                            >
                                                            <DashboardTemplateKPI  
                                                                index={index}
                                                                categories={categories}
                                                                tabKey={tabKey}
                                                                item={item}
                                                                selectedTemplateItem={selectedTemplateItem}
                                                                handleSelectItem={handleSelectItem}
                                                                sqlExampleResult={sqlExampleResult}
                                                                category={item.category}
                                                                handleSelectCategory={handleSelectCategory}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            {/* Add Ons */}
                                            {/* <div className='addOneContainer' style={{ border: 'unset' }}>
                                                <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Add Ons</Label>
                                                <div className='addOneContent'>
                                                <div>Text Box</div>
                                                <div>Note</div>
                                                <div>Image</div>
                                                </div>
                                            </div>
                                            */}
                                            <div className="modal-footer">
                                                <CustomButton
                                                    type="button"
                                                    onClick={() => handleSave()}
                                                    outlineStyle={false}
                                                    title="Save Dashboard"
                                                />
                                                <CustomButton
                                                    onClick={() => {
                                                        return history.push(`/client/customDashboard`);
                                                    }}
                                                    outlineStyle={true}
                                                    data-dismiss="modal"
                                                    // className="ml-auto"
                                                    type="button"
                                                    color="link"
                                                    title="Close"
                                                /> 
                                            
                                            </div> 
                                        </div>
                                    }
                                    {onEditVisual &&
                                        <div className='onEditVisualContainer'>
                                            <div className='onEditVisualContent'>
                                                <EVAVisualEditDashboard
                                                    index={onEditItemIndex}
                                                    name={onEditItem.name}
                                                    query={onEditItem.query}
                                                    item={onEditItem}
                                                    selectedTemplateItem={selectedTemplateItem}
                                                    onEditVisual={onEditVisual}
                                                    handleCloseEditVisual={handleCloseEditVisual}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className='templateListContainer' style={{ marginTop: 10, height: screenHeight, background: '#f7f7f7', padding: 10, overflow: 'auto' }}>
                                        <ManageDashboardDnD 
                                            selectedTemplateItem={selectedTemplateItem}
                                            handleOnEditVisual={handleOnEditVisual}
                                            onEditVisual={onEditVisual}
                                            handleCloseEditVisual={handleCloseEditVisual}
                                            newUpdateCount={newUpdateCount}
                                        />
                                    </div>
                                </div>
                            :
                                <>
                                    <div className="dashboardItems" style={{
                                        height: '200px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center', // Center horizontally
                                        justifyContent: 'center', // Center vertically
                                        flexWrap: 'wrap'
                                    }}>
                                        <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No available templates for this client</div>
                                        <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                                    </div>
                                </>
                            }
                        </FormGroup>
                    </Form>
                    }
                
                </PanelCard>
            </div>
        </Container>
    )
}

export default DashboardTemplate