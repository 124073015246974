import { BlobServiceClient } from '@azure/storage-blob';
import { QueueServiceClient } from '@azure/storage-queue';

export const uploadBlob = async (file, blobFileName, tokenData) => {
  // Upload to Azure Blob
  const { token, accountName, containerName } = tokenData
  const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net?${token}`);
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobFileName);

  try {
    const options = {
      blobHTTPHeaders: {
        blobContentType: 'image/png',
      },
    };
    await blockBlobClient.uploadBrowserData(file, options);

  } catch (e) {
    console.log('FAILED TO UPLOAD IMAGE TO AZURE', e);
  }
}

export const pushToQueue = async (queueName, blobFileName, tokenData, messageMetadata) => {
  const { token, accountName } = tokenData
  try {
    const QUEUENAME = queueName;
    const queueServiceClient = new QueueServiceClient(`https://${accountName}.queue.core.windows.net?${token}`);
    const queueClient = queueServiceClient.getQueueClient(QUEUENAME);

    const sendMessageResponse = await queueClient.sendMessage(JSON.stringify({
      csvFileName: blobFileName,
      metadata: messageMetadata
    }));

    // console.log('messageMetadata', messageMetadata);
    console.log(`Sent message to queue with ID: ${sendMessageResponse.messageId}`);

  } catch (e) {
    console.log('FAILED TO PUSH TO QUEUE', e);
  }
}

export const pbixPushToQueue = async (queueName, blobFileName, tokenData, messageMetadata) => {
  const { token, accountName } = tokenData
  try {
    const QUEUENAME = queueName;
    const queueServiceClient = new QueueServiceClient(`https://${accountName}.queue.core.windows.net?${token}`);
    const queueClient = queueServiceClient.getQueueClient(QUEUENAME);

    const sendMessageResponse = await queueClient.sendMessage(JSON.stringify({
      pbixFileName: blobFileName,
      metadata: messageMetadata
    }));

    // console.log('messageMetadata', messageMetadata);
    // console.log(`Sent message to queue with ID: ${sendMessageResponse.messageId}`);

    // console.log(sendMessageResponse)

    if (sendMessageResponse.messageId) {
      return sendMessageResponse
    }

  } catch (e) {
    console.log('FAILED TO PUSH TO QUEUE', e);
  }
}

