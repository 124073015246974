import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { ChromePicker } from 'react-color';
import VisualGraphView from './VisualGraphView';
import VisualGridView from './VisualGridView';
import { registerDashboardTemplate } from 'services/mongoDB';
import VisualPieChartView from './VisualPieChartView';
import VisualCardView from './VisualCardView';
import { updateVisualPersonalDashboard } from 'services/mongoDB';
import { updateVisualUserDashboard } from 'services/mongoDB';

function DashboardVisual({ item, index, gridHeader, sqlresult, dataValue, theme, treeViewData, isWithChild, 
    isShowGrid, toggleAll, isShowGrap, selectedDashboardTemplate, handleSetItemIndexToEdit, 
    toggleFullScreen, handleNewUpdate, templateItemVisual, onEditName, newDashboardItemName, tempLayout }) 
{
    const {
        bgColor,
        textColor
    } = useContext(MainContext);

    const [dataSet, setDataSet] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedFooter, setSelectedFooter] = useState(null);
    const [headerToMultiSelect, setHeaderToMultiSelect] = useState([])
    const [selectedData, setSelectedData] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([{ label: '', data: '', color: bgColor, isOpen: false }]);
    const [chartDataSet, setChartDataSet] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const [color, setColor] = useState('#ffffff');
    const [selectedViewOption, setSelectedViewOption] = useState('Grid')

    useEffect(() => {
        const dataSet = [gridHeader, ...sqlresult]
        const headerToMultiSelect = gridHeader.map((data, index) => {return {
            id: index, name: data
        }})
        setDataSet(dataSet)
        setHeaderToMultiSelect(headerToMultiSelect)
        // console.log(gridHeader)
    },[gridHeader])

    useEffect(() => {
        // if(!chartDataSet) console.log(chartDataSet)
    },[chartDataSet])

    useEffect(()=> {
        // console.log(templateItemVisual)
        if(templateItemVisual)
            setSelectedViewOption(templateItemVisual.selectedViewOption ? templateItemVisual.selectedViewOption : 'Grid')
    },[templateItemVisual])

    const viewOption = ['Grid', 'Card', 'Pie Chart', 'Line Chart', 'Bar Chart']
    const handleSelectViewOption = (item) => {
        setSelectedViewOption(item)
    }
    const handleSaveVisual = (param) => 
    {
        console.log(tempLayout[index]) 
        // return
        const height = item.visual?.height !== undefined ? item.visual.height : 14;
        const width = item.visual?.width !== undefined ? item.visual.width : 5;
    
        param.height = tempLayout[index] ? tempLayout[index].h : height;
        param.width = tempLayout[index] ? tempLayout[index].w :width;
        param.x = tempLayout[index] ? tempLayout[index].x :item.x
        param.y = tempLayout[index] ? tempLayout[index].y :item.y
        item.visual = param;
        item.name = newDashboardItemName
        
        if(selectedDashboardTemplate.personalDashboards) updateVisualPersonalDashboard(selectedDashboardTemplate);
        else if (selectedDashboardTemplate.userDashboard) updateVisualUserDashboard(selectedDashboardTemplate);
        else registerDashboardTemplate(selectedDashboardTemplate);

        handleSetItemIndexToEdit(-1);
        toggleFullScreen();
        handleNewUpdate();
    }
    const handleCloseEditVisual = () => {
        toggleFullScreen();
        handleSetItemIndexToEdit(-1);
    }
    return (
        <div className='dashboardSettingContainer'>
            <h3>Dashboard Visual</h3>
            <div className='templateVisualContainer'>
                {viewOption.map((item) => (
                    <div 
                        className='templateVisualItem' 
                        style={selectedViewOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                        onClick={() => handleSelectViewOption(item)}
                    >{item}</div>
                ))}
            </div>
            <div className='templateGraphContainer'>
                {selectedViewOption === 'Grid' &&
                    <VisualGridView
                        gridHeader={gridHeader}
                        sqlresult={sqlresult}
                        theme={theme}
                        treeViewData={treeViewData}
                        isWithChild={isWithChild}
                        isShowGrid={isShowGrid}
                        toggleAll={toggleAll}
                        isShowGrap={isShowGrap}
                        handleSaveVisual={handleSaveVisual}
                        selectedViewOption={selectedViewOption}
                        onEditName={onEditName}
                        handleCloseEditVisual={handleCloseEditVisual}
                    />
                }
                {selectedViewOption === 'Card' &&
                    <VisualCardView
                        gridHeader={gridHeader}
                        sqlresult={sqlresult}
                        theme={theme}
                        treeViewData={treeViewData}
                        isWithChild={isWithChild}
                        isShowGrid={isShowGrid}
                        toggleAll={toggleAll}
                        isShowGrap={isShowGrap}
                        handleSaveVisual={handleSaveVisual}
                        templateItemVisual={templateItemVisual}
                        headerToMultiSelect={headerToMultiSelect}
                        selectedViewOption={selectedViewOption}
                        onEditName={onEditName}
                        handleCloseEditVisual={handleCloseEditVisual}
                    />
                }
                {selectedViewOption === 'Bar Chart' &&
                    <VisualGraphView
                        gridHeader={gridHeader}
                        selectedViewOption={selectedViewOption}
                        sqlresult={sqlresult}
                        dataValue={dataValue}
                        headerToMultiSelect={headerToMultiSelect}
                        handleSaveVisual={handleSaveVisual}
                        templateItemVisual={templateItemVisual}
                        onEditName={onEditName}
                        handleCloseEditVisual={handleCloseEditVisual}
                    />
                }
                {selectedViewOption === 'Line Chart' &&
                    <VisualGraphView
                        gridHeader={gridHeader}
                        selectedViewOption={selectedViewOption}
                        sqlresult={sqlresult}
                        dataValue={dataValue}
                        headerToMultiSelect={headerToMultiSelect}
                        handleSaveVisual={handleSaveVisual}
                        templateItemVisual={templateItemVisual}
                        onEditName={onEditName}
                        handleCloseEditVisual={handleCloseEditVisual}
                    />
                }
                {selectedViewOption === 'Pie Chart' &&
                    <VisualPieChartView
                        gridHeader={gridHeader}
                        selectedViewOption={selectedViewOption}
                        sqlresult={sqlresult}
                        headerToMultiSelect={headerToMultiSelect}
                        handleSaveVisual={handleSaveVisual}
                        templateItemVisual={templateItemVisual}
                        onEditName={onEditName}
                        handleCloseEditVisual={handleCloseEditVisual}
                    />
                }

            </div>
        </div>
    )
}

export default DashboardVisual