import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  CardImg,
} from "reactstrap";
import { Route, useHistory, useLocation } from "react-router";
import Switch from "react-bootstrap/esm/Switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

import "../login.css";

// core components
import BlockUI from "components/Misc/BlockUI";
import AdminFooter from "components/Footers/MainFooter";
import { PasswordLabel } from "customComponents/customComponents";
import { CustomInputFields } from "customComponents/customComponents";

const ResetPassword = () => {
  // const { firmDetails } = useContext(MainContext)
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  // eslint-disable-next-line
  const [user, setUser] = useState({});
  let [error, setError] = useState("");
  let [alert, setAlert] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  // eslint-disable-next-line
  const [displayLocation, setDisplayLocation] = useState("");
  const [transitionStage, setTransistionStage] = useState("fadeFrom");
  const [valid, setValid] = useState({
    validChar: false,
    validLength: false,
  });
  const [value, setValue] = useState({
    repassword: "",
    password: "",
  });

  const length = new RegExp("(?=.{8,}$).*$");
  const char = new RegExp(
    "^(?=(.*?[A-Z]){1,})(?=(.*[a-z]){1,})(?=(.*?[0-9]){1,})(?=(.*?[!@#$%^&*]){1,}).*$"
  );

  let testCharacter = char.test(value.password);
  let testLength = length.test(value.password);

  useEffect(() => {
    if (routerLocation === displayLocation) setTransistionStage("fadeTo");
  }, [routerLocation, displayLocation]);

  const handleChange = (e) => {
    e.preventDefault();

    const target = e.target;
    const name = target.name;
    const id = target.id;

    const newValue = Object.assign({}, value);

    newValue[name] = target.value;
    setValue(newValue);

    setError("");

    if (id === "Password") {
      setValid({
        validChar: false,
        validLength: false,
      });
      return setAlert(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!testLength && !testCharacter) {
      setAlert(true);
      return setValid({
        validChar: true,
        validLength: true,
      });
    }
    if (!testLength) {
      setAlert(true);
      return setValid({
        validLength: true,
      });
    }
    if (!testCharacter) {
      setAlert(true);
      return setValid({
        validChar: true,
      });
    }

    if (!testLength && !testCharacter && value.password !== value.repassword) {
      setError("Password did not match!");
      return setValid({
        validChar: true,
        validLength: true,
      });
    }

    if (value.password !== value.repassword)
      return setError("Password did not match!");

    setShowBlockUI(true);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("access_token");
    const bname = urlParams.get("bname");

    value.bname = bname;

    fetch(`/api/user/changePassword?access_token=${token}${bname ? `&bname=${bname}` : ""}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(value),
    })
      .then((response) => {
        // console.log(response)
        return response.json();
      })
      .then((result) => {
        setTimeout(() => {
          // console.log(result)

          if (result && result.error) {
            // console.log('Error', result.error)

            setShowBlockUI(false);
            return warningAlert(result);
          }
          if (result.success) {
            setUser(result);

            setValue({
              repassword: "",
              password: "",
            });
            setShowBlockUI(false);

            setTransistionStage("fadeTo");

            setTimeout(() => {
              routerHistory.push(
                // "/auth/setup-new-password/password-succesfully-changed"
                "/client"
              );
              // window.location.href =
              // '/auth/create-password/password-succesfully-changed'
              setTransistionStage("fadeFrom");
            }, 250);
          }
        }, 1300);
      })
      .catch((Err) => {
        setShowBlockUI(true);

        setTimeout(() => {
          console.log(Err);
          setShowBlockUI(false);
          warningAlert(
            "Something went wrong, Please contact your system administrator."
          );
        }, 1000);
      });
  };

  const warningAlert = (res) => {
    setIsInvalid(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setIsInvalid(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {res || res.error || res.message}
      </ReactBSAlert>
    );
  };

  const errorStyle = (res) => {
    let val = "";

    res === true
      ? (val = value.password !== "" && testCharacter && testLength)
      : (val =
        value.password === value.repassword &&
        value.password !== "" &&
        testCharacter &&
        testLength);

    return alert !== false || error !== ""
      ? { border: "1px solid #fb6340" }
      : {
        border: val ? "1px solid #2dce89" : "1px solid transparent",
      };
  };

  const passwordLabels = () => {
    if (value.password !== "" && testCharacter && testLength) return null;
    return (
      <div
        className="d-flex flex-column text-sm"
        style={{ transition: "ease-in-out .2s" }}
      >
        <PasswordLabel
          label="Password must contain uppercase, lowercase, numeric, and special characters"
          validType={valid.validChar === true}
          testType={testCharacter}
          password={value.password}
          repassword={value.repassword}
        />
        <PasswordLabel
          label="Password must be at least 8 characters long"
          validType={valid.validLength === true}
          testType={testLength}
          password={value.password}
          repassword={value.repassword}
        />
      </div>
    );
  };

  return (
    <>
      {isInvalid}
      {showBlockUI ? <BlockUI /> : null}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "#fff",
          display: "grid",
        }}
      >
        {/* <img
          className="fadeIn"
          src={require('assets/img/box.svg').default}
          alt="box"
          style={{
            position: 'absolute',
            width: '110%',
            height: '100%',
            bottom: -250,
            top: 0,
            opacity: 0.8,
          }}
        /> */}
        <Container className="align-items-center " style={{ display: "grid" }}>
          <Row className="justify-content-center">
            <Col
              lg="6"
              md="8"
              sm="12"
              xs="12"
              className={`${transitionStage} mt-2 px-0 px-lg-3`}
            >
              <Switch className="pl-0">
                <Route
                  path="/auth/setup-new-password"
                  exact
                  render={() => (
                    <Card className=" bg-transparent shadow-none p-3 mb-0 py-3 px-lg-5 mx-2 ">
                      <CardImg
                        alt="Login"
                        src={"/api/accountingFirm/getThemeLogo"}
                        style={{ width: "60px" }}
                        className=" justify-content-center text-center rounded mx-auto"
                        top
                      />
                      <CardHeader className="px-0 pb-0 bg-transparent">
                        <h1 className="h2 text-center text-normal">
                          Create new password
                        </h1>
                      </CardHeader>
                      <CardBody className="pt-2 p-0">
                        <Form role="form" onSubmit={handleSubmit}>
                          <CustomInputFields
                            type={!showPassword ? "password" : "text"}
                            onClick={() => setShowPassword(!showPassword)}
                            placeholder="Enter your new password here"
                            className={`${value.password === value.repassword &&
                              value.password !== "" &&
                              testCharacter &&
                              testLength
                              ? "fas fa-check text-success "
                              : "fas fa-unlock-alt"
                              }`}
                            htmlFor="Password"
                            label="Password"
                            id="Password"
                            name="password"
                            value={value.password}
                            onChange={handleChange}
                            showPassword={showPassword}
                            showPasswordIcon={true}
                            alert={alert}
                            error={error}
                            // required={true}
                            style={errorStyle(true)}
                          />

                          <CustomInputFields
                            onClick={() => setShowRePassword(!showRePassword)}
                            type={!showRePassword ? "password" : "text"}
                            placeholder="Re-enter your password here"
                            showPassword={showRePassword}
                            alert={alert}
                            error={error}
                            className={`${value.password === value.repassword &&
                              value.password !== "" &&
                              testCharacter &&
                              testLength
                              ? "fas fa-check text-success "
                              : "fas fa-lock"
                              }`}
                            label="Confirm Password"
                            value={value.repassword}
                            showPasswordIcon={true}
                            htmlFor="RePassword"
                            id="RePassword"
                            name="repassword"
                            onChange={handleChange}
                            // required={true}
                            style={errorStyle(false)}
                          />

                          {error !== "" ? (
                            <Row className=" text-sm">
                              <Col
                                xs="1"
                                style={{
                                  fontSize: "12px ",
                                  marginTop: "5px",
                                }}
                              >
                                <i className="text-warning mx-1 fas fa-times " />
                              </Col>
                              <Col lg="10" xs="10">
                                <span
                                  className="text-warning font-weight-600"
                                  style={{ fontSize: "12px" }}
                                >
                                  {error}
                                </span>
                              </Col>
                            </Row>
                          ) : null}

                          {passwordLabels()}
                          <div className="text-center mt-4">
                            <Button
                              className="mt-3 bg-gradient-primary"
                              color="primary"
                              type="submit"
                              block
                              disabled={
                                value.password === "" || value.repassword === ""
                                  ? true
                                  : false
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  )}
                />
                <Route
                  path="/auth/setup-new-password/password-succesfully-changed"
                  render={() => (
                    <Card
                      className="bg-transparent p-sm-2 py-3 p-lg-4 mx-3 shadow-none"
                      style={{ overflow: "hidden" }}
                    >
                      <CardImg
                        alt="Login"
                        src={
                          require("../../../../assets/img/success-36.svg")
                            .default
                        }
                        style={{ width: "80px", margin: "0 auto" }}
                        className="spin justify-content-center text-center rounded my-3"
                        top
                      />
                      <CardHeader className="bg-transparent text-center border-0 py-2">
                        <h1 className="h1" style={{ color: "#272A65" }}>
                          Password setup completed!
                        </h1>
                      </CardHeader>
                      <CardHeader className="bg-transparent text-center border-0 px-xl-4 px-2  mt--2 text-sm-sm">
                        You have successfully setup your account password, click
                        <span
                          className="text-primary ml-2 text-underline"
                          style={{ cursor: "pointer" }}
                          onClick={() => (window.location.href = "/auth/login")}
                        >
                          here
                        </span>{" "}
                        to login on your account in the CMS.
                      </CardHeader>
                    </Card>
                  )}
                />
              </Switch>
              {window.location.pathname !==
                ("/auth/setup-new-password" &&
                  "/auth/setup-new-password/password-succesfully-changed") ? (
                <Row className="w-xs-100 mx-auto mt-3 px-5 spin">
                  <AdminFooter />
                </Row>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;
