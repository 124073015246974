import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';

function DashboardTemplateItemCardView({index, sqlresult, templateItemVisual, layouts, onFullScreenOnly}) {
    const {
        bgColor,
        textColor,
    } = useContext(MainContext);
    const [cardItem, setCardItem] = useState([])
    const [cardWidth, setCardWidth] = useState(null)
    const [fontSizeFactor, setFontSizeFactor] = useState(null)

    useEffect(() => {
        const { cardItem, headerToMultiSelect } = templateItemVisual
        if(layouts !== undefined) setFontSizeFactor(layouts.lg[index].w * 7)
        else setFontSizeFactor(60)

        if(cardItem && cardItem.length > 0)
        {
            loadCards(cardItem, headerToMultiSelect)
            const getPercentage = 100 / cardItem.length
            const cardWidth = getPercentage > 20 ? getPercentage : 20

            setCardWidth(cardWidth)
        }
        
    })
    const loadCards = (cardItem, headerToMultiSelect) => {
        cardItem.forEach((card) => {
            let totalAmount = 0;
            let isPercentage = false;
            let percentageSum = 0;
            let percentageCount = 0;
    
            const processItem = (item) => {
                const res = headerToMultiSelect.filter((e) => e.name === card.columnToSum);
                if (res.length > 0) {
                    const value = item[res[0].id];
                    if (typeof value === 'string' && value.includes('%')) {
                        isPercentage = true;
                        const numericValue = Number(parseFloat(value.replace('%', '')));
                        if (!isNaN(numericValue)) {
                            percentageSum += numericValue;
                            percentageCount++;
                        }
                    } else if (typeof value === 'number') {
                        totalAmount += value;
                    }
                }
            };
    
            if (card.columnItem !== "") {
                sqlresult.forEach((item) => {
                    if (item[card.id] === card.columnItem) {
                        processItem(item);
                    }
                });
            } else {
                sqlresult.forEach((item) => {
                    processItem(item);
                });
            }
    
            if (isPercentage && percentageCount > 0) {
                card.sum = (percentageSum / percentageCount).toFixed(1) + '%';
            } else {
                card.sum = totalAmount.toFixed(2);
            }
        });

        setCardItem(cardItem);
    }
    const formatAmount = (number) => {
        const isNegative = number < 0;
        const absNumber = Math.abs(number);
      
         if (isNaN(number) && !number.includes("%")) return 0;

        if(typeof number === 'string' && number.includes("%")) return number

        if (absNumber >= 1e9) {
          return isNegative ? "-" + (absNumber / 1e9).toFixed(1) + "B" : (absNumber / 1e9).toFixed(1) + "B";
        } else if (absNumber >= 1e6) {
          return isNegative ? "-" + (absNumber / 1e6).toFixed(1) + "M" : (absNumber / 1e6).toFixed(1) + "M";
        } else if (absNumber >= 1e3) {
          return isNegative ? "-" + Math.round(absNumber / 1e3) + "K" : Math.round(absNumber / 1e3) + "K";
        } else {
          return Math.round(absNumber);
        }
    };
    const titleSize = (fontSizeFactor) => {
        return fontSizeFactor / 3  >= 20 ? 20 : fontSizeFactor / 3 <= 15 ? 15 : fontSizeFactor / 3
    }
    return (
        <div className='cardContent'>
            {cardItem.map((card, index) => (
                <div
                    key={index}
                    style={{ width: `calc(${cardWidth}% - 0px)`, padding: '0px 5px', minWidth: 170, minHeight: 150, marginBottom: 10, maxWidth: 400 }}
                >
                    <div className='cardContainer'>
                        <div className='cardAmount' style={{ fontSize: fontSizeFactor > 100 ? 100 : fontSizeFactor < 50 ? 50 : fontSizeFactor }}>
                            {formatAmount(card.sum)}
                        </div>
                        <div className='cardTitle' style={{ fontSize: titleSize(fontSizeFactor) }}>
                            {card.columnItem !== '' ? card.columnItem : card.column}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default DashboardTemplateItemCardView