import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  CardHeader,
  Container,
  Spinner,
  Button,
  UncontrolledTooltip,
  TabContent,
  Nav,
  Row,
  Col,
  CardBody,
  Input,
  Card,
  ButtonGroup,
} from "reactstrap";
// react component used to create sweet alerts
import moment from "moment";
import classnames from "classnames";
// import { BeatLoader } from 'react-spinners'
import { Route, Switch } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useFullScreenHandle } from "react-full-screen";

import { MainContext } from "context/mainContext";
import ClientAccountantCard from "./clientReportsCard";
import ClientsInfo from "../../../components/ClientInfo/ClientInfo";
import CustomClientReports from "customComponents/customClientReports";
// import { Route, Switch } from "react-router";
import { useHistory } from "react-router";
import CreateModal from "./createModal";
import CustomTab from "customComponents/customTab";
import CustomHeader from "customComponents/customHeader";
import { CustomList } from "customComponents/customThirdParty";
import avatar from "../../../assets/img/clients/blank-avatar.png";
import BlockUI from "components/Misc/BlockUI";
import { CustomModalButton } from "components/Button/CustomButton";

import "./style.css";
import CustomSpinner from "components/Misc/CustomSpinner";
import DuplicateReportModal from "./duplicateReportModal";
import axios from "axios";
import { uploadBlob, pbixPushToQueue } from "service/AzureBlobService";
import PowerBITable from "components/Table/PowerBITable";
import DashboardModal from "./DashboardModal";
import PinToDashboard from "components/PinToDashboard/PinToDashboard";
import { deletePersonalDashboardBIByReport } from "services/mongoDB";
import { deleteGroupDashboardBIByReport } from "services/mongoDB";
import { deleteUserDashboardBIByReport } from "services/mongoDB";
import { generateUUID } from "services/EVAResponseService";
import { registerGroupNotification } from "services/mongoDB";
import SendMultiDeviceNotification from "services/NotificationService";
import SendExpoNotifications from "services/ExpoNotificationService";
import { mongoFetchToken } from "services/mongoDB";
// import socket from "services/MainSocket";


const Reports = () => {
  const history = useHistory();
  const handle = useFullScreenHandle();
  const [startDate, setStartDate] = useState(new Date());
  const {
    textColor,
    bgColor,
    selectedClientID,
    setSelectedClientID,
    userDetails,
    firmDetails
  } = useContext(MainContext);
  const [selectedClient, setSelectedClient] = useState({});
  const [subscribed, setsubscribed] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [active, setActive] = useState(false)
  const [clients, setClients] = useState([]);
  const [alert, setAlert] = useState(false);
  const [color, setColor] = useState(false);
  const [height, setHeight] = useState(0);
  // eslint-disable-next-line
  const [access, setAccess] = useState(7);
  // eslint-disable-next-line
  const [width, setWidth] = useState(0);
  const [user, setUser] = useState([]);
  const [dashboardsReportsData, setDashboardsReportsData] = useState([])
  const [filterType, setFilterType] = useState("all")
  const [tabs, setTabs] = useState(1);
  const [showPbixFileUpload, setShowPbixFileUpload] = useState(false);
  const [pbixFileName, setPbixFileName] = useState("");
  const [pbixFile, setPbixFile] = useState(null)
  const fileRef = React.useRef(null);
  const [nameValid, setNameValid] = useState(true)
  const [fileValid, setFileValid] = useState(true)
  const [reportsNameList, setReportsNameList] = useState([])
  // eslint-disable-next-line
  const [mainURL, setMainURL] = useState("");

  const [showBlockUI, setShowBlockUI] = useState(false);

  const [embeddedComponent, setEmbeddedComponent] = useState({});
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [dataToPin, setDataToPin] = useState(null)

  const [pageSelectionModal, setPageSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
    selected: [],
  });
  const [reportPageSelectionModal, setReportPageSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
    selected: "",
  });
  const [schedulerModal, setSchedulerModal] = useState({
    show: false,
    record: {
      Scheduled: true,
    },
  });
  const [singleUserSelectionModal, setSingleUserSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
  });
  const [reportGenerator, setReportGenarator] = useState({
    show: false,
    record: {},
    type: "View",
    config: {},
  });
  const [settingsModal, setSettingsModal] = useState({
    show: false,
    record: {},
  });
  const [dashboardSettingsModal, setDashboardSettingsModal] = useState({
    show: false,
    record: {},
  });
  const [userSelectionModal, setUserSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    report: {},
    selected: [],
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [oldReportDetail, setOldReportDetail] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [exportingPdf, setExportingPdf] = useState(false);
  const [exportingPdfStatus, setExportingPdfStatus] = useState('');
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const [clientUsers, setClientUsers] = useState();
  const [reportName, setReportName] = useState("")
  const MAX_SELECTED_PAGE_EXPORT_PDF = 10;
  // const [createModal, setCreateModal] = useState({
  //   show: false,
  //   config: {},
  // })
  // const [modal, setModal] = useState({
  //   title: 'Upload Report',
  //   show: false,
  //   record: {},
  //   action: 'create',
  // })

  const maxExportPdfWarning = () => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => {
          setAlert(null)
        }}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        <p>
          You can only select maximum of {MAX_SELECTED_PAGE_EXPORT_PDF} pages<br /> to export to PDF format.
        </p>
      </ReactBSAlert>
    );
  }

  const fetchAPIs = async () => {
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    // eslint-disable-next-line
    let error = "";
    let reports = [];
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    let firstClient = [];
    let allClients = [];
    let url = window.location.pathname.split("/");

    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')

    setReportGenarator({
      show: false,
      record: {},
      type: "View",
      config: {},
    })

    // console.log(window.location.pathname)
    if (userDetails && userDetails.User && userDetails.User.UserAccess === 1) {
      try {
        const getAllRecords = await fetch(`/api/client/getAll`)
        const allClientRecords = await getAllRecords.json()
        firstClient = allClientRecords[0]
        let allDatas = []

        if (allClientRecords && firstClient) {
          const getClientDetails = await fetch(`/api/client/showDetails/${sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]}`)
          const clientDetails = await getClientDetails.json()
          // console.log("showDetails", clientDetails)
          if (clientDetails && !clientDetails.error) {
            if (clientDetails.message === "Client not found") {
              // setClientID(firstClient.ClientID);
              setSelectedClientID(firstClient.ClientID);
              return history.push(`/auth/page-not-found`);
            }
            setSelectedClient(clientDetails);
            getClientUsers(clientDetails)
            // if (clientDetails.Details.ShowPowerBIPage) {
            const getPowerBIReports = await fetch(`/api/customReport/getPowerBIReports/${clientDetails.id}`)
            const powerBIReports = await getPowerBIReports.json()
            // console.log(powerBIReports)
            if (powerBIReports.error) {
              if (
                powerBIReports.error ===
                "There is no PowerBI Application Workspace for this client yet. Please create one in 3rd Party Applications tab."
              )
                error = powerBIReports.error;
              setLoading(false);
              return warningAlert(powerBIReports);
            }

            powerBIReports.subscribed = subscribed;

            let reportNames = []
            powerBIReports.data.map(rep => {
              reportNames.push(rep.Name.split(" ").join("%20"))
            })

            setReportsNameList(reportNames);
            setsubscribed(subscribed);

            const getPowerBIDashboards = await fetch(`/api/customReport/getPowerBIDashboards/${clientDetails.id}`)
            const powerBIDashboards = await getPowerBIDashboards.json()
            // console.log(powerBIDashboards)
            if (!powerBIReports.error && !powerBIDashboards.error) {
              // console.log([...powerBIDashboards.data, ...powerBIReports.data])
              allDatas = [...powerBIDashboards.data, ...powerBIReports.data]
              setUser([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setDashboardsReportsData([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setFilterData([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setLoading(false)
              let selectedReportIDFromLocal = localStorage.getItem("selectedReportID") ?? null
              if (selectedReportIDFromLocal) {
                // console.log(selectedReportIDFromLocal)
                let foundData = allDatas.filter(dr => dr.ReportID === selectedReportIDFromLocal)
                // console.log(foundData[0])
                if (foundData.Type === 1) {
                  openViewReport(foundData[0])
                }
                if (foundData.Type === 2) {
                  openViewDashboard(foundData[0])
                }
              }
            }
            // } else {
            //   setLoading(false)
            // }
          }
        }

      } catch (e) {
        console.log(e);
        warningAlert(e);
      }
    } else {
      try {
        const getAllRecords = await fetch(`/api/client/getAllRecords`)
        const allClientRecords = await getAllRecords.json()
        // console.log('allClientRecords', allClientRecords)
        firstClient = allClientRecords[0]
        let allDatas = []

        if (allClientRecords && firstClient) {
          const getClientDetails = await fetch(`/api/client/showDetails/${sessionSelectedClientID ? sessionSelectedClientID : url.length < 4 ? firstClient.ClientID : url[url.length - 1]}`)
          const clientDetails = await getClientDetails.json()
          // console.log(clientDetails)
          if (clientDetails && !clientDetails.error) {
            if (clientDetails.message === "Client not found") {
              // setClientID(firstClient.ClientID);
              setSelectedClientID(firstClient.ClientID);
              return history.push(`/auth/page-not-found`);
            }
            setSelectedClient(clientDetails);
            getClientUsers(clientDetails)
            // if (clientDetails.Details.ShowPowerBIPage) {
            const getPowerBIReports = await fetch(`/api/customReport/getPowerBIReports/${clientDetails.id}`)
            const powerBIReports = await getPowerBIReports.json()
            // console.log(powerBIReports)
            if (powerBIReports.error) {
              if (
                powerBIReports.error ===
                "There is no PowerBI Application Workspace for this client yet. Please create one in 3rd Party Applications tab."
              )
                error = powerBIReports.error;
              setLoading(false);
              return warningAlert(powerBIReports);
            }

            powerBIReports.subscribed = subscribed;

            let reportNames = []
            powerBIReports.data.map(rep => {
              reportNames.push(rep.Name.split(" ").join("%20"))
            })

            setReportsNameList(reportNames);
            setsubscribed(subscribed);

            const getPowerBIDashboards = await fetch(`/api/customReport/getPowerBIDashboards/${clientDetails.id}`)
            const powerBIDashboards = await getPowerBIDashboards.json()
            // console.log(powerBIDashboards)
            if (!powerBIReports.error && !powerBIDashboards.error) {
              // console.log([...powerBIDashboards.data, ...powerBIReports.data])
              allDatas = [...powerBIDashboards.data, ...powerBIReports.data]
              setUser([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setDashboardsReportsData([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setFilterData([...powerBIDashboards.data, ...powerBIReports.data].sort((a, b) => a.Name.localeCompare(b.Name)))
              setLoading(false)
              let selectedReportIDFromLocal = localStorage.getItem("selectedReportID") ?? null
              if (selectedReportIDFromLocal) {
                // console.log(selectedReportIDFromLocal)
                let foundData = allDatas.filter(dr => dr.ReportID === selectedReportIDFromLocal)
                // console.log(foundData[0])
                if (foundData[0].Type === 1) {
                  openViewReport(foundData[0])
                }
                if (foundData[0].Type === 2) {
                  openViewDashboard(foundData[0])
                }
              }
            }
            // } else {
            //   setLoading(false)
            // }
          }
        }
      } catch (e) {
        console.log(e);
        warningAlert(e);
      }
    }
  };

  useEffect(() => {
    // console.log('selectedClientID', selectedClientID)
    if (selectedClientID) {
      history.push(`/client/reports/${selectedClientID}`);
    }
    setLoading(true)
    let isMounted = true;

    if (isMounted) fetchAPIs();

    //eslint-disable-next-line
    return () => {
      isMounted = false;
    }
  }, [userDetails, selectedClientID]); //Edited by Joef: Removed settingsModal. This causes the page to refresh everytime settings modal is being opened

  const getClientUsers = async (clientDetails) => {
    if (clientDetails.id === undefined) return
    fetch(
      `/api/user/getUsersAndAllAccountantsByClientID/${clientDetails.id}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false);
        if (response.error) return warningAlert(response);
        // console.log(response.data)
        setClientUsers(response.data)
      });
  }

  const reportsList = (id) => {
    if (id === 1) {
      if (filterData.length === 0) {
        if (loading) return <CustomSpinner />;

        return (
          <div className="col-sm-12 mt-3 text-center">
            <h5> No Dashboard/Reports found.</h5>
          </div>
        );
      } else {
        return (
          <Col>
            <PowerBITable
              data={filterData}
              access={access}
              submitButton={submitButton}
              submitDashboardButton={submitDashboardButton}
              bgColor={bgColor}
              textColor={textColor}
              handleDelete={confirmDelete}
              openViewReport={openViewReport}
              showTrafficLight={showTrafficLight}
              openReportUpdate={openReportUpdate}
              openSettingsModal={openSettingsModal}
              openReportPageSelection={openReportPageSelection}
              openSingleUserSelectionModal={openSingleUserSelectionModal}
              userDetails={userDetails}
              deletePBIReport={confirmDeletePBIReport}
              pinToDashboard={handlePinToDashboard}
              clonePBIReport={openDuplicateModal}
              downloadPbixReport={downloadPbixReport}
              openViewDashboard={openViewDashboard}
              handleShowDashboardSettings={handleShowDashboardSettings}
              addToFavorite={addToFavorite}
              removeToFavorite={removeToFavorite}
            />
          </Col>
        )
        return filterData.map((data, i) => reportsCard(data, i));
      }
    } else if (id === 2) {
      //Edited by Joef: Scheduled PDF tabs fix
      let filter = filterData.filter(
        (e) => Object.keys(e.Details).indexOf("Schedule") > 0
      );
      if (filter.length === 0) {
        if (loading) return <CustomSpinner />;

        return (
          <div className="col-sm-12 mt-3 text-center">
            <h5>No Scheduled PDF found.</h5>
          </div>
        );
      } else {
        return filterData
          .filter((e) => Object.keys(e.Details).indexOf("Schedule") > 0)
          .map((data, i) => reportsCard(data, i));
      }
    }
  };

  const handleFilteredBy = (type) => {
    let sorted = []
    if (type === "all") {
      sorted = dashboardsReportsData.sort((a, b) => a.Name.localeCompare(b.Name));
    } else {
      const filterType = type === 'report' ? 1 : 2
      const filtered = user.filter(f => f.Type === filterType)
      // console.log(filtered)
      sorted = filtered.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    setFilterType(type)
    setFilterData(sorted)
  }

  const reportsCard = (data, i) => (
    <React.Fragment key={i}>
      <ClientAccountantCard
        i={i}
        data={data}
        access={access}
        bgColor={bgColor}
        textColor={textColor}
        submitButton={submitButton}
        handleDelete={confirmDelete}
        openViewReport={openViewReport}
        showTrafficLight={showTrafficLight}
        openReportUpdate={openReportUpdate}
        openSettingsModal={openSettingsModal}
        openReportPageSelection={openReportPageSelection}
        openSingleUserSelectionModal={openSingleUserSelectionModal}
        userDetails={userDetails}
        deletePBIReport={confirmDeletePBIReport}
        clonePBIReport={openDuplicateModal}
        downloadPbixReport={downloadPbixReport}
      />
    </React.Fragment>
  );

  const downloadPbixReport = (record) => {
    // console.log(record)
    setShowBlockUI(true)
    fetch(`/api/customReport/downloadPbixFile`)
      .then((res) => res.blob().then((pbix) => {
        let url = window.URL.createObjectURL(pbix);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${record.Name}.pbix`;
        a.click();
      }))
      .then((data) => {
        setShowBlockUI(false)
        // console.log(data)
      })
      .catch(e => {
        setShowBlockUI(false)
        console.log(e)
        warningAlert({ message: e })
      })
  }

  const userSelectList = () => {
    //let loading = this.state.loading;
    let data = userSelectionModal.filteredData;

    if (data.length === 0) {
      if (loading) return <CustomSpinner />;
      return (
        <div className="col-sm-12 mt-4 text-center">
          <h5>No users found.</h5>
        </div>
      );
    } else {
      return data.map((obj, i) => {
        const index = userSelectionModal.selected.indexOf(obj.id);
        return (
          <CustomList
            i={i}
            key={i}
            src={obj.Avatar || avatar}
            onClick={() => selectUser(obj)}
            name={obj.FirstName + " " + obj.LastName}
            span={<span> {obj.UserAccess <= 4 ? "Accountant" : "Client"}</span>}
            icon={
              <i
                className="fas fa-check-circle mt-2"
                style={{
                  color: index > -1 ? "green" : "gray",
                  fontSize: "20px",
                  marginTop: "5px",
                }}
              />
            }
          />
        );
      });
    }
  };
  const pageSelectList = () => {
    let data = pageSelectionModal.filteredData;

    if (data.length === 0) {
      if (loading)
        return (
          <Container className="text-center">
            <Spinner />
          </Container>
        );
      return (
        <div className="col-sm-12 mt-4 text-center">
          <h5>No page found.</h5>
        </div>
      );
    } else {
      return data.map((obj, i) => {
        const index = pageSelectionModal.selected.indexOf(obj.Name);
        return (
          <CustomList
            i={i}
            key={i}
            src={obj.Avatar || avatar}
            onClick={() => selectPage(obj)}
            name={obj.displayName}
            span={<span> {obj.UserAccess <= 4 ? "Accountant" : "Client"}</span>}
            icon={
              <i
                className="fas fa-check-circle mt-2"
                style={{
                  color: index > -1 ? "green" : "gray",
                  fontSize: "20px",
                  marginTop: "5px",
                }}
              />
            }
          />
        );
      });
    }
  };
  const reportPageSelectList = () => {
    let data = reportPageSelectionModal.filteredData;

    if (data.length === 0) {
      if (loading)
        return (
          <Container className="text-center">
            <Spinner />
          </Container>
        );
      return (
        <div className="col-sm-12 mt-4 text-center">
          <h5>No page found.</h5>
        </div>
      );
    } else {
      return data.map((obj, i) => {
        let selected = reportPageSelectionModal.selected;
        return (
          <CustomList
            i={i}
            key={i}
            src={obj.Avatar || avatar}
            onClick={() => {
              setReportPageSelectionModal({
                show: reportPageSelectionModal.show,
                data: reportPageSelectionModal.data,
                filteredData: reportPageSelectionModal.filteredData,
                report: reportPageSelectionModal.report,
                selected: obj.Name,
              });
              // console.log(reportPageSelectionModal);
            }}
            name={obj.displayName}
            span={null}
            icon={
              <i
                className="fas fa-check-circle mt-2"
                style={{
                  color: selected === obj.Name ? "green" : "gray",
                  fontSize: "20px",
                  marginTop: "5px",
                }}
              />
            }
          />
        );
      });
    }
  };

  // const singleUserSelectList = () => {
  //   let data = singleUserSelectionModal.filteredData;

  //   if (data.length === 0) {
  //     if (loading)
  //       return (
  //         <Container className="text-center">
  //           <Spinner />
  //         </Container>
  //       );
  //     return (
  //       <div className="col-sm-12 mt-4 text-center">
  //         <h5>No users found.</h5>
  //       </div>
  //     );
  //   } else {
  //     return data.map((obj, i) => {
  //       return (
  //         <CustomList
  //           i={i}
  //           key={i}
  //           src={obj.Avatar || avatar}
  //           onClick={() => openViewReport(singleUserSelectionModal.report, obj)}
  //           name={obj.FirstName + " " + obj.LastName}
  //           span={<span> {obj.UserAccess <= 4 ? "Accountant" : "Client"}</span>}
  //           icon={null}
  //         />
  //       );
  //     });
  //   }
  // };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const handleSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();
    const filtered = user.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setFilterData(filtered);
  };

  const handlePageSearch = (event) => {
    event.preventDefault();
    let filterString = event.target.value.toLowerCase();

    const filtered = pageSelectionModal.data.filter(function (obj) {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setPageSelectionModal({
      show: pageSelectionModal.show,
      data: pageSelectionModal.data,
      filteredData: filtered,
      report: pageSelectionModal.report,
      selected: pageSelectionModal.selected,
    });
  };
  const handleReportPageSearch = (event) => {
    event.preventDefault();
    let filterString = event.target.value.toLowerCase();

    const filtered = reportPageSelectionModal.data.filter(function (obj) {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setReportPageSelectionModal({
      show: reportPageSelectionModal.show,
      data: reportPageSelectionModal.data,
      filteredData: filtered,
      report: reportPageSelectionModal.report,
      selected: reportPageSelectionModal.sele,
    });
  };
  const handleUserSearch = (event) => {
    event.preventDefault();
    let filterString = event.target.value.toLowerCase();

    const filtered = userSelectionModal.data.filter(function (obj) {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setUserSelectionModal({
      show: userSelectionModal.show,
      data: userSelectionModal.data,
      filteredData: filtered,
      report: userSelectionModal.report,
      selected: userSelectionModal.selected,
    });
  };

  const handleDelete = (record) => {
    reportGenerator.show = false;

    setReportGenarator(reportGenerator);

    let group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
    let report = record.ReportID;

    fetch(`/api/customReport/deleteRecord?group=${group}&report=${report}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);
        for (let i = 0; i < user.length; i++) {
          if (user[i].ReportID === report) {
            user.splice(i, 1);
            break;
          }
        }
        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].ReportID === report) {
            filterData.splice(i, 1);
            break;
          }
        }
        // console.log(record.id);
        setUser(user);
        setFilterData(filterData);
        // console.log(filterData);
        successAlert(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDelete = (data) => {
    // setAlert(
    //   <ReactBSAlert
    //     warning
    //     title="Warning"
    //     onConfirm={() => handleDelete(data)}
    //     onCancel={() => setAlert(null)}
    //     confirmBtnBsStyle="warning"
    //     confirmBtnText="Ok"
    //     showCancel={true}
    //     btnSize=""
    //   >
    //     <p>
    //       Are you sure you want to delete report:
    //       <br />
    //       <strong className="text-warning">{`${data.Name}`}</strong>?
    //     </p>
    //   </ReactBSAlert>
    // );
  };
  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message || response.error || response}
      </ReactBSAlert>
    );
  };
  const warning = (error) => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {error.message || error}
      </ReactBSAlert>
    );
  };
  const error = (error) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="Error"
        confirmBtnText="Ok"
        btnSize=""
      >
        {error.message || error}
      </ReactBSAlert>
    );
  };

  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message || response.error}
      </ReactBSAlert>
    );
  };

  // const closeModal = () => {
  //   modal.show = false;
  //   setModal(modal);
  // };
  // const closeCreateModal = () => {
  //   createModal.show = false;
  //   setCreateModal(createModal);
  // };

  const closeSettingsModal = () => {
    userSelectionModal.selected = [];
    settingsModal.show = false;
    // settingsModal.record = [];
    setSettingsModal({
      show: settingsModal.show,
      record: settingsModal.record,
    });

    setDashboardSettingsModal({
      show: settingsModal.show,
      record: settingsModal.record,
    })
    setUserSelectionModal(userSelectionModal);
  };

  const closeReportGenerator = () => {
    localStorage.removeItem('selectedReportID')
    localStorage.removeItem('clientOfSelectedReport')
    setReportGenarator({
      show: false,
      record: reportGenerator.record,
      config: reportGenerator.config,
      type: reportGenerator.type,
    });

  };

  const closeUserSelectionModal = () => {
    userSelectionModal.show = false;
    //userSelectionModal.selected = [];
    setUserSelectionModal({
      show: userSelectionModal.show,
      data: userSelectionModal.data,
      filteredData: userSelectionModal.filteredData,
      report: userSelectionModal.report,
      //selected: userSelectionModal.selected,
      selected: selectedUsers,
    });




    // console.log("SELECTEDUSERS", selectedUsers)
    // console.log("USERSELECTED", userSelectionModal.filteredData);
    // console.log("report", userSelectionModal);
    sendNotification(selectedUsers, clientUsers, `turned the ${userSelectionModal.report.Name} report available for viewing`)

    //return false;
    if (!userSelectionModal.settings)
      //Added by Joef: Shouldn't be called when coming from settings
      handleUpdate(
        "/api/customReport/updateLive?id=" + userSelectionModal.report.ReportID,
        4,
        { report: userSelectionModal.report, recipient: selectedUsers }
      );

  };

  const sendNotification = async (selectedUsers, userList, message) => 
  {
    let msg = ""
    let users = []
    for (const item of selectedUsers) {

      const user = userList.find(userInfo => userInfo.id === item);
      if (user && userDetails && user.Email !== userDetails.User.Email) {
        const createdBy = userDetails.User.FirstName + ' ' + userDetails.User.LastName;
        const notifications = {
          uid: await generateUUID(),
          id: selectedClientID,
          clientId: selectedClientID,
          message: `${createdBy} ${message}`,
          createdAt: new Date(),
          sender: userDetails.User.Email,
          isReport: true,
          email: user.Email,
          user: user.Email,
          url: '/client/reports/',
          groupId: selectedClientID,
        };
        users.push(user.Email)
        msg = notifications.message
        // await socket.emit("newClientNotification", notifications);
        registerGroupNotification(notifications)
      }
    }

    sendNotificationHandler(users, false, msg, selectedClient.Name)
  };

  const sendNotificationHandler = async (users, isRemoved, msg, groupName) => 
  {
    const ClientID = selectedClientID
    const notification = {
      title: `new notification from the "${groupName}" group`,
      body: msg,
      sound: 'default',
      badge: '1',
    };
    
    const clientToken = await mongoFetchToken({ clientid : selectedClientID, groupId: selectedClientID })
    if(!clientToken) return 
    
    const filteredTokens = clientToken.tokens
        .filter(token => users.includes(token.email.toLowerCase()))
        .map(token => token.token);

    if (filteredTokens.length === 0) return

    const message = {
      tokens: filteredTokens,
      data: { ClientID: ClientID, Name: selectedClient.Name,  type: 'report', Id: selectedClient.id},
      notification: notification,
    };

    SendMultiDeviceNotification(message)
    SendExpoNotifications(message)
};


  const closeSingleUserSelectionModal = () => {
    setSingleUserSelectionModal({
      show: false,
      data: singleUserSelectionModal.data,
      filteredData: singleUserSelectionModal.filteredData,
      report: singleUserSelectionModal.report,
    });
  };

  // const handleInputChange = (event) => {
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;

  //   modal.record[name] = value;

  //   setModal(modal);
  // };

  const deletePBIReport = (record) => {

    console.log(record)
    setShowBlockUI(true);
    let group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
    let report = record.ReportID;
    let requestURI = `/api/customReport/deletePowerBIReport?group=${group}&report=${report}`;

    // console.log(group)
    // console.log(report)

    fetch(requestURI, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setShowBlockUI(false);
        if (data.success) {
          successAlert('Report has been deleted successfully')
          let newFilteredReports = filterData.filter(rep => rep.ReportID !== record.ReportID)
          setUser(newFilteredReports)
          setFilterData(newFilteredReports)
          deletePersonalDashboardBIByReport({ 'reportID': record.ReportID })
          deleteGroupDashboardBIByReport({ 'reportID': record.ReportID })
          deleteUserDashboardBIByReport({ 'reportID': record.ReportID })
        }
        if (data.error) {
          error(data.error)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handlePinToDashboard = (data) => {
    console.log(data)
    setDataToPin(data)
    setIsPinModalOpen(!isPinModalOpen)
  }
  const confirmDeletePBIReport = (record) => {

    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Warning"
        onConfirm={() =>
          deletePBIReport(record)
        }
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Continue"
        btnSize=""
        showCancel={true}
      >
        {`Are you sure you want to delete ${record.Name}?`}
      </ReactBSAlert>
    );
  };

  const openViewReport = (record, user, isEditReport) => {
    // console.log("Called openViewReport", isEditReport);
    setShowBlockUI(true); //Added by Joef: All api calls should have ui block to avoid multi-press
    let message = "This report is not available in desktop view!";
    if (
      !(
        !record.hasOwnProperty("Details") ||
        !record.Details.hasOwnProperty("Settings") ||
        !record.Details.Settings.hasOwnProperty("Desktop") ||
        record.Details.Settings.Desktop
      )
    )
      return warning(message);

    let group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
    let report = record.ReportID;
    let role =
      record.Details &&
        record.Details.Settings &&
        record.Details.Settings.Role &&
        record.Details.Settings.RLS
        ? record.Details.Settings.Role
        : "";
    let dataset = record.Details.datasetId;

    let requestURI = `/api/customReport/generateEmbedToken?group=${group}&report=${report}&dataset=${dataset}&role=${role}`;
    // let requestURI = `/api/customReport/generateToken?group=${group}&report=${report}&dataset=${dataset}&role=${role}`; //Commented By Joef: RLS Fix

    // no backend api function

    if (user && user.Email) requestURI += `&username=${user.Email}`;

    fetch(requestURI, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false); //Added by Joef: All api calls should have ui block to avoid multi-press
        if (response.error) return warningAlert(response);

        // console.log("AfterFetch", response);


        // const models = window["powerbi-client"].models;
        // console.log(response.accessToken);
        const config = {
          type: "report",
          accessToken: response.accessToken,
          embedUrl: record.Details.embedUrl,
          id: record.ReportID,
          viewMode: isEditReport ? 1 : 0,
          permissions: isEditReport ? 1 : 0,
          //permissions: models.Permissions.Read,
          // permissions: models.Permissions.ReadWrite /*both save & save as buttons will be visible*/,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled:
              record.hasOwnProperty("Details") &&
              record.Details.hasOwnProperty("Settings") &&
              record.Details.Settings.hasOwnProperty("ShowTabs") &&
              record.Details.Settings.ShowTabs,
            //layoutType: models.LayoutType.MobilePortrait,
          },
        };

        if (role) config.tokenType = 1;
        if (record.Details && record.Details.DefaultPage)
          config.pageName = record.Details.DefaultPage;

        const reportGeneratorHolder = {};

        reportGeneratorHolder.show = true;
        reportGeneratorHolder.record = record;
        reportGeneratorHolder.config = config;
        reportGeneratorHolder.type = "View";

        if (user && user.Email) {
          // singleUserSelectionModalHolder.show = false;

          //Edited by Joef: For RLS Fix
          // singleUserSelectionModal.config = {
          //   type: config.type,
          //   accessToken: config.accessToken,
          //   embedUrl: config.embedUrl,
          //   id: config.id,
          //   permissions: config.permissions,
          //   settings: config.settings,
          // };

          //Edited by Joef: Using the state variable for set state causes delays
          // setSingleUserSelectionModal(singleUserSelectionModalHolder);
          closeSingleUserSelectionModal();

          // console.log("Has User Email", singleUserSelectionModal);
          reportGeneratorHolder.type = "Preview";
        }

        //Edited by Joef: RLS Fix
        //Edited by Joef: Using the state variable for set state causes delays
        setReportGenarator({
          show: true,
          record,
          config,
          type: reportGeneratorHolder.type,
        });
        localStorage.removeItem('selectedReportID')
        localStorage.removeItem('clientOfSelectedReport')

        // console.log("Trigger Report Generator", reportGeneratorHolder);
        // setReportGenarator({
        //   show: true,
        //   record: record,
        //   config: config,
        //   type: "View",
        // });

        // reportIntance = powerbi.embed(self.reportGenerator, config);

        //let report = powerbi.embed(self.reportGenerator, config);
        //let capturedBookmark = report.bookmarksManager.capture();
      })
      .catch((err) => {
        console.log(err);
        setShowBlockUI(false);
      });
  };

  const openViewDashboard = (record) => {
    // console.log("RECORD", record);
    setShowBlockUI(true);
    let message = "This report is not available in desktop view.";
    if (
      !(
        !record.hasOwnProperty("Details") ||
        !record.Details.hasOwnProperty("Settings") ||
        !record.Details.Settings.hasOwnProperty("Desktop") ||
        record.Details.Settings.Desktop
      )
    )
      return warning(message);

    fetch("/api/customReport/generateToken?clientId=" + record.id, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);
        // const models = window['powerbi-client'].models;

        //console.log("RECORD", record);
        setShowBlockUI(false);

        // console.log("RESPONSE", response);

        const config = {
          type: "dashboard",
          accessToken: response.accessToken,
          embedUrl: record.Details.embedUrl,
          dashboardId: record.ReportID,
          tokenType: 1
        };

        setReportGenarator({
          show: true,
          type: config.type,
          id: config.dashboardId,
          embedUrl: config.embedUrl,
          accessToken: config.accessToken,
          config,
          record: response
        });
        localStorage.removeItem('selectedReportID')
        localStorage.removeItem('clientOfSelectedReport')
        // let report = powerbi.embed(reportGenerator, config);

        // console.log(record);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const updateSettingsToEdit = () => {
  //   const models = window["powerbi-client"].models;

  //   reportGenerator.config.permissions = models.Permissions.ReadWrite;

  //   reportGenerator.config.settings = {
  //     filterPaneEnabled: true,
  //     navContentPaneEnabled: true
  //   };

  //   reportGenerator.type = "Edit";

  //   setReportGenarator(reportGenerator);

  //   reportIntance.switchMode("edit");

  //   reportIntance.updateSettings({
  //     filterPaneEnabled: true,
  //     navContentPaneEnabled: true,
  //     extensions: [
  //       {
  //         command: {
  //           name: "extension command",
  //           title: "Extend commands",
  //           icon: base64Icon,
  //           extend: {
  //             // Define visualOptionsMenu to extend options menu
  //             visualOptionsMenu: {
  //               // Define title to override default title.
  //               // You can override default icon as well.
  //               title: "Extend options menu"
  //             }
  //           }
  //         }
  //       }
  //     ]
  //   });
  // };

  const openReportUpdate = (record) => {
    fetch("/api/customReport/generateToken?clientId=" + record.id, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);

        // const models = window["powerbi-client"].models;
        const config = {
          type: "report",
          accessToken: response.accessToken,
          //embedUrl: 'https://embedded.powerbi.com/appTokenReportEmbed',
          embedUrl: record.Details.embedUrl,
          dashboardId: record.ReportID,
          // permissions:
          //   models.Permissions
          //     .ReadWrite /*both save & save as buttons will be visible*/,
          // viewMode: models.ViewMode.Edit,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: true,
          },
        };

        if (record.Details && record.Details.DefaultPage)
          config.pageName = record.Details.DefaultPage;
        setReportGenarator({
          show: true,
          record: record,
          config: config,
          type: "Edit",
        });
        // reportIntance = powerbi.embed(self.reportGenerator, config);
      });
  };

  // const openReportGenerator = () => {
  //   fetch("/api/powerbiEmbed/getCreateReportByDatasetToken", {
  //     method: "GET",
  //     credentials: "include"
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return console.log(response.error);

  //       const config = {
  //         accessToken: response.embedToken,
  //         embedUrl: "https://embedded.powerbi.com/appTokenReportEmbed",
  //         datasetId: response.datasetId
  //       };

  //       reportGenerator.show = true;
  //       setReportGenarator(reportGenerator);

  //       let report = powerbi.createReport(reportGenerator, config);

  //       report.on("saved", (event) => {
  //         modal.title = "Save Report";
  //         modal.show = true;
  //         modal.record = {
  //           ReportID: event.detail.reportObjectId,
  //           Name: event.detail.reportName
  //         };
  //         modal.actionUrl = "/api/customReport/createRecord";
  //         modal.actionUrlMethod = "POST";
  //         // report.off removes a given event handler if it exists.
  //         reportGenerator.show = false;
  //         setReportGenarator(reportGenerator);
  //         setModal(modal);
  //         report.off("saved");
  //       });
  //     });
  // };

  // const openUserSelectionModal = (record, settings) => {
  //   fetch('/api/user/getUsersAndAllAccountantsByClientID', {
  //     method: 'GET',
  //     credentials: 'include',
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return warningAlert(response)

  //       if (!userSelectionModal.selected.length && settings) {
  //         userSelectionModal.selected =
  //           record.Details && record.Details.recipient
  //             ? JSON.parse(JSON.stringify(record.Details.recipient))
  //             : []
  //       }
  //       setUserSelectionModal({
  //         show: true,
  //         report: record,
  //         data: response.data || [],
  //         filteredData: response.data || [],
  //         settings: settings,
  //         selected: userSelectionModal.selected,
  //       })
  //       // console.log(userSelectionModal);
  //     })
  // }

  const openUserSelectionModal = (record, settings) => {
    // console.log(record)
    // console.log(settings)
    setShowBlockUI(true);
    fetch(
      `/api/user/getUsersAndAllAccountantsByClientID/${selectedClient.id}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        setShowBlockUI(false);

        if (response.error) return warningAlert(response.error);

        // console.log(response.data);

        //console.log("USERSELECTIONRECORD",record);

        const userSelectionModalHandler = {
          show: true,
          report: record,
          data: response.data || [],
          filteredData: response.data || [],
          settings,
        };

        // userSelectionModal.show = true;
        // userSelectionModal.report = record;
        // userSelectionModal.data = response.data || [];
        // userSelectionModal.filteredData = response.data || [];

        // userSelectionModal.settings = settings;

        // if (!userSelectionModal.selected.length && settings) {
        //   userSelectionModalHandler.selected =
        //     record.Details && record.Details.recipient
        //       ? JSON.parse(JSON.stringify(record.Details.recipient))
        //       : [];
        // }

        if (!userSelectionModal.selected || (userSelectionModal.selected.length === 0 && settings)) {
          if (record.Details && record.Details.recipient) {
            userSelectionModalHandler.selected = JSON.parse(JSON.stringify(record.Details.recipient));
          } else {
            userSelectionModalHandler.selected = [];
          }
        }

        //console.log("userSelectionModalHandler", userSelectionModalHandler);
        setUserSelectionModal(userSelectionModalHandler);

        //setSelectedUsers(userSelectionModalHandler.selected);
        //console.log("userSelectionModal", userSelectionModal);

        // self.setState({ userSelectionModal });
      });
  };

  const openSingleUserSelectionModal = (record) => {
    let url = window.location.pathname.split("/");
    setShowBlockUI(true); //Added by Joef: All api calls should have ui block to avoid multi-press
    fetch(
      `/api/user/getUsersAndAllAccountantsByClientID/${selectedClient.id}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false); //Added by Joef: All api calls should have ui block to avoid multi-press
        if (response.error) return warningAlert(response);
        // console.log("getUsersAndAllAccountantsByClientID", response.data);
        setSingleUserSelectionModal({
          show: true,
          report: record,
          data: response.data || [],
          filteredData: response.data || [],
        });
      });
  };

  // const openCreateModal = (record) => {
  //   fetch("/api/powerbiEmbed/getCreateReportByDatasetToken", {
  //     method: "GET",
  //     credentials: "include"
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return console.log(response.error);
  //      //console.log(document.getElementById("reportContainer"));
  //       //console.log(self.reportContainer);
  //       createModal.config = {
  //         accessToken: response.embedToken,
  //         embedUrl: "https://embedded.powerbi.com/appTokenReportEmbed",
  //         datasetId: response.datasetId
  //       };
  //       setCreateModal({
  //         show: true,
  //         config: createModal.config
  //       });
  //     });
  // };

  // const launchReportCreator = (object) => {
  //   let report = powerbi.createReport(object, createModal.config);

  //   report.on("saved", (event) => {
  //     modal.title = "Save Report";
  //     modal.show = true;
  //     modal.record = {
  //       ReportID: event.detail.reportObjectId,
  //       Name: event.detail.reportName
  //     };
  //     modal.actionUrl = "/api/customReport/createRecord";
  //     modal.actionUrlMethod = "POST";
  //     // report.off removes a given event handler if it exists.
  //     report.off("saved");

  //     createModal.show = false;
  //     setCreateModal(createModal);

  //     setModal(modal);
  //   });
  // };

  const selectAll = () => {
    for (let i = 0; i < userSelectionModal.data.length; i++) {
      userSelectionModal.selected.push(userSelectionModal.data[i].id);
    }
    setUserSelectionModal(userSelectionModal);
    setColor(!color);
    // console.log(userSelectionModal)
  };

  const unselectAll = () => {
    userSelectionModal.selected = [];
    setUserSelectionModal(userSelectionModal);
    setColor(!color);
    // console.log(userSelectionModal)
  };
  const selectPageAll = () => {
    for (let i = 0; i < pageSelectionModal.data.length; i++) {
      pageSelectionModal.selected.push(pageSelectionModal.data[i].Name);
    }
    setPageSelectionModal(pageSelectionModal);
    setColor(!color);
    // console.log(pageSelectionModal)
  };

  const unselectPageAll = () => {
    pageSelectionModal.selected = [];
    setPageSelectionModal(pageSelectionModal);
    setColor(!color);
    // console.log(pageSelectionModal)
  };

  const handleSingleUserSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();

    const filtered = singleUserSelectionModal.data.filter(function (obj) {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });

    setSingleUserSelectionModal({
      show: singleUserSelectionModal.show,
      data: singleUserSelectionModal.data,
      filteredData: filtered,
      report: singleUserSelectionModal.report,
    });
    // console.log(singleUserSelectionModal)
  };

  const handleUserSelect = () => {
    settingsModal.record.Details = settingsModal.record.Details || {};
    settingsModal.record.Details.recipient = userSelectionModal.selected;

    setUserSelectionModal({
      show: false,
      selected: userSelectionModal.selected,
      filteredData: userSelectionModal.filteredData,
    });
    // console.log('reports', userSelectionModal)
  };

  const selectUser = (record) => {
    const index = userSelectionModal.selected.indexOf(record.id);

    if (index > -1) {
      userSelectionModal.selected.splice(index, 1);
      setColor(!color);
    } else {
      userSelectionModal.selected.push(record.id);
      setColor(!color);
    }

    setUserSelectionModal(userSelectionModal);
    // console.log(userSelectionModal)
  };

  // const openModal = (record) => {
  //   if (record) {
  //     modal.title = "Edit Report";
  //     modal.show = true;
  //     modal.record = record;
  //     modal.actionUrl = "/api/customReport/updateRecord?id=" + record.id;
  //     modal.actionUrlMethod = "PUT";
  //   } else {
  //     modal.title = "Upload Report";
  //     modal.show = true;
  //     modal.record = {};
  //     modal.actionUrl = "/api/customReport/createRecord";
  //     modal.actionUrlMethod = "POST";
  //   }
  //   setModal(modal);
  // };
  function validateChanges(oldData, newData) {
    const changes = {};

    const compareObjects = (oldObj, newObj, path = []) => {
      // Check for keys present in newObj but not in oldObj
      for (const key in newObj) {
        if (!oldObj.hasOwnProperty(key)) {
          const nestedPath = [...path, key];
          changes[nestedPath.join('.')] = { fld: nestedPath.join('.'), old: undefined, new: newObj[key] };
        }
      }

      for (const key in oldObj) {
        if (typeof oldObj[key] === 'object' && oldObj[key] !== null && typeof newObj[key] === 'object' && newObj[key] !== null) {
          compareObjects(oldObj[key], newObj[key], [...path, key]);
        } else {
          if (oldObj[key] !== newObj[key]) {
            const nestedPath = [...path, key];
            changes[nestedPath.join('.')] = { fld: nestedPath.join('.'), old: oldObj[key], new: newObj[key] };
          }
        }
      }
    };

    compareObjects(oldData, newData);

    return changes;
  }

  const handleChangeSettings = (event) => {
    // console.log("handleChangeSettings", settingsModal.record.Details);
    event.preventDefault();

    setShowBlockUI(true)

    let id = settingsModal.record.id;

    settingsModal.record.Details.recipient = userSelectionModal.selected;
    // console.log(id)
    // console.log("oldReportDetail: ", oldReportDetail)
    // console.log("newReportDetail: ", settingsModal.record.Details)
    const changes = validateChanges(oldReportDetail, settingsModal.record.Details)
    console.log(Object.keys(changes).length)


    fetch(`/api/customReport/updateRecord?id=${id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        Details: settingsModal.record.Details,
        Description: settingsModal.record.Description || "",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false)
        if (response.error) return warningAlert(response);

        successAlert(response);

        // console.log("updateRecord", response);

        let userCopy = user;
        let filtereDataCopy = filterData;

        for (let i = 0; i < userCopy.length; i++) {
          if (userCopy[i].id === id) {
            userCopy[i].Details = settingsModal.record.Details;
            break;
          }
        }
        for (let i = 0; i < filtereDataCopy.length; i++) {
          if (filtereDataCopy[i].id === id) {
            filtereDataCopy[i].Details = settingsModal.record.Details;
            break;
          }
        }

        setReportGenarator({
          ...reportGenerator,
          show: false
        });

        settingsModal.record = {};
        settingsModal.show = false;

        if (Object.keys(changes).length > 0) {
          sendNotification(selectedUsers, clientUsers, `modified ${oldReportDetail.name} report settings`)
        };

        setUser(user);
        setFilterData(filterData);
        setDashboardSettingsModal(settingsModal)
      });
  };

  const handleDashboardChangeSettings = (event) => {
    // console.log("handleChangeSettings", settingsModal.record.Details);
    event.preventDefault();

    setShowBlockUI(true)


    let id = dashboardSettingsModal.record.id;

    dashboardSettingsModal.record.Details.recipient = userSelectionModal.selected;
    // console.log(id)



    fetch(`/api/customReport/updateRecord?id=${id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        Details: dashboardSettingsModal.record.Details,
        Description: dashboardSettingsModal.record.Description || "",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false)
        if (response.error) return warningAlert(response);

        successAlert(response);
        // console.log("updateRecord", response);

        let userCopy = user;
        let filtereDataCopy = filterData;

        for (let i = 0; i < userCopy.length; i++) {
          if (userCopy[i].id === id) {
            userCopy[i].Details = dashboardSettingsModal.record.Details;
            break;
          }
        }
        for (let i = 0; i < filtereDataCopy.length; i++) {
          if (filtereDataCopy[i].id === id) {
            filtereDataCopy[i].Details = dashboardSettingsModal.record.Details;
            break;
          }
        }




        setReportGenarator({
          ...reportGenerator,
          show: false
        });

        dashboardSettingsModal.record = {};
        dashboardSettingsModal.show = false;

        //console.log(filteredData);
        setUser(user);
        setFilterData(filterData);
        setDashboardSettingsModal(dashboardSettingsModal)

      });
  };

  const addToFavorite = async (data, i) => {
    setShowBlockUI(true)
    // console.log(data)
    // console.log(i)
    // console.log(userDetails.User.Details.Favorites)
    let fav = userDetails.User.Details?.Favorites?.CustomReports ?? []

    let newDatas = [...filterData]
    if (fav.length) {
      fav.map(async f => {
        if (f.Details.ReportID !== data.ReportID) {
          setShowBlockUI(false)
          const setAddToFavorite = await fetch(`/api/favorites/addToFavorite`, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify({
              data
            }),
          })
          const favorite = await setAddToFavorite.json()
          // console.log(favorite)
          if (favorite.error) {
            return warningAlert(favorite.error);
          }
          if (favorite.message === 'success') {
            successAlert({ message: "Successfully added to favorites" })
            newDatas.map(nd => {
              if (nd.ReportID === favorite.ReportID) {
                nd.isFavorite = true
              }
            })
            setFilterData(newDatas.sort((a, b) => a.Name.localeCompare(b.Name)))
          }
        } else {
          setShowBlockUI(false)
          // console.log("SAME")
        }
      })
    } else {
      const setAddToFavorite = await fetch(`/api/favorites/addToFavorite`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({
          data
        }),
      })
      const favorite = await setAddToFavorite.json()
      // console.log(favorite)
      setShowBlockUI(false)
      if (favorite.error) {
        return warningAlert(favorite.error);
      }
      if (favorite.message === 'success') {
        successAlert({ message: "Successfully added to favorites" })
        newDatas.map(nd => {
          if (nd.ReportID === favorite.ReportID) {
            nd.isFavorite = true
          }
        })

        setFilterData(newDatas.sort((a, b) => a.Name.localeCompare(b.Name)))
      }
    }
  }

  const removeToFavorite = async (data) => {
    // console.log(data)
    setShowBlockUI(true)
    let newDatas = [...filterData]
    try {
      const removeFaveRequest = await fetch(`/api/favorites/removeToFavorite`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ ReportID: data.ReportID })
      })
      const result = await removeFaveRequest.json()
      // console.log(result)
      setShowBlockUI(false)
      if (!result.error) {
        newDatas.map(nd => {
          if (nd.ReportID === result.removedID) {
            nd.isFavorite = false
          }
        })

        setFilterData(newDatas.sort((a, b) => a.Name.localeCompare(b.Name)))
      }
    } catch (error) {
      setShowBlockUI(false)
      // console.log(error)
    }
  }

  const handleExportToPDF = (report) => {
    setAlert(null);

    openPageSelectionModal(report, false, true);
  };

  const handleUserSelection = (report) => {
    setAlert(null);

    openUserSelectionModal(report, false);
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   fetch(modal.actionUrl, {
  //     method: modal.actionUrlMethod,
  //     body: JSON.stringify(modal.record),
  //     credentials: "include"
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return console.log(response.error);

  //       if (response.record) {
  //         filterData.push(response.record);
  //         setFilterData(filterData);
  //       }
  //       console.log(response.message);
  //       modal.show = false;

  //       setModal(modal);
  //     });
  // };

  const handleUpdate = (url, toStatus, report) => {
    // console.log("REPORT!!!", report);
    setShowBlockUI(true)
    fetch(url, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(report),
    })
      .then((response) => response.json())
      .then((response) => {
        let id = report.id;

        // console.log("RESPONSE", response);
        setShowBlockUI(false)
        if (response.error) return warningAlert(response);


        if (userSelectionModal.show === true && toStatus === 4) {
          report = report.report;
          userSelectionModal.show = false;
          setUserSelectionModal(userSelectionModal);
          // console.log(report);
        }

        // console.log("ReportsList", user);
        // console.log("Report", report);

        let userCopy = user;
        let filtereDataCopy = filterData;
        let settingsModalCopy = settingsModal;
        settingsModalCopy.record.Details = response.record.Details;

        for (let i = 0; i < userCopy.length; i++) {
          if (userCopy[i].ReportID === response.record.ReportID) {
            userCopy[i].Status = toStatus;
            if (id === 0 && response.record.id > 0)
              userCopy[i] = response.record;
            break;
          }
        }
        for (let i = 0; i < filtereDataCopy.length; i++) {
          if (filtereDataCopy[i].ReportID === response.record.ReportID) {
            filtereDataCopy[i].Status = toStatus;
            if (id === 0 && response.record.id > 0)
              filtereDataCopy[i] = response.record;
            break;
          }
        }
        setUser(userCopy);
        setFilterData(filtereDataCopy);
        setSettingsModal(settingsModalCopy);
        successAlert(response);
      });
  };

  const submitButton = (status, id, report) => {

    //Commented by Joef: We are skipping the "Yellow" traffic light and go directly to "Green traffic light"
    // if (status === 1) {
    //   return (
    //     <Button
    //       color="warning"
    //       size="sm"
    //       onClick={() => {
    //         handleUpdate(
    //           "/api/customReport/updateSubmit?id=" + report.ReportID,
    //           5,
    //           report
    //         );
    //       }}
    //       style={{ borderRadius: "50px" }}
    //     >
    //       Submit
    //     </Button>
    //   );
    // } else if (status === 5) {
    if (status === 5 || status === 1) {
      return setAlert(
        <ReactBSAlert
          warning
          title="Export to PDF"
          onConfirm={() => handleExportToPDF(report)}
          onCancel={() => handleUserSelection(report)}
          confirmBtnBsStyle="warning"
          confirmBtnText="Yes, Turn On Export to PDF"
          cancelBtnText="No, Leave Export to PDF Off"
          reverseButtons={true}
          showCancel={true}
          btnSize=""
        >
          <p>
            Do you want to turn on Export to PDF for client users?
            <br />
            <strong className="text-warning">
              {/*Edited by Joef: Change requested by Cameron*/}
              Don't worry if you do not know the answer to this yet. You
              can always turn this on and off after you go live in the
              settings.
            </strong>
          </p>
        </ReactBSAlert>
      );
      // return (
      //   <Button
      //     color="success"
      //     size="sm"
      //     onClick={() => {
      //       setAlert(
      //         <ReactBSAlert
      //           warning
      //           title="Export to PDF"
      //           onConfirm={() => handleExportToPDF(report)}
      //           onCancel={() => handleUserSelection(report)}
      //           confirmBtnBsStyle="warning"
      //           confirmBtnText="Yes, Turn On Export to PDF"
      //           cancelBtnText="No, Leave Export to PDF Off"
      //           reverseButtons={true}
      //           showCancel={true}
      //           btnSize=""
      //         >
      //           <p>
      //             Do you want to turn on Export to PDF for client users?
      //             <br />
      //             <strong className="text-warning">
      //               {/*Edited by Joef: Change requested by Cameron*/}
      //               Don't worry if you do not know the answer to this yet. You
      //               can always turn this on and off after you go live in the
      //               settings.
      //             </strong>
      //           </p>
      //         </ReactBSAlert>
      //       );
      //       // if (
      //       //   !(
      //       //     report.hasOwnProperty('Details') &&
      //       //     report.Details.hasOwnProperty('Settings') &&
      //       //     report.Details.Settings.hasOwnProperty('RLS') &&
      //       //     report.Details.Settings.RLS
      //       //   )
      //       // ) {
      //       //   return openPageSelectionModal(report, false, true)
      //       // } else {
      //       //   return openUserSelectionModal(report, false)
      //       // }
      //     }}
      //   >
      //     Go Live
      //   </Button>
      // );
    } else if (status === 4) {
      console.log(report)
      console.log(report.Details.recipient)
      sendNotification(report.Details.recipient, clientUsers, `turned off the viewing availability for ${report.Name} report`)
      return handleUpdate(
        "/api/customReport/updateSubmit?id=" + report.ReportID,
        1, //Edited by Joef: skip yellow
        report
      );
      // return (
      //   <Button
      //     // id={`reprocess${id}`}
      //     //color="danger" //Commented by Joef. Having it color red makes it more confusing.
      //     size="sm"
      //     onClick={() => {
      //       handleUpdate(
      //         "/api/customReport/updateSubmit?id=" + report.ReportID,
      //         1, //Edited by Joef: skip yellow
      //         report
      //       );
      //     }}
      //   >
      //     {/* <i className="fas fa-redo" /> */}
      //     {/*Edited by Joef: Changed from Re-process*/}
      //     Hide Report from Client
      //     {/* <UncontrolledTooltip target={`reprocess${id}`}>
      //       Re-process
      //     </UncontrolledTooltip> */}
      //   </Button>
      // );
    } else return null;
  };

  const submitDashboardButton = (status, id, report) => {
    // if (status == 1 && authority.AnalyticsDashboardReportOrange) {
    if (status === 5 || status === 1) {
      return openUserSelectionModal(report, false);
      // return (
      //   <Button
      //     size="sm"
      //     color="success"
      //     onClick={(e) => {
      //       e.preventDefault();
      //     }}
      //   >
      //     <i className="fas fa-cloud-upload-alt  mr-1" />
      //     Go Live
      //   </Button>
      // );
      // } else if (status == 4 && authority.AnalyticsDashboardReportGreen) {
    } else if (status === 4) {
      return handleUpdate(
        "/api/customReport/updateSubmit?id=" + report.ReportID,
        1, //Edited by Joef: Skip Yellow
        report
      );
      // return (
      //   <Button
      //     size="sm"
      //     // color="danger"
      //     onClick={(e) => {
      //       e.preventDefault();
      //     }}
      //   >
      //     {/* <i className="fas fa-sync-alt mr-1" /> */}
      //     {/*Edited by Joef: Changed from Re-process*/}
      //     Hide from Clients
      //   </Button>
      // );
    }
  };

  const showTrafficLight = (status, i) => {
    // console.log(status, i);
    if (status === 1) {
      return (
        <>
          <Button
            color="danger"
            id={`btn2${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10 }}
          />
          <UncontrolledTooltip
            target={`btn2${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            {/*Edited by Joef: Previous message: This report is still private and can only be seen by you.*/}
            Offline: This report is offline and can only be seen by admins
            with access to this client
          </UncontrolledTooltip>
        </>
      );
    } else if (status === 5) {
      return (
        <>
          <Button
            color="warning"
            id={`warning${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10 }}
          />
          <UncontrolledTooltip
            target={`warning${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            This report is for processing and can be seen by other accountant.
          </UncontrolledTooltip>
        </>
      );
    } else if (status === 4) {
      return (
        <>
          <Button
            color="success"
            id={`success${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10 }}
          />
          <UncontrolledTooltip
            target={`success${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            {/*Edited by Joef: Previous message: This report is live and can be seen by client through their Mobile
            Accounting App.*/}
            Live: This report is live and can be seen by the client users you
            have selected in the settings
          </UncontrolledTooltip>
        </>
      );
    }
  };

  const openSettingsModal = (record) => {
    // console.log("openSettingsModal", record);
    userSelectionModal.selected =
      record.Details && record.Details.recipient
        ? JSON.parse(JSON.stringify(record.Details.recipient))
        : [];

    // settingsModal.show = true;

    setSettingsModal({
      show: true,
      record: JSON.parse(JSON.stringify(record)),
    });

    setUserSelectionModal(userSelectionModal);
    // console.log(settingsModal.record.id);
    setOldReportDetail(record.Details)
    setSelectedUsers(record.Details.recipient);
  };

  const handleShowDashboardSettings = (record) => {
    // console.log(record)
    userSelectionModal.selected =
      record.Details && record.Details.recipient
        ? JSON.parse(JSON.stringify(record.Details.recipient))
        : [];

    setDashboardSettingsModal({
      show: true,
      record: JSON.parse(JSON.stringify(record)),
    })

    setUserSelectionModal(userSelectionModal);
    // console.log(settingsModal.record.id);
    setSelectedUsers(record.Details.recipient);
  }

  const openReportPageSelection = (record) => {
    const group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
    const report = record.ReportID;

    fetch(`/api/powerbi/getReportPages?group=${group}&report=${report}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        if (response.error) return warningAlert(response);

        reportPageSelectionModal.data.unshift({
          Name: "",
          displayName: "Default",
        });
        setReportPageSelectionModal({
          show: true,
          report: record,
          Name: record.Name,
          data: response.value,
          filteredData: response.value,
        });
        // console.log(reportPageSelectionModal);
      });
  };

  const clonePBIReport = (record) => {
    setShowBlockUI(true)
    let group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
    let report = record.ReportID;
    fetch(`/api/powerbi/cloneReport?group=${group}&report=${report}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        name: `${reportName ? reportName : `${record.Name} (Copy)`}`
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setShowBlockUI(false)
        if (data.error) {
          error(data.error)
          closeDuplicateModal()
        }
        closeDuplicateModal()
        fetchAPIs()
        successAlert({ message: "Duplication of Report has been successful." });
      })
      .catch(e => {
        console.log(e)
      })
  }

  const openDuplicateModal = (record) => {
    setShowDuplicateModal(true)
    setSelectedReport(record)
  }

  const closeDuplicateModal = () => {
    setShowDuplicateModal(false)
  }

  const handleReportNameChange = (e) => {
    setReportName(e.target.value)
  }

  // const unsubscribe = () => {
  //   fetch("/api/customReport/unsubscribeToWorkspace", {
  //     method: "GET",
  //     credentials: "include"
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return console.log(response.error);
  //       console.log(response.message);

  //       setsubscribed(subscribed);
  //     });
  // };

  const openSchedulerModal = (record) => {
    if (record.Details.Schedule)
      schedulerModal.record = record.Details.Schedule;
    else
      schedulerModal.record = {
        Scheduled: true,
      };

    // schedulerModal.show = true;
    setSchedulerModal({
      show: true,
      record: schedulerModal.record,
    });
    // console.log(schedulerModal);
  };

  const handleRemoveSchedule = () => {
    delete settingsModal.record.Details.Schedule;
    setSettingsModal(settingsModal);

    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        Removed schedule successfully
      </ReactBSAlert>
    );

    // console.log(settingsModal);
  };

  const handleSchedulerInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    schedulerModal.record[name] = value;
    setSchedulerModal({
      show: schedulerModal.show,
      record: schedulerModal.record,
    });
    // console.log(schedulerModal.record);
  };

  const handleSchedulerDateChange = (value) => {
    const currentDate = new Date();
    //console.log(value);

    if (moment(value).isBefore(currentDate, "day")) {
      value = schedulerModal.record.Date;
      let message = "The date you selected is invalid!";
      warning(message);
    } else {
      schedulerModal.record.Date = value;
    }
    setSchedulerModal({
      show: schedulerModal.show,
      record: schedulerModal.record,
    });
    setStartDate(value);
    // console.log(schedulerModal.record);
  };

  const handleSchedulerSubmit = (event) => {
    event.preventDefault();
    let message = "Please select page first";
    if (
      !(
        schedulerModal.record &&
        schedulerModal.record.Page &&
        schedulerModal.record.Page.length
      )
    )
      return warning(message);

    settingsModal.record.Details = settingsModal.record.Details || {};
    settingsModal.record.Details.Schedule = schedulerModal.record;

    schedulerModal.show = false;

    setSettingsModal(settingsModal);
    setSchedulerModal({
      show: false,
      record: schedulerModal.record,
    });
  };

  // const openPageSelectionModal = (record, settings, exportToPDF) => {
  //   const group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1]
  //   const report = record.ReportID

  //   fetch(`/api/powerbi/getReportPages?group=${group}&report=${report}`, {
  //     method: 'GET',
  //     credentials: 'include',
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return warningAlert(response)

  //       let selected = pageSelectionModal.selected

  //       if (settings && !exportToPDF) {
  //         if (
  //           schedulerModal.record &&
  //           schedulerModal.record.Page &&
  //           schedulerModal.record.Page.length
  //         ) {
  //           selected = JSON.parse(JSON.stringify(schedulerModal.record.Page))
  //         } else {
  //           selected =
  //             record.Details &&
  //             record.Details.Schedule &&
  //             record.Details.Schedule.Page
  //               ? JSON.parse(JSON.stringify(record.Details.Schedule.Page))
  //               : []
  //         }
  //       } else if (settings && exportToPDF) {
  //         if (
  //           settingsModal.record &&
  //           settingsModal.record.Details &&
  //           settingsModal.record.Details.ExportToPDF &&
  //           settingsModal.record.Details.ExportToPDF.Page &&
  //           settingsModal.record.Details.ExportToPDF.Page.length
  //         ) {
  //           selected = JSON.parse(
  //             JSON.stringify(settingsModal.record.Details.ExportToPDF.Page),
  //           )
  //         } else {
  //           selected =
  //             record.Details &&
  //             record.Details.ExportToPDF &&
  //             record.Details.ExportToPDF.Page
  //               ? JSON.parse(JSON.stringify(record.Details.ExportToPDF.Page))
  //               : []
  //         }
  //       } else if (!settings && exportToPDF) {
  //         selected = []
  //       } else {
  //         pageSelectionModal.settings = settings
  //         pageSelectionModal.exportToPDF = exportToPDF
  //       }

  //       setPageSelectionModal({
  //         show: true,
  //         report: record,
  //         data: response.value,
  //         filteredData: response.value,
  //         settings: settings,
  //         exportToPDF: exportToPDF,
  //         selected: selected,
  //       })
  //       // console.log(pageSelectionModal);
  //     })
  // }

  const openPageSelectionModal = (record, settings, exportToPDF) => {
    setShowBlockUI(true);

    const group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];

    const report = record.ReportID;

    const modal = {};

    //console.log(record,group,report);

    //console.log(`/api/powerbi/getReportPages?group=${group}&report=${report}`);

    fetch(`/api/powerbi/getReportPages?group=${group}&report=${report}`, {
      method: "GET",
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        setShowBlockUI(false);

        if (response.error) return warningAlert(response.error);

        // console.log(response.value);

        modal.show = true;
        modal.report = record;
        modal.data = response.value;

        modal.settings = settings;
        modal.selected = [];

        if (settings && !exportToPDF) {
          // console.log("Scheduler Modal", schedulerModal);

          if (
            schedulerModal.record &&
            schedulerModal.record.Page &&
            schedulerModal.record.Page.length
          ) {
            modal.selected = JSON.parse(
              JSON.stringify(schedulerModal.record.Page)
            );
          } else {
            modal.selected =
              record.Details &&
                record.Details.Schedule &&
                record.Details.Schedule.Page
                ? JSON.parse(JSON.stringify(record.Details.Schedule.Page))
                : [];
          }
        } else if (settings && exportToPDF) {
          // console.log("Settings Modal", settingsModal);

          if (
            settingsModal.record &&
            settingsModal.record.Details &&
            settingsModal.record.Details.ExportToPDF &&
            settingsModal.record.Details.ExportToPDF.Page &&
            settingsModal.record.Details.ExportToPDF.Page.length
          ) {
            modal.selected = JSON.parse(
              JSON.stringify(settingsModal.record.Details.ExportToPDF.Page)
            );
          } else {
            modal.selected =
              record.Details &&
                record.Details.ExportToPDF &&
                record.Details.ExportToPDF.Page
                ? JSON.parse(JSON.stringify(record.Details.ExportToPDF.Page))
                : [];
          }
        } else if (!settings && exportToPDF) {
          modal.selected = [];
        }

        modal.settings = settings;

        modal.exportToPDF = exportToPDF;

        // console.log("MODAL", modal);

        setPageSelectionModal(modal);

        setSelectedPages(modal.selected);
      });
  };

  const selectPage = (record) => {
    const index = pageSelectionModal.selected.indexOf(record.Name);

    if (index > -1) {
      pageSelectionModal.selected.splice(index, 1);
      setColor(!color);
    } else {
      pageSelectionModal.selected.push(record.Name);
      setColor(!color);
    }
    setPageSelectionModal(pageSelectionModal);
    // console.log(pageSelectionModal);
  };
  const closeSchedulerModal = () => {
    schedulerModal.show = false;

    setSchedulerModal({
      show: false,
      record: schedulerModal.record,
    });
  };

  const closePageSelectionModal = () => {
    //pageSelectionModal.selected = [];

    const modal = pageSelectionModal; //Edited By Joef: Remove the Json parse and strify that causes export to pdf not updating properly
    // console.log("Close Selection Modal", modal);

    modal.show = false;
    modal.selected = selectedPages;

    //console.log(modal);

    //console.log("ALL PAGES", pageSelectionModal.data);
    if (modal.report?.Details)
      modal.report.Details.ExportToPDF = { Page: selectedPages };

    //console.log("Close Selection Modal", modal);

    setPageSelectionModal(modal);

    // console.log("Page Selection Report", pageSelectionModal.report);
    //console.log("SLECTED PAGES", selectedPages);
    if (!modal.settings)
      openUserSelectionModal(pageSelectionModal.report, false);
    // if(modal.settings && modal.exportToPDF)
    // console.log(pageSelectionModal);
    else if (modal.settings && modal.exportToPDF) {
      //Edited By Joef: Changed to else if to ensure only one condition is being passed
      const settings = JSON.parse(JSON.stringify(settingsModal));
      settings.record.Details.ExportToPDF = modal.report.Details.ExportToPDF;

      setSettingsModal(settings);
    } else if (modal.settings && !modal.exportToPDF) {
      //Edited By Joef: Changed to else if to ensure only one condition is being passed
      const scheduler = JSON.parse(JSON.stringify(schedulerModal));
      scheduler.record.Page = selectedPages;

      setSchedulerModal(scheduler);
    }
  };

  const closeReportPageSelectionModal = () => {
    // console.log("Close Report Selection Modal", {
    //   show: false,
    //   data: reportPageSelectionModal.data,
    //   filteredData: reportPageSelectionModal.filteredData,
    //   report: reportPageSelectionModal.report,
    //   selected: reportPageSelectionModal.selected,
    // });

    setReportPageSelectionModal({
      show: false,
      data: reportPageSelectionModal.data,
      filteredData: reportPageSelectionModal.filteredData,
      report: reportPageSelectionModal.report,
      selected: reportPageSelectionModal.selected,
    });
  };

  const handleReportPageNameChange = (event) => {
    const target = event.target;
    const value = target.value;
    reportPageSelectionModal.Name = value;

    setReportPageSelectionModal(reportPageSelectionModal);
  };

  const handleReportPageSelect = () => {
    let group = /\/groups\/(.+?)\/reports\//g.exec(
      reportPageSelectionModal.report.Details.webUrl
    )[1];
    let report = reportPageSelectionModal.report.ReportID;

    fetch(`/api/customReport/cloneReport?group=${group}&report=${report}`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        name: reportPageSelectionModal.Name,
        page: reportPageSelectionModal.selected,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);
        // console.log("RECORD", response.record);

        filterData.push(response.record);

        reportPageSelectionModal.show = false;
        reportGenerator.show = false;

        setReportPageSelectionModal(reportPageSelectionModal);
        setReportGenarator(reportGenerator);
        setUser(user);

        successAlert(response);
        // console.log(reportPageSelectionModal);
      });
  };

  // const handlePageSelect = () => {
  //   if (pageSelectionModal.settings && !pageSelectionModal.exportToPDF) {
  //     schedulerModal.record.Page = pageSelectionModal.selected

  //     setSchedulerModal(schedulerModal)
  //   } else if (pageSelectionModal.settings && pageSelectionModal.exportToPDF) {
  //     settingsModal.record.Details = settingsModal.record.Details || {}
  //     settingsModal.record.Details.ExportToPDF = {
  //       Page: pageSelectionModal.selected,
  //     }

  //     setSettingsModal(settingsModal)
  //   } else if (!pageSelectionModal.settings && pageSelectionModal.exportToPDF) {
  //     pageSelectionModal.report.Details =
  //       pageSelectionModal.report.Details || {}
  //     pageSelectionModal.report.Details.ExportToPDF = {
  //       Page: JSON.parse(JSON.stringify(pageSelectionModal.selected)),
  //     }

  //     pageSelectionModal.show = false
  //     setPageSelectionModal(pageSelectionModal)
  //     return openUserSelectionModal(pageSelectionModal.report, false)
  //   }

  //   // pageSelectionModal.show = false;
  //   setPageSelectionModal({
  //     show: false,
  //     data: pageSelectionModal.data,
  //     filteredData: pageSelectionModal.filteredData,
  //     report: pageSelectionModal.report,
  //     selected: pageSelectionModal.selected,
  //   })
  //   // console.log("settings", pageSelectionModal);
  // }

  // const exportToPDF = (record) => {
  //   const group = /\/groups\/(.+?)\/reports\//g.exec(record.Details.webUrl)[1];
  //   const checkExportStatus = (record, cb) => {
  //     fetch(
  //       `https://api.powerbi.com/v1.0/myorg/groups/091af970-ad2c-4802-abaf-51e3d8a2639e/reports/${record.reportId}/exports/${record.id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${record.token}`
  //         }
  //       }
  //     )
  //       .then( (response) =>  response.json())
  //       .then( (response) => {
  //         console.log(response);

  //         if (response.status === "Succeeded") {
  //           cb(response);
  //         } else {
  //           console.log(`Exporting to PDF: ${response.percentComplete}%`);
  //           blockUIDialog.message = `Exporting to PDF: ${response.percentComplete}%`;

  //           setTimeout( () => {
  //             checkExportStatus(record, cb);
  //           }, 30000);
  //         }
  //       });
  //   }
  //   reportIntance.bookmarksManager
  //     .capture()
  //     .then( (capturedBookmark) => {
  //       //console.log("REPORT STATE!!!", capturedBookmark.state);

  //       return fetch(`/api/powerbi/exportToPDF?id=${record.id}`, {
  //         method: "POST",
  //         credentials: "include",
  //         body: JSON.stringify({ state: capturedBookmark.state })
  //       });
  //     })
  //     .then( (response) => response.json())
  //     .then( (response) => {
  //       //console.log(response);
  //       return checkExportStatus(response, (result) => {
  //         //console.log("done", result);

  //         return fetch(
  //           `https://api.powerbi.com/v1.0/myorg/groups/091af970-ad2c-4802-abaf-51e3d8a2639e/reports/${response.reportId}/exports/${response.id}/file`,
  //           {
  //             method: "GET",
  //             headers: {
  //               Authorization: `Bearer ${response.token}`
  //             }
  //           }
  //         ).then((response) => {
  //           console.log(response);

  //           // blockUIDialog.show = false;
  //           blockUIDialog.message = "Exporting to PDF: 0%";

  //           if (response.status != 200)
  //             return console.log();("Error encountered while exporting");

  //           response.blob().then((blob) => {
  //             let url = window.URL.createObjectURL(blob);
  //             let a = document.createElement("a");
  //             a.href = url;
  //             a.download = `${record.Name}.pdf`;
  //             a.click();
  //           });
  //           //window.location.href = response.url;
  //         });
  //       });
  //     });

  //   //window.open(`/api/powerbi/cloneReport?group=${group}&report=${this.state.reportGenerator.record.ReportID}&name=${this.state.reportGenerator.record.Name}`, "_blank");
  // };

  const handleRemoveExportToPDF = () => {
    delete settingsModal.record.Details.ExportToPDF;
    setSettingsModal(settingsModal);
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
      >
        Removed Export To PDF for Client
      </ReactBSAlert>
    );
  };
  const displayScheduler = (frequency) => {
    const record = schedulerModal.record;
    const timeSelected = record.Time;
    const dateSelected = record.Date;
    const repeat = record.Repeat;
    const reverse = record.Reverse;
    let daysReversed = 0;
    let dayOfMonth = 0;
    let warning = "";

    if (!repeat) return null;

    if (!(timeSelected && dateSelected))
      return (
        <p>
          Note: Please select {!dateSelected ? "date" : ""}
          {!dateSelected && !timeSelected ? " and " : ""}
          {!timeSelected ? "time" : ""}.
        </p>
      );

    if (reverse) {
      const endOfMonth = moment(dateSelected).endOf("month");
      daysReversed = moment(endOfMonth).diff(dateSelected, "days");
    } else {
      dayOfMonth = moment(dateSelected).format("D");
    }

    switch (frequency) {
      case "daily":
        return (
          <p>
            Note: The notification will be sent everyday at{" "}
            {moment(timeSelected, "HH").format("ha")} starting from{" "}
            {moment(dateSelected).format("MM/DD/YYYY")}.
          </p>
        );

      case "weekly":
        return (
          <p>
            Note: The notification will be sent every{" "}
            {moment(dateSelected).format("dddd")} at{" "}
            {moment(timeSelected, "HH").format("ha")} starting from{" "}
            {moment(dateSelected).format("MM/DD/YYYY")}.
          </p>
        );

      case "monthly":
        if (reverse && daysReversed >= 29)
          warning =
            "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: Fabruary 1)";
        else if (!reverse && dayOfMonth >= 29)
          warning =
            "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: Fabruary 28)";

        return (
          <div>
            {warning ? <p>{warning}</p> : null}
            <p>
              Note: The notification will be sent{" "}
              {reverse
                ? `${daysReversed ? `${daysReversed} days before` : "at"
                } the end of every Month`
                : `every ${moment(dateSelected).format(
                  "Do [day of the Month]"
                )}`}{" "}
              at {moment(timeSelected, "HH").format("ha")} starting from{" "}
              {moment(dateSelected).format("MM/DD/YYYY")}.
            </p>
          </div>
        );
      case "quarterly":
        let monthOfYear = moment(dateSelected).format(`M`);
        let monthOfQuarter = monthOfYear % 3 || 3;
        let monthOfQuarterStr = "";
        // eslint-disable-next-line
        switch (monthOfQuarter) {
          case 1:
            monthOfQuarterStr = monthOfQuarter + "st";

            if (reverse && daysReversed >= 30)
              warning =
                "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: April 1)";
            else if (!reverse && dayOfMonth >= 31)
              warning =
                "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: April 30)";

            break;
          case 2:
            if (reverse && daysReversed >= 28)
              warning =
                "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: Fabruary 1)";
            else if (!reverse && dayOfMonth >= 29)
              warning =
                "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: Fabruary 28)";

            monthOfQuarterStr = monthOfQuarter + "nd";
            break;
          case 3:
            if (reverse && daysReversed >= 30)
              warning =
                "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: June 1 )";
            else if (!reverse && dayOfMonth >= 31)
              warning =
                "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: June 30)";

            monthOfQuarterStr = monthOfQuarter + "rd";
            break;
        }

        return (
          <div>
            {warning ? <p>{warning}</p> : null}
            <p>
              Note: The notification will be sent{" "}
              {reverse
                ? `${daysReversed ? `${daysReversed} days before` : "at"
                } the end of every`
                : `every ${moment(dateSelected).format(
                  "Do [day of the]"
                )}`}{" "}
              {`${monthOfQuarterStr} month of the quarter`} at{" "}
              {moment(timeSelected, "HH").format("ha")} starting from{" "}
              {moment(dateSelected).format("MM/DD/YYYY")}.
            </p>
          </div>
        );

      case "annually":
        let monthOfY = moment(dateSelected).format(`M`);

        if (reverse && monthOfY === 2 && daysReversed === 28)
          warning =
            "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: Fabruary 1)";
        else if (!reverse && monthOfY === 2 && dayOfMonth === 29)
          warning =
            "Warning: For months that doesn't have the day you selected, it will fall back to the last possible date. (Example: Fabruary 28)";

        return (
          <div>
            {warning ? <p>{warning}</p> : null}
            <p>
              Note: The notification will be sent{" "}
              {reverse
                ? `${daysReversed ? `${daysReversed} days before` : "at"
                } the end of ${moment(dateSelected).format("MMMM")}`
                : `every ${moment(dateSelected).format(
                  "Do [day of] MMMM"
                )}`}{" "}
              at {moment(timeSelected, "HH").format("ha")} starting from{" "}
              {moment(dateSelected).format("MM/DD/YYYY")}.
            </p>
          </div>
        );
      default:
        return;
    }
  };

  const onToggle = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (!settingsModal.record.hasOwnProperty("Details")) {
      settingsModal.record.Details = {};
    }
    if (!settingsModal.record.Details.hasOwnProperty("Settings")) {
      let Details = settingsModal.record.Details;
      Details.Settings = {};
      Details.Settings.Mobile = !value;
      Details.Settings.Desktop = !value;
      Details.Settings.ShowTabs = !value;
      Details.Settings.RLS = !value;
    }
    if (
      !settingsModal.record.Details.Settings.Mobile &&
      !(
        !settingsModal.record.Details.Settings.hasOwnProperty("Desktop") ||
        settingsModal.record.Details.Settings.Desktop
      )
    ) {
      settingsModal.record.Details.Settings.Desktop = true;
      settingsModal.record.Details.Settings.Mobile = true;
    }
    settingsModal.record.Details.Settings[name] = value;
    setSettingsModal({
      show: settingsModal.show,
      record: settingsModal.record,
    });
    // console.log(settingsModal.record.Details.Settings);
  };

  const inputChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // schedulerModal.record.Reverse = !value;
    // schedulerModal.record.Repeat = !value
    settingsModal.record[name] = value;

    setSettingsModal(settingsModal);
  };

  const inputSchedularChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // schedulerModal.record.Reverse = !value;
    // schedulerModal.record.Repeat = !value
    schedulerModal.record[name] = value;

    setSchedulerModal({
      show: schedulerModal.show,
      record: schedulerModal.record,
    });
    // console.log(schedulerModal.record);
  };

  // const toggleNavs = (e, state, index) => {
  //   e.preventDefault()
  //   setTabs(index)
  // }

  const selectUsers = (data) => {
    // console.log(data);
    // console.log(selectedUsers);
    const selected = selectedUsers || [];
    const index = selected?.indexOf(data.id);

    if (index > -1) {
      selected?.splice(index, 1);
      setColor(!color);
    } else {
      selected?.push(data.id);
      setColor(!color);
    }

    // console.log(selected);
    setSelectedUsers(selected);
  };

  const selectAllUsers = () => {
    const userLists = userSelectionModal.filteredData;
    const selected = [];
    for (let i = 0; i < userLists.length; i++) {
      selected.push(userLists[i].id);
    }

    setSelectedUsers(selected);
    // console.log(selectedUsers);
  };

  const unselectAllUsers = () => {
    const selected = [];
    setSelectedUsers(selected);
    // console.log(selectedUsers);
  };

  const selectPages = (record) => {
    const selected = selectedPages;
    const index = selected.indexOf(record.Name);

    if (index > -1) {
      selected.splice(index, 1);
      setColor(!color);
    } else {
      selected.push(record.Name);
      setColor(!color);
    }

    const cleanSelected = selected.filter(report => typeof report === 'string');
    if (cleanSelected?.length > MAX_SELECTED_PAGE_EXPORT_PDF) {
      maxExportPdfWarning();
      const excludePage = selected.indexOf(record.Name);
      cleanSelected.splice(excludePage, 1);
    } else {
      setAlert(null)
    }

    setSelectedPages(cleanSelected);
  };

  const selectAllPages = () => {
    const pageLists = pageSelectionModal.data;
    const selected = [];
    for (let i = 0; i < pageLists.length; i++) {
      selected.push(pageLists[i].Name);
    }

    setSelectedPages(selected);
  };

  const unselectAllPages = () => {
    const selected = [];
    setSelectedPages(selected);
  };

  const delay = (ms) => {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  const checkExportStatus = (record) => {
    const { reportId, id, token } = record;
    const getExportPdfExportStatusUrl = `https://api.powerbi.com/v1.0/myorg/reports/${reportId}/exports/${id}`;

    return fetch(getExportPdfExportStatusUrl, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).then(async (response) => {
      // console.log('GET PowerBi Report Status', response);

      if (response.status === 200) {
        return Promise.resolve(response);
      } else {
        // console.log(`Exporting to PDF: ${response.percentComplete}%`);
        setExportingPdfStatus('Checking PDF status');
        return delay(5000).then(() => checkExportStatus(record));
      }
    });
  }

  const downloadPdf = async (record) => {
    const { group, reportId, id, token, name } = record;

    const exportPdfUrl = `https://api.powerbi.com/v1.0/myorg/groups/${group}/reports/${reportId}/exports/${id}/file`;
    // Set a timeout duration in milliseconds (e.g., 60 seconds)
    const ABORT_TTL = 60000;
    const controller = new AbortController();
    const signal = controller.signal;
    // Create a timer to abort the request after the timeout
    const timeoutId = setTimeout(() => {
      controller.abort(); // Abort the request after the timeout
    }, ABORT_TTL);

    setExportingPdfStatus('Downloading PDF ');

    return fetch(exportPdfUrl, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      signal
    })
      .then(response => {
        clearTimeout(timeoutId);
        setShowBlockUI(false);
        if (response.status === 504) {
          console.log('Request timed out (504 error)');
          // Handle the 504 error here
        } else {
          setExportingPdfStatus('');
          setExportingPdf(false);
          response.blob().then((pdfFile) => {
            let url = window.URL.createObjectURL(pdfFile);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${record.name}.pdf`;
            a.click();
          });
        }
      })
      .catch(error => {
        clearTimeout(timeoutId);
        console.log('ERROR', error);
        setShowBlockUI(false);
      });
  }

  const exportToPDF = (record) => {
    setShowBlockUI(true);
    setExportingPdf(true);
    setExportingPdfStatus('Generating PDF ');

    embeddedComponent.bookmarksManager
      .capture()
      .then(function (capturedBookmark) {
        return fetch(`/api/powerbi/exportToPDF?id=${record.id}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ state: capturedBookmark.state }),
        });
      })
      .then(function (response) {
        return response.json();
      })
      .then(async (response) => {
        const { group, exportObj: { id, reportId, token }, name } = response;
        // check status of PowerBI export file

        const record = { group, reportId, id, token, name };
        checkExportStatus(record).then(async (response) => {
          await downloadPdf(record);
          setShowBlockUI(false);
        });

      });


  };

  const getEmbeddedComponent = (embeddedComponent) => {
    // console.log("TESTING");
    // console.log(embeddedComponent)
    setEmbeddedComponent(embeddedComponent);
  };

  const showBlockUIModal = () => {
    return <BlockUI />;
  };

  const handleShowPbixFileUpload = () => {
    setShowPbixFileUpload(true)
  }
  const handleClosePbixFileUpload = () => {
    setShowPbixFileUpload(false)
  }
  const handleImportPbixFile = async () => {
    // console.log(pbixFile)
    let pbixFileName = ''
    // if (pbixFileName === "") {
    //   setNameValid(false)
    //   return
    // }
    if (!pbixFile) {
      setFileValid(false)
      return
    }
    if (pbixFile) {
      pbixFileName = pbixFile.name.replace(/\..+$/, '')

      if (reportsNameList.includes(pbixFileName)) {
        warning({ message: "Report with the same name already exists. Please use different file name." })
        return
      }
      let getFileType = pbixFile.name.split('.')
      if (getFileType[getFileType.length - 1] !== 'pbix') {
        warning({ message: "File not supported. Please import .pbix file only." })
        return
      }
      if (pbixFile.size > 524288000) {
        warning({ message: "File is too large. Please upload 500mb or lower." })
        return
      }

    }
    setShowBlockUI(true)
    // setPbixFileName(pbixFileName.trim())
    const uniqueFileId = new Date().getTime();
    const blobFileName = `POWERBI/PBIX/${uniqueFileId}-pbix-upload.pbix`;

    await fetch(`/api/customReport/uploadPbixBlob`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ fileName: blobFileName })
    })
      .then(res => res.json())
      .then(async data => {
        // console.log("helloworld", data)

        await uploadBlob(pbixFile, blobFileName, data)

        const QUEUENAME = 'powerbi-pbix-upload-queue';
        const messageMetadata = {
          firmId: selectedClient.AccountingFirm,
          clientId: selectedClient.id,
          originalFileName: pbixFile.name,
          reportName: pbixFileName.trim().split(" ").join("%20"),
          blobFileName,
          uploader: {
            id: userDetails.User.id,
            email: userDetails.User.Email,
            userAccess: userDetails.User.UserAccess
          }
        }
        let queueResult = await pbixPushToQueue(QUEUENAME, blobFileName, data, messageMetadata)
        if (!queueResult.errorCode) {
          successAlert({ message: "Your file has been successfully uploaded. Please allow approximately 3 minutes for it to be updated in your list." })
          setShowPbixFileUpload(false)
          setPbixFile(null)
          setPbixFileName("")
          setShowBlockUI(false)
          setNameValid(true)
          setFileValid(true)
        }
      })
      .catch((err) => {
        console.log(err)
        setShowBlockUI(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            err
          </ReactBSAlert>
        );
      })
  }

  const handleImportPbixChange = (e) => {
    // console.log(e)
    let target = e.target
    // if (target.type === 'text') {
    //   let value = target.value.trim().split(" ").join("%20")
    //   setNameValid(true)
    //   setPbixFileName(value)
    // }
    if (target.type === 'file') {
      // console.log(target.files[0])
      setFileValid(true)
      setPbixFile(target.files[0])
    }
  }

  let allReportsComponent = (
    <CustomClientReports
      headerName={loading ? "Dashboards/Reports" : "Dashboards/Reports"}
      access={access}
      height={height}
      onClick={handle.enter}
      handleSearch={handleSearch}
      cardList={reportsList(1)}
      confirmDelete={confirmDelete}
      reportGenerator={reportGenerator}
      openSettingsModal={openSettingsModal}
      closeReportGenerator={closeReportGenerator}
      openReportPageSelection={openReportPageSelection}
      exportToPDF={exportToPDF}
      exportingPdf={exportingPdf}
      exportingPdfStatus={exportingPdfStatus}
      getEmbeddedComponent={getEmbeddedComponent}
      userDetails={userDetails}
      handleImportPbixFile={handleImportPbixFile}
      handleShowPbixFileUpload={handleShowPbixFileUpload}
      showPbixFileUpload={showPbixFileUpload}
      setShowPbixFileUpload={setShowPbixFileUpload}
      handleImportPbixChange={handleImportPbixChange}
      handleClosePbixFileUpload={handleClosePbixFileUpload}
      nameValid={nameValid}
      fileValid={fileValid}
      firmDetails={firmDetails}
      selectedClientID={selectedClientID}
      handleFilteredBy={handleFilteredBy}
      filterType={filterType}
      selectedClient={selectedClient}
      loading={loading}
    />
  );

  let allReportsRoute = "/client/reports";
  let scheduledReportsRoute = "/client/reports/schedulepdf";

  return (
    <>
      {alert}
      <CustomHeader />
      <Container className="mt-6 fadeIn" fluid>
        {showBlockUI ? showBlockUIModal() : null}
        <div className="nav-wrapper">
          {allReportsComponent}
        </div>
      </Container>
      <Modal
        size="xl"
        className="modal-dialog"
        isOpen={userSelectionModal.show}
      // toggle={props.toggle}
      >
        <div className="modal-body">
          <h5 className="modal-title mb-0">Select User</h5>
          <CardHeader className="px-0">
            {/*props.fieldGroup*/}
            <Row>
              <Col lg="8">
                <div className="mb-3">
                  <ButtonGroup>
                    <Button
                      style={{
                        backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                        color: `${textColor === "#000" ? "#fff" : textColor}`,
                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                      }}
                      size="sm"
                      type="button"
                      onClick={() => selectAllUsers()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i
                          className="ni ni-fat-add"
                          style={{ fontSize: "1rem" }}
                        />
                      </span>
                      Select All
                    </Button>
                    <Button
                      style={{
                        color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                      }}
                      size="sm"
                      type="button"
                      onClick={() => unselectAllUsers()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i
                          className="ni ni-fat-delete"
                          style={{ fontSize: "1rem" }}
                        />
                      </span>
                      Unselect All
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
              <Col lg="4">
                <div className="mb-3">
                  <Input
                    bsSize="sm"
                    type="text"
                    placeholder="Search"
                  //onChange={props.searchInput}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <Row className="">
            {
              // console.log(userSelectionModal.filteredData)
              // console.log(selectedUsers)
            }
            {userSelectionModal?.filteredData.map((user, i) => {
              const index = selectedUsers?.indexOf(user.id);
              return (
                <Col md={4} key={i} className="px-1">
                  <Card
                    onClick={() => selectUsers(user)}
                    className="mb-2"
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div
                            className="avatar  rounded-circle"
                          // onClick={(e) => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={
                                user && user.Avatar
                                  ? user.Avatar
                                  : require("assets/img/blank-avatar.png")
                                    .default
                              }
                              style={{ height: "48px", width: "48px" }}
                            />
                          </div>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            {`${user.FirstName} ${user.LastName}`}
                          </h4>
                          <span>
                            {`${user.UserAccess <= 4 ? "Admin" : "User"
                              }`}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <Button
                            size="sm"
                            type="button"
                            style={{
                              borderRadius: "50%",
                              backgroundColor: index > -1 ? "#2dce89" : "gray",
                              borderColor: index > -1 ? "#2dce89" : "gray",
                              color: "#fff",
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-check-bold" />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <CustomModalButton
            submitButton={() => {
              closeUserSelectionModal();
            }}
            closeButton={() => {
              setUserSelectionModal({
                show: false,
                data: [],
                filteredData: [],
                report: {},
                selected: [],
              });
            }}
            submitText={userSelectionModal.settings ? "Submit" : "Go Live"}
            cancelText="Cancel"
            submitType="button"
          />
        </div>
      </Modal>
      {/* Added by Joef: Created a new modal. The previous code is throwing an error*/}
      <Modal
        size="xl"
        className="modal-dialog"
        isOpen={singleUserSelectionModal.show}
      // toggle={props.toggle}
      >
        <div className="modal-body">
          <h5 className="modal-title mb-0">Select User</h5>
          <CardHeader className="px-0">
            {/*props.fieldGroup*/}
            <Row>
              <Col lg="8"></Col>
              <Col lg="4">
                <div className="mb-3">
                  <Input
                    bsSize="sm"
                    type="text"
                    placeholder="Search"
                    onChange={handleSingleUserSearch}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <Row className="">
            {
              // console.log(userSelectionModal.filteredData)
              // console.log(selectedUsers)
            }
            {/* {singleUserSelectList()} */}
            {singleUserSelectionModal?.filteredData.map((user, i) => {
              return (
                <Col md={4} key={i} className="px-1">
                  <Card
                    onClick={() =>
                      openViewReport(singleUserSelectionModal.report, user)
                    }
                    className="mb-2"
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div
                            className="avatar  rounded-circle"
                          // onClick={(e) => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={
                                user && user.Avatar
                                  ? user.Avatar
                                  : require("assets/img/blank-avatar.png")
                                    .default
                              }
                              style={{ height: "48px", width: "48px" }}
                            />
                          </div>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            {`${user.FirstName} ${user.LastName}`}
                          </h4>
                          <span>
                            {`${user.UserAccess <= 4 ? "Accountant" : "Client"
                              }`}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <CustomModalButton
            submitButton={false}
            closeButton={closeSingleUserSelectionModal}
            // submitText="Preview RLS"
            cancelText="Cancel"
            submitType="button"
          />
        </div>
      </Modal>

      <Modal
        size="xl"
        className="modal-dialog"
        isOpen={pageSelectionModal.show}
      // toggle={props.toggle}
      >
        <div className="modal-body">
          <h5 className="modal-title mb-0">Select page for exporting</h5>
          <CardHeader className="px-0">
            {/*props.fieldGroup*/}
            <Row>
              <Col lg="8">
                <div className="mb-3">
                  <ButtonGroup>
                    {/* <Button
                      style={{
                        backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                        color: `${textColor === "#000" ? "#fff" : textColor}`,
                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                      }}
                      size="sm"
                      type="button"
                      onClick={() => selectAllPages()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i
                          className="ni ni-fat-add"
                          style={{ fontSize: "1rem" }}
                        />
                      </span>
                      Select All
                    </Button> */}
                    <Button
                      style={{
                        color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                        borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                          }`,
                      }}
                      size="sm"
                      type="button"
                      onClick={() => unselectAllPages()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i
                          className="ni ni-fat-delete"
                          style={{ fontSize: "1rem" }}
                        />
                      </span>
                      Unselect All
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
              <Col lg="4">
                <div className="mb-3">
                  <Input
                    bsSize="sm"
                    type="text"
                    placeholder="Search"
                  //onChange={props.searchInput}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <Row className="">
            {
              // console.log(pageSelectionModal.data)
              // console.log(selectedPages)
            }
            {pageSelectionModal.data.map((page, i) => {
              const index = selectedPages.indexOf(page.Name);
              return (
                <Col md={12} key={i}>
                  <Card
                    onClick={() => selectPages(page)}
                    className="mb-3"
                    style={{ cursor: "pointer" }}
                  >
                    <CardBody>
                      <Row className="align-items-center">
                        <div className="col ml--2">
                          <h4 className="mb-0">{`${page.displayName}`}</h4>
                        </div>
                        <Col className="col-auto">
                          <Button
                            size="sm"
                            type="button"
                            style={{
                              borderRadius: "50%",
                              backgroundColor: index > -1 ? "#2dce89" : "gray",
                              borderColor: index > -1 ? "#2dce89" : "gray",
                              color: "#fff",
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-check-bold" />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <CustomModalButton
            submitButton={closePageSelectionModal}
            closeButton={() => {
              setPageSelectionModal({
                show: false,
                data: [],
                filteredData: [],
                report: {},
                selected: [],
              });
            }}
            submitText="Submit"
            cancelText="Cancel"
            submitType="button"
          />
        </div>
      </Modal>
      <DuplicateReportModal
        showDuplicateModal={showDuplicateModal}
        closeDuplicateModal={closeDuplicateModal}
        clonePBIReport={clonePBIReport}
        textColor={textColor}
        bgColor={bgColor}
        selectedReport={selectedReport}
        handleReportNameChange={handleReportNameChange}
      />
      {/*Added by Joef: Placed create modal here. Before, it was inside the loop duplicating the modal */}
      <CreateModal
        i={0}
        // data={data} //Commented by Joef: This is not being used
        key={0}
        bgColor={bgColor}
        onToggle={onToggle}
        textColor={textColor}
        startDate={startDate}
        selectAll={selectAll}
        inputChange={inputChange}
        unselectAll={unselectAll}
        handleUpdate={handleUpdate}
        selectPageAll={selectPageAll}
        settingsModal={settingsModal}
        userSelectList={userSelectList}
        schedulerModal={schedulerModal}
        pageSelectList={pageSelectList}
        unselectPageAll={unselectPageAll}
        handleUserSearch={handleUserSearch}
        handleUserSelect={handleUserSelect}
        handlePageSearch={handlePageSearch}
        displayScheduler={displayScheduler}
        //handlePageSelect={handlePageSelect}
        setSchedulerModal={setSchedulerModal}
        closeSettingsModal={closeSettingsModal}
        openSchedulerModal={openSchedulerModal}
        userSelectionModal={userSelectionModal}
        pageSelectionModal={pageSelectionModal}
        closeSchedulerModal={closeSchedulerModal}
        inputSchedularChange={inputSchedularChange}
        handleChangeSettings={handleChangeSettings}
        reportPageSelectList={reportPageSelectList}
        handleRemoveSchedule={handleRemoveSchedule}
        // singleUserSelectList={singleUserSelectList}
        handleSchedulerSubmit={handleSchedulerSubmit}
        handleSingleUserSearch={handleSingleUserSearch}
        openUserSelectionModal={openUserSelectionModal}
        openPageSelectionModal={openPageSelectionModal}
        handleReportPageSelect={handleReportPageSelect}
        handleReportPageSearch={handleReportPageSearch}
        closePageSelectionModal={closePageSelectionModal}
        handleRemoveExportToPDF={handleRemoveExportToPDF}
        closeUserSelectionModal={closeUserSelectionModal}
        singleUserSelectionModal={singleUserSelectionModal}
        reportPageSelectionModal={reportPageSelectionModal}
        handleSchedulerDateChange={handleSchedulerDateChange}
        handleSchedulerInputChange={handleSchedulerInputChange}
        handleReportPageNameChange={handleReportPageNameChange}
        closeSingleUserSelectionModal={closeSingleUserSelectionModal}
        closeReportPageSelectionModal={closeReportPageSelectionModal}
      />
      <DashboardModal
        i={0}
        heigh={height}
        bgColor={bgColor}
        onToggle={onToggle}
        textColor={textColor}
        selectAll={selectAll}
        unselectAll={unselectAll}
        handleUpdate={handleUpdate}
        handleChange={inputChange}
        settingsModal={dashboardSettingsModal}
        userSelectList={userSelectList}
        reportGenerator={reportGenerator}
        handleUserSelect={handleUserSelect}
        handleUserSearch={handleUserSearch}
        // openSettingsModal={handleShowDashboardSettings}
        closeSettingsModal={closeSettingsModal}
        userSelectionModal={userSelectionModal}
        handleChangeSettings={handleDashboardChangeSettings}
        closeReportGenerator={closeReportGenerator}
        openUserSelectionModal={openUserSelectionModal}
        closeUserSelectionModal={closeUserSelectionModal}
      />

      {isPinModalOpen && <PinToDashboard
        theme={{ PrimaryColor: bgColor, TextColor: textColor }}
        onShowPinModal={handlePinToDashboard}
        isBI={true}
        dataToPin={dataToPin}
      // userData={userData}
      // messageId={messages._id}
      // stext={stext}
      // version={version}
      />}
    </>
  );
};

export default Reports;
