import React, { useContext, useState, useEffect } from "react";
import { Button, CardBody, Col, Input, Row, Container, Spinner, Modal, Form, FormGroup, Label, FormText, ButtonGroup } from "reactstrap";
import PanelCard from "components/Cards/PanelCard";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { PowerBIEmbed } from "powerbi-client-react";
import { MainContext } from "context/mainContext";
import { CustomButton } from "components/Button/CustomButton";

import "./index.css";
import EVA from "eva-component/EVA";
import { checkEvaAccess } from "services/EVAService";
import CustomSpinner from "components/Misc/CustomSpinner";
import Chat from "chat-components/Chat";
import { useHistory } from "react-router";

const CustomClientReports = ({
  handleSearch,
  selectedGroupId,
  cardList,
  headerName,
  reportGenerator,
  handleImportPbixFile,
  handleShowPbixFileUpload,
  showPbixFileUpload,
  setShowPbixFileUpload,
  handleImportPbixChange,
  handleClosePbixFileUpload,
  firmDetails,
  selectedClientID,
  userDetails,
  handleFilteredBy,
  filterType,
  selectedClient,
  loading,
  ...props
}) => {
  const { bgColor, textColor } = useContext(MainContext);
  const history = useHistory();
  const handle = useFullScreenHandle();

  const [isFullscreen, setIsFullscreen] = useState(false);

  const [isEdit, setIsEdit] = useState(false)
  const [reload, setReload] = useState(false)
  const [showEva, setShowEva] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [evaIframeUrl, setEvaIframeUrl] = useState(null);
  const [noEvaAccess, setNoEvaAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    // console.log("CustomClientReports: ", selectedGroupId)
    // console.log("selectedClient: ", selectedClient)
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [selectedGroupId, selectedClient]);

  // console.log(props.userDetails)
  // console.log('reportGenerator', reportGenerator)
  // Modified By Joef: Added fix to fit report on browser and fix to hide the limited token warning

  const handleToggleEdit = () => {
    setIsEdit(!isEdit)
    setReload(true)
    setTimeout(() => {
      setReload(false)
    }, 2000)
  }

  const handleSetEvaIframeUrl = (value) => {
    // console.log(value)
    setIsLoading(false)
    setEvaIframeUrl(value)
  }
  const handleSetNoEvaAccess = (value) => {
    setIsLoading(false)
    setNoEvaAccess(value)
  }
  const handleShowChat = () => {
    setShowChat(!showChat)
  }
  const handleShowEVA = async () => {
    setShowEva(!showEva)
    setIsLoading(true)
    checkEvaAccess(selectedClientID, userDetails, handleSetEvaIframeUrl, handleSetNoEvaAccess)
  }
  const handleSHowHideEVAChat = () => {
    setShowEva(false)
    setShowChat(false)
  }

  const generateEVAIframeUrl = async (params) => {
    const details = await fetch("/api/eva/evaParams", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });
    const data = await details.json();

    if (data.success) {
      const token = data.data;
      const url = `${process.env.REACT_APP_EVA_URL}?token=${token}`;

      console.log(url)
      setEvaIframeUrl(url);
    } else {
      setEvaIframeUrl(null);
    }
  }

  const handleRedirect = (type) => {
    if (type === 'chat') return history.push(`/client/lists`)
    if (type === 'eva') return history.push(`/client/thirdpartyapplication`)
  }

  return (
    <PanelCard
      headerName={
        reportGenerator.show
          ? `${reportGenerator?.record?.Details?.name} Report`
          : headerName
      }
    >
      {
        loading ?
          <Container className="text-center" style={{ paddingTop: '150px', paddingBottom: '150px' }}>
            <Spinner />
          </Container>
          :
          selectedClient?.Details?.ShowPowerBIPage ?
            // true ?
            <>
              <Row style={{ display: !reportGenerator.show ? "block" : "none" }}>
                <div className="col mt-2">
                  <Row>
                    <Col>
                      {userDetails?.User?.UserAccess <= 4 ?
                        (
                          <Button
                            style={{
                              backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                }`,
                              color: `${textColor === "#000" ? "#fff" : textColor}`,
                              borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                }`,
                            }}
                            size="sm"
                            type="button"
                            onClick={() => handleShowPbixFileUpload()}
                            id="addAccountant"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i
                                className="fas fa-file-upload"
                                style={{ fontSize: "1rem" }}
                              />
                            </span>
                            Import
                          </Button>
                        ) : ""
                      }
                      <Modal
                        className="modal-dialog-top"
                        isOpen={showPbixFileUpload}
                        toggle={() => setShowPbixFileUpload(!showPbixFileUpload)}
                        size="lg"
                        backdrop="static"
                        centered
                      >
                        <div className="modal-header">
                          <h6 className="modal-title" id="modal-title-default">
                            Import Report
                          </h6>
                          <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => handleClosePbixFileUpload()}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Form>
                            <FormGroup>
                              <label className="form-control-label" htmlFor="pbixFile">
                                File <span className="text-warning">*</span>
                              </label>
                              <Input
                                id="pbixFile"
                                name="pbixFile"
                                type="file"
                                className="getData"
                                style={{ border: props.fileValid ? "1px solid #ced4da" : "1px solid red", padding: "0.375rem 0.75rem 0.375rem 0.375rem", borderRadius: "0.25rem" }}
                                onChange={handleImportPbixChange}
                                accept=".pbix"
                              />
                              {
                                props.fileValid ? (
                                  ""
                                ) : (
                                  <small className="text-warning">
                                    This field is required.
                                  </small>
                                )
                              }
                              <FormText>
                                Import .pbix file only.
                              </FormText>
                            </FormGroup>
                          </Form>
                        </div>
                        <div className="modal-footer">
                          <CustomButton
                            type="button"
                            onClick={() => handleImportPbixFile()}
                            outlineStyle={false}
                            title={"Save"}
                          />
                          <CustomButton
                            onClick={() => handleClosePbixFileUpload()}
                            outlineStyle={true}
                            data-dismiss="modal"
                            className="ml-auto"
                            type="button"
                            color="link"
                            title="Close"
                          />
                        </div>
                      </Modal>
                    </Col>
                    <Col lg="4" sm="4" className="mt-0 ml-auto">
                      <div className="ml-auto">
                        <Row>
                          <Col className="text-right pr-2" style={{ paddingRight: 0 }}>
                            <ButtonGroup>
                              <Button
                                size="sm"
                                type="button"
                                style={{
                                  backgroundColor: `${filterType === 'all'
                                    ? "#2dce89"
                                    : bgColor === "#fff"
                                      ? "#5e72e4"
                                      : bgColor
                                    }`,
                                  borderColor: `${filterType === 'all'
                                    ? "#2dce89"
                                    : bgColor === "#fff"
                                      ? "#5e72e4"
                                      : bgColor
                                    }`,
                                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                                  paddingTop: "0.3rem",
                                  paddingBottom: "0.3rem"
                                }}
                                onClick={() => handleFilteredBy('all')}
                              >
                                All
                              </Button>
                              <Button
                                size="sm"
                                type="button"
                                style={{
                                  backgroundColor: `${filterType === 'dashboard'
                                    ? "#2dce89"
                                    : bgColor === "#fff"
                                      ? "#5e72e4"
                                      : bgColor
                                    }`,
                                  borderColor: `${filterType === 'dashboard'
                                    ? "#2dce89"
                                    : bgColor === "#fff"
                                      ? "#5e72e4"
                                      : bgColor
                                    }`,
                                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                                  paddingTop: "0.3rem",
                                  paddingBottom: "0.3rem"
                                }}
                                onClick={() => handleFilteredBy('dashboard')}
                              >
                                Dashboards
                              </Button>
                              <Button
                                size="sm"
                                type="button"
                                style={{
                                  backgroundColor: `${filterType === 'report'
                                    ? "#2dce89"
                                    : bgColor === "#fff"
                                      ? "#5e72e4"
                                      : bgColor
                                    }`,
                                  borderColor: `${filterType === 'report'
                                    ? "#2dce89"
                                    : bgColor === "#fff"
                                      ? "#5e72e4"
                                      : bgColor
                                    }`,
                                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                                  paddingTop: "0.3rem",
                                  paddingBottom: "0.3rem"
                                }}
                                onClick={() => handleFilteredBy('report')}
                              >
                                Reports
                              </Button>
                            </ButtonGroup>
                          </Col>
                          <Col>
                            <Input
                              placeholder="Search"
                              type="text"
                              bsSize="sm"
                              onChange={handleSearch}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <CardBody>
                    <Row>{cardList}</Row>
                  </CardBody>
                </div>
              </Row>
              <Row>
                <Col lg={`${showEva ? '8' : '12'}`} sm={`${showEva ? '8' : '12'}`}>
                  <div
                    style={{
                      display: reportGenerator.show ? "block" : "none",
                    }}
                    className=""
                  >
                    {
                      showEva ?
                        <div className="text-right mb-1" style={{ paddingTop: `${reportGenerator.config.type === "report" ? '29px' : '17px'}` }}></div>
                        :
                        <div className="text-right mb-1">
                          <Button
                            style={{
                              backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                }`,
                              color: `${textColor === "#000" ? "#fff" : textColor}`,
                              borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                }`,
                            }}
                            size="sm"
                            type="button"
                            onClick={() => handleShowEVA()}
                            id="addAccountant"
                          >
                            {showEva ? `Hide EVA/Group Chat` : `Show EVA/Group Chat`}
                          </Button>
                        </div>
                    }
                    {/* <div>
          <strong>{`${reportGenerator?.record?.Details?.name} Report`}</strong>
        </div> */}
                    {
                      reload ?
                        <Container className="text-center" style={{ paddingTop: '330px', paddingBottom: '330px' }}>
                          <Spinner />
                        </Container>
                        :
                        reportGenerator.config.type === "report" ?
                          <FullScreen handle={handle}>
                            <div>
                              {/* {console.log("REPORT GENERATOR!!!", reportGenerator)} */}
                              {reportGenerator?.show ? (
                                <>
                                  <div
                                    style={{
                                      backgroundColor: "#fff",
                                      position: "relative",
                                      zIndex: reportGenerator.config.tokenType == 1 ? 1 : 0,
                                      height: "30px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      position: "relative",
                                      top: "-30px",
                                      height: (props.height - (!isFullscreen ? 290 : 0)) + "px",
                                    }}
                                  >
                                    <PowerBIEmbed
                                      embedConfig={{
                                        ...reportGenerator.config,
                                      }}
                                      cssClassName="dashboard-style-class"
                                      eventHandlers={
                                        new Map([
                                          [
                                            "loaded",
                                            function () {
                                              console.log("Report loaded");
                                            },
                                          ],
                                          [
                                            "rendered",
                                            function () {
                                              console.log("Report rendered");
                                            },
                                          ],
                                          // ['error', function (event) {console.log(event.detail);}]
                                        ])
                                      }
                                      getEmbeddedComponent={(embeddedReport) => {
                                        props.getEmbeddedComponent(embeddedReport);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </FullScreen>
                          :
                          <FullScreen handle={handle}>
                            <div
                              className="my-3"
                              style={{
                                height: props.height - 20 + "px",
                              }}
                            // ref={(object) =>  reportGenerator= object }
                            >
                              {reportGenerator.show ? (
                                <PowerBIEmbed
                                  embedConfig={{
                                    type: "dashboard",
                                    id: reportGenerator.dashboardId || null,
                                    embedUrl: reportGenerator.embedUrl || null,
                                    accessToken: reportGenerator.accessToken || null,
                                  }}
                                  cssClassName="dashboard-style-class"
                                  eventHandlers={
                                    new Map([
                                      [
                                        "loaded",
                                        function () {
                                          console.log("Report loaded");
                                        },
                                      ],
                                      [
                                        "rendered",
                                        function () {
                                          console.log("Report rendered");
                                        },
                                      ],
                                      // ['error', function (event) {console.log(event.detail);}]
                                    ])
                                  }
                                />
                              ) : null}
                            </div>
                          </FullScreen>
                    }
                    <div className="text-right">
                      {props.userDetails?.User?.UserAccess <= 4 ? (
                        <>
                          {reportGenerator.type === "View" ? (
                            <>
                              {/* {access <= 4 ? ( */}
                              {/*  Trello Card - Edited by Paul: https://trello.com/c/8PMrDtza/286-reports-hide-duplicate-edit <Button
                    style={{
                      color: `${bgColor}`,
                      borderColor: `${bgColor}`,
                    }}
                    outline
                    onClick={() =>
                      props.openReportPageSelection(reportGenerator.record)
                    }
                  >
                    Duplicate Report
                  </Button> */}
                              {/* ) : null} */}
                              {/* {access <= 4 ? ( */}
                              {/* <Button
                    style={{
                      color: `${bgColor}`,
                      borderColor: `${bgColor}`,
                    }}
                    outline
                    onClick={() => props.confirmDelete(reportGenerator.record)}
                  >
                    Delete Report
                  </Button> */}
                              {/* ) : null} */}
                              {/* {access <= 4 && reportGenerator.record.Status == 4 ? ( */}
                              {reportGenerator.record.Status === 4 ? (
                                <Button
                                  style={{
                                    color: `${bgColor}`,
                                    borderColor: `${bgColor}`,
                                  }}
                                  outline
                                  onClick={() =>
                                    props.openSettingsModal(reportGenerator.record)
                                  }
                                >
                                  Settings
                                </Button>
                              ) : null}
                              {/* Commented by Joef: Placed it outside so that client users can see this as well 
                  {!(
                    reportGenerator.record.hasOwnProperty("Details") &&
                    reportGenerator.record.Details.hasOwnProperty("Settings") &&
                    reportGenerator.record.Details.Settings.hasOwnProperty(
                      "RLS"
                    ) &&
                    reportGenerator.record.Details.Settings.RLS
                  ) &&
                  reportGenerator.record.Status === 4 &&
                  reportGenerator.record.Details &&
                  reportGenerator.record.Details.ExportToPDF &&
                  reportGenerator.record.Details.ExportToPDF.Page &&
                  reportGenerator.record.Details.ExportToPDF.Page.length ? (
                    <Button
                      style={{
                        color: `${bgColor}`,
                        borderColor: `${bgColor}`,
                      }}
                      outline
                      onClick={() => props.exportToPDF(reportGenerator.record)}
                    >
                      Export to PDF
                    </Button>
                  ) : null} */}
                              {/*
                    (access <= 4)?
                    <Button 
                      className={`${s.closeReportGenerator}`}
                      style={{ marginRight: '10px' }}
                      onClick={() => this.updateSettingsToEdit()}
                    >Edit Report
                    </Button>:null
                  */}
                            </>
                          ) : null}
                        </>
                      ) : null}
                      {/*Added by Joef: Export to PDF should be accessible to client users*/}
                      {/* {props.userDetails?.User?.UserAccess <= 4 ?
            <Button
              style={{
                color: `${bgColor}`,
                borderColor: `${bgColor}`,
              }}
              outline
              onClick={handleToggleEdit}
            >
              Toggle {isEdit ? 'View' : 'Edit'}
            </Button>
            : null
          } */}
                      {!(
                        reportGenerator.record.hasOwnProperty("Details") &&
                        reportGenerator.record.Details.hasOwnProperty("Settings") &&
                        reportGenerator.record.Details.Settings.hasOwnProperty("RLS") &&
                        reportGenerator.record.Details.Settings.RLS
                      ) &&
                        reportGenerator.record.Status === 4 &&
                        reportGenerator.record.Details &&
                        reportGenerator.record.Details.ExportToPDF &&
                        reportGenerator.record.Details.ExportToPDF.Page &&
                        reportGenerator.record.Details.ExportToPDF.Page.length ? (
                        <Button
                          style={{
                            color: `${bgColor}`,
                            borderColor: `${bgColor}`,
                          }}
                          outline
                          onClick={() => props.exportToPDF(reportGenerator.record)}
                        >
                          Export to PDF
                        </Button>
                      ) : null}
                      <Button
                        style={{
                          color: `${bgColor}`,
                          borderColor: `${bgColor}`,
                        }}
                        outline
                        onClick={handle.enter}
                      >
                        Full Screen
                      </Button>
                      <Button
                        style={{
                          color: `${bgColor}`,
                          borderColor: `${bgColor}`,
                        }}
                        outline
                        onClick={() => {
                          props.closeReportGenerator()
                          setShowEva(false)
                        }}
                      >
                        Close Report
                      </Button>
                    </div>
                  </div>
                </Col>
                {
                  showEva ?
                    <Col lg='4'>
                      <div className="text-right mb-1">
                        <Button
                          style={{
                            backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                              }`,
                            color: `${textColor === "#000" ? "#fff" : textColor}`,
                            borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                              }`,
                          }}
                          size="sm"
                          type="button"
                          onClick={() => handleShowChat()}
                          id="addAccountant"
                        >
                          {!showChat ? `Show Chat` : `Show EVA`}
                        </Button>
                        <Button
                          style={{
                            backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                              }`,
                            color: `${textColor === "#000" ? "#fff" : textColor}`,
                            borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                              }`,
                          }}
                          size="sm"
                          type="button"
                          onClick={() => handleSHowHideEVAChat()}
                          id="addAccountant"
                        >
                          {showEva || showChat ? `Hide EVA/Group Chat` : `Show EVA/Group Chat`}
                        </Button>

                      </div>
                      <div className="">
                        {
                          isLoading ? <CustomSpinner /> :
                            evaIframeUrl ?
                              <>
                                <Row>
                                  <Col>
                                    {/* <iframe src={evaIframeUrl} width="100%" height="663" style={{ border: '1px solid #e5e7eb' }}></iframe> */}
                                    {showChat ?
                                      <>
                                        {
                                          selectedClient?.Details?.ShowChatPage ?
                                            <Chat token={evaIframeUrl} fromReport={true} selectedGroupId={selectedGroupId} />
                                            :
                                            <Row className="text-center d-flex align-items-center justify-content-center" >
                                              <Col style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {
                                                  userDetails?.User?.UserAccess >= 5 ?
                                                    <span>Chat is not yet enabled for this Client. Please speak to your advisor about turning this feature on.</span>
                                                    :
                                                    <span>Chat is not yet enabled for this Client. Please click <strong style={{ color: "#5e72e4", cursor: "pointer" }} onClick={() => handleRedirect('chat')}>here</strong> go to clients to enable Chat.</span>
                                                }
                                              </Col>
                                            </Row>
                                        }
                                      </>
                                      :
                                      <>
                                        {
                                          selectedClient?.Details?.ShowEvaPage ?
                                            <EVA token={evaIframeUrl} />
                                            :
                                            <Row className="text-center d-flex align-items-center justify-content-center" >
                                              <Col style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {
                                                  userDetails?.User?.UserAccess >= 5 ?
                                                    <span>EVA page is not yet enabled for this Client. Please speak to your advisor about turning this feature on.</span>
                                                    :
                                                    <span>There are no valid EVA integrations. Please click <strong style={{ color: "#5e72e4", cursor: "pointer" }} onClick={() => handleRedirect('eva')}>here</strong> go to integrations page to add <strong>Xero Blue</strong>, <strong>XPM</strong> or <strong>Workflow Max</strong> to be able to use EVA.</span>
                                                }
                                              </Col>
                                            </Row>
                                        }
                                      </>
                                    }
                                  </Col>
                                </Row>
                              </>
                              : noEvaAccess ?
                                <>
                                  {
                                    showChat ?
                                      <>
                                        {
                                          selectedClient?.Details?.ShowChatPage ?
                                            <Chat token={evaIframeUrl} fromReport={true} selectedGroupId={selectedGroupId} />
                                            :
                                            <Row className="text-center d-flex align-items-center justify-content-center" >
                                              <Col style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {
                                                  userDetails?.User?.UserAccess >= 5 ?
                                                    <span>Chat is not yet enabled for this Client. Please speak to your advisor about turning this feature on.</span>
                                                    :
                                                    <span>Chat is not yet enabled for this Client. Please click <strong style={{ color: "#5e72e4", cursor: "pointer" }} onClick={() => handleRedirect('chat')}>here</strong> go to clients to enable Chat.</span>
                                                }
                                              </Col>
                                            </Row>
                                        }
                                      </>
                                      :
                                      <Row className="text-center d-flex align-items-center justify-content-center">
                                        <Col style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                          <div>
                                            <span>Selected client is not allowed to access EVA.</span><br />
                                            <span>Please select another client to view EVA.</span>
                                          </div>
                                        </Col>
                                      </Row>
                                  }
                                </>
                                : null
                        }
                      </div>
                    </Col>
                    : null
                }
              </Row>
            </>
            :
            <Row>
              <Col className="text-center">
                {
                  userDetails?.User?.UserAccess >= 5 ?
                    <span>Power BI page is not yet enabled for this Client. Please speak to your advisor about turning this feature on.</span>
                    :
                    <span>Power BI page is not yet enabled for this Client. Please click <a href="/client/lists" ><strong>here</strong></a> go to clients to enable Power BI page.</span>
                }
              </Col>
            </Row>
      }
    </PanelCard>
  );
};

window.addEventListener('fullscreenchange', () => {
  const isFullscreen = !!document.fullscreenElement;
  // Update the 'isFullscreen' state here or trigger a state update in the React component
});

export default CustomClientReports;
