import React from 'react'
import _, { forEach } from "lodash";
import { useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useContext } from 'react';
import { MainContext } from 'context/mainContext';
import { updateVisualPersonalDashboard } from 'services/mongoDB';
import { registerDashboardTemplate } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { updateVisualUserDashboard } from 'services/mongoDB';
import DashboardTemplateItemNew from '../dashboards/DashboardTemplateItemNew';
import DashboardTemplateItemModal from '../dashboards/DashboardTemplateItemModal';

const WidthResponsive = WidthProvider(Responsive);

function ManageDashboardDnD({selectedTemplateItem, handleOnEditVisual, onEditVisual, handleCloseEditVisual, newUpdateCount, ...props}) {
    const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
    const [compactType, setCompactType] = useState("vertical");
    const [mounted, setMounted] = useState(false);
    const [toolbox, setToolbox] = useState({});
    const [layouts, setLayouts] = useState({});
    const [withError, setWithError] = useState(false)
    const { 
        bgColor, 
        textColor
    } = useContext(MainContext);
    const [alert, setAlert] = useState(false);
    const [onEditName, setOnEditName] = useState(false)

    useEffect(() => {
    }, [layouts]);

    useEffect(() => {
        // console.log(selectedTemplateItem)
        if (selectedTemplateItem && selectedTemplateItem.length > 0) 
        {
            // console.log(selectedTemplateItem)
            const templateItemList = []
            const item = {
                lg: selectedTemplateItem.map(function (item, i) 
                {
                    // // console.log(item)
                    const newItem = { ...item };
                    if(newItem.tempVisual)
                    {
                        const x = (newItem.tempVisual.x !== null && newItem.tempVisual.x !== undefined) ? newItem.tempVisual.x : 0;
                        const y = (newItem.tempVisual.y !== null && newItem.tempVisual.y !== undefined) ? newItem.tempVisual.y : i * 14;
                        const w = (newItem.tempVisual.width !== null && newItem.tempVisual.width !== undefined) ? newItem.tempVisual.width : 5;
                        const h = (newItem.tempVisual.height !== null && newItem.tempVisual.height !== undefined) ? newItem.tempVisual.height : 14;
                        templateItemList.push(newItem)
                        return {
                            x: x,
                            y: y,
                            w: w,
                            h: h,
                            i: i.toString(),
                        };
                    } else if (newItem.visual) {
                        const x = (newItem.visual.x !== null && newItem.visual.x !== undefined) ? newItem.visual.x : 0;
                        const y = (newItem.visual.y !== null && newItem.visual.y !== undefined) ? newItem.visual.y : i * 14;
                        const w = (newItem.visual.width !== null && newItem.visual.width !== undefined) ? newItem.visual.width : 5;
                        const h = (newItem.visual.height !== null && newItem.visual.height !== undefined) ? newItem.visual.height : 14;
                        
                        templateItemList.push(newItem)
                        return {
                            x: x,
                            y: y,
                            w: w,
                            h: h,
                            i: i.toString(),
                        };
                    } else {
                        const x =  0
                        const y = i * 14
                        const w = 5
                        const h = 14

                        const visual = {
                            "width": w,
                            "height": h,
                            "x": x,
                            "y": y,
                        }

                        item.tempVisual = visual
                        templateItemList.push(item)
                        return {
                            x: x,
                            y: y,
                            w: w,
                            h: h,
                            i: i.toString(),
                        };
                    }
                  
                })
            };
            setLayouts(item);
        }

    }, [selectedTemplateItem, newUpdateCount]);

    useEffect(() => {
       
    },[newUpdateCount])

    useEffect(() => {
        setMounted(true);
    }, []);

    const onBreakpointChange = (breakpoint) => {
        setCurrentBreakpoint(breakpoint);
        setToolbox({
            ...toolbox,
            [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
        });
    };

    const onLayoutChange = (layout, layouts) => {
        // console.log({layout, layouts})
        setLayouts({ ...layouts });
    };

    const onDrop = (layout, layoutItem, _ev) => {
        // console.log(`Element parameters:\n${JSON.stringify(layoutItem, ["x", "y", "w", "h"], 2)}`);
    };

    const onHandleEditVisual = (index) => {
        handleOnEditVisual(index)
    }
    const handleIsWithError = (value) => {
        setWithError(value)
    }
    const generateDOM = () => {
        return (
            selectedTemplateItem && selectedTemplateItem.length > 0 && selectedTemplateItem.map((item, index) => (
                <div key={index} style={{ background: "#fff", zIndex: 999, overflow: 'hidden' }}>
                    {/* // className={"static"} */}
                    

                    <DashboardTemplateItemModal
                        index={index}
                        name={item.name}
                        query={item.query}
                        item={item}
                        layout={layouts?.lg?.[index]}
                        selectedTemplateItem={selectedTemplateItem}
                        onEditVisual={onEditVisual}
                        handleCloseEditVisual={handleCloseEditVisual}
                        handleIsWithError={handleIsWithError}
                        onHandleEditVisual={onHandleEditVisual}
                    />
                </div>
            ))
        );
    };
    
    return (
        <>
            <div className={`mb-4`}>
                <WidthResponsive
                    {...props}
                    // style={{ background: "#fff" }}
                    layouts={layouts}
                    measureBeforeMount={false}
                    useCSSTransforms={mounted}
                    compactType={compactType}
                    preventCollision={!compactType}
                    onLayoutChange={onLayoutChange}
                    onBreakpointChange={onBreakpointChange}
                    onDrop={onDrop}
                    // isDroppable={props.editMode}
                >
                    {generateDOM()}
                </WidthResponsive>
            </div>
        </>
    );
}

export default ManageDashboardDnD

ManageDashboardDnD.defaultProps = {
    className: "layout",
    rowHeight: 30,
    onLayoutChange: (layout, layouts) => {},
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    containerPadding: [0, 0],
  };