import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import moment from "moment";
import CustomTooltip from "customComponents/customComponents";
import { ClipLoader } from "react-spinners";
var momentTZ = require("moment-timezone");



const ClientThirdPartyCard = ({ data, i, intDetails, userDetails, ...props }) => {
  const [isUploadingData, setisUploadingData] = useState(true)

  // console.log(intDetails)
  // console.log(userDetails)
  // console.log(data)
  return (
    <Col lg="4" xl="3" md="4" sm="6" key={data.id}>
      <Card className="">
        <CardBody className="d-flex flex-column p-2">
          <div style={{ position: "absolute", right: "10px", top: "10px" }}>
            <Row>
              {/* {data.Service.id === 1 ? (
                <Col className="pl-1" style={{ paddingRight: "5px" }}>
                  <CustomTooltip
                    i={i}
                    id={`refresh${i}`}
                    message="Update"
                    className="fas fa-pen fa-xs"
                  />
                </Col>
              ) : null} */}
              {/* {data.Service.id === 24 ? (
                <Col className="pl-1" style={{ paddingRight: "5px" }}>
                  <CustomTooltip
                    i={i}
                    id={`edit${i}`}
                    message="Edit Staff Details"
                    className="fas fa-edit text-sm"
                    onClick={() => props.openStaffList(data)}
                  />
                </Col>
              ) : null} */}
              {data?.Service?.id === 1 && !data?.Details?.BudgetUpdated ? (
                <Col className="ml--4">
                  <CustomTooltip
                    i={i}
                    id={`xero${i}`}
                    message="This Xero Connection has missing budget scope. please click here to reconnect and get the lates updates"
                    onClick={() =>
                      props.handleReconnect(data.Service.id, data.id)
                    }
                    className="fas fa-exclamation-circle text-danger"
                  />
                </Col>
              ) : null}

              {data.Service.id !== 3 && userDetails.User.UserAccess < 5 ? (
                <Col className="pl-1">
                  <CustomTooltip
                    i={i}
                    id={`viewReports${i}`}
                    message="Delete"
                    className="fas fa-times fa-sm"
                    onClick={() => props.confirmDelete(data)}
                  />
                </Col>
              ) : null}
            </Row>
          </div>
          <div className="pt-3 px-5 text-center">
            <img
              alt="..."
              className="img-center rounded img-fluid shadow-none"
              src={data.Service.Logo}
              style={{ height: "80px", objectFit: "contain" }}
            />
          </div>

          <Row className="mb-1">
            <Col sm="12">
              <hr className="my-3" />
            </Col>
            <Col sm="12" className="text-center " style={{ height: "100px" }}>
              <h4>
                {data.Details.Organisation ||
                  data.Details.Name ||
                  data.Details.name ||
                  data.Details.orgkey ||
                  (data.Status === 9 ? "Provisioning" : "Dataset Active")}
              </h4>
              <p className="text-sm mb-0 h5"> {data?.Service?.id === 24 && data?.Details?.dbDestination === 'DW' ? `${data.Service.Name} (DW)` : data.Service.Name}</p>
              <span className="text-xs text-muteds ml-2">
                {`Organisation ID: ${data.Details?.Legacy?.id ?? data.id}`}
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              {data?.Service?.id === 3 ? (
                <>
                  <h5>Workspace ID:</h5>
                  <span className="text-xs text-muteds ml-2">
                    {data.Details.id || ""}
                  </span>
                </>
              ) : data?.Service?.id === 24 ?
                (
                  <Row>
                    <Col xs={data?.Details?.dbDestination === "DW" ? "4" : "12"} className="text-center">
                      <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openStaffList(data)} style={{ cursor: 'pointer' }}>
                        <span className="btn-inner--icon">
                          <i className={`fas fa-user-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                        </span>
                        <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Users</h5>
                      </div>
                    </Col>
                    {
                      data?.Service?.id === 24 && data?.Details?.dbDestination === "DW" ?
                        <>
                          <Col xs="4" className="text-center" >
                            {data.isUploadingData ?
                              <>
                                <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                                <h5>Uploading Data</h5>
                              </>
                              : <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                                <span className="btn-inner--icon">
                                  <i className={`fas fa-file-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                                </span>
                                <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Wip Cleared</h5>
                              </div>
                            }
                          </Col>
                          <Col xs="4" className="text-center">
                            <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openJobCostUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                              <span className="btn-inner--icon">
                                <i className={`far fa-calendar-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                              </span>
                              <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Cost</h5>
                            </div>
                          </Col>
                        </>
                        : null
                    }
                  </Row>
                )
                : data?.Service?.id === 55 && data?.Details?.dbDestination === "DW" && props.firmDetails.id === 124 ?
                  <Row>
                    <Col xs={data?.Service?.id === 55 ? "12" : data?.Details?.dbDestination === "DW" ? "4" : "12"} className="text-center">
                      <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openStaffList(data)} style={{ cursor: 'pointer' }}>
                        <span className="btn-inner--icon">
                          <i className={`fas fa-user-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                        </span>
                        <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Users</h5>
                      </div>
                    </Col>
                  </Row>
                  : data?.Service?.id === 68 ?
                    <Row>
                      <Col xs="6" className="text-center" >
                        <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openJobTaskCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                          <span className="btn-inner--icon">
                            <i className={`fas fa-tasks ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                          </span>
                          <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Task</h5>
                        </div>
                      </Col>
                      <Col xs="6" className="text-center" >
                        {data.isUploadingData ?
                          <>
                            <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                            <h5>Uploading Data</h5>
                          </>
                          : <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openIncomeTaxCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`fas fa-hand-holding-usd ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Income Tax</h5>
                          </div>
                        }
                      </Col>
                    </Row>
                    :
                    data?.Service?.id === 69 ?
                      <Row>
                        <Col xs={data?.Details?.dbDestination === "DW" ? "4" : "12"} className="text-center">
                          <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBStaffList(data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`fas fa-user-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Users</h5>
                          </div>
                        </Col>
                        <Col xs="4" className="text-center" >
                          <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`fas fa-file-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Wip Cleared</h5>
                          </div>
                        </Col>
                        <Col xs="4" className="text-center">
                          <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBJobCostUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`far fa-calendar-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Cost</h5>
                          </div>
                        </Col>
                      </Row>
                      : data?.Service?.id === 70 ?
                        <Row>
                          <Col xs="6" className="text-center" >
                            <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBJobTaskCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                              <span className="btn-inner--icon">
                                <i className={`fas fa-tasks ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                              </span>
                              <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Task</h5>
                            </div>
                          </Col>
                          <Col xs="6" className="text-center" >
                            {data.isUploadingData ?
                              <>
                                <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                                <h5>Uploading Data</h5>
                              </>
                              : <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBIncomeTaxCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                                <span className="btn-inner--icon">
                                  <i className={`fas fa-hand-holding-usd ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                                </span>
                                <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Income Tax</h5>
                              </div>
                            }
                          </Col>
                        </Row>
                        :
                        (
                          <div style={{ height: "52px" }}></div>
                        )}
            </Col>
            <Col sm="12">
              <hr className="my-3" />
            </Col>
            <Col xs="12" className="text-left">
              <span className="btn-inner--icon ml-1">
                <i className="far fa-calendar-plus"></i>
              </span>
              <span className="text-xs text-muted ml-2">
                Created At: {moment(data.createdAt).format("DD-MM-YYYY")}
              </span>
            </Col>
            <Col xs="12" className="text-left">
              <span className="btn-inner--icon ml-1">
                <i className="far fa-calendar-plus"></i>
              </span>
              <span className="text-xs text-muted ml-2">
                Last Refresh:{" "}
                {data.LastRefreshDate
                  ? props.accountingFirm.TimeZone
                    ? momentTZ
                      .tz(data.LastRefreshDate, props.accountingFirm.TimeZone)
                      .format("DD-MM-YYYY hh:mm")
                    : moment(data.LastRefreshDate).format("DD-MM-YYYY hh:mm")
                  : "No record found"}
              </span>
            </Col>
          </Row>
        </CardBody>
        <div className="card-footer">
          <Row>
            <Col className="text-center">
              {props.showStatus(data.Status, data.Service.id, data.id, i)}
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default ClientThirdPartyCard;
