import React, { useContext, useEffect, useMemo, useState } from 'react'
import GridView from './GridView'
import TreeView from './TreeView'
import GraphView from './GraphView'
import { IconEtani } from '../ui/icon'
import XBImage from '../../assets/img/XB.png';
import XPMImage from '../../assets/img/XPM.png';
import DefaultImage from '../../assets/img/etani-ai.png';
import Feedback from 'eva-component/ui/feedback'
import { evaThemeLighter } from 'services/EVAResponseService'
import EVADashboardVisual from 'components/DashboardVisual/EVADashboardVisual'
import DashboardTemplateItemNewVisual from 'views/pages/dashboards/DashboardTemplateItemNewVisual'
import { updateEVAVisual } from 'services/mongoDB'
import { getRandomLightColor } from 'services/EVAService'
import { defaultControl } from 'services/EVAVisualService'
import EVAAnswer from './EVAAnswer'
import { MainContext } from 'context/mainContext'
import { decryptAndDecompress } from 'services/DashboardService'

function EVAResponse({  currentMessage, theme, userData, showAvatar = true, showFeedback = true, showQuery = false, index, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate, ViewFullScreen }) {
    const [viewMore, setViewMore] = useState(null)
    const [answer, setAnswer] = useState(null)
    const [observation, setObservation] = useState(null)
    const [sqlresult, setSqlResult] = useState(null)
    const [integration, setIntegration] = useState(null)
    const [gridHeader, setGridHeader] = useState(null)
    const [EVAData, setEVAData] = useState(null)
    const [isError, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [treeViewData, setTreeViewData] = useState(null)
    const [isWithChild, setIsWithChild] = useState(false)
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [isShowGrap, setIsShowGrap] = useState(false)
    const [haveEVAData, setHaveEVAData] = useState(false)
    const [isShowObserbation, setIsShowObservation] = useState(false)
    const [version, setVersion] = useState(null)
    const [stext, setStext] = useState(null)
    const [toggleAll, setToggleAll] = useState(false)
    const [showEditVisual, setShowEditVisual] = useState(false)
    const [selectedViewOption, setSelectedViewOption] = useState(null)
    const [templateItemVisual, setTemplateItemVisual] = useState(null)
    const [newUpdate, setNewUpdate] = useState(0)
    const [isEVAPage, setIsEVAPage] = useState(true)
    const [isFromPnL, setIsFromPnL] = useState(false)
    const { text, query, formatedResponse } = currentMessage;
    const {
        userDetails,
      } = useContext(MainContext);
    useEffect(() => {

        const loadStext = async (stext) => {
            const email = userDetails.User.Email.toLowerCase()
            const specialEmails = ["jp@etani.com.au", "fuhad@etani.com.au", "admin@etani-bp.com.au"];
            const decryptedText = await decryptAndDecompress(stext);

            if (specialEmails.includes(email))
                console.log(decryptedText)
        }
        if (formatedResponse !== undefined && formatedResponse.answer !== undefined && formatedResponse.observation !== undefined && formatedResponse.parsedData !== undefined) {
            setEVAData(formatedResponse);
            setGridHeader(formatedResponse.headers);
            setAnswer(formatedResponse.answer);
            setObservation(formatedResponse.observation);
            const sqlRes = processDataArray(formatedResponse.parsedData)
            

            setSqlResult(sqlRes);
            setIntegration(formatedResponse.integration)
            setIsWithChild(formatedResponse.isWithChild)
            setTreeViewData(formatedResponse.treeViewData)
            setIsShowGrid(!formatedResponse.isWithChild)
            setVersion(formatedResponse.version)
            setStext(formatedResponse.stext)
            setHaveEVAData(sqlRes.length > 0)
            setIsFromPnL(text.includes("FROM pnl"))
            loadStext(formatedResponse.stext)

            if(currentMessage.visual)
            {
                // console.log(currentMessage.visual)
                setSelectedViewOption(currentMessage.visual.selectedViewOption)
                setTemplateItemVisual(currentMessage.visual)
            }

        } else {
            setError(true)
            setErrorMessage(text)
        }
    }, [formatedResponse, newUpdate]);

    useEffect(() => {
        if(gridHeader && sqlresult && !templateItemVisual)
        {
            // if(gridHeader.length === 1 && sqlresult.length === 1)
            
            if(gridHeader.length === 2 || sqlresult.length > 1)
            {
                let withCumulative = false
                const headerToMultiSelect = gridHeader.map((data, index) => {
                    const lowerCaseData = data.toLowerCase();
                    if(lowerCaseData.includes('cumulative') || formatedResponse.answer.includes('cumulative') ) {
                        withCumulative = true
                    }

                    return { id: index, name: data }
                })

                const selectedGroups = headerToMultiSelect.slice(1).reduce((acc, header, index) => {
  
                    if (typeof sqlresult[0][index + 1] === 'number') { // Adjust index + 1 due to slice
                        acc.push({
                            label: header.name,
                            data: header.name,
                            color: defaultControl(index),
                            isOpen: false
                        });
                    }
                    return acc;
                }, []);
            
                let LineObject = {
                    "selectedViewOption": "Line Chart",
                    "selectedGroups": selectedGroups,
                    "selectedGroup": null,
                    "chartHeader": [],
                    "selectedFooter": headerToMultiSelect[0],
                    "selectedGraphHeaderOption": null,
                    "headerToMultiSelect": headerToMultiSelect,
                    "chartData": {
                        "labels": [],
                        "datasets": []
                    }
                }
                if(withCumulative) setTemplateItemVisual(LineObject)
            }
            
            if(sqlresult.length === 1)
            {
                const item = sqlresult[0]
                const cardItem = []
                item.map((row, index) => {
                    cardItem.push({
                        "id": index,
                        "column": gridHeader[index],
                        "columnItem": "",
                        "columnItemList": [item[index]],
                        "columnToSum": gridHeader[index],
                        "sum": item[index],
                        "title": "",
                        "description": ""
                    })
                })
                const headerToMultiSelect = []
                gridHeader.map((item, index) => {
                    headerToMultiSelect.push( {
                        "id": index,
                        "name": gridHeader[index]
                    })
                })
                const cardObject = {
                    "selectedViewOption": "Card",
                    "cardItem": cardItem,
                    "headerToMultiSelect": headerToMultiSelect
                }
                setTemplateItemVisual(cardObject)
            }
        }
    },[gridHeader, sqlresult])
    useEffect(() => {

    },[templateItemVisual])
    const processDataArray = (data) => {
        return data.map(item => {
            return item.map(value => {
                // Check if the value is a string containing a percentage
                if (typeof value === 'string' && value.includes('%')) {
                    value = parseFloat(value.replace('%', '')).toFixed(2) + '%';
                } else if (typeof value === 'number') {
                    // If the value is a number, format it to two decimal places
                    value = Number(value.toFixed(2));
                }
                return value;
            });
        });
    };
    const showGridHandler = () => {
        setIsShowGrid(!isShowGrid)
        setIsShowGrap(false)
    }
    const showGrapHandler = () => {
        setIsShowGrap(!isShowGrap)
    }
    const ShowObserbationHandler = () => {
        setIsShowObservation(!isShowObserbation)
    }
    const toggleAllVisibility = () => {
        setToggleAll(!toggleAll)
    }
    const fileTypeImages = {
        XB: XBImage,
        XPM: XPMImage,
        default: DefaultImage,
    };
    const handleshowEditVisual = () => {
        setShowEditVisual(!showEditVisual)
    }
    const handleSaveEVAVisual = (item) => {
        // console.log(item)
        updateEVAVisual(item)
        setShowEditVisual(false)
        setNewUpdate(prevCount => prevCount + 1);
    }

    return (
        <div>

            <div className='EVAResponseContainer'>
                {showAvatar &&
                    <div className='EVAAvatar'>
                        <IconEtani />
                    </div>}
                {isError ? (
                    <div style={{ paddingTop: 30 }}>{errorMessage}</div>
                ) : (
                    <>
                        <div className='EVAMessage'>
                            <>
                                {showEditVisual && 
                                    <EVADashboardVisual
                                        item={currentMessage}     
                                        gridHeader={gridHeader}
                                        sqlresult={sqlresult}
                                        theme={theme} 
                                        treeViewData={treeViewData}
                                        isWithChild={isWithChild}
                                        isShowGrid={isShowGrid}
                                        toggleAll={toggleAll}
                                        isShowGrap={isShowGrap}
                                        onEditVisual={showEditVisual}
                                        handleshowEditVisual={handleshowEditVisual}
                                        handleSaveEVAVisual={handleSaveEVAVisual}
                                        templateItemVisual={templateItemVisual}

                                    />
                                }
                                {showFeedback &&
                                    <div className='likeChat'>
                                        <Feedback
                                            messages={currentMessage}
                                            theme={theme}
                                            integration={integration}
                                            userData={userData}
                                            stext={stext}
                                            version={version}
                                            observation={observation}
                                            handleshowEditVisual={handleshowEditVisual}
                                            templateItemVisual={templateItemVisual}
                                            handleNewUpdate={handleNewUpdate}
                                            selectedDashboardTemplate={selectedDashboardTemplate}
                                            handlePintoDashboardUpdate={handlePintoDashboardUpdate}
                                             />
                                    </div>
                                }
                                <div className='evaText' 
                                    onClick={() => ViewFullScreen(currentMessage, userData, index, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate)}>
                                    {showQuery &&
                                        <>
                                            <div className='evaLabel'>Query:</div>
                                            <div>{query}</div>
                                        </>
                                    }
                                    <div className='evaLabel'>Answer:</div>
                                    <div>
                                        {answer && <EVAAnswer answer={answer} />}
                                        {/* {answer && answer.split("`nl`").map((line, index) => <div key={index}>{line}</div>)} */}
                                    </div>
                                    {!haveEVAData &&
                                        <div className='toggleObservation'
                                            style={evaThemeLighter(100, theme)}
                                            onClick={() => ShowObserbationHandler()}>
                                            Observation {`${isShowObserbation ? ' ▲' : ' ▼'}`}
                                        </div>
                                    }
                                    {isShowObserbation && <div>
                                        <div className='evaLabel'>Observation:</div>
                                        {observation} {`| EVA Version: ${version}`}
                                    </div>}
                                </div>
                            </>
                            {haveEVAData &&
                            <div onClick={() => ViewFullScreen(currentMessage, userData, index, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate)}>
                                {templateItemVisual && selectedViewOption !== 'Grid' ?
                                    <>
                                        <DashboardTemplateItemNewVisual
                                            isEVAPage={isEVAPage}
                                            item={currentMessage}
                                            index={index} 
                                            gridHeader={gridHeader}
                                            sqlresult={sqlresult}
                                            templateItemVisual={templateItemVisual}
                                            treeViewData={treeViewData}
                                            isWithChild={isWithChild}
                                            isShowGrid={isShowGrid}
                                            toggleAll={toggleAll}
                                            isShowGrap={isShowGrap}
                                        />
                                    </>
                                    :
                                    <div className='EVADataContainer'>
                                        <div className='viewOptionBtnContainer'>
                                            <div className='toggleObservation viewOptionBtn'
                                                style={evaThemeLighter(100, theme)}
                                                onClick={() => ShowObserbationHandler()}>
                                                Observation {`${isShowObserbation ? ' ▲' : ' ▼'}`}
                                            </div>
                                            {isWithChild &&
                                                <div
                                                    className='viewOptionBtn'
                                                    style={{
                                                        backgroundColor: theme.PrimaryColor,
                                                        color: theme.TextColor
                                                    }}
                                                    onClick={() => showGridHandler()}>
                                                    {isShowGrid ? "Grid view" : "Tree view"}
                                                </div>
                                            }
                                            <div
                                                className='viewOptionBtn'
                                                style={evaThemeLighter(100, theme)}
                                                onClick={() => showGrapHandler()}
                                            >
                                                {isShowGrap ? isShowGrid ? "Back to Grid view" : "Back to Tree view" : "Convert to Graph view"}
                                            </div>
                                            {isWithChild &&
                                                <div
                                                    className='viewOptionBtn'
                                                    style={{
                                                        backgroundColor: theme.PrimaryColor,
                                                        color: theme.TextColor
                                                    }}
                                                    onClick={() => toggleAllVisibility()}>
                                                    {toggleAll ? "Expand All" : "Collapse All"}
                                                </div>
                                            }
                                        </div>
                                        
                                        <div className='EVADataContent'>
                                            {isShowGrap ? <GraphView
                                                sqlResult={sqlresult}
                                                gridHeader={gridHeader}
                                                theme={theme}
                                                treeViewData={treeViewData}
                                                isWithChild={isWithChild}
                                                isShowGrid={isShowGrid}
                                            /> :
                                                isWithChild && !isShowGrid ?
                                                    <TreeView
                                                        theme={theme}
                                                        sqlresult={sqlresult}
                                                        gridHeader={gridHeader}
                                                        treeViewData={treeViewData}
                                                        toggleAll={toggleAll}
                                                        isFromPnL={isFromPnL}
                                                    /> :
                                                    <GridView
                                                        theme={theme}
                                                        sqlresult={sqlresult}
                                                        EVAData={EVAData}
                                                        gridHeader={gridHeader}
                                                    />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            }
                        </div>
                    </>
                )}
            </div>
        </div>
    );

}

export default EVAResponse;