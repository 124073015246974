import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import Messages from "./Messages";
import Input from "./Input";
import { fetchEVAMessages, getMessageReact, sendEVAPrivateMessage } from "../services/mongoDB";
import EVAResponse from "./response/EVAResponse";
import { EVAPrivate, EVAService, EVAServiceStream } from "../services/EVAService";
import { CloseTab } from "./ui/icon";
import EVATyping from "./ui/eva-typing";
import EVAWelcome from "./response/EVAWelcome";
import { evaThemeLighter } from "services/EVAResponseService";
import EVAStreamLoading from "./response/EVAStreamLoading";

const Chat = ({ isEVAPage = false, handleOpenTab, onSelectUUID, selectedUUID, ClientID, userid, userData, theme, onIsLoading, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate }) => {

  const { business, client, user} = userData
  const [messages, setMessages] = useState([]);
  const [streamMessages, setStreamMessages] = useState([]);
  const [isEVATyping, setIsEVATyping] = useState(false)
  const [inputText, setInputText] = useState('');
  const [isStreamLoading, setIsStreamLoading] = useState(true)
  const [streamMessage, setStreamMessage] = useState([])
  const messagesContainerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenContent, setFullScreenContent] = useState(null);

  useEffect(()=> {
    const getLocalStorage = async () => {
      try {
        setMessages([])
        setInputText("")

        await loadMessages()
        // const messageReact = await getMessageReact({chatId : user.uid})
        // console.log(messageReact)
      } catch (error) {
          console.log(error);
      }
    };
    getLocalStorage();
  }, [selectedUUID])
  const handleSendEVAStreamMessage = async (text) => {
    // setIsEVATyping(true)
    setIsStreamLoading(true)
    const newMessage = {
      uuid: selectedUUID,
      clientid: client.uid,
      userid: user.uid,
      // _id: Math.floor(Math.random() * 10000000000000),
      text: text,
      sender: user.uid,
      user: mapUser(),
      createdAt: new Date(),
    }
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    sendEVAPrivateMessage(newMessage)
    setTimeout(() => {
      scrollToBottom()
    }, 200);

    setEVAStream('')
    const raw = {
      "chatId": selectedUUID,
      "query": text,
      "UserInfo": {
        "chatId": selectedUUID,
        "UserId": user.uid,
        "Name": user.fullName,
        "ClientId": client.uid,
        "BusinessId": business.uid
      }
    };

    try {
     
      // const response = await fetch('https://evastaging.azurewebsites.net/eva_stream', {
        const response = await fetch('https://evabetaprod.azurewebsites.net/eva_stream', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(raw)
      });
      
      const reader = response.body.getReader();
      let chunks = '';
      const streamMessageArray = []

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks += new TextDecoder().decode(value);
        const messagesArray = chunks.split('\n');
        streamMessageArray.push(chunks)
        setStreamMessage([...streamMessageArray])
   
      }
      await saveEVAStream(streamMessageArray[streamMessageArray.length - 1], text)
    } catch (error) {
        await saveEVAStream(`We are currently encountering an issue while processing your request. 
                            We kindly ask that you attempt your request again at a later time. 
                            If you continue to receive this message repeatedly, 
                            we urge you to contact our dedicated support team for prompt assistance. 
                            Thank you!`, text);
    }
    setIsStreamLoading(false)
    setStreamMessage([])
  }
  const handleSendEVAMessage = async (text) => {
    setIsEVATyping(true)
    const newMessage = {
      uuid: selectedUUID,
      clientid: client.uid,
      userid: user.uid,
      // _id: Math.floor(Math.random() * 10000000000000),
      text: text,
      sender: user.uid,
      user: mapUser(),
      createdAt: new Date(),
    }
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    // 
    // setEVAType()
    sendEVAPrivateMessage(newMessage)
    const response = await EVAService(text, "", client.uid, user.uid, selectedUUID, user.fullName, business.uid)
    await loadMessages()
    setIsEVATyping(false)
  }
  const saveEVAStream = async (message, msg) => {
    const response = await EVAPrivate(message, "", client.uid, user.uid, selectedUUID, msg);
    await loadMessages()
  };
  const setEVAStream = (message) => {
    const newMessage = {
      uuid: selectedUUID,
      clientid: client.uid,
      userid: user.uid,
      // _id: Math.floor(Math.random() * 10000000000000),
      text: message,
      sender: user.uid,
      user: mapEVA(),
      createdAt: new Date(),
      isStreamMessage: true
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const handleLoading = (value) => {
    onIsLoading(value);
  }   
  function mapEVA()
  {
      return {
          _id: Math.floor(Math.random() * 10000000000000),
          sender: Math.floor(Math.random() * 10000000000000),
          name: "EVA" ,
          // avatar: '../../etani.png'
      };
  }
  const loadMessages = async () => {
    const myChatroom = await fetchMessages();
    isEVATyping && handleLoading(false)
    if (myChatroom && myChatroom !== undefined)
        setMessages(myChatroom);

    setTimeout(() => {
      scrollToBottom()
    }, 1000);
      
  }
  function mapUser() {
    return {
        _id: user.uid,
        sender: user.uid,
        name: user.fullName,
    };
  }
  const fetchMessages = useCallback(async (messageLimit) => {
    try {
        if(client.uid !== undefined)
        {
          const EVAMessage = await fetchEVAMessages({ clientid: client.uid, userid: user.uid, pageSize: 0, uuid: selectedUUID })
          return EVAMessage
        }else return []
    } catch (error) {
        console.error('Error fetching messages:', error);
        return [];
    }
  }, [selectedUUID]);
  const handleSetInputText = (text) => {
    console.log(text)
    setInputText(text)
  }
  const scrollToBottom = () => {
    // console.log("scroll to bottom!");
    // console.log("messagesContainerRef.current:", messagesContainerRef.current);
    // console.log("scrollHeight:", messagesContainerRef.current?.scrollHeight);
    messagesContainerRef.current?.scrollTo(0, messagesContainerRef.current?.scrollHeight);
  };
  const ViewFullScreen = (message, userData, index, handleNewUpdate, selectedDashboardTemplate, handlePintoDashboardUpdate) => {
    // setIsFullScreen(true);
    // setFullScreenContent(
    //   <EVAResponse
    //     currentMessage={message}
    //     userData={userData}
    //     theme={theme}
    //     index={index}
    //     handleNewUpdate={handleNewUpdate}
    //     selectedDashboardTemplate={selectedDashboardTemplate}
    //     handlePintoDashboardUpdate={handlePintoDashboardUpdate}
    //   />
    // );
  };
  const exitFullScreen = () => {
    setIsFullScreen(false);
    setFullScreenContent(null);
  };
  return (
    <div className="chat" style={{ border: `2px solid ${theme.PrimaryColor}` }}>
      {isFullScreen ? (
        <div className="messages">
          <div key={0} className="message leftMessage">
            <div className="fullScreenContainer">
              <div className="fullScreenContent">
                {fullScreenContent}
                <div className="fullScreenHeader">
                  <button onClick={exitFullScreen}>Exit Full Screen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="chatHeader" style={evaThemeLighter(100, theme)} onClick={handleOpenTab}>
            <div className="closeTab">
              <CloseTab />
            </div>
          </div>
          <div className="messages">
            {messages.length > 0 ? (
              messages.map((message, index) =>
                message.user.name === "EVA" ? (
                  <div key={index} className="message leftMessage">
                    {message.isStreamMessage ? (
                      <EVAStreamLoading streamMessage={streamMessage} />
                    ) : (
                        <EVAResponse
                          currentMessage={message}
                          userData={user}
                          theme={theme}
                          index={index}
                          handleNewUpdate={handleNewUpdate}
                          selectedDashboardTemplate={selectedDashboardTemplate}
                          handlePintoDashboardUpdate={handlePintoDashboardUpdate}
                          ViewFullScreen={ViewFullScreen}
                        />
                    )}
                  </div>
                ) : (
                  <div key={index} className="message owner">
                    <div className="userMessage" style={evaThemeLighter(100, theme)}>
                      <div className="messageText">{message.text}</div>
                    </div>
                  </div>
                )
              )
            ) : (
              <EVAWelcome userName={user.fullName} theme={theme} onSetInputText={handleSetInputText} />
            )}
          </div>
          <div>
            <Input
              selectedUUID={selectedUUID}
              onSendEVAMessage={handleSendEVAStreamMessage}
              onSelectUUID={onSelectUUID}
              ClientID={client.uid}
              userid={user.uid}
              theme={theme}
              onInputText={inputText}
            />
          </div>
          {isEVATyping && <EVATyping text=". . . ." delay={150} isEVAPage={isEVAPage} infinite />}
        </>
      )}
    </div>
  );
};

export default Chat;