import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { CardImg, Spinner, Button, Modal } from "reactstrap";
import QRcode from "qrcode.react";
import "./styles.css";
import { MainContext } from "context/mainContext";

const MFAPushNotificationPage = () => {
  const { firmDetails } = useContext(MainContext)
  const routerHistory = useHistory();
  const [userDetails, setUserDetails] = useState("");
  const [passCode, setPassCode] = useState("");
  const [mfaTextbox, setMfaTextbox] = useState("");
  const [message, setMessage] = useState("");
  const [verifyPushNotifCode, setVerifyPushNotifCode] = useState("");
  const emailAddress = routerHistory.location.state;
  const [loading, setLoading] = useState(false);
  const [loadingVerfication, setLoadingVerification] = useState(false);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThreeBackupEmail, setStepThreeBackupEmail] = useState(false);
  const [stepFourBackupEmail, setStepFourBackupEmail] = useState(false);
  const [stepFiveSecurityQuestion, setStepFiveSecurityQuestion] =
    useState(false);
  const [stepSixSuccessScreen, setSixSuccessScreen] = useState(false);
  const [accountingLogo, setAccountingLogo] = useState("");
  const [backupEmailModal, setBackupEmailModal] = useState(false);
  const [backupEmailError, setBackupEmailError] = useState(false);
  const [backUpEmailMessage, setBackUpMessage] = useState("");
  const [backUpEmail, setBackEmail] = useState("");
  const [mailData, setMailData] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [confirmCodeEmail, setConfirmCodeEmail] = useState("");
  const [saveMessageData, setSaveMessageData] = useState("");
  const [mfaCodeModal, setMfaCodeModal] = useState(false);
  const [qr, setQr] = useState({
    android: "https://play.google.com/",
    ios: "https://apps.apple.com/au/app/enspira-financial/id1668934734",
  });

  const [btnLoading, setBtnLoading] = useState(false)

  const defaultQuestions = [
    "As a child, what did you want to be when you grew up?",
    "What is your dream car?",
    "What is your dream job?",
    "What street did you live on when you were 10 years old?",
    "What was the best holiday gift you ever received?",
    "What was the name of your first pet?",
    "Who is your favourite fictional character?",
    "Who is your favourite person from history?",
    "Who is your favourite poet?",
    "What was your favourite place to visit as a child?",
    "What is the first name of your closest childhood friend?",
    "What road did you live on when you first started school?",
    "What road did your best friend in secondary/high school live on?",
    "What is the phone number you remember best from your childhood?",
    "What road did you live on when you attended secondary/high school?",
    "What is your most disliked movie?",
    "What is your most disliked holiday?",
    "Which movie scared you most as a child?",
    "What is the name of your favourite cartoon?",
    "what was the name of your favourite stuffed animal?",
    "What was the first concert you attended?",
  ];

  const [questions, setQuestions] = useState([]);

  // Handlers

  const closeModalMfaCodeVerification = () => {
    setMfaCodeModal(false);
  };

  const confirmEmailCodeInputHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setConfirmCodeEmail(value);
  };

  const onConfirmCodeEmail = () => {
    saveMFASettingsData();
  };

  const saveMFASettingsData = async () => {
    // setLoading(true);
    setBtnLoading(true)

    try {
      const response = await fetch('/api/auth/verifyEmailCode', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          code: confirmCodeEmail,
          uid: userDetails.id,
          ubID: userDetails.ubID
        }),
      });

      const emailValidation = await response.json();

      if (emailValidation.error) {
        setBtnLoading(false)
        return setMfaCodeModal(true);
      } else {
        const mfaData = async () => {
          try {
            const mfaSettings = await fetch("/api/auth/saveBackUpEmailMFA", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                backupEmail: backUpEmail,
                ubID: userDetails.ubID
              }),
            });
            const data = await mfaSettings.json();
            // setLoading(false);
            setSaveMessageData(data);
            return setTimeout(() => {
              setStepFourBackupEmail(false);
              setSixSuccessScreen(true);
              setBtnLoading(false)
            }, 2000);
          } catch (error) {
            setBtnLoading(false)
            console.log(error);
          }
        };

        return mfaData();
      }
    } catch (error) {
      setBtnLoading(false)
      console.log(error);
    }

    // const userDetailsUpdated = await fetch(`/api/user/userDetails?code=${confirmCodeEmail}`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // const response = await userDetailsUpdated.json();
    // console.log(response)
    // console.log(confirmCodeEmail)
    // if (!response.backupEmailCodeMatch) {
    //   setBtnLoading(false)
    //   return setMfaCodeModal(true);
    // }
    // if (response.backupEmailCodeMatch) {
    //   mfaData();
    // }
  };

  // Use Effects
  useEffect(() => {
    const getUserDetails = async () => {
      const details = await fetch("/api/user/userDetails", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await details.json();
      setUserDetails(data);
    };
    getUserDetails();
  }, [userDetails.deviceToken]);

  useEffect(() => {
    fetchFirmLogo();
  }, [mailData]);

  useEffect(() => {
    const checkIfLogin = async () => {
      const details = await fetch("/api/auth/checkLogin", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await details.json();
      if (!data.authenticated) {
        return routerHistory.push("/auth/login");
      }
    };
    checkIfLogin();
  }, []);

  const sendMFAEmail = () => {
    // setLoading(true);
    setBtnLoading(true)
    const sendMfaCode = async () => {
      try {
        const mail = await fetch("/api/auth/sendBackUpEmailMFACode", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromEmail: backUpEmail.toLowerCase(),
            accountingFirmLogo: accountingLogo.logo,
            firmCountry: firmDetails?.CountryCode
          }),
        });
        const data = await mail.json();
        // console.log(data)
        setLoading(false);
        setMailData(data);
        return setTimeout(() => {
          setStepThreeBackupEmail(false);
          setStepFourBackupEmail(true);
          setBtnLoading(false)
        }, 2500);
      } catch (error) {
        console.log(error);
        setBtnLoading(false)
      }
    };
    sendMfaCode();
  };

  const backUpEmailOnChangeHandler = (e) => {
    const value = e.target.value;
    setBackEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(value));
    setBackupEmailError(false)
    setBackUpMessage("")
  };

  const confirmBackUpEmailMFA = () => {
    setBtnLoading(true)
    if (backUpEmail === "") {
      setBackUpMessage("Email is required. Try again.");
      return setTimeout(() => {
        setBackUpMessage("");
        setBtnLoading(false)
      }, 1000);
    }

    if (!isValid) {
      setBackUpMessage("Email address is invalid. Try again");
      return setTimeout(() => {
        setBackUpMessage("");
        setBtnLoading(false)
      }, 1000);
    }

    const confirmBackUpEmail = async () => {
      try {
        const auth = await fetch("/api/auth/confirmMFABackupEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            backupEmail: backUpEmail.toLowerCase(),
            ubID: userDetails.ubID
          }),
        });
        const data = await auth.json();

        if (!data.success) {
          setBtnLoading(false)
          setBackupEmailError(true)
          return setBackUpMessage("Please provide an alternate email address that is distinct from the one used for your login.")
          // return setBackupEmailModal(true);
        }
        setBtnLoading(false)
        sendMFAEmail();
      } catch (error) {
        console.log(error);
        setBtnLoading(false)
      }
    };
    confirmBackUpEmail();
  };

  const securityQuestionDefaultPage = () => {
    setStepFiveSecurityQuestion(true);
    setStepThreeBackupEmail(false);
    setStepOne(false);
    setStepTwo(false);
    setStepFourBackupEmail(false);
  };

  const setupCompletedScreen = () => {
    setSixSuccessScreen(true);
    setStepFiveSecurityQuestion(false);
    setStepThreeBackupEmail(false);
    setStepOne(false);
    setStepTwo(false);
    setStepFourBackupEmail(false);
  };

  // TO DO : Add redirecting route if MFA setting is enabled

  // Verify Mobile Device
  const onVerifyMobileAccount = async () => {
    setBtnLoading(true)
    try {
      const user = await fetch("/api/user/userDetails", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await user.json();
      // console.log(data)
      if (!data.MFA.DeviceToken) {
        setBtnLoading(false)
        setLoading(false);
        setMessage("There are no devices found under this account.");
        setTimeout(() => {
          setMessage("");
        }, 2000);
        return;
      }
      const sendNotification = async () => {
        const addDeviceToken = await fetch("/api/auth/mfaPushNotification", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pushToken: data.MFA.DeviceToken,
            uid: data.id,
            ubID: data.ubID
          }),
        });
        const tokenData = await addDeviceToken.json();
        setPassCode(tokenData);
      };
      setLoading(false);
      setMessage("Your account has been validated.");
      setTimeout(() => {
        setBtnLoading(false)
        setMessage("");
        setStepOne(false);
        setStepTwo(true);
      }, 2000);
      setTimeout(() => {
        sendNotification();
      }, 2000);
    } catch (error) {
      setBtnLoading(false)
      console.log(error);
    }
  };

  const mfaOnChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMfaTextbox(value);
  };

  const onVerifyHandler = async () => {
    setBtnLoading(true)
    if (mfaTextbox === "") {
      setVerifyPushNotifCode("Text field is empty. Try again.");
      return setTimeout(() => {
        setBtnLoading(false)
        setVerifyPushNotifCode("");
      }, 2000);
    }

    const verifyMobileCode = await fetch(`/api/auth/verifyMFAMobileCode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: mfaTextbox,
        uid: userDetails.id,
        ubID: userDetails.ubID
      }),
    });

    const verified = await verifyMobileCode.json();


    if (!verified.success) {
      setVerifyPushNotifCode("Invalid 4-digit pin. Try again.");
      return setTimeout(() => {
        setBtnLoading(false)
        setVerifyPushNotifCode("");
      }, 2000);
    }

    if (verified.success) {
      setLoadingVerification(false);
      setVerifyPushNotifCode("Verification successful");
      setTimeout(async () => {
        let removeCode = await fetch(`/api/auth/removeMobileCode`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: userDetails.id,
            ubID: userDetails.ubID
          }),
        });
        const removed = await removeCode.json();
        if (removed.success) {
          setBtnLoading(false)
          setStepTwo(false);
          setStepThreeBackupEmail(true);
        }
      }, 2000);
    }
  };

  const resendCodeClickHandler = async () => {
    // console.log(userDetails)
    setBtnLoading(true)
    try {
      const sendNotification = async () => {
        const addDeviceToken = await fetch("/api/auth/mfaPushNotification", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pushToken: userDetails.MFA.DeviceToken,
            uid: userDetails.id,
            ubID: userDetails.ubID
          }),
        });
        const tokenData = await addDeviceToken.json();
        setPassCode(tokenData);
        setBtnLoading(false)
      };
      sendNotification();
    } catch (error) {
      setBtnLoading(false)
      console.log(error);
    }
  };

  const fetchFirmLogo = () => {
    const firmLogo = async () => {
      try {
        const data = await fetch("/api/accountingFirm/getLogo", {
          method: "GET",
        });
        const logo = await data.json();
        setAccountingLogo(logo);
      } catch (error) {
        console.log(error);
      }
    };
    firmLogo();
  };

  const closeModalHandlerBackupEmail = () => {
    setBackupEmailModal(false);
  };

  // ADDED

  const [modalSecurityQuestions, setModalSecurityQuestion] = useState(false);

  const submitSecurityQuestions = (event) => {
    event.preventDefault();
    setLoading(true);
    const saveSecQuestionData = async () => {
      try {
        const answers = await fetch("/api/auth/saveSecurityQuestions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            questions: questions,
          }),
        });
        const data = await answers.json();
        if (data.success) {
          setLoading(false);
          setMessage("Your answers are now saved.");
          return setTimeout(() => {
            setupCompletedScreen();
          }, 1500);
        } else {
          setLoading(false);
          return setMessage("Something went wrong saving the date. Try again.");
        }
      } catch (error) {
        console.log(error);
      }
    };
    saveSecQuestionData();
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    const isDuplicate = newQuestions.some(
      (question, i) => i !== index && question.question === value
    );
    if (isDuplicate) {
      setModalSecurityQuestion(true);
      newQuestions[index].question = "";
      return;
    }
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].answer = value;
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "", answer: "" }]);
  };

  const canSaveQuestions = () => {
    if (questions.length < 3) {
      return false;
    }
    return questions.every(
      (q) => q.question.trim() !== "" && q.answer.trim() !== ""
    );
  };

  const closeModalHandlerSecurityQuestions = () => {
    setModalSecurityQuestion(false);
  };

  const goBackBackupEmailScreen = () => {
    setStepThreeBackupEmail(true);
    setStepFiveSecurityQuestion(false);
    setStepOne(false);
    setStepTwo(false);
    setStepFourBackupEmail(false);
    setSixSuccessScreen(false);
  };

  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={modalSecurityQuestions}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Duplicate question
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            Please choose a different question to proceed.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandlerSecurityQuestions}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={backupEmailModal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid Email Address
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            Please provide an alternate email address that is distinct from the
            one used for your login.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandlerBackupEmail}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={mfaCodeModal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid Authentication Code
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            Check your secondry email inbox and copy the correct code.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalMfaCodeVerification}
          >
            Close
          </Button>
        </div>
      </Modal>
      {stepOne && (
        <>
          <div className="mfa-push-notification-container-blk">
            <div></div>
            <h1>Multi-Factor Setup</h1>
            <p>Follow the steps below to configure MFA on your account</p>
            <div className="form-mfa-container-blk">
              <hr />
              <>
                <p>
                  <b>Step 1:</b> Scan the QR Code below to install the app on
                  your device.
                </p>
                <div className="card-flex-blk">
                  <div>
                    <CardImg
                      src={require("assets/img/play.svg").default}
                      alt="google play"
                    />
                  </div>
                  <div>
                    <CardImg
                      src={require("assets/img/ios.svg").default}
                      alt="app store"
                    />
                  </div>
                </div>
                <div className="card-flex-blk">
                  <div>
                    <QRcode
                      id="https://xyz.etani.com.au/auth/login"
                      src={require("assets/img/play.svg").default}
                      href={qr.android}
                      value={qr.android}
                      alt="play-store"
                      style={{
                        width: "140px",
                        height: "140px",
                        margin: "0px auto",
                        display: "block",
                      }}
                    />
                    <span className="footnote-qrcode-blk">
                      Scan QR Code for Android
                    </span>
                  </div>
                  <div>
                    <QRcode
                      id="https://xyz.etani.com.au/auth/login"
                      src={require("assets/img/play.svg").default}
                      href={qr.ios}
                      value={qr.ios}
                      alt="play-store"
                      style={{
                        width: "140px",
                        height: "140px",
                        margin: "0px auto",
                        display: "block",
                      }}
                    />
                    <span className="footnote-qrcode-blk">
                      Scan QR Code for IOS
                    </span>
                  </div>
                </div>
                <br />
                <p>
                  <b>Step 2:</b>{" "}
                  {`Open the app and login to your account (${emailAddress}).`}
                </p>

                <p>
                  <b>Step 3:</b> After you login, click the button below to
                  verify your mobile device.
                </p>
                <div
                  className="card-flex-blk button-blk-mfa-container"
                  style={{ marginTop: "0px", marginBottom: "10px" }}
                >
                  <div>
                    <button
                      className="mfa-btn-blk-class"
                      onClick={() => {
                        routerHistory.push("/client/accountant/details");
                      }}
                      disabled={btnLoading ? true : false}
                    >
                      Skip for now
                    </button>
                  </div>
                  <div>
                    <button
                      className="mfa-btn-blk-class verify-blk-btn"
                      onClick={onVerifyMobileAccount}
                      disabled={btnLoading ? true : false}
                    >
                      {
                        btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} />
                          :
                          <div>
                            Verify Mobile Device
                            <span className="btn-inner--icon"></span>
                          </div>
                      }
                    </button>
                  </div>
                </div>

                {loading ? (
                  <Spinner
                    style={{ display: "block", margin: " 30px auto 0px auto" }}
                  />
                ) : !message ? (
                  ""
                ) : (
                  <div
                    className={
                      message === "Your account has been validated."
                        ? "message-blk-validation-success"
                        : "message-blk-validation-error"
                    }
                  >
                    {message}
                  </div>
                )}
              </>
              <hr style={{ marginTop: "15px" }} />
              <Link
                to={{
                  pathname: "/auth/multi-factor-google-authenticator",
                  state: { emailAddress },
                }}
              >
                Do you want to setup up mfa using Google Authenticator instead?
              </Link>
            </div>
          </div>
          <div className="footer-blk">
            <p>
              &copy; 2023 - <span>Etani</span>
            </p>
          </div>
        </>
      )}

      {stepTwo && (
        <>
          <div className="mfa-push-notification-container-blk" style={{ height: "350px" }}>
            <div></div>
            <h1>Multi-Factor Setup</h1>
            <p>Follow the steps below to configure MFA on your account</p>
            <div className="form-mfa-container-blk">
              <hr />
              <>
                <p>
                  <b>Step 4:</b> Enter the 4-digit code found on the app and
                  click Verify.
                </p>
                <div className="form-blk-mfa">
                  <input
                    type="text"
                    placeholder="Enter your code here..."
                    maxLength={4}
                    value={mfaTextbox}
                    onChange={mfaOnChangeHandler}
                  />
                  <button onClick={onVerifyHandler} disabled={btnLoading ? true : false}>{btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} /> : `VERIFY`}</button>
                  {loadingVerfication ? (
                    <Spinner
                      style={{
                        display: "block",
                        margin: " 30px auto 0px auto",
                      }}
                    />
                  ) : verifyPushNotifCode ? (
                    verifyPushNotifCode === "Verification successful" ? (
                      <div className="message-blk-validation-success">
                        {verifyPushNotifCode}
                      </div>
                    ) : (
                      <div className="message-blk-validation-error">
                        {verifyPushNotifCode}
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <>
                  <p>
                    <div
                      className="card-flex-blk button-blk-mfa-container"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <div>
                        <button
                          className="mfa-btn-blk-class"
                          onClick={() => {
                            routerHistory.push("/client/accountant/details");
                          }}
                          disabled={btnLoading ? true : false}
                        >
                          Skip for now
                        </button>
                      </div>
                      <div>
                        <button
                          className="mfa-btn-blk-class"
                          onClick={resendCodeClickHandler}
                          disabled={btnLoading ? true : false}
                        >
                          <div>
                            Resend Code
                            <span className="btn-inner--icon"></span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </p>
                </>
              </>
              <hr style={{ marginTop: "15px" }} />
              <Link
                to={{
                  pathname: "/auth/multi-factor-google-authenticator",
                  state: { emailAddress },
                }}
              >
                Do you want to setup up mfa using Google Authenticator instead?
              </Link>
            </div>
          </div>
          <div className="footer-blk">
            <p>
              &copy; 2023 - <span>Etani</span>
            </p>
          </div>
        </>
      )}

      {stepThreeBackupEmail && (
        <>
          <div
            className="mfa-push-notification-container-blk"
            style={{ height: "350px" }}
          >
            <div></div>
            <>
              <h1>Setup Backup Email Address</h1>
              <div className="form-mfa-container-blk">
                <hr />
                <div>
                  <p>
                    <b>Step 5:</b> This email is only used to log in to your
                    account if you're locked out or don't have your phone.
                    <br />
                  </p>
                  <div className="form-blk-mfa">
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                        marginTop: "0px",
                        borderColor: `${backupEmailError ? 'red' : "#D9D9D9"}`
                      }}
                      value={backUpEmail}
                      placeholder="Enter email address..."
                      onChange={backUpEmailOnChangeHandler}
                    />
                    {!backUpEmailMessage ? (
                      ""
                    ) : (
                      <div
                        className={
                          backUpEmailMessage ===
                            "Email is required. Try again." ||
                            backUpEmailMessage ===
                            "Email address is invalid. Try again" ||
                            backUpEmailMessage === "Please provide an alternate email address that is distinct from the one used for your login."
                            ? "message-blk-validation-error"
                            : "message-blk-validation-success"
                        }
                      >
                        {backUpEmailMessage}
                      </div>
                    )}

                    <i
                      style={{
                        fontSize: "12px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        display: "block",
                      }}
                    >
                      <b>Note:</b> It must be different to the email you are
                      using when logging in to your account
                    </i>
                  </div>

                  <div
                    className="card-flex-blk button-blk-mfa-container"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      <button
                        className="mfa-btn-blk-class"
                        onClick={() => {
                          routerHistory.goBack();
                        }}
                        disabled={btnLoading ? true : false}
                      >
                        Back to Prev Page
                      </button>
                    </div>

                    <div>
                      <button
                        className="mfa-btn-blk-class verify-blk-btn"
                        onClick={confirmBackUpEmailMFA}
                        disabled={btnLoading ? true : false}
                      >
                        {
                          btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} />
                            :
                            <div>
                              CONTINUE
                              <span className="btn-inner--icon"></span>
                            </div>
                        }
                      </button>
                    </div>
                  </div>
                  <div>
                    {loading ? (
                      <Spinner style={{ display: "block", margin: "0 auto" }} />
                    ) : (
                      <div
                        className={
                          mailData.length === 0
                            ? ""
                            : mailData.message ===
                              "Verify your secondary email to retrieve the authentication code."
                              ? "message-blk-validation-success"
                              : "message-blk-validation-error"
                        }
                      >
                        {mailData.message}
                      </div>
                    )}
                  </div>
                </div>
                <hr style={{ marginTop: "15px" }} />
                <Link onClick={securityQuestionDefaultPage}>
                  Use security questions as your back up method instead
                </Link>
              </div>
            </>
          </div>
          <div className="footer-blk">
            <p>
              &copy; 2023 - <span>Etani</span>
            </p>
          </div>
        </>
      )}

      {stepFourBackupEmail && (
        <>
          <div
            className="mfa-push-notification-container-blk"
            style={{ height: "350px" }}
          >
            <div></div>
            <>
              <h1>Setup Backup Email Address</h1>
              <p>Check your back up email and enter the code below</p>
              <div className="form-mfa-container-blk">
                <hr />
                <div>
                  <p>
                    <b>Step 6:</b> Enter the code sent to <b>{backUpEmail}</b>{" "}
                    to confirm.
                    <br />
                  </p>
                  <div className="form-blk-mfa">
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                        marginTop: "0px",
                        marginBottom: "8px",
                      }}
                      value={confirmCodeEmail}
                      placeholder="Enter your code here..."
                      onChange={confirmEmailCodeInputHandler}
                      maxLength={6}
                    />
                  </div>

                  <div
                    className="card-flex-blk button-blk-mfa-container"
                    style={{ marginBottom: "10px" }}
                  >
                    <div>
                      <button
                        className="mfa-btn-blk-class"
                        onClick={() => {
                          routerHistory.goBack();
                        }}
                        disabled={btnLoading ? true : false}
                      >
                        Back to Prev Page
                      </button>
                    </div>

                    <div>
                      <button
                        className="mfa-btn-blk-class verify-blk-btn"
                        onClick={onConfirmCodeEmail}
                        disabled={btnLoading ? true : false}
                      >
                        {
                          btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} />
                            :
                            <div>
                              CONTINUE
                              <span className="btn-inner--icon"></span>
                            </div>
                        }
                      </button>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <Spinner
                    style={{ display: "block", margin: "25px auto 0px auto" }}
                  />
                ) : (
                  <div
                    className={
                      saveMessageData.length === 0
                        ? ""
                        : saveMessageData.message ===
                          "Your account's Multi-Factor Authentication has been set up successfully."
                          ? "message-blk-validation-success"
                          : "message-blk-validation-error"
                    }
                  >
                    {saveMessageData.message}
                  </div>
                )}
                <hr style={{ marginTop: "15px" }} />
              </div>
            </>
          </div>
          <div className="footer-blk">
            <p>
              &copy; 2023 - <span>Etani</span>
            </p>
          </div>
        </>
      )}

      {stepFiveSecurityQuestion && (
        <div className="mfa-push-notification-container-blk">
          <div></div>
          <h1>Security Questions</h1>
          <p>
            Add three security questions below. These questions will help us
            verify your identity should you not have access to mobile app or Google Authenticator app
          </p>
          <div>
            <hr />
            <form onSubmit={submitSecurityQuestions}>
              {questions.map((question, index) => (
                <div
                  key={index}
                  className="question"
                  style={{
                    background: "#f6f6f6",
                    margin: "10px 0px 0px 0px",
                    padding: "20px 20px 0px 20px",
                  }}
                >
                  <div>
                    <label htmlFor={`question-${index}`}>
                      Question {index + 1} :
                    </label>
                    <select
                      style={{
                        width: "82%",
                        marginLeft: "10px",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      id={`question-${index}`}
                      value={question.question}
                      onChange={(event) =>
                        handleQuestionChange(index, event.target.value)
                      }
                    >
                      <option value="">Select a question...</option>
                      {defaultQuestions.map((q, i) => (
                        <option key={i} value={q}>
                          {q}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      margin: "10px 0px 0px 0px",
                      padding: "10px 0px 10px 10px",
                    }}
                  >
                    <label htmlFor={`answer-${index}`}>Answer : </label>
                    <input
                      id={`answer-${index}`}
                      style={{
                        width: "86%",
                        marginLeft: "10px",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      type="text"
                      value={question.answer}
                      onChange={(event) =>
                        handleAnswerChange(index, event.target.value)
                      }
                    />
                    <button
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginTop: "20px",
                        marginRight: "10px",
                        borderRadius: "5px",
                      }}
                      type="button"
                      onClick={() => handleRemoveQuestion(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
              {questions.length >= 3 ? (
                <button
                  type="button"
                  onClick={handleAddQuestion}
                  disabled
                  style={{
                    borderRadius: "5px",
                    marginTop: "20px",
                    borderColor: "#fff",
                    background: "#dadae1",
                    color: "#979797;",
                    width: "50%",
                    paddingRight: "10px",
                  }}
                >
                  Add Security Question
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleAddQuestion}
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "20px",
                    width: "50%",
                    paddingRight: "10px",
                  }}
                >
                  Add Security Question
                </button>
              )}
              {!canSaveQuestions() ? (
                <button
                  type="submit"
                  disabled
                  style={{
                    borderRadius: "5px",
                    marginTop: "20px",
                    borderColor: "#dadae1",
                    background: "#dadae1",
                    color: "#979797;",
                    width: "49%",
                    marginLeft: "5px",
                  }}
                >
                  Save Security Questions
                </button>
              ) : (
                <button
                  type="submit"
                  style={{
                    borderRadius: "5px",
                    marginTop: "20px",
                    width: "49%",
                    marginLeft: "5px",
                  }}
                >
                  Save Security Questions
                </button>
              )}
            </form>
            {loading ? (
              <Spinner
                style={{ display: "block", margin: " 30px auto 0px auto" }}
              />
            ) : !message ? (
              ""
            ) : (
              <div
                className={
                  message === "Your answers are now saved."
                    ? "message-blk-validation-success"
                    : "message-blk-validation-error"
                }
              >
                {message}
              </div>
            )}
            <hr />
            <button
              onClick={goBackBackupEmailScreen}
              style={{
                display: "block",
                textAlign: "center",
                fontSize: "14px",
                color: "#32325d",
                paddingTop: "20px",
                paddingBottom: "50px",
                background: "none",
                border: "none",
                margin: "0 auto",
              }}
            >
              Use back up email instead?
            </button>
          </div>
        </div>
      )}

      {stepSixSuccessScreen && (
        <>
          <div
            className="mfa-push-notification-container-blk"
            style={{ height: "250px" }}
          >
            <div></div>
            <>
              <h1>SETUP COMPLETE</h1>
              <p>
                Next time you log in, you'll need to open your mobile app
                and use the 4-digit code to authenticate your login
              </p>
              <button
                style={{
                  width: "50%",
                  margin: "0 auto",
                  display: "block",
                }}
                className="mfa-btn-blk-class verify-blk-btn"
                onClick={() => {
                  routerHistory.push("/client/accountant/details");
                }}
              >
                <div>
                  CONTINUE
                  <span className="btn-inner--icon"></span>
                </div>
              </button>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default MFAPushNotificationPage;
