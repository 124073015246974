import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button } from "reactstrap";
import moment from "moment";
const ClientHistoryTable = ({ detailsType, data, isCurrent, estimatedCost, ...props }) => {
  // console.log(estimatedCost)
  // console.log(data)
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {/* <th scope="col">
              ClientID
            </th> */}
            <th scope="col">
              Name
            </th>
            <th scope="col">{isCurrent ? "Client Price" : "Action"}</th>
            {
              isCurrent ?
              <>
                <th scope="col">Users Price</th>
                <th scope="col">Integrations Price</th>
                <th scope="col">Total Price</th>
              </>
              : null
            }
            <th scope="col">Initiator</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {
            data?.length >= 1 ? data?.map((d, i) => (
              <tr key={i}>
                {/* <th scope="row">{d.Details.Client.ClientID}</th> */}
                <td>{isCurrent ? d.Name : d.Details?.Client?.Name}</td>
                <td>{isCurrent ? "$20" : d.Action}</td>
                {
                  isCurrent ?
                  <>
                    <td>{isCurrent ? `$${i === 0 ? d.TotalUsersCost + estimatedCost?.MultiClientUsers?.cost ?? 0 * 30 : d.TotalUsersCost }` : d.Action}</td>
                    <td>{isCurrent ? `$${d.TotalIntegrationCost}` : d.Action}</td>
                    <td>{isCurrent ? `$${i === 0 ? d.TotalUsersCost + d.TotalIntegrationCost + 20 + estimatedCost?.MultiClientUsers?.cost ?? 0 * 30 : 20 + d.TotalUsersCost + d.TotalIntegrationCost }` : d.Action}</td>
                  </>
                  : null
                }
                <td>{isCurrent ? d.LastAddedBy.Email : d.Details?.Initiator.id <= 4 ? 'System' : `${d.Details?.Initiator.Email}` }</td>
                <td>{moment(isCurrent ? d.createdAt : d.createdAt).format("DD-MM-YYYY LT")}</td>
              </tr>
            ))
              : null
          }
        </tbody>
      </Table>
      {
        data?.length < 1 ?
          <Row>
            <Col>
              <p className="text-center text-sm mt-3">No data found.</p>
            </Col>
          </Row>
          : null
      }
    </>
  )
}
export default ClientHistoryTable;