import GraphView from 'eva-component/response/GraphView'
import GridView from 'eva-component/response/GridView'
import TreeView from 'eva-component/response/TreeView'
import React, { useEffect } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'

function VisualGridView({ gridHeader, sqlresult, theme, treeViewData, isWithChild, isShowGrid, toggleAll , isShowGrap, handleSaveVisual, onEditVisual, selectedViewOption, handleCloseEditVisual, onEditName }) 
{
    useEffect(() => {
        // console.log(onEditVisual)
        // console.log(onEditName)
    },[])
    const onHandleSaveVisual = () => {
        const param = {
            selectedViewOption: selectedViewOption,
        }
        handleSaveVisual(param)
    }
    return (
        <>
        {(onEditVisual || onEditName) &&
            <FormGroup>
                <Col sm='12'>.
                    <Row>
                        <div 
                            className='addNewVisualData' 
                            style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                            onClick={onHandleSaveVisual}
                        >Save View</div>
                        <div 
                            className='addNewVisualData' 
                            style={{ border: '1px solid #333', color: '#333', width: 100, marginTop: 10, marginRight: 0 }}
                            onClick={handleCloseEditVisual}
                        >Cancel</div>
                    </Row>
                </Col>
            </FormGroup>
        }
        <div className='EVAResponseContainer visualGridView' style={{ display: 'unset', padding: 0 }}>
            <div className='EVADataContent' style={{ overflow: 'auto' }}>
                {isShowGrap ? <GraphView
                    sqlResult={sqlresult}
                    gridHeader={gridHeader}
                    theme={theme} 
                    treeViewData={treeViewData}
                    isWithChild={isWithChild}
                    isShowGrid={isShowGrid}
                /> : 
                isWithChild && !isShowGrid ? 
                    <TreeView
                        theme={theme} 
                        sqlresult={sqlresult} 
                        gridHeader={gridHeader}
                        treeViewData={treeViewData}
                        toggleAll={toggleAll}
                    /> :
                    <GridView
                        theme={theme} 
                        sqlresult={sqlresult} 
                        EVAData={sqlresult}
                        gridHeader={gridHeader}
                    />
                }
            </div>
        </div>
        </>
    )
}

export default VisualGridView