import { MainContext } from 'context/mainContext'
import GraphView from 'eva-component/response/GraphView'
import GridView from 'eva-component/response/GridView'
import TreeView from 'eva-component/response/TreeView'
import React, { useContext, useEffect, useState } from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

function VisualCardView({ gridHeader, sqlresult, theme, treeViewData, isWithChild, isShowGrid, toggleAll , isShowGrap, 
    templateItemVisual, headerToMultiSelect, handleSaveVisual, selectedViewOption, visualType, onEditVisual, handleCloseEditVisual, index, layouts, item, onEditName  }) 
{
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [cardItem, setCardItem] = useState([{ id: '', column: '', columnItem: '', columnItemList: [], columnToSum: '', sum: 0, title: '', description: '' }])
    const [cardWidth, setCardWidth] = useState(null)
    const [fontSizeFactor, setFontSizeFactor] = useState(null)
    const {
        bgColor,
        textColor
    } = useContext(MainContext);

    useEffect(() => {
        if(!templateItemVisual) return
        
        // console.log(templateItemVisual)

        const { cardItem, headerToMultiSelect } = templateItemVisual
        if(templateItemVisual.selectedViewOption === 'Card')
            setCardItem(cardItem)
            
    }, [templateItemVisual])

    useEffect(() => {
        // console.log(cardItem)
    },[cardItem])

    useEffect(() => {
        if(onEditVisual) return 
        if(!templateItemVisual) return

        const { cardItem, headerToMultiSelect } = templateItemVisual
        if(layouts !== undefined) setFontSizeFactor(layouts.w * 7)
        else setFontSizeFactor(60)

        if(cardItem && cardItem.length > 0)
        {
            loadCards(cardItem, headerToMultiSelect)
            const cardWidth = 100 / cardItem.length
            setCardWidth(cardWidth)
        }
    })
    const loadCards = (cardItem, headerToMultiSelect) => {
        cardItem.forEach((card) => {
            let totalAmount = 0;
            let isPercentage = false;
            let percentageSum = 0;
            let percentageCount = 0;
    
            const processItem = (item) => {
                const res = headerToMultiSelect.filter((e) => e.name === card.columnToSum);
                if (res.length > 0) {
                    const value = item[res[0].id];
                    if (typeof value === 'string' && value.includes('%')) {
                        isPercentage = true;
                        const numericValue = Number(parseFloat(value.replace('%', '')));
                        if (!isNaN(numericValue)) {
                            percentageSum += numericValue;
                            percentageCount++;
                        }
                    } else if (typeof value === 'number') {
                        totalAmount += value;
                    }
                }
            };
    
            if (card.columnItem !== "") {
                sqlresult.forEach((item) => {
                    if (item[card.id] === card.columnItem) {
                        processItem(item);
                    }
                });
            } else {
                sqlresult.forEach((item) => {
                    processItem(item);
                });
            }
    
            if (isPercentage && percentageCount > 0) {
                card.sum = (percentageSum / percentageCount).toFixed(1) + '%';
            } else {
                card.sum = totalAmount.toFixed(2);
            }
        });

        setCardItem(cardItem);
    }
    useEffect(() => {
        // console.log(gridHeader)
        // console.log(sqlresult)
        // console.log(visualType)
        if(visualType) processVisualType(gridHeader, sqlresult)
    }, [visualType])
    const processVisualType = (gridHeader, sqlresult) => {
        // console.log(gridHeader.length)
        // console.log(sqlresult.length)

        if(gridHeader.length === 1)
        {
            handleCardItemChange(0, 'column', gridHeader[0])
            handleCardItemChange(0, 'columnToSum', gridHeader[0])
            const param = {
                selectedViewOption: selectedViewOption,
                cardItem: cardItem,
                headerToMultiSelect: headerToMultiSelect
            }

            if (item !== undefined) {
                if (item.tempVisual === undefined && item.visual === undefined) {
                    item.tempVisual = param;
                    // item.visual = param;
                }
            }
        }
    }

    const onHandleSaveVisual = () => {
        const param = {
            selectedViewOption: selectedViewOption,
            cardItem: cardItem,
            headerToMultiSelect: headerToMultiSelect
        }
        
        handleSaveVisual(param)
    }
    
    const handleCardItemChange = (index, type, value) => {
        if(value !== "")
        {
            const res = headerToMultiSelect.filter((e) => e.name === value);
            setCardItem(prevGroups => {
                const updatedGroups = [...prevGroups];
                updatedGroups[index][type] = value;

                if(type === 'column')
                {
                    updatedGroups[index]['id'] = res[0].id;
                    updatedGroups[index]['columnItemList'] = getColumnItemList(res[0].id)
                }
                    
                if(type === 'columnToSum' || type === 'columnItem')
                {
                    if(type === 'columnItem')
                    {   
                        if(updatedGroups[index]['columnToSum'] !== '')
                        {
                            const res = headerToMultiSelect.filter((e) => e.name === updatedGroups[index]['columnToSum']);
                            updatedGroups[index]['sum'] = getColumnItemListSum(updatedGroups[index], res[0].id)
                        }
                    }else updatedGroups[index]['sum'] = getColumnItemListSum(updatedGroups[index], res[0].id)
                }

                return updatedGroups;
            });
        }else{
            setCardItem(prevGroups => {
                const updatedGroups = [...prevGroups];
                updatedGroups[index][type] = '';
                updatedGroups[index]['id'] = '';
                updatedGroups[index]['columnItemList'] = [];
                return updatedGroups;
            });
        }
    };
    const getColumnItemList = (id) => {
        const itemList = []
        sqlresult.forEach(element => {
            if(!itemList.includes(element[id]))
            itemList.push(element[id])
        });
        return itemList
    }
    const getColumnItemListSum = (cardItem,id) => {
        let totalAmount = 0;
        let isPercentage = false;
        let percentageSum = 0;
        let percentageCount = 0;
    
        if (cardItem.columnItem !== '') {
            sqlresult.forEach((data) => {
                if (data[cardItem.id] === cardItem.columnItem) {
                    totalAmount += data[id];
                }
            });
        } else {
            sqlresult.forEach((data) => {
                if (typeof data[id] === 'string' && data[id].includes('%')) {
                    isPercentage = true;
                    const numericValue = parseFloat(data[id].replace('%', ''));
                    if (!isNaN(numericValue)) {
                        percentageSum += numericValue;
                        percentageCount++;
                    }
                } else if (typeof data[id] === 'number') {
                    totalAmount += data[id];
                }
            });
    
            if (isPercentage && percentageCount > 0) {
                totalAmount = (percentageSum / percentageCount).toFixed(1) + '%';
            }
        }
    
        return totalAmount;
    }
    const formatAmount = (number) => {
        const isNegative = number < 0;
        const absNumber = Math.abs(number);
        
        if (isNaN(number) && !number.includes("%")) return 0;

        if(typeof number === 'string' && number.includes("%")) return number

        if (absNumber >= 1e9) {
          return isNegative ? "-" + (absNumber / 1e9).toFixed(1) + "B" : (absNumber / 1e9).toFixed(1) + "B";
        } else if (absNumber >= 1e6) {
          return isNegative ? "-" + (absNumber / 1e6).toFixed(1) + "M" : (absNumber / 1e6).toFixed(1) + "M";
        } else if (absNumber >= 1e3) {
          return isNegative ? "-" + Math.round(absNumber / 1e3) + "K" : Math.round(absNumber / 1e3) + "K";
        } else {
          return Math.round(absNumber);
        }
    };
      
    const addCardItem = () => {
        setCardItem(prevCard => [...prevCard, { id: '', column: '', columnItem: '', columnItemList: [], columnToSum: '', sum: 0, title: '', description: '' }])
    }
    const removeCardItem = (index) => {
        setCardItem(prevCard => prevCard.filter((card, i) => i !== index))
    }
    const titleSize = (fontSizeFactor) => {
        return fontSizeFactor / 3  >= 20 ? 20 : fontSizeFactor / 3 <= 15 ? 15 : fontSizeFactor / 3
    }
    const splitTextIntoLines = (text, maxLength) => {
        if (text.length <= maxLength) return text;
    
        const lines = [];
        for (let i = 0; i < text.length; i += maxLength) {
            lines.push(text.substring(i, i + maxLength));
        }
    
        return lines.join('\n');
    }
    return (
        <>
            {(onEditVisual || onEditName) && (
                <>
                    {cardItem && cardItem.length > 0 && cardItem.map((card, index) => (
                        <FormGroup key={index}>
                            <Col sm='12'>
                                <Row>
                                    <Col sm='12' md='2'>
                                        <Label htmlFor={`selectGroupLabel${index}`}>Select Column</Label>
                                        <Input type="select" id={`selectGroupLabel${index}`} value={card.column} onChange={e => handleCardItemChange(index, 'column', e.target.value)}>
                                            <option value="">Select Column</option>
                                            {gridHeader.map(group => (
                                                <option key={group} value={group}>
                                                    {group}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col sm='12' md='2'>
                                        <Label htmlFor={`selectGroupLabel${index}`}>Select Column Item</Label>
                                        <Input type="select" id={`selectGroupLabel${index}`} value={card.columnItem} onChange={e => handleCardItemChange(index, 'columnItem', e.target.value)}>
                                            <option value="">Select Column Item</option>
                                            {card.columnItemList.length > 0 && card.columnItemList.map(itemListItem => (
                                                <option key={itemListItem} value={itemListItem}>
                                                    {itemListItem}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col sm='12' md='2'>
                                        <Label htmlFor={`selectGroupLabel${index}`}>Select Column to Summarize</Label>
                                        <Input type="select" id={`selectGroupLabel${index}`} value={card.columnToSum} onChange={e => handleCardItemChange(index, 'columnToSum', e.target.value)}>
                                            <option value="">Select Column to Summarize</option>
                                            {gridHeader.map(group => (
                                                <option key={group} value={group}>
                                                    {group}
                                                </option>
                                            ))}
                                        </Input>
                                        <div className='cardControllerContainer'>
                                            {index === cardItem.length - 1 && (
                                                <div 
                                                    className='addNewCardlData'
                                                    onClick={addCardItem}
                                                ><i className="fas fa-solid fa-plus"></i></div>
                                            )}
                                            {cardItem.length > 1 && (
                                                <div className='removeCardlData' onClick={() => removeCardItem(index)}><i className="fas fa-solid fa-trash"></i></div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                    ))}
                    <FormGroup>
                        <Col sm='12'>
                            <Row>
                                <div 
                                    className='addNewVisualData' 
                                    style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                                    onClick={onHandleSaveVisual}
                                >Save View</div>
                                <div 
                                    className='addNewVisualData' 
                                    style={{ border: '1px solid #333', color: '#333', width: 100, marginTop: 10, marginRight: 0 }}
                                    onClick={handleCloseEditVisual}
                                >Cancel</div>
                            </Row>
                        </Col>
                    </FormGroup>
                </>
            )}
            <div className='cardContent'>
                {cardItem && cardItem.length > 0 && cardItem.map((card, index) => (
                    <div key={index} style={{ width : `calc(${cardWidth}% - 0px)`, padding: '0px 5px', minWidth: 170, minHeight: 200, marginBottom: 10, maxWidth: 400 }}>
                        <div className='cardContainer'>
                            <div className='cardAmount' style={{ fontSize: fontSizeFactor > 100 ? 100 : fontSizeFactor < 50 ? 50 : fontSizeFactor}}>
                                {formatAmount(card.sum)}
                            </div>
                            <div className='cardTitle' style={{ fontSize: titleSize(fontSizeFactor) }}>
                                {splitTextIntoLines(card.columnItem !== '' ? card.columnItem : card.column, 25)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
    
}

export default VisualCardView