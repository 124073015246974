import React, { useEffect, useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

function DashboardTemplateKPI({ index, categories, item, tabKey, selectedTemplateItem, handleSelectItem, sqlExampleResult, category, handleSelectCategory }) {
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        // console.log(item.integration)
    },[])

    // const getCategory = (data) => {
    //     const categories = [];
    //     data.forEach(item => {
    //         if(item.category)
    //             if (!categories.includes(item.category)) {
    //                 categories.push(item.category);
    //             }
    //     });
    //     return categories;
    // }

    return (
        <>
        {/* {tabKey === item.integration && categoryList.map((category) => ( */}
            <div style={{ padding: 10 }}>
                {/* <h3>{category}</h3> */}
                <div className='categoryInput'>
                <FormGroup check>
                 
                    <Label check>
                        <Input
                            type="checkbox"
                            bsSize="xs"
                            // checked={selectedTemplateItem.some(selectedItem => selectedItem.name === detail.display_name)}
                            onChange={(e) => handleSelectCategory(e.target.checked, item)}
                        />
                        {category}
                    </Label>
                   
                </FormGroup>
                </div>
                {item.details.map((detail, detailIndex) => 
                (
                    <div
                        className='templateItem'
                        key={detailIndex}
                    >
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    bsSize="xl"
                                    checked={selectedTemplateItem.some(selectedItem => selectedItem.name === detail.display_name)}
                                    onChange={() => handleSelectItem(
                                    { 
                                        name: detail.display_name, 
                                        query: detail.query, 
                                        visual_type: detail.visual_type, 
                                        visual_data: detail.visual_data,
                                        integration: item.integration
                                    })}
                                />
                                {detail.display_name}
                            </Label>
                        </FormGroup>
                    </div>
                ))}
            </div>
        {/* ))} */}
        </>
    )
}

export default DashboardTemplateKPI