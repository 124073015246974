import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { ChromePicker } from 'react-color';
import VisualGraphView from './VisualGraphView';
import VisualGridView from './VisualGridView';
import { registerDashboardTemplate } from 'services/mongoDB';
import VisualPieChartView from './VisualPieChartView';
import VisualCardView from './VisualCardView';
import { updateEVAVisual } from 'services/mongoDB';

function EVADashboardVisual({ item, index, gridHeader, sqlresult, theme, treeViewData, isWithChild, 
    isShowGrid, toggleAll, isShowGrap, selectedDashboardTemplate, handleSetItemIndexToEdit, 
    toggleFullScreen, handleNewUpdate, templateItemVisual, handleshowEditVisual, handleSaveEVAVisual, onEditVisual }) 
{
    const {
        bgColor,
        textColor
    } = useContext(MainContext);

    const [dataSet, setDataSet] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedFooter, setSelectedFooter] = useState(null);
    const [headerToMultiSelect, setHeaderToMultiSelect] = useState([])
    const [selectedData, setSelectedData] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([{ label: '', data: '', color: bgColor, isOpen: false }]);
    const [chartDataSet, setChartDataSet] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const [color, setColor] = useState('#ffffff');
    const [selectedViewOption, setSelectedViewOption] = useState('Grid')

    useEffect(() => {
        const dataSet = [gridHeader, ...sqlresult]
        const headerToMultiSelect = gridHeader.map((data, index) => {return {
            id: index, name: data
        }})
        setDataSet(dataSet)
        setHeaderToMultiSelect(headerToMultiSelect)
        console.log(templateItemVisual)
    },[gridHeader])

    useEffect(() => {
        // if(!chartDataSet) console.log(chartDataSet)
    },[chartDataSet])

    useEffect(()=> {
        if(templateItemVisual)
            setSelectedViewOption(templateItemVisual.selectedViewOption ? templateItemVisual.selectedViewOption : 'Grid')
    },[templateItemVisual])

    const viewOption = ['Grid', 'Card', 'Pie Chart', 'Line Chart', 'Bar Chart']
    const handleSelectViewOption = (item) => {
        setSelectedViewOption(item)
    }
    const handleSaveVisual = (param) => 
    {
        if (param.height && param.width) 
        {
            param.height = item.visual.height
            param.width = item.visual.width
        }
     
        item.visual = param
        // console.log(item)
        handleSaveEVAVisual(item)
    }
    const onHandleshowEditVisual = () => 
    {
        handleshowEditVisual()
    }
    return (
        <div className='EVAVisualContainer'>
            <div className='EVAVisualContent'>
                <div className='EVAVisualBody' style={{ height: 'calc(100% - 50px)'}}>
                    <div className='dashboardTemplateItemContainer' style={{ display: 'block', maxHeight: 'none'}}>
                        <div className="dashboardItemBody" style={{ padding: 0}}>
                            <div className='dashboardSettingContainer'>
                                <h3>Dashboard Visual</h3>
                                <div className='templateVisualContainer'>
                                    {viewOption.map((item) => (
                                        <div 
                                            className='templateVisualItem' 
                                            style={selectedViewOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                            onClick={() => handleSelectViewOption(item)}
                                        >{item}</div>
                                    ))}
                                </div>
                                <div className='templateGraphContainer'>
                                    {selectedViewOption === 'Grid' &&
                                        <VisualGridView
                                            gridHeader={gridHeader}
                                            sqlresult={sqlresult}
                                            theme={theme}
                                            treeViewData={treeViewData}
                                            isWithChild={isWithChild}
                                            isShowGrid={isShowGrid}
                                            toggleAll={toggleAll}
                                            isShowGrap={isShowGrap}
                                            handleSaveVisual={handleSaveVisual}
                                            selectedViewOption={selectedViewOption}
                                            onEditVisual={onEditVisual}
                                        />
                                    }
                                    {selectedViewOption === 'Card' &&
                                        <VisualCardView
                                            gridHeader={gridHeader}
                                            sqlresult={sqlresult}
                                            theme={theme}
                                            treeViewData={treeViewData}
                                            isWithChild={isWithChild}
                                            isShowGrid={isShowGrid}
                                            toggleAll={toggleAll}
                                            isShowGrap={isShowGrap}
                                            handleSaveVisual={handleSaveVisual}
                                            templateItemVisual={templateItemVisual}
                                            headerToMultiSelect={headerToMultiSelect}
                                            selectedViewOption={selectedViewOption}
                                            onEditVisual={onEditVisual}
                                        />
                                    }
                                    {selectedViewOption === 'Bar Chart' &&
                                        <VisualGraphView
                                            gridHeader={gridHeader}
                                            selectedViewOption={selectedViewOption}
                                            sqlresult={sqlresult}
                                            headerToMultiSelect={headerToMultiSelect}
                                            handleSaveVisual={handleSaveVisual}
                                            templateItemVisual={templateItemVisual}
                                            onEditVisual={onEditVisual}
                                        />
                                    }
                                    {selectedViewOption === 'Line Chart' &&
                                        <VisualGraphView
                                            gridHeader={gridHeader}
                                            selectedViewOption={selectedViewOption}
                                            sqlresult={sqlresult}
                                            headerToMultiSelect={headerToMultiSelect}
                                            handleSaveVisual={handleSaveVisual}
                                            templateItemVisual={templateItemVisual}
                                            onEditVisual={onEditVisual}
                                        />
                                    }
                                    {selectedViewOption === 'Pie Chart' &&
                                        <VisualPieChartView
                                            gridHeader={gridHeader}
                                            selectedViewOption={selectedViewOption}
                                            sqlresult={sqlresult}
                                            headerToMultiSelect={headerToMultiSelect}
                                            handleSaveVisual={handleSaveVisual}
                                            templateItemVisual={templateItemVisual}
                                            onEditVisual={onEditVisual}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div style={{ display:'flex', flexDirection: 'row-reverse' }}>
                        <div className='closeEVAVisual' onClick={onHandleshowEditVisual} style={{ background: bgColor, color: textColor }}>
                            Close
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default EVADashboardVisual