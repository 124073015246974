import React, { useEffect, useState, useContext } from "react";
import {
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Spinner,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
// import { clientsInfo } from "../../../utils/dummyData";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { PowerBIEmbed } from "powerbi-client-react";

import SelectModal from "./DashboardModal";
import PanelCard from "components/Cards/PanelCard";
import CustomHeader from "customComponents/customHeader";
import CustomSpinner from "components/Misc/CustomSpinner";
import ClientsInfo from "../../../components/ClientInfo/ClientInfo";
import ClientDashboardCard from "components/Cards/clientDashboardCard";

import { MainContext } from "context/mainContext";
import avatar from "../../../assets/img/clients/blank-avatar.png";
import BlockUI from "components/Misc/BlockUI";
import "./index.css";
import { useHistory } from "react-router";

const Dashboard = () => {
  const handle = useFullScreenHandle();
  const {
    textColor,
    bgColor,
    selectedClientID,
    setSelectedClientID,
    userDetails,
    firmDetails
  } = useContext(MainContext);
  const history = useHistory();
  // eslint-disable-next-line
  const [viewDashBoard, setviewDashBoard] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  // eslint-disable-next-line
  const [isFullScreen, setFullScreen] = useState(false);
  // eslint-disable-next-line
  const [subscribed, setSubscribed] = useState(false);
  const [filterData, setFilterData] = useState([]);
  // const [authority, setAuthority] = useState({});
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [color, setColor] = useState(false);
  const [alert, setAlert] = useState(false);
  // eslint-disable-next-line
  const [save, setSave] = useState(false);
  const [height, setHeight] = useState(0);
  // eslint-disable-next-line
  const [access, setAccess] = useState(7);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  // eslint-disable-next-line
  const [width, setWidth] = useState(0);
  const [user, setUser] = useState([]);
  const [reportGenerator, setReportGenerator] = useState({
    show: false,
    type: "",
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
  });
  const [showBlockUI, setShowBlockUI] = useState(false); //Added by Joef: Block UI is needed when calling apis
  // const [schedulerModal, setSchedulerModal] = useState({
  //   show: false,
  //   record: {
  //     Scheduled: true
  //   }
  // });
  // const [createModal, setCreateModal] = useState({
  //   show: false,
  //   config: {}
  // });
  // const [modal, setModal] = useState({
  //   title: "Upload Report",
  //   show: false,
  //   record: {},
  //   action: "create"
  // });
  const [settingsModal, setSettingsModal] = useState({
    show: false,
    record: {},
  });
  const [userSelectionModal, setUserSelectionModal] = useState({
    show: false,
    data: [],
    filteredData: [],
    record: {},
    selected: [],
    settings: false,
  });

  const fetchAPIs = () => {
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    let reports = [];
    // eslint-disable-next-line
    let error = "";
    let firstClient = [];
    let url = window.location.pathname.split("/");

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    if (userDetails && userDetails.User && userDetails.User.UserAccess === 1) {
      fetch(`/api/client/getAll`)
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            // let unsignedClient = data.filter((clnt) => clnt.Status === 6);
            setClients(data);
            firstClient = data[0];
          } else {
            warningAlert(data.error);
            return;
          }
        })
        .then(() => {
          if (firstClient) {
            setSelectedClientID(
              url.length < 4 ? firstClient.ClientID : url[url.length - 1]
            );
            fetch(
              `/api/client/showDetails/${url.length < 4 ? firstClient.ClientID : url[url.length - 1]
              }`
            )
              .then((res) => res.json())
              .then((data) => {
                if (data && !data.error) {
                  setSelectedClient(data);
                  fetch(`/api/customReport/getPowerBIDashboards/${data.id}`)
                    .then((res) => res.json())
                    .then((response) => {
                      if (response.error) {
                        if (
                          response.error ===
                          "There is no PowerBI Application Workspace for this client yet. Please create one in 3rd Party Applications Page."
                        )
                          error = response.error;
                        return warningAlert(response);
                      }

                      reports = response.data;
                      response.subscribed = subscribed;

                      setUser(reports);
                      setFilterData(reports);
                      setLoading(false);
                      // console.log(response);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  setLoading(false);
                  return;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else setLoading(false);
        })
        .catch((error) => {
          let data = [];

          setFilterData(data);
          console.log(error);
        });
    } else {
      fetch(`/api/client/getAllRecords`)
        .then((res) => res.json())
        .then((data) => {
          if (data && !data.error) {
            // console.log(data)
            // let unsignedClient = data.filter((clnt) => clnt.Status === 6);
            setClients(data);
            firstClient = data[0];
          } else {
            // console.log("asdf")
            warningAlert(data.error);
            return;
          }
        })
        .then(() => {
          if (firstClient) {
            setSelectedClientID(
              url.length < 4 ? firstClient.ClientID : url[url.length - 1]
            );
            fetch(
              `/api/client/showDetails/${url.length < 4 ? firstClient.ClientID : url[url.length - 1]
              }`
            )
              .then((res) => res.json())
              .then((data) => {
                if (data && !data.error) {
                  if (data.message === "Client not found") {
                    // setClientID(firstClient.ClientID);
                    setSelectedClientID(firstClient.ClientID);
                    return history.push(`/auth/page-not-found`);
                  }
                  setSelectedClient(data);
                  fetch(`/api/customReport/getPowerBIDashboards/${data.id}`)
                    .then((res) => res.json())
                    .then((response) => {
                      if (response.error) {
                        if (
                          response.error ===
                          "There is no PowerBI Application Workspace for this client yet. Please create one in 3rd Party Applications Page."
                        )
                          error = response.error;
                        return warningAlert(response);
                      }

                      reports = response.data;
                      response.subscribed = subscribed;

                      setUser(reports);
                      setLoading(false);
                      setFilterData(reports);
                      // console.log(response);
                    });
                } else {
                  console.log("error");
                  setLoading(false);
                  return;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else setLoading(false);
        })
        .catch((error) => {
          let data = [];

          setFilterData(data);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    // console.log('selectedClientID', selectedClientID)
    if (selectedClientID) {
      history.push(`/client/dashboard/${selectedClientID}`);
    }
    setTimeout(() => {
      fetchAPIs();
    }, 1000);
    return () => {
      setUser(user);
      setAlert(alert);
      setClients(clients);
      setLoading(loading);
      setFilterData(filterData);
      setSelectedClient(selectedClient);
    };
    //eslint-disable-next-line
  }, [userDetails, selectedClientID]);

  const dashboardList = () => {
    if (filterData.length === 0) {
      if (loading) return <CustomSpinner />;

      return (
        <div className="col-sm-12 text-center">
          <h5>No Dashboard found.</h5>
        </div>
      );
    } else {
      return filterData.map((data, i) => (
        <React.Fragment key={i}>
          <ClientDashboardCard
            i={i}
            data={data}
            access={access}
            bgColor={bgColor}
            textColor={textColor}
            handleDelete={handleDelete}
            handleSearch={handleSearch}
            submitButton={submitButton}
            settingsModal={settingsModal}
            viewDashBoard={viewDashBoard}
            showTrafficLight={showTrafficLight}
            openViewDashboard={openViewDashboard}
            openSettingsModal={openSettingsModal}
            userDetails={userDetails}
          />
          <SelectModal
            i={i}
            data={data}
            heigh={height}
            bgColor={bgColor}
            onToggle={onToggle}
            textColor={textColor}
            selectAll={selectAll}
            unselectAll={unselectAll}
            handleUpdate={handleUpdate}
            handleChange={handleChange}
            isFullScreen={isFullScreen}
            settingsModal={settingsModal}
            userSelectList={userSelectList}
            reportGenerator={reportGenerator}
            handleUserSelect={handleUserSelect}
            handleUserSearch={handleUserSearch}
            openSettingsModal={openSettingsModal}
            closeSettingsModal={closeSettingsModal}
            userSelectionModal={userSelectionModal}
            handleChangeSettings={handleChangeSettings}
            closeReportGenerator={closeReportGenerator}
            openUserSelectionModal={openUserSelectionModal}
            closeUserSelectionModal={closeUserSelectionModal}
          />
        </React.Fragment>
      ));
    }
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const closeReportGenerator = () => {
    reportGenerator.show = false;
    setReportGenerator(reportGenerator);
  };

  // const onFullScreen = () => {
  //   setFullScreen(!isFullScreen);
  //   // console.log(isFullScreen);
  // };
  const handleSearch = (event) => {
    event.preventDefault();

    let filterString = event.target.value.toLowerCase();
    const filtered = user.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });
    setFilterData(filtered);
  };

  const handleUserSearch = (e) => {
    let data = userSelectionModal.data;
    e.preventDefault();

    let filterString = e.target.value.toLowerCase();
    const filtered = data.filter((obj) => {
      let included = false;

      for (let index in obj) {
        if (
          obj.hasOwnProperty(index) &&
          typeof obj[index] === "string" &&
          obj[index].toLowerCase().indexOf(filterString) >= 0
        ) {
          included = true;
          break;
        }
      }
      return included;
    });

    setUserSelectionModal({
      show: userSelectionModal.show,
      data: userSelectionModal.data,
      filteredData: filtered,
      record: userSelectionModal.record,
      selected: userSelectionModal.selected,
      settings: userSelectionModal.settings,
    });
    // console.log(userSelectionModal.filteredData);
  };

  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(false)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message || response.error || response}
      </ReactBSAlert>
    );
  };
  const warning = (res) => {
    setAlert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => setAlert(false)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {res.message || res.error}
      </ReactBSAlert>
    );
  };

  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => setAlert(false)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message}
      </ReactBSAlert>
    );
  };

  const showBlockUIModal = () => {
    return <BlockUI />;
  };

  const userSelectList = () => {
    let data = userSelectionModal.filteredData;

    if (data.length === 0) {
      if (loading)
        return (
          <Container className="text-center">
            <Spinner />
          </Container>
        );

      return (
        <div className="col-sm-12 text-center mt-4">
          <h5>No users found.</h5>
        </div>
      );
    } else {
      return data.map(function (obj, i) {
        const index = userSelectionModal.selected.indexOf(obj.id);
        return (
          <Col
            className="d-flex  mt-3 p-2 text-center "
            lg="6"
            style={{ flexDirection: "column", cursor: "pointer" }}
            key={i}
          >
            <Row className="justify-content-space-between p-3  ">
              <Col
                className="text-left border d-flex p-3 shadow--hover rounded"
                onClick={() => selectUser(obj)}
              >
                <img
                  alt="..."
                  className="rounded-circle img-fluid shadow shadow-lg--hover"
                  src={obj.Avatar || avatar}
                  style={{ width: "40px", height: "40px", objectFit: "cover" }}
                />
                <span className="m-auto" style={{ fontSize: "14px" }}>
                  {obj.FirstName + " " + obj.LastName}
                </span>
                <i
                  className="fas fa-check-circle mt-2"
                  style={{
                    color: index > -1 ? "green" : "gray",
                    fontSize: "20px",
                    marginTop: "5px",
                  }}
                />
              </Col>
            </Row>
          </Col>
        );
      });
    }
  };

  const handleDelete = (obj) => {
    fetch("/api/customReport/deleteRecord?id=" + obj.id, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);

        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].id === obj.id) {
            filterData.splice(i, 1);
            break;
          }
        }
        setFilterData(filterData);

        successAlert(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeSettings = (e) => {
    e.preventDefault();
    fetch(`/api/customReport/updateRecord?id=${settingsModal.record.id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        Details: settingsModal.record.Details,
        Description: settingsModal.record.Description || "",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);

        successAlert(response);

        for (let i = 0; i < user.length; i++) {
          if (user[i].id === settingsModal.record.id) {
            user[i].Details = settingsModal.record.Details;
            break;
          }
        }

        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].id === settingsModal.record.id) {
            filterData[i].Details = settingsModal.record.Details;
            break;
          }
        }

        settingsModal.record = {};
        settingsModal.show = false;

        setSettingsModal(settingsModal);
        setFilterData(filterData);
        setUser(user);
        // console.log(settingsModal);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   fetch(modal.actionUrl, {
  //     method: modal.actionUrlMethod,
  //     body: JSON.stringify(modal.record),
  //     credentials: "include"
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return warningAlert(response);
  //       if (response.record) {
  //         user.push(response.record);
  //         setUser(user);
  //       }
  //       successAlert(response);
  //       modal.show = false;
  //       setModal(modal);
  //     });
  // };

  const handleUpdate = (url, toStatus, report) => {
    fetch(url, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(report),
    })
      .then((response) => response.json())
      .then((response) => {
        let id = report.id;
        if (response.error) return warningAlert(response);
        successAlert(response);

        if (userSelectionModal.show === true && toStatus === 4) {
          report = report.report;
          userSelectionModal.show = false;
          setUserSelectionModal(userSelectionModal);
        }

        for (let i = 0; i < user.length; i++) {
          if (user[i].ReportID === report.ReportID) {
            user[i].Status = toStatus;
            if (id === 0 && response.record.id > 0)
              user[i].id = response.record.id;
            break;
          }
        }

        for (let i = 0; i < filterData.length; i++) {
          if (filterData[i].ReportID === report.ReportID) {
            filterData[i].Status = toStatus;
            if (id === 0 && response.record.id > 0)
              user[i].id = response.record.id;
            break;
          }
        }

        userSelectionModal.show = false;
        setUserSelectionModal(userSelectionModal);

        setUser(user);
        setFilterData(filterData);
        // console.log(report.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitButton = (status, id, report) => {
    // if (status == 1 && authority.AnalyticsDashboardReportOrange) {
    if (status === 5 || status === 1) {
      return (
        <Button
          size="sm"
          color="success"
          onClick={(e) => {
            e.preventDefault();
            openUserSelectionModal(report, false);
          }}
        >
          <i className="fas fa-cloud-upload-alt  mr-1" />
          Go Live
        </Button>
      );
      // } else if (status == 4 && authority.AnalyticsDashboardReportGreen) {
    } else if (status === 4) {
      return (
        <Button
          size="sm"
          // color="danger"
          onClick={(e) => {
            e.preventDefault();
            handleUpdate(
              "/api/customReport/updateSubmit?id=" + report.ReportID,
              1, //Edited by Joef: Skip Yellow
              report
            );
          }}
        >
          {/* <i className="fas fa-sync-alt mr-1" /> */}
          {/*Edited by Joef: Changed from Re-process*/}
          Hide from Clients
        </Button>
      );
    }
  };

  const showTrafficLight = (status, i) => {
    // console.log(status, i);
    if (status === 1) {
      return (
        <>
          <Button
            color="danger"
            id={`btn2${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10 }}
          />
          <UncontrolledTooltip
            target={`btn2${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            {/*Edited by Joef: Previous message: This report is still private and can only be seen by you.*/}
            Offline: This dashboard is offline and can only be seen by advisors
            with access to this client
          </UncontrolledTooltip>
        </>
      );
    } else if (status === 5) {
      return (
        <>
          <Button
            color="warning"
            id={`warning${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10 }}
          />
          <UncontrolledTooltip
            target={`warning${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            This dashboard is for processing and can be seen by other
            accountant.
          </UncontrolledTooltip>
        </>
      );
    } else if (status === 4) {
      return (
        <>
          <Button
            color="success"
            id={`success${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10 }}
          />
          <UncontrolledTooltip
            target={`success${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            {/*Edited by Joef: Previous message: This report is live and can be seen by client through their Mobile
            Accounting App.*/}
            Live: This dashboard is live and can be seen by the client users you
            have selected in the settings
          </UncontrolledTooltip>
        </>
      );
    }
  };

  const selectUser = (record) => {
    const index = userSelectionModal.selected.indexOf(record.id);

    if (index > -1) {
      userSelectionModal.selected.splice(index, 1);
      setColor(!color);
    } else {
      userSelectionModal.selected.push(record.id);
      setColor(!color);
    }
    setUserSelectionModal(userSelectionModal);
  };

  const selectAll = () => {
    for (let i = 0; i < userSelectionModal.data.length; i++) {
      userSelectionModal.selected.push(userSelectionModal.data[i].id);
    }
    setUserSelectionModal(userSelectionModal);
    setColor(!color);
  };

  const unselectAll = () => {
    userSelectionModal.selected = [];

    setUserSelectionModal(userSelectionModal);
    setColor(!color);
  };

  const closeSettingsModal = () => {
    setSettingsModal({
      show: false,
      record: settingsModal.record,
    });
    setUserSelectionModal({
      selected: userSelectionModal.selected,
      filteredData: userSelectionModal.filteredData,
    });
  };

  const openViewDashboard = (record) => {
    console.log("RECORD", record);
    let message = "This report is not available in desktop view.";
    if (
      !(
        !record.hasOwnProperty("Details") ||
        !record.Details.hasOwnProperty("Settings") ||
        !record.Details.Settings.hasOwnProperty("Desktop") ||
        record.Details.Settings.Desktop
      )
    )
      return warning(message);

    fetch("/api/customReport/generateToken?clientId=" + record.id, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return warningAlert(response);
        // const models = window['powerbi-client'].models;

        //console.log("RECORD", record);

        //console.log("RESPONSE", response);

        const config = {
          type: "dashboard",
          accessToken: response.accessToken,
          embedUrl: record.Details.embedUrl,
          dashboardId: record.ReportID,
        };

        setReportGenerator({
          show: true,
          type: config.type,
          id: config.dashboardId,
          embedUrl: config.embedUrl,
          accessToken: config.accessToken,
        });
        // let report = powerbi.embed(reportGenerator, config);

        // console.log(record);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const openReportUpdate = (record) => {
  //   fetch(
  //     "/api/powerbiEmbed/getUpdateReportByDatasetToken?ReportID=" +
  //       record.ReportID,
  //     {
  //       method: "GET",
  //       credentials: "include"
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return warningAlert(response.error);

  //       const models = window["powerbi-client"].models;

  //       const config = {
  //         type: "report",
  //         accessToken: response.embedToken,
  //         embedUrl: "https://embedded.powerbi.com/appTokenReportEmbed",
  //         id: record.ReportID,
  //         permissions:
  //           models.Permissions
  //             .Read /*both save & save as buttons will be visible*/,
  //         viewMode: models.ViewMode.Edit,
  //         settings: {
  //           filterPaneEnabled: true,
  //           navContentPaneEnabled: true
  //         }
  //       };

  //       reportGenerator = true;
  //       setReportGenerator(reportGenerator);

  //       // let report = powerbi.embed(reportGenerator, config);

  //       report.on("saved", function (event) {
  //         reportGenerator = false;
  //         setReportGenerator(reportGenerator);
  //       });
  //     });
  // };

  // const subscribe = () => {
  //   fetch('/api/customReport/subscribeToWorkspace', {
  // 		method: 'GET',
  // 		credentials: 'include'
  // 	})
  // 		.then(function (response) {
  // 			return response.json()
  // 		}).then(function (response) {

  // 			if (response.error) return console.log(response.error);

  // 			console.log(response.message);

  // 			 setSubscribed(true)

  // 		});
  // }

  // const openReportGenerator = () => {
  //   fetch("/api/powerbiEmbed/getCreateReportByDatasetToken", {
  //     method: "GET",
  //     credentials: "include"
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return console.log(response.error);

  //       const config = {
  //         accessToken: response.embedToken,
  //         embedUrl: "https://embedded.powerbi.com/appTokenReportEmbed",
  //         datasetId: response.datasetId
  //       };

  //       reportGenerator = true;
  //       setReportGenerator(reportGenerator);

  //       let report = powerbi.createReport(reportGenerator, config);

  //       report.on("saved", (event) => {
  //         modal.title = "Save Report";
  //         modal.show = true;
  //         modal.record = {
  //           ReportID: event.detail.reportObjectId,
  //           Name: event.detail.reportName
  //         };
  //         modal.actionUrl = "/api/customReport/createRecord";
  //         modal.actionUrlMethod = "POST";
  //         // report.off removes a given event handler if it exists.
  //         reportGenerator = false;
  //         setReportGenerator(reportGenerator);
  //         setModal(modal);
  //         report.off("saved");
  //       });
  //     });
  // };

  // const openCreateModal = () => {
  //   fetch("/api/powerbiEmbed/getCreateReportByDatasetToken", {
  //     method: "GET",
  //     credentials: "include"
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.error) return console.log(response.error);

  //       //console.log(document.getElementById("reportContainer"));

  //       //console.log(self.reportContainer);
  //       createModal.show = true;
  //       createModal.config = {
  //         accessToken: response.embedToken,
  //         embedUrl: "https://embedded.powerbi.com/appTokenReportEmbed",
  //         datasetId: response.datasetId
  //       };

  //       setCreateModal(createModal);
  //     });
  // };

  const openUserSelectionModal = (record, settings) => {
    setShowBlockUI(true);

    fetch("/api/user/getUsersByClientID", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        setShowBlockUI(false);

        if (response.error) return warningAlert(response);

        if (!userSelectionModal.selected.length && settings) {
          userSelectionModal.selected =
            record.Details && record.Details.recipient
              ? JSON.parse(JSON.stringify(record.Details.recipient))
              : [];
        }

        setUserSelectionModal({
          show: true,
          report: record,
          data: response.data,
          filteredData: response.data,
          settings: settings,
          selected: userSelectionModal.selected,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(userSelectionModal);
  };

  const handleUserSelect = () => {
    settingsModal.record.Details = settingsModal.record.Details || {};
    settingsModal.record.Details.recipient = userSelectionModal.selected;
    // userSelectionModal.show = false;

    setUserSelectionModal({
      show: false,
      selected: userSelectionModal.selected,
      filteredData: userSelectionModal.filteredData,
    });
    setSettingsModal(settingsModal);
    // console.log("handleuSer", userSelectionModal);
  };
  // const openSchedulerModal = (record) => {
  //   if(record.Details.Schedule) schedulerModal.record = record.Details.Schedule
  // 	else schedulerModal.record = {
  // 		Scheduled: true,
  // 	}

  // 	schedulerModal.show = true;

  //   setSchedulerModal(schedulerModal)
  // }

  // const handleRemoveSchedule = () => {

  // 	delete settingsModal.record.Details.Schedule

  // 	setSettingsModal(settingsModal)
  // }

  // const closeSchedulerModal = () => {

  //   schedulerModal.show = false;

  //   setSchedulerModal(schedulerModal)
  // }

  // const handleSchedulerInputChange = (event) => {
  //   const target = event.target;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   const name = target.name;

  //   schedulerModal.record[name] = value;

  //   setSchedulerModal(schedulerModal)
  // }

  // const handleSchedulerDateChange = (value) => {
  //   const currentDate = new Date();
  //   //console.log(value);

  //   if (moment(value).isBefore(currentDate, 'day')) {
  //     value = schedulerModal.record.Date;
  //     console.log("The date you selected is invalid");
  //   } else {
  //     schedulerModal.record.Date = value;
  //   }
  //   setSchedulerModal(schedulerModal)
  // }

  // const handleSchedulerSubmit = (event) => {
  //   event.preventDefault();

  // 	settingsModal.record.Details = settingsModal.record.Details || {};
  // 	settingsModal.record.Details.Schedule = schedulerModal.record;

  // 	schedulerModal.show = false;

  // 	setSettingsModal(settingsModal)
  //   setSchedulerModal(schedulerModal)
  // }

  // const openModal = () => {

  // 	if (record) {

  // 		modal.title = 'Edit Report';
  // 		modal.show = true;
  // 		modal.record = record;
  // 		modal.actionUrl = '/api/customReport/updateRecord?id=' + record.id;
  // 		modal.actionUrlMethod = 'PUT';

  // 	} else {

  // 		modal.title = 'Upload Report';
  // 		modal.show = true;
  // 		modal.record = {};
  // 		modal.actionUrl = '/api/customReport/createRecord';
  // 		modal.actionUrlMethod = 'POST';

  // 	}
  // 	setModal(modal)
  // }

  const openSettingsModal = (record) => {
    // console.log(record);
    settingsModal.record = JSON.parse(JSON.stringify(record));
    setSettingsModal({
      show: true,
      record: settingsModal.record,
    });
    // console.log(record);
    // console.log(settingsModal.record);
  };

  const onToggle = (e) => {
    //console.log(value);
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (!settingsModal.record.hasOwnProperty("Details")) {
      settingsModal.record.Details = {};
    }
    if (!settingsModal.record.Details.hasOwnProperty("Settings")) {
      let Details = settingsModal.record.Details;
      Details.Settings = {};
      Details.Settings.Mobile = !value;
      Details.Settings.Desktop = !value;
    }
    if (
      !settingsModal.record.Details.Settings.Mobile &&
      !(
        !settingsModal.record.Details.Settings.hasOwnProperty("Desktop") ||
        settingsModal.record.Details.Settings.Desktop
      )
    ) {
      let Details = settingsModal.record.Details;
      Details.Settings.Desktop = true;
      Details.Settings.Mobile = true;
    }
    settingsModal.record.Details.Settings[name] = value;
    // console.log(settingsModal.record.Details.Settings);
    setSettingsModal(settingsModal);
  };

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    settingsModal.record[name] = value;
    // console.log(value);
    setSettingsModal(settingsModal);
  };

  const closeUserSelectionModal = () => {
    setUserSelectionModal({
      show: false,
      selected: userSelectionModal.selected,
      filteredData: userSelectionModal.filteredData,
    });
  };

  return (
    <>
      {alert}
      {save}
      <CustomHeader />
      <Container className="mt-5 fadeIn" fluid>
        {/* <div className="mt-3">
          <ClientsInfo
            data={selectedClient}
            clientsList={clients}
            isLoading={loading}
            page={`dashboard`}
          />
        </div> */}
        <div className="nav-wrapper">
          <PanelCard headerName="Dashboard">
            <Row style={{ display: !reportGenerator.show ? "block" : "none" }}>
              <div className="col mt-2">
                <Row>
                  <Col>
                    {!loading && !error && access <= 4 ? (
                      <label className="custom-toggle custom-toggle-success mr-1">
                        <input type="checkbox" name="Mobile" />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Yes"
                        />
                        <i
                          style={{
                            position: "absolute",
                            top: "-5px",
                            left: "-5px",
                          }}
                          className="fas fa-question-circle text-danger"
                          id="toggle"
                        />
                        <UncontrolledTooltip target="toggle" placement="top">
                          Note: This feature allows you to show or hide the client
                          workspace on Power BI web
                        </UncontrolledTooltip>
                      </label>
                    ) : null}
                  </Col>
                  <Col lg="4" sm="4" className="ml-auto">
                    <div className="ml-auto">
                      <Input
                        placeholder="Search"
                        type="text"
                        bsSize="sm"
                        onChange={handleSearch}
                      />
                    </div>
                  </Col>
                </Row>
                <CardBody className="text-center">
                  <Row>{dashboardList()}</Row>
                </CardBody>
              </div>
            </Row>
            <div style={{ display: reportGenerator.show ? "block" : "none" }}>
              <FullScreen handle={handle}>
                <div
                  className="my-3"
                  style={{
                    height: height - 20 + "px",
                  }}
                // ref={(object) =>  reportGenerator= object }
                >
                  {reportGenerator.show ? (
                    <PowerBIEmbed
                      embedConfig={{
                        type: "dashboard",
                        id: reportGenerator.dashboardId || null,
                        embedUrl: reportGenerator.embedUrl || null,
                        accessToken: reportGenerator.accessToken || null,
                      }}
                      cssClassName="dashboard-style-class"
                      eventHandlers={
                        new Map([
                          [
                            "loaded",
                            function () {
                              console.log("Report loaded");
                            },
                          ],
                          [
                            "rendered",
                            function () {
                              console.log("Report rendered");
                            },
                          ],
                          // ['error', function (event) {console.log(event.detail);}]
                        ])
                      }
                    />
                  ) : null}
                </div>
              </FullScreen>
              <Button
                style={{
                  color: `${bgColor}`,
                  borderColor: `${bgColor}`,
                }}
                onClick={() =>
                  setReportGenerator({
                    show: false,
                  })
                }
              >
                Close Dashboard
              </Button>
              <Button
                style={{
                  color: `${textColor}`,
                  backgroundColor: `${bgColor}`,
                  borderColor: `${bgColor}`,
                }}
                outline
                onClick={handle.enter}
              >
                Full Screen
              </Button>
            </div>
          </PanelCard>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
