import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { sendEVAPrivateMessage } from "../services/mongoDB";
import { EVAService } from "../services/EVAService";
import { generateUUID } from "services/EVAResponseService";
import { evaThemeLighter } from "services/EVAResponseService";

const Input = ({ selectedUUID, onSendEVAMessage,onSelectUUID, ClientID, userid, theme , onInputText }) => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);

  useEffect(()=>{
    setText(onInputText)
  }, [selectedUUID ,onInputText])

  const handleSend = async () => {
    if(text)
    {
      onSendEVAMessage(text)
      setText("")
    }
  };

  const handleClick = async () => {
    const newUUID = await generateUUID();
    onSelectUUID(newUUID);
};

  return (
    <div className="input">
      <div className="new">
        <button style={evaThemeLighter(100, theme)} onClick={handleClick}>New Chat</button>
      </div>
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSend();
          }
        }}
      />
      <div className="send">
        <button  style={{ backgroundColor: theme.PrimaryColor }} onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Input;