import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import PanelCard from "components/Cards/PanelCard";
import { MainContext } from "context/mainContext";
import "./ribbon.css";
import CustomHeader from "customComponents/customHeader";
import { CustomPricingCard } from "customComponents/customPricing";
import PricingDetailsModal from "customComponents/customPricing";
// import { detailsData } from 'utils/pricingData'
import { planDetails } from "utils/pricingData";
import PricingCard from "components/Cards/PricingCard";
import { BeatLoader } from "react-spinners";
import UpgradeModal from "components/PlanUpgrade/UpgradeModal";
import BlockUI from "components/Misc/BlockUI";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router";
// import { CustomPricingTable } from 'customComponents/customPricing'

const Pricing = () => {
  const { bgColor, textColor, firmDetails, userDetails } = useContext(MainContext);
  const [plan, setPlan] = useState({});
  const [pricingPlans, setPricingPlans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [alert, setAlert] = useState(null)
  const routerHistory = useHistory();

  const fetchAPI = () => {
    setIsLoading(true)
    fetch("/api/pricing/getAllRecords")
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          // setPricing(data);
          // console.log(data)
          // console.log(firmDetails)
          let filteredPricing = []
          // let filteredPricing = data.filter(d => firmDetails.Details.Plan.Code === d.Code || firmDetails.Details.Plan.Code === 'TIER1' && d.Code === 'TIER2' || firmDetails.Details.Plan.Code === 'TIER2' && d.Code === 'TIER3' || firmDetails.Details.Plan.Code === 'TIER4' && d.Code === 'TIER4')
          data.filter(d => d.CountryCode === firmDetails.CountryCode).map(d => {
            if (firmDetails.PricingPlan.Code === d.Code) {
              filteredPricing.push(d)
            }
            if (firmDetails.PricingPlan.Code === 'TIER1' && d.Code === 'TIER2') {
              filteredPricing.push(d)
            }
            if (firmDetails.PricingPlan.Code === 'TIER2' && d.Code === 'TIER3') {
              filteredPricing.push(d)
            }
            if (firmDetails.PricingPlan.Code === 'TIER3' && d.Code === 'TIER4') {
              filteredPricing.push(d)
            }
          })
          // console.log(filteredPricing)
          setPricingPlans(filteredPricing)
        }
      })
      .then(() => {
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false)
      });
  };

  useEffect(() => {
    fetchAPI()
  }, [firmDetails])

  const showFullDetails = (id) => {
    let key = {};
    key[id] = true;

    setPlan(Object.assign({}, plan, key));
  };
  const closeFullDetails = (id) => {
    let key = {};
    key[id] = false;
    setPlan(Object.assign({}, plan, key));
  };

  const handleUpgradeOrDowngradeChangePlan = (params) => {
    // console.log('P', params)
    setShowBlockUI(true)
    fetch("/api/pricing/upgradeOrDowngradePlan", {
      method: "PUT",
      body: JSON.stringify({
        planCode: params.Code,
        countryCode: firmDetails.CountryCode,
        forUpgrade: true,
      }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        if (data && !data.error) {
          successAlert(data.record.Details, true)
          // successAlert(data.record.Details.Plan.Name, true)
          setShowBlockUI(false)
        }
      })
      .catch(e => {
        console.log(e)
        setShowBlockUI(false)
        errorAlert(e)
      })
  }

  const handleShowPlanVideo = () => {
    // console.log('video')
    setShowUpgradeModal(!showUpgradeModal)
  }

  const toggleUpgradeModal = () => {
    setShowUpgradeModal(!showUpgradeModal)
  }

  const successAlert = (response, success) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => {
          setAlert(null)
          routerHistory.push(
            `/client/business/details`
          );
          window.location.reload();
        }}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {
          response.Plan.Name === 'Etani Business' ?
            <>
              <p>
                You are now subscribed to <strong style={{ fontWeight: 700 }}>{`${response.Plan.Name}`}</strong>.
              </p>
              <p>
                You can continue to use the platform.etani URL but we have now setup a white-labelled platform for your business at <strong><a href={`https://${response.azureDomain}`} target="_blank" rel="noreferrer">{response.azureDomain}</a></strong>
              </p>
              <p>
                We are also launching your mobile app available for download from the Apple App Store and Google Play Store. We will send you an email with the links when live.
              </p>
              <p>
                Welcome to Etani!
              </p>
            </>
            :
            <p>
              You are now subscribed to <strong style={{ fontWeight: 700 }}>{`${response.Plan.Name}`}</strong>.
            </p>
        }
      </ReactBSAlert>
    );
  };

  const errorAlert = (message) => {
    setAlert(
      <ReactBSAlert
        danger
        title="Error"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const warningAlert = (params, type) => {
    // console.log(params, type)
    type === 'cancelSubs' || type === 'downgrade' ?
      setAlert(
        <ReactBSAlert
          warning
          title="Warning"
          onConfirm={() => type === 'cancelSubs' ? handleCancelSubscription(params) : handleUpgradeOrDowngradeChangePlan(params, type)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="warning"
          confirmBtnText={type === 'cancelSubs' ? 'Proceed' : type === 'downgrade' ? 'Downgrade' : 'Upgrade'}
          btnSize=""
          showCancel={true}
        >
          {
            type === 'cancelSubs' ? <p>You are about to <strong style={{ fontWeight: 700 }}>cancel</strong> your subscription to <strong style={{ fontWeight: 700 }}>{params.Name} Plan</strong>.</p>
              :
              <p>You are about to <strong style={{ fontWeight: 700 }}>{type}</strong> to <strong style={{ fontWeight: 700 }}>{params.Name} Plan</strong>. Click downgrade to continue</p>
          }
        </ReactBSAlert>
      )
      :
      setAlert(
        <ReactBSAlert
          info
          title=""
          onConfirm={() => type === 'cancelSubs' ? handleCancelSubscription(params) : handleUpgradeOrDowngradeChangePlan(params, type)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          confirmBtnText={type === 'cancelSubs' ? 'Proceed' : type === 'downgrade' ? 'Downgrade' : 'Upgrade'}
          btnSize=""
          showCancel={true}
        >
          <>
            <p>You are about to <strong style={{ fontWeight: 700 }}>{type}</strong> to <strong style={{ fontWeight: 700 }}>{params.Name} Plan</strong>.</p>
            <p>
              We cannot wait to open up all the features and functionality of the <strong style={{ fontWeight: 700 }}>{params.Name} Plan</strong>.
            </p>
            <p>Click {type} to continue.</p>
          </>
        </ReactBSAlert>
      );
  };

  const handleCancelSubscription = (params) => {
    //console.log('Canceled')
    setShowBlockUI(true)
    fetch("/api/pricing/cancelSubscription", {
      method: "POST",
      body: JSON.stringify({
        planCode: params.Code,
      }),
    })
      .then(res => res.json())
      .then(data => {
        //console.log(data)
        if (!data.error) {
          successAlert(data.message, 'cancelSubs')
          setShowBlockUI(false)
        } else {
          setShowBlockUI(false)
          errorAlert(data.error)
        }
      })
      .catch(e => {
        setShowBlockUI(false)
        errorAlert(e)
        console.log(e)
      })
  }

  return (
    <>
      {showBlockUI ? <BlockUI /> : null}
      {alert}
      <UpgradeModal
        bgColor={bgColor}
        textColor={textColor}
        showUpgradeModal={showUpgradeModal}
        toggleUpgradeModal={toggleUpgradeModal}
      />
      <CustomHeader style={null} />
      <Container className="mt-5" fluid>
        <div className="mt-5"></div>
        <PanelCard headerName={"Plan Upgrade"}>
          <Row className="mb-3">
            <Col>
              <p className='text-left font-weight-600'>Considering the {pricingPlans[1]?.Code === 'TIER2' ? `Etani Business` : pricingPlans[1]?.Code === 'TIER3' ? `Etani Premium` : `Etani Platinum`} Plan?</p>
              {
                pricingPlans[1]?.Code === 'TIER2' ?
                  <p className='text-left'>If you find yourself in need of inviting more Advisors into the platform to assist in building the Power BI Reports, the Business Plan is the right fit. Moreover, as your data needs grow, and you start adding several data integrations, it's worth noting that these can add up. The <strong>Etani Business</strong> plan includes integrations, making it a more price-appropriate choice when you're looking to expand your integrations.</p>
                  : pricingPlans[1]?.Code === 'TIER3' ?
                    <>
                      <p className='text-left'>As your clientele grows and your demands expand, <strong>Etani Premium</strong> is tailored to meet those advanced needs. With Premium, you unlock the capacity to add even more Clients and Client Users, ensuring that your expanding business remains supported. Furthermore, you'll benefit from increased limits for basic client data integrations and the ability to send out more PDF email reports. So, if you're aiming for an elevated data analytics experience with broader outreach, the <strong>Etani Premium</strong> plan might just be the perfect upgrade for you.</p>
                    </>
                    : <p className='text-left'>As your clientele grows and your demands expand, <strong>Etani Platinum</strong> is tailored to meet those advanced needs. With Platinum, you unlock the capacity to add even more Clients and Client Users, ensuring that your expanding business remains supported. Furthermore, you'll benefit from increased limits for basic client data integrations and the ability to send out more PDF email reports. So, if you're aiming for an elevated data analytics experience with broader outreach, the <strong>Etani Platinum</strong> plan might just be the perfect upgrade for you.</p>
              }
            </Col>
          </Row>
          {/* <Row className="mb-3">
            <Col>
              <p className='text-left font-weight-600'>
                <span className="btn-link" style={{ cursor: 'pointer' }} onClick={handleShowPlanVideo}>Click to watch video</span>
              </p>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <p className='text-left font-weight-600'>
                Plan Comparison
              </p>
            </Col>
          </Row>
          <Row>
            {
              isLoading ? (
                <Container className="text-center my-5">
                  <BeatLoader color={bgColor} size={10} margin={2} />
                </Container>
              )
                :
                pricingPlans?.length >= 1 ?
                  pricingPlans.map((plan, i) => (
                    <Col xl="6" lg="4" md="12" sm="12" key={i}>
                      <PricingCard
                        textColor={textColor}
                        bgColor={bgColor}
                        i={i}
                        firmDetails={firmDetails}
                        record={plan}
                        handleUpgradePlan={warningAlert}
                        userDetails={userDetails}
                      />
                    </Col>
                  ))
                  : (
                    <div className="col-sm-12 mt-3 text-center">
                      <h5> </h5>
                    </div>
                  )
            }
            {/* {planDetails.map((record, i) => (
              <Col xl="6" lg="4" md="12" sm="12" key={i}>
                <CustomPricingCard
                  record={record}
                  showFullDetails={showFullDetails}
                  textColor={textColor}
                  bgColor={bgColor}
                />
                <PricingDetailsModal
                  closeFullDetails={closeFullDetails}
                  isOpen={plan[record.id]}
                  record={record}
                />
              </Col>
            ))} */}
          </Row>
        </PanelCard>
      </Container>
    </>
  );
};

export default Pricing;
