import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2';
import { getRandomLightColor } from 'services/EVAService';
import { defaultControl } from 'services/EVAVisualService';

function DashboardTemplateltemPieGraph({ sqlresult, templateItemVisual, selectedViewOption }) 
{
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(templateItemVisual.selectedGraphHeaderOption)
    const [selectedDataList, setSelectedDataList] = useState([]);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const {
        bgColor,
        textColor
    } = useContext(MainContext);
    const { 
        selectedGroup,
        selectedGroups,
        chartHeader,
        selectedLabel,
        selectedData,
        pieItem,
        selectedFooter,
        headerToMultiSelect
    } = templateItemVisual

    const [chartDataSet, setChartDataSet] = useState(null);
    let chartData = {
        labels: [],
        datasets: [],
    }

    useEffect(() => {
        if(selectedLabel && selectedData)
        {
            const label = getPieChartLabel(selectedLabel.id)
            const data = getPieChartData(selectedData.id)
            setSelectedDataList(data)
        }
        // const footer = getChartFooter(selectedFooter.id)
        // chartData.labels = footer
        // getChartData(selectedGroups)
    },[selectedGraphHeaderOption])
    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    const getPieChartLabel = (id) => {
        const label = []
        const chartLabel = []
        sqlresult.forEach(element => {
            if(selectedGraphHeaderOption)
            {
                if(!label.includes(element[id]) && element[chartHeader.fieldIndex] === selectedGraphHeaderOption)
                chartLabel.push(element[id])
                label.push(element[id])
            } else {
                if(!label.includes(element[id]))
                chartLabel.push(element[id])
                label.push(element[id])
            }
        });
        chartData.labels = chartLabel
        return label
    }
    useEffect(() => {
    },[chartDataSet])
    const getPieChartData = (id) => {
        const data = []
        const color = []
        sqlresult.forEach((element, index) => {
            if (selectedGraphHeaderOption) {
                if (!data.includes(element[id]) && element[chartHeader.fieldIndex] === selectedGraphHeaderOption) {
                    data.push(element[id]);
                }
            } else {
                if (!data.includes(element[id])) {
                    data.push(element[id]);
                }
            }
            if (pieItem[index]) {
                color.push(pieItem[index].color);
            } else {
                // Handle the case where pieItem[index] is undefined
                // For example, push a default color or handle it accordingly
                color.push(defaultControl(index)); // Replace 'defaultColor' with an actual default color if needed
            }
        });
        chartData.datasets = [{
            label: '',
            data: data,
            backgroundColor: color,
            hoverOffset: 4,
        }]
        setChartDataSet(chartData)
        return data
    }

    const handleSelectGraphHeader = (item) => {
        setSelectedGraphHeaderOption(item)
    }

    return (
        <>
            <div className='templateVisualGraphHeaderContent'>
                {chartDataSet &&
                    <>
                        <div className='templateVisualGraphHeaderContainer'>
                            {chartHeader && chartHeader.header && chartHeader.header.map((item) => (
                            <div 
                                className='templateVisualGraphHeaderItem' 
                                style={selectedGraphHeaderOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                onClick={() => handleSelectGraphHeader(item)}
                            >{item}</div>
                            ))}
                        </div>
                        <div style={{ maxHeight: screenHeight  - 300 }}>
                            <Pie
                                data={chartDataSet}
                            />
                        </div>
                    
                    </>
                }
            </div>
        </>   
    )
}

export default DashboardTemplateltemPieGraph