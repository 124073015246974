import { IconEdit } from 'chat-components/ui/icon';
import { IconTrash } from 'chat-components/ui/icon';
import { IconExpand } from 'chat-components/ui/icon';
import { IconMinimize } from 'chat-components/ui/icon';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import GraphView from 'eva-component/response/GraphView';
import GridView from 'eva-component/response/GridView';
import TreeView from 'eva-component/response/TreeView';
import React, { useContext, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-date-picker';
import { Button, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { submitEvaQueryHandler } from 'services/DashboardService';
import { evaThemeLighter } from 'services/EVAResponseService';
import { isWithChildHandler } from 'services/EVAResponseService';
import { convertToTreeViewData } from 'services/EVAResponseService';
import { registerDashboardTemplate } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactDatePicker from 'react-datepicker';
import ComparativeDatePicker from 'components/ComparativeDatePicker/ComparativeDatePicker';
import DashboardItemHeader from './DashboardItemHeader';
import DashboardVisual from 'components/DashboardVisual/DashboardVisual';
import DashboardTemplateItemNewVisual from './DashboardTemplateItemNewVisual';
import { reFormatEVADateRange } from 'services/EVAResponseService';

function DashboardTemplateItem({ handleSetItemIndexToEdit, isEditIndex, editMode = false , selectedDashboardTemplate, selectedTemplate, item, index, handleNewUpdate, handleDeleteDashboardTemplateItem}) 
{
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [isShowGrap, setIsShowGrap] = useState(false)
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [toggleAll, setToggleAll] = useState(false)
    const [sqlresult, setSqlResult] = useState(null)
    const [gridHeader, setGridHeader] = useState(null)
    const [EVAData, setEVAData] = useState(null)
    const [treeViewData, setTreeViewData] = useState(null)
    const [isWithChild, setIsWithChild] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [onEditName, setOnEditName] = useState(false)
    const [queryResult, setQueryResult] = useState(null)
    const [alert, setAlert] = useState(false);
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [selectedViewOption, setSelectedViewOption] = useState(null)
    const [templateItemVisual, setTemplateItemVisual] = useState(null)
    const [dataValue, setDataValue] = useState(null)
    const theme = { PrimaryColor: bgColor, TextColor: textColor }

    useEffect(() => {
        const processData = async () => {
            try{
                
                if(item.visual)
                {
                    setSelectedViewOption(item.visual.selectedViewOption)
                    setTemplateItemVisual(item.visual)
                }

                const res = await submitEvaQueryHandler(item.query, selectedClient, firmDetails, userDetails);
                setQueryResult(res)
                if(!res) {
                    setError(true)
                    setLoading(false);
                    return
                }
                
                const sqlresult = res.map(obj => Object.values(obj));
                const header = res.length > 0 ? Object.keys(res[0]) : [];
                const newRes = await reFormatEVADateRange(header, sqlresult)
                setDataValue(newRes.dataValue)
                const treeViewData = convertToTreeViewData(sqlresult)
                const isWithChild = isWithChildHandler(treeViewData)

                setEVAData(res)
                setSqlResult(sqlresult)
                setGridHeader(header)
                setTreeViewData(treeViewData)
                setIsWithChild(isWithChild)
                setLoading(false)
                setError(false)
            }catch (error)
            {
                console.log(error)
                setError(true)
                setLoading(false);
            }
        }
        processData();
    }, [selectedClientID, newUpdateCount])

    useEffect(() => {
        setOnEditName(isEditIndex === index)
    },[isEditIndex])

    const showGridHandler = () => {
        setIsShowGrid(!isShowGrid)
        setIsShowGrap(false)
    }
    const showGrapHandler = () => {
        setIsShowGrap(!isShowGrap)
    }
    const toggleAllVisibility = () => {
        setToggleAll(!toggleAll)
    }
    const toggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
    }
    const ItemFullScreenView = () => {
        return (
            <div className='ItemFullScreenViewContainer'>
                <div  className='ItemFullScreenViewContent'>
                    <ItemContentView />
                </div>
            </div>
        )
    }

    const ItemContentView = () => {
        return (
            <>
            {alert}


            <div className={`dashboardItems ${templateItemVisual ? onEditName ? 'col-xs-6' : 'col-xs-12 col-md-6' : ''}`} style={{ border: `1px solid ${bgColor}` }} key={index}>
                {loading ? <CustomSpinner /> :
                    isError && !loading ? 
                    <>
                        <div className="dashboardItems" style={{
                            height: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center horizontally
                            justifyContent: 'center', // Center vertically
                            flexWrap: 'wrap'
                        }}>
                            <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No data found</div>
                            <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                        </div>
                    </>
                    :
                    queryResult && queryResult.length > 0 ?
                    <div className="dashboardItemBody" style={{ padding: 20}}>
                        {onEditName ?
                            <>
                                {/* <ComparativeDatePicker
                                    selectedDashboardTemplate={selectedDashboardTemplate} 
                                    selectedTemplate={selectedTemplate} 
                                    item={item} 
                                    index={index}  
                                /> */}

                                <DashboardVisual 
                                    item={item}
                                    index={index} 
                                    selectedDashboardTemplate={selectedDashboardTemplate}
                                    gridHeader={gridHeader}
                                    sqlresult={sqlresult}
                                    dataValue={dataValue}
                                    theme={theme} 
                                    treeViewData={treeViewData}
                                    isWithChild={isWithChild}
                                    isShowGrid={isShowGrid}
                                    toggleAll={toggleAll}
                                    isShowGrap={isShowGrap}
                                    toggleFullScreen={toggleFullScreen}
                                    handleSetItemIndexToEdit={handleSetItemIndexToEdit}
                                    handleNewUpdate={handleNewUpdate}
                                    templateItemVisual={templateItemVisual}
                                />
                            </>
                        :
                            <>
                                {templateItemVisual && selectedViewOption !== 'Grid' ?
                                <>
                                    
                                    <DashboardTemplateItemNewVisual 
                                        item={item}
                                        index={index}
                                        gridHeader={gridHeader}
                                        sqlresult={sqlresult}
                                        dataValue={dataValue}
                                        templateItemVisual={templateItemVisual}
                                        theme={theme}
                                        treeViewData={treeViewData}
                                        isWithChild={isWithChild}
                                        isShowGrid={isShowGrid}
                                        toggleAll={toggleAll}
                                        isShowGrap={isShowGrap}
                                    />
                                </>
                                :
                                <>
                                    <DashboardItemHeader 
                                        onEditName={onEditName}
                                        item={item}
                                        editMode={editMode}
                                        index={index}
                                        handleSetItemIndexToEdit={handleSetItemIndexToEdit}
                                        handleDeleteDashboardTemplateItem={handleDeleteDashboardTemplateItem}
                                        selectedDashboardTemplate={selectedDashboardTemplate}
                                        toggleFullScreen={toggleFullScreen}
                                        isFullScreen={isFullScreen}
                                    />
                                    <div className='EVAResponseContainer' style={{ display: 'unset', padding: 0 }}>
                                        <div className='EVADataContent'>
                                            {isShowGrap ? <GraphView 
                                                sqlResult={sqlresult}
                                                gridHeader={gridHeader}
                                                theme={theme} 
                                                treeViewData={treeViewData}
                                                isWithChild={isWithChild}
                                                isShowGrid={isShowGrid}
                                            /> : 
                                            isWithChild && !isShowGrid ? 
                                                <TreeView
                                                    theme={theme} 
                                                    sqlresult={sqlresult} 
                                                    gridHeader={gridHeader}
                                                    treeViewData={treeViewData}
                                                    toggleAll={toggleAll}
                                                /> :
                                                <GridView
                                                    theme={theme} 
                                                    sqlresult={sqlresult} 
                                                    EVAData={sqlresult}
                                                    gridHeader={gridHeader}
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                                }
                                
                            </>
                        }
                    </div>
                :
                    <>
                        <div className="dashboardItems" style={{
                            height: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center horizontally
                            justifyContent: 'center', // Center vertically
                            flexWrap: 'wrap'
                        }}>
                            <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No data found</div>
                            <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                        </div>
                    </>
                }
            </div>
            </>
        )
    }

    return (
        isFullScreen ? 
        <ItemFullScreenView /> :
        <ItemContentView />
        // </Col>
    )
}

export default DashboardTemplateItem