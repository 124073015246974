import { createElement } from "react";

function IconUser({ className, ...props }) {
    return (
      createElement('svg', {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 256 256',
        fill: 'currentColor',
        ...props
      },
        createElement('path', {
          d: 'M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z',
        })
      )
    );
  }
function IconEtani({ className, ...props }) {
    return (
        createElement('svg', {
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 0 256 256',
            ...props,
            fill: '#fff',
            style: { backgroundColor: '#fff', borderRadius: '4px', width: '40px', height: '40px' }
        },
                createElement('g', { transform: 'translate(15.000000,240.000000) scale(0.100000,-0.100000)', stroke: 'none' },
                createElement('path', { d: 'M180 1095 l0 -965 950 0 950 0 0 115 0 115 -832 2 -833 3 -3 727 -2 728 832 2 833 3 3 118 3 117 -951 0 -950 0 0 -965z', fill: '#292a63' }),
                createElement('path', { d: 'M600 1095 l0 -545 740 0 740 0 0 115 0 115 -625 0 -625 0 0 310 0 310 623 2 622 3 3 118 3 117 -741 0 -740 0 0 -545z', fill: '#292a63' }),
                createElement('path', { d: 'M1030 1095 l0 -115 525 0 525 0 0 115 0 115 -525 0 -525 0 0 -115z', fill: '#292a63' }),
            )
        )
    );
}
function CloseTab({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      ...props,
      fill: 'none', // Set fill to none to make the background transparent
      style: {
        backgroundColor: 'transparent', // Set background color to transparent
        borderRadius: '4px',
        width: '35px',
        height: '35px',
      }
    },
      createElement('g', { stroke: '#fff' },
        createElement('path', { d: 'M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM40 56h40v144H40Zm176 144H96V56h120v144Z', fill: '#fff' }),
      )
    )
  );
}

function IconTrash({ className, ...props }) {
  return (
    createElement('svg', {
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 256 256',
      fill: '#ff3e3e',
      style: { width: '45px', height: '20px' },
      ...props
    },
      createElement('path', { d: 'M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16ZM96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Zm48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Z' }),
    )
  );
}
function IconLike({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#626262',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M2 9h3v12H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1zm5.293-1.293l6.4-6.4a.5.5 0 0 1 .654-.047l.853.64a1.5 1.5 0 0 1 .553 1.57L14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H8a1 1 0 0 1-1-1V8.414a1 1 0 0 1 .293-.707z' }),
      )
    )
  );
}

function IconDislike({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#626262',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M22 15h-3V3h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zm-5.293 1.293l-6.4 6.4a.5.5 0 0 1-.654.047L8.8 22.1a1.5 1.5 0 0 1-.553-1.57L9.4 16H3a2 2 0 0 1-2-2v-2.104a2 2 0 0 1 .15-.762L4.246 3.62A1 1 0 0 1 5.17 3H16a1 1 0 0 1 1 1v11.586a1 1 0 0 1-.293.707z' }),
      )
    )
  );
}
function Shield({ className, ...props }) {
  return (
    createElement('svg', {
      viewBox: '0 0 24 24',
      fill: '#fff',
      xmlns: 'http://www.w3.org/2000/svg',
      style: { width: '25px', height: '15px', stroke: '#333' },
      ...props
    },
      createElement('g', null,
        createElement('path', { d: 'M19,14.7368421 C19,17.122807 16.6666667,19.2105263 12,21 C7.33333333,19.2105263 5,17.122807 5,14.7368421 C5,12.3508772 5,9.36842105 5,5.78947368 C8.13611482,4.59649123 10.4694481,4 12,4 C13.5305519,4 15.8638852,4.59649123 19,5.78947368 C19,9.36842105 19,12.3508772 19,14.7368421 Z' }),
      )
    )
  );
}

function IconSort({ className, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" style={{ width: '20px', height: '20px' }} {...props}>
      <path d="M4 8H13" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6 13H13" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 18H13" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 20V4L20 8" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}


export {
    IconUser,
    IconEtani,
    CloseTab,
    IconTrash,
    IconLike,
    IconDislike,
    IconSort,
    Shield
}