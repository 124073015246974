import React, { useEffect } from 'react'

function EVAAnswer({ answer }) 
{
    useEffect(() => {
        // const  strAnswer = answer.split("`nl`").map((line, index) => <div key={index}>{line.replace(/\[/g, "<b>").replace(/\]/g, "</b>")}</div>)
        // console.log(strAnswer)
    },[answer])
    return (
        <div>
            {answer && answer.split("`nl`").map((line, index) => (
                <div key={index}>
                    {line.replace(/\[/g, "<b>").replace(/\]/g, "</b>")}
                </div>
            ))}
        </div>
    )
}

export default EVAAnswer