import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2';

function DashboardTempaltemGraphView({ sqlresult, templateItemVisual, selectedViewOption, newUpdateCount }) 
{
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(templateItemVisual.selectedGraphHeaderOption)
    const {
        bgColor,
        textColor
    } = useContext(MainContext);
    const { chartHeader, 
        selectedFooter, 
        selectedGroup, 
        selectedGroups, 
        headerToMultiSelect,
    } = templateItemVisual

    const [chartDataSet, setChartDataSet] = useState(null);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    let chartData = {
        labels: [],
        datasets: [],
    }


    useEffect(() => {
        if (!selectedFooter) {
            return;
        }
    
        const footer = getChartFooter(selectedFooter.id);
        if (!footer) {
            console.error("Footer with ID", selectedFooter.id, "not found");
            return;
        }
    
        chartData.labels = footer;
        getChartData(selectedGroups);
    }, [selectedGraphHeaderOption, newUpdateCount]);
    
    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
    },[chartDataSet])

    const getChartFooter = (id) => {
        const label = []
        sqlresult.forEach(element => {
            if(selectedGraphHeaderOption)
            {
                if(!label.includes(element[id]) && element[chartHeader.fieldIndex] === selectedGraphHeaderOption)
                label.push(element[id])
            }else{
                if(!label.includes(element[id]))
                label.push(element[id])
            }
           
        });
        return label
    }
    const getChartData = (filter) => 
    {
        filter.forEach((fil, index) => {
            if(fil.data !== '' && fil.label !== '')
            {
                const res = headerToMultiSelect.filter((e) => e.name === fil.data);
                const id = res[0]?.id
                if(chartData.labels.length > 0)
                {
                    const item = {
                        id: index,
                        label: fil.label,
                        data: [],
                        backgroundColor: fil.color,
                        borderColor: fil.color,
                    }
                    let itemData = []
                    chartData.labels.forEach((item) => {
                        let totalAmount = 0
                        sqlresult.forEach((data) => {
                            if(item === data[selectedFooter.id])
                            {
                                // console.log(data[id])
                                totalAmount += data[id]
                            }
                        })

                        itemData.push(totalAmount)
                    })
                    
                    item.data = itemData
                    chartData.datasets.push(item)
                }
            }
        })
        if(chartData.datasets.length > 0 && chartData.labels.length > 0)
        {
            // console.log(chartData)
            setChartDataSet(chartData)
        }
    }
    const handleSelectGraphHeader = (item) => {
        setSelectedGraphHeaderOption(item)
    }
    const options = {
        scales: {
            x: {
                ticks: {
                    color: 'black' // X-axis label color
                }
            },
            y: {
                ticks: {
                    color: 'black' // Y-axis label color
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: 'black' // Legend label color
                }
            }
        }
    };
    return (
        <>
        {chartDataSet &&
            <>
                <div className='templateVisualGraphHeaderContainer'>
                    {chartHeader && chartHeader.header && chartHeader.header.map((item) => (
                    <div 
                        className='templateVisualGraphHeaderItem' 
                        style={selectedGraphHeaderOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                        onClick={() => handleSelectGraphHeader(item)}
                    >{item}</div>
                    ))}
                </div>
                <div  style={{ maxHeight: screenHeight  - 300 }}>
                    {selectedViewOption === 'Line Chart' &&
                        <Line
                            datasetIdKey='id'
                            data={chartDataSet}
                            options={options}
                        />}
                    {selectedViewOption === 'Bar Chart' &&
                        <Bar
                            datasetIdKey='id'
                            data={chartDataSet}
                            options={options}
                    />
                }
                </div>
            </>
        }
        </>
    )
}

export default DashboardTempaltemGraphView