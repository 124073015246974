import React, { useState, useEffect, useContext } from 'react'
import { FormGroup, Col, Row, Button, Card, CardBody, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { MainContext } from "../../context/mainContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "utils/CheckoutForm";
import CustomSpinner from "components/Misc/CustomSpinner";
import ReactBSAlert from "react-bootstrap-sweetalert";

const PaymentDetails = ({ ...props }) => {
  const {
    userDetails,
    firmDetails,
    bgColor
  } = useContext(MainContext);
  const [showEditPayment, setShowEditPayment] = useState(false)
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [alert, setAlert] = useState(null)
  const [paymentDetailsSaveType, setPaymentDetailsSaveType] = useState()


  const renderCardIcon = (brand) => {

    let cardLogo = ''

    switch (brand) {
      case "amex":
        cardLogo = "amex"
        break;
      case "diners":
        cardLogo = "diners"
        break;
      case "discover":
        cardLogo = "discover"
        break;
      case "jcb":
        cardLogo = "jcb"
        break;
      case "mastercard":
        cardLogo = "mastercard"
        break;
      case "unionpay":
        cardLogo = "unionpay"
        break;
      case "visa":
        cardLogo = "visa"
        break;
      default:
        // code block
        cardLogo = "unknown"
    }

    return (
      <img
        style={{
          background: "#eee",
          width: "auto",
          height: "20px",
          objectFit: "contain",
          // borderRadius: "10px",
          // border: "2px solid #e2e2e2",
        }}
        alt={`${brand}`}
        // className="rounded-circle"
        src={require(`assets/img/cards/${cardLogo}.png`).default}
      />
    )
  }

  const handleEditPaymentMethod = () => {
    // console.log(props.userPaymentDetails)
  }

  const handleShowEditPaymentMethod = (val) => {
    setPaymentDetailsSaveType(val)
    setShowEditPayment(!showEditPayment)
    fetch("/api/stripe/getConfig", {
      method: "POST",
      body: JSON.stringify({
        email: `${userDetails?.User?.Email}`,
      }),
    }).then(async (r) => {
      const { publishKey } = await r.json();
      setStripePromise(loadStripe(publishKey));
      // console.log(publishKey);
      if (publishKey) {
        fetch("/api/stripe/setUpIntent", {
          method: "POST",
          body: JSON.stringify({
            name: `${userDetails?.User?.FirstName} ${userDetails?.User?.LastName} - ${firmDetails?.Name}`,
            email: `${firmDetails?.Email}`,
            description: `${firmDetails?.Name}`,
            country: `${userDetails?.User?.Details?.Country}`,
          }),
        }).then(async (result) => {
          let { clientSecret } = await result.json();
          // console.log(clientSecret);
          setClientSecret(clientSecret);
        });
      }
    });
  }

  const successAlert = (response) => {
    setAlert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => {
          setAlert(null)
          window.location.reload();
        }}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        <p>
          {`Payment details ${paymentDetailsSaveType === "updatePayment" ? "has been updated" : "setup"} successfully.`}
        </p>
      </ReactBSAlert>
    );
  };


  const stripeSuccess = (result) => {
    if (result?.setupIntent?.status === 'succeeded') {
      setShowEditPayment(false)
      successAlert()
    }
  }

  return (
    <>
      {alert}
      {
        showEditPayment ?
          <>
            <Row className='mb-3'>
              <Col className="text-center">
                <FormGroup>
                  <label
                    className="form-control-label btn-link"
                    htmlFor="exampleFormControlInput1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleShowEditPaymentMethod()}
                  >
                    <span className="btn-inner--icon ml-1">
                      <i className="ni ni-bold-left mr-2" />
                    </span> Back to payment details
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {clientSecret && stripePromise ? (
                  <Elements stripe={stripePromise} options={{
                    appearance: {
                      theme: "stripe",
                    },
                    clientSecret,
                  }}>
                    <CheckoutForm
                      secret={clientSecret}
                      userDetails={userDetails}
                      firmDetails={firmDetails}
                      // stripeSuccess={stripeSuccess}
                      userEmail={userDetails?.User?.Email}
                      saveType={paymentDetailsSaveType}
                      stripeSuccess={stripeSuccess}
                      description={firmDetails?.Name}
                    />
                  </Elements>
                ) : (
                  <CustomSpinner />
                )}
              </Col>
            </Row>
          </>
          :
          <>
            {/* <Row>
              <Col className="text-center">
                <span className="btn-inner--icon text-xl">
                  <i className="far fa-credit-card" />
                </span>
              </Col>
            </Row> */}
            {/* <Row>
              <Col className=" mb-3">
                <p style={{ fontWeight: "700" }}>Manage Payment Details</p>
              </Col>
            </Row> */}
            {
              props.userDetails?.User?.UserAccess <= 3 && props.userPaymentDetails ?
                <Row>
                  <Col
                  // lg={props.userDetails?.User?.UserAccess <= 3 ? '5' : '6'} className=""
                  >
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Payment Type
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {props.userPaymentDetails?.type === 'card' ? 'Credit Card' : props.userPaymentDetails?.type === 'au_becs_debit' ? 'Bank' : "N/A"}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow d-inline-block">
                              {/* <i className="ni ni-active-40" /> */}
                              {props.userPaymentDetails?.type === 'card' ?
                                <i className="fas fa-credit-card" />
                                : props.userPaymentDetails?.type === 'au_becs_debit' ?
                                  <i className="fas fa-university" />
                                  : "N/A"
                              }
                            </div>
                            <div className='d-inline-block ml-3'>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  color=""
                                  role="button"
                                  size="sm"
                                  style={{ margin: '0px' }}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    // href="#pablo"
                                    onClick={() => handleShowEditPaymentMethod("updatePayment")}
                                  >
                                    Change Payment Details
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col>
                            {props.userPaymentDetails?.type === 'card' ?
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="CardNumber"
                                >
                                  <span style={{ fontWeight: 400 }}>
                                    Card Number:{" "}
                                  </span>
                                  {` •••• •••• •••• ${props.userPaymentDetails?.card?.last4} `}
                                  {
                                    renderCardIcon(props.userPaymentDetails?.card?.brand)
                                  }
                                </label>
                              </FormGroup>
                              :
                              props.userPaymentDetails?.type === 'au_becs_debit' ?
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="CardNumber"
                                  >
                                    <span style={{ fontWeight: 400 }}>
                                      Account Number:{" "}
                                    </span>
                                    {` •••••${props.userPaymentDetails?.au_becs_debit?.last4} `}
                                  </label>
                                </FormGroup>
                                :
                                "No format yet."

                            }
                          </Col>
                          <Col>
                            {props.userPaymentDetails?.type === 'card' ?
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="ExpDateMonth"
                                >
                                  <span style={{ fontWeight: 400 }}>
                                    Expiration:{" "}
                                  </span>
                                  {`${props.userPaymentDetails?.card?.exp_month}/${props.userPaymentDetails?.card?.exp_year}`}
                                </label>
                              </FormGroup>
                              :
                              props.userPaymentDetails?.type === 'au_becs_debit' ?
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="ExpDateMonth"
                                  >
                                    <span style={{ fontWeight: 400 }}>
                                      BSB:{" "}
                                    </span>
                                    {`${props.userPaymentDetails?.au_becs_debit?.bsb_number}`}
                                  </label>
                                </FormGroup>
                                : "No format yet."
                            }
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {/* {props.userPaymentDetails?.type === 'card' ?
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="CardNumber"
                        >
                          <span style={{ fontWeight: 400 }}>
                            Card Number:{" "}
                          </span>
                          {` •••• •••• •••• ${props.userPaymentDetails?.card?.last4} `}
                          {
                            renderCardIcon(props.userPaymentDetails?.card?.brand)
                          }
                        </label>
                      </FormGroup>
                      :
                      props.userPaymentDetails?.type === 'au_becs_debit' ?
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="CardNumber"
                          >
                            <span style={{ fontWeight: 400 }}>
                              Account Number:{" "}
                            </span>
                            {` •••• ${props.userPaymentDetails?.au_becs_debit?.last4} `}
                          </label>
                        </FormGroup>
                        :
                        "No format yet."
                    } */}
                  </Col>
                  {/* <Col lg={props.userDetails?.User?.UserAccess <= 3 ? '5' : '6'} className="text-center">
                    {props.userPaymentDetails?.type === 'card' ?
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="ExpDateMonth"
                        >
                          <span style={{ fontWeight: 400 }}>
                            Expiration:{" "}
                          </span>
                          {`${props.userPaymentDetails?.card?.exp_month}/${props.userPaymentDetails?.card?.exp_year}`}
                        </label>
                      </FormGroup>
                      :
                      props.userPaymentDetails?.type === 'au_becs_debit' ?
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="ExpDateMonth"
                          >
                            <span style={{ fontWeight: 400 }}>
                              BSB:{" "}
                            </span>
                            {`${props.userPaymentDetails?.au_becs_debit?.bsb_number}`}
                          </label>
                        </FormGroup>
                        : "No format yet."
                    }
                  </Col> */}
                  {/* {
                    props.userDetails?.User?.UserAccess <= 3 ?
                      <Col lg='2' className="text-center">
                        <FormGroup>
                          <label
                            className="form-control-label btn-link"
                            htmlFor="exampleFormControlInput1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleShowEditPaymentMethod("updatePayment")}
                          >
                            <span className="btn-inner--icon ml-1">
                              <i className="fas fa-edit" />
                            </span> Edit
                          </label>
                        </FormGroup>
                      </Col>
                      : null
                  } */}

                </Row>
                :
                <>
                  <Row className='my-3'>
                    <Col>
                      <p>No payment details available.</p>
                    </Col>
                  </Row>
                  {
                    props.userDetails?.User?.UserAccess <= 3 ?
                      <Row>
                        <Col className='text-center'>
                          <Button
                            // color="primary"
                            className="btn-link"
                            // outline
                            onClick={() => handleShowEditPaymentMethod("setupPayment")}
                            style={{
                              backgroundColor: `${"#fff"}`,
                              color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                              borderColor: `${"#fff"}`,
                            }}
                          >
                            Setup Payment Details
                          </Button>
                        </Col>
                      </Row>
                      : null
                  }
                </>
            }
            {

            }
            {/* <Row>
              <Col className="text-center mt-5">
                <FormGroup>
                  <label
                    className="form-control-label btn-link"
                    htmlFor="exampleFormControlInput1"
                    style={{ cursor: 'pointer' }}
                    onClick={props.handleShowPaymentInfo}
                  >
                    <span className="btn-inner--icon ml-1">
                      <i className="ni ni-bold-left mr-2" />
                    </span> Back to Details
                  </label>
                </FormGroup>
              </Col>
            </Row> */}
          </>
      }
    </>
  )
}

export default PaymentDetails