import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  CardImg,
  Spinner
} from "reactstrap";
import { useHistory } from "react-router";

import AdminFooter from "components/Footers/MainFooter";
import BlockUI from "components/Misc/BlockUI";
import { ClipLoader } from "react-spinners";
import { CustomInputFields } from "customComponents/customComponents";
import { CustomQRCode } from "customComponents/customComponents";
import { ScanQRPage } from "customComponents/customComponents";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { MainContext } from "context/mainContext";
import { useSocketEvent, useRoom } from "service/SocketContext";

const ClientLogin = () => {
  const { userDetails } = useContext(MainContext);
  const [transitionStage, setTransistionStage] = useState("fadeFrom");
  const [showPassword, setShowPassword] = useState(false);
  const [, setfocusedEmail] = useState(false);
  const [showBlockUI, setShowBlockUI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [alert, setalert] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [btnLoading, setBtnLoading] = useState(false)
  const [qr] = useState({
    playstore: "https://play.google.com/store",
    apple: "https://www.apple.com/ph/",
  });
  const [isOpen, setIsOpen] = useState({
    android: false,
    ios: false,
  });
  const [value, setValue] = useState({
    email: "",
    password: "",
    trust: "",
  });

  const routerHistory = useHistory();

  const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value.email.trim());

  useEffect(() => { }, [userDetails]);

  localStorage.setItem('timed-out', 'false');

  const handleChangeStep = (e) => {
    e.preventDefault();
    setBtnLoading(true)
    if (!email) {
      setBtnLoading(true)
      return setError("Please enter a valid email!");
    }

    fetch("/api/auth/checkEmail", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email: value.email.trim() }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.error) {
          setBtnLoading(false)
          return setError(result.error)
        };
        if (result.success) {
          setBtnLoading(false)
          setUser(result);

          if (!result.newUser) {
            setError("");
            setStep(2);
          } else {
            setError(result.message);
          }
        }
      })
      .catch((err) => {
        setBtnLoading(false)
        console.log(err);
      });
  };

  const handleBack = () => {
    setError("");
    if (step > 1) return setStep(step - 1);
  };

  const handleChange = (e) => {
    e.preventDefault();

    const target = e.target;
    const name = target.name;

    const newValue = Object.assign({}, value);

    newValue[name] = target.value;

    setValue(newValue);
    setError("");
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (value.password === "") {
      return setError("Please enter a password!");
    }

    let newValue = { ...value };
    newValue.email = newValue.email.trim();

    setShowBlockUI(true);

    setTimeout(() => {
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(newValue),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          // console.log(result);
          setShowBlockUI(false);
          // Paul Code Starts Here -------------------->>>
          if (result.mfaEnabled) {
            setTransistionStage("fadeTo");
            setTimeout(() => {
              return routerHistory.push("/auth/mfa-login", { value });
            }, 500);
          }
          // <<<-------------------- Paul Code Ends Here

          if (result.error) return setError(result.error);
          if (result.success) {
            setUser(result);
            setTransistionStage("fadeTo");
            setTimeout(() => {
              if (result.needSetPayment) {
                return routerHistory.push("/signup/payment-setup");
              }
              return routerHistory.push("/");
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1500);
  };

  const onOpen = (mobile) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setShowQR(true);
    mobile === true ? setIsOpen({ android: true }) : setIsOpen({ ios: true });
  };

  const onShowQrCode = (show) => {
    setTransistionStage("fadeTo");
    setTimeout(() => {
      onOpen(show);
      setTransistionStage("fadeFrom");
    }, 500);
  };

  const handleForgotPassword = () => {
    setShowBlockUI(true);
    if (!value.email) return errorAlert("Please enter your email.");

    value.business = user.business ? user.business : null;

    fetch("/api/auth/forgotPassword", {
      method: "POST",
      body: JSON.stringify(value),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        const record = {
          email: "",
          password: "",
          trust: "",
        };

        setValue(record);

        if (data.error || data.stack) {
          setShowBlockUI(false);
          setStep(1);
          return errorAlert(data.error.message || data.error || data.message);
        }
        setShowBlockUI(false);
        setStep(1);
        return successAlert(`${data.message}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const errorAlert = (message) => {
    setalert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setalert(null)}
        confirmBtnBsStyle="error"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const successAlert = (message) => {
    setalert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {` ${message}`}
      </ReactBSAlert>
    );
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
        background: "#F7FAFC",
        position: "relative",
        display: "grid",
      }}
    >
      {alert}
      <Container className="align-items-center" style={{ display: "grid" }}>
        <div>
          {loading ? (
            <div className="justify-content-center d-flex align-items-center flex-column">
              <ClipLoader color="#5e72e4" size={35} margin={2} />

              <h1 className="h3 mt-3">Generating QR Code...</h1>
            </div>
          ) : (
            <Row className="justify-content-center">
              {showQR ? (
                <Col className={`${transitionStage} px-0`}>
                  <Card className="d-flex justify-content-center bg-transparent shadow-none px-3 px-sm-4">
                    <ScanQRPage
                      qr={qr}
                      isOpen={isOpen}
                      toNewRoute={() => {
                        setTransistionStage("fadeTo");
                        setTimeout(() => {
                          setTransistionStage("fadeFrom");
                          setShowQR(false);
                        }, 500);
                      }}
                    />
                  </Card>
                </Col>
              ) : (
                <Col xl="5" lg="6" md="8" sm="12" xs="12">
                  <Card
                    className={`${transitionStage} shadow-none  p-sm-3 p-0 mb-0 py-5 mt-5 `}
                    style={{ background: "#F7FAFC" }}
                  >
                    <CardHeader
                      className="text-center h2  border-0 mt-4 pb-0"
                      style={{ background: "#F7FAFC" }}
                    >
                      <CardImg
                        alt="Login"
                        src={"/api/accountingFirm/getThemeLogo"}
                        className="mx-auto justify-content-center text-center rounded w-50 h-10 shadow"
                        top
                      />
                    </CardHeader>
                    {step > 1 ? (
                      <i
                        style={{ fontSize: "20px", position: "absolute" }}
                        className="fas fa-long-arrow-alt-left mt-3 "
                        onClick={handleBack}
                      />
                    ) : null}
                    <CardBody className="pt-sm-2 px-0 pb-sm-4">
                      <Form
                        role="form"
                        onSubmit={
                          step === 1
                            ? handleChangeStep
                            : // : showMultifactorAuth //>>>>>>  for multifactor authentication
                            handleLogin
                        }
                      >
                        <CustomInputFields
                          error={error}
                          autoComplete="true"
                          name={step === 1 ? "email" : "password"}
                          id={step === 1 ? "ClientEmail" : "ClientPassword"}
                          value={step === 1 ? value.email : value.password}
                          placeholder={step === 1 ? "Email" : "Password"}
                          onClick={() => setShowPassword(!showPassword)}
                          onFocus={() => setfocusedEmail(true)}
                          onBlur={() => setfocusedEmail(true)}
                          showPassword={showPassword}
                          onChange={handleChange}
                          showPasswordIcon={step === 1 ? false : true}
                          step={step}
                          htmlFor={
                            step === 1 ? "ClientEmail" : "ClientPassword"
                          }
                          label={step === 1 ? "Enter Email" : "Enter Password"}
                          className={
                            step === 1 ? "ni ni-email-83" : "fas fa-unlock-alt"
                          }
                          style={
                            error !== ""
                              ? { border: "1px solid #fb6340" }
                              : { border: "" }
                          }
                          type={
                            step === 1
                              ? "text"
                              : !showPassword
                                ? "password"
                                : "text"
                          }
                        />

                        {error !== "" ? (
                          <div
                            className="text-justify"
                            style={{ fontSize: "12px" }}
                          >
                            <i className="text-warning mx-1 fas fa-exclamation-circle" />
                            {/* <span className="text-warning ml-1">{error}</span> */}
                            <span className="text-warning ml-1" dangerouslySetInnerHTML={{ __html: error }} />
                          </div>
                        ) : null}

                        <div className="text-center">
                          <Button
                            className="mt-3 mb-2 bg-gradient-primary "
                            color="primary"
                            type="submit"
                            block
                            disabled={btnLoading ? true : false}
                          >
                            {btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} /> : step === 1 ? "Submit" : "Login"}
                          </Button>
                        </div>
                        <Row className="px-4 mt-3">
                          <Col xs="8"></Col>
                          {step === 1 ? null : (
                            <Col xs="4" className="text-right px-0">
                              <span
                                className="text"
                                style={{
                                  fontSize: "11px",
                                  cursor: "pointer",
                                }}
                                onClick={handleForgotPassword}
                              >
                                Forgot password?
                              </span>
                            </Col>
                          )}
                        </Row>
                      </Form>
                    </CardBody>
                    <h2 className=" text-center h4 sub font-weight-500 mb-2">
                      You may view your reports and dashboards on mobile.
                    </h2>
                    <Row className="mx-auto">
                      <CustomQRCode
                        type="Android"
                        alt="play-store"
                        isDescription={true}
                        cardStyle={{ width: "100%" }}
                        link="https://play.google.com/"
                        srcImg={require("assets/img/play.svg").default}
                        onClick={() => onShowQrCode(true)}
                        qrDescription="Android"
                        padding={true}
                        qr={qr.playstore}
                      />
                      <CustomQRCode
                        type="IOS"
                        alt="apple-store"
                        isDescription={true}
                        cardStyle={{ width: "100%" }}
                        link="https://www.apple.com/ph/"
                        srcImg={require("assets/img/ios.svg").default}
                        onClick={() => onShowQrCode(false)}
                        qrDescription="IOS"
                        padding={false}
                        qr={qr.apple}
                      />
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>
          )}

          {showQR || loading ? null : (
            <Row
              className={`${transitionStage} w-xs-100`}
              style={{ width: "40%", margin: "0 auto" }}
            >
              <AdminFooter />
            </Row>
          )}
        </div>
      </Container>

      {showBlockUI ? <BlockUI /> : null}
      {alert}
    </div>
  );
};

export default ClientLogin;
