import { IconExpand } from 'chat-components/ui/icon';
import { IconMinimize } from 'chat-components/ui/icon';
import VisualCardView from 'components/DashboardVisual/VisualCardView';
import VisualGraphView from 'components/DashboardVisual/VisualGraphView';
import VisualGridView from 'components/DashboardVisual/VisualGridView';
import VisualPieChartView from 'components/DashboardVisual/VisualPieChartView';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import GraphView from 'eva-component/response/GraphView';
import GridView from 'eva-component/response/GridView';
import TreeView from 'eva-component/response/TreeView';
import React, { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import { validateAndReplaceNulls } from 'services/DashboardService';
import { submitEvaQueryHandler } from 'services/DashboardService';
import { evaThemeLighter } from 'services/EVAResponseService';
import { reFormatEVADateRange } from 'services/EVAResponseService';
import { isWithChildHandler } from 'services/EVAResponseService';
import { convertToTreeViewData } from 'services/EVAResponseService';
import { getRandomLightColor } from 'services/EVAService';
import { defaultControl } from 'services/EVAVisualService';
import { processGridView } from 'services/EVAVisualService';
import { processCardView } from 'services/EVAVisualService';
import { processPieGraph } from 'services/EVAVisualService';
import { processGraphView } from 'services/EVAVisualService';

export default function DashboardTemplateItemModal({ name, query , index, item, layout, selectedTemplateItem, onEditVisual, handleCloseEditVisual, handleIsWithError, onHandleEditVisual }) {
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [isShowGrap, setIsShowGrap] = useState(false)
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [toggleAll, setToggleAll] = useState(false)
    const [sqlresult, setSqlResult] = useState(null)
    const [gridHeader, setGridHeader] = useState(null)
    const [EVAData, setEVAData] = useState(null)
    const [treeViewData, setTreeViewData] = useState(null)
    const [isWithChild, setIsWithChild] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [queryResult, setQueryResult] = useState(null)
    const [visualType, setVisualType] = useState(null)
    const [visualData, setVisualData] = useState(null)
    const [selectedViewOption, setSelectedViewOption] = useState('Grid')
    const [headerToMultiSelect, setHeaderToMultiSelect] = useState([])
    const [templateItemVisual, setTemplateItemVisual] = useState(null)
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [dataValue, setDataValue] = useState(null)

    const viewOption = ['Grid', 'Card', 'Pie Chart', 'Line Chart', 'Bar Chart']

    const theme = { PrimaryColor: bgColor, TextColor: textColor }

    useEffect(() => {
        const processData = async () => {
            try{
                const res = await submitEvaQueryHandler(query, selectedClient, firmDetails, userDetails);
                if(!res || res === undefined) {
                    setError(true)
                    setLoading(false);
                    handleIsWithError(true)
                    return
                }
                // console.log(item)
                setQueryResult(res)
                const sqlresult = res.map(obj => Object.values(obj));
                validateAndReplaceNulls(sqlresult)
                const header = res.length > 0 ? Object.keys(res[0]) : [];

                const newRes = await reFormatEVADateRange(header, sqlresult)
                if(newRes.dataValue.length > 0)
                {
                    processDataValue(newRes.dataValue)
                }
                
                setDataValue(newRes.dataValue)
                const treeViewData = convertToTreeViewData(sqlresult)
                const isWithChild = isWithChildHandler(treeViewData)
                setVisualData(item.visual_data)
                setEVAData(res)
                setSqlResult(sqlresult)
                setGridHeader(header)
                setTreeViewData(treeViewData)
                setIsWithChild(isWithChild)
                handleIsWithError(false)
                setLoading(false)
                setError(false)
            
                if(item.visual_type && item.visual_data)
                    processDefaultView(sqlresult, item.visual_type)
            }catch (error)
            {
                console.log(error)
                setError(true)
                setLoading(false);
            }
        }
        processData();
    }, [query])
    
    useEffect(() => {
        const loadData = async () => {            
            
            if(item.visual_type === undefined && item.visual_data === undefined && item.visual) item.tempVisual = item.visual

            if(layout === undefined && !gridHeader) return
            if((item.visual_type && (item.visual_type ==='Card' || item.visual_data)) || (item.tempVisual || item.tempVisual))
                processDefaultVisual(item, layout)
        }
        loadData();
    },[layout, gridHeader, newUpdateCount])

    useEffect(() => {
        if(templateItemVisual) setSelectedViewOption(templateItemVisual.selectedViewOption)
    },[templateItemVisual])
    const processDataValue = (dataValue) => {
        // console.log({dataValue, item })
        if(dataValue.length > 0 && item.visual)
        {   
            switch(item.visual.selectedViewOption) 
            {
                case "Pie Chart":
                    break;
                case "Bar Chart":
                    dataValue.map((e,i) => {
                        item.visual.selectedGroups[i].label = e
                        item.visual.selectedGroups[i].data = e
                    })
                    break;
                case "Line Chart":
                    
                    dataValue.map((e,i) => {
                        item.visual.selectedGroups[i].label = e
                        item.visual.selectedGroups[i].data = e
                    })
                    break;
                case "Card":
                    dataValue.map((e,i) => {
                        item.visual.cardItem[i].column = e
                        item.visual.cardItem[i].column = e
                        item.visual.cardItem[i].columnToSum = e
                        item.visual.cardItem[i].columnToSum = e
                        item.visual.headerToMultiSelect[i].name = e
                        item.visual.headerToMultiSelect[i].name = e
                    })
                    break;
            }
        }else if(dataValue.length > 0 && item.visual === undefined && item.visual_type !== 'Card' && item.visual_type !== 'Grid')
        {
            item.visual_data.dataValue = dataValue[0]
        }
    }
    const processDefaultVisual = (item, layout) => {

        if (!gridHeader) return;
    
        const headerToMultiSelect = gridHeader.map((data, index) => ({
            id: index,
            name: data,
        }));
    
        setHeaderToMultiSelect(headerToMultiSelect);
        if (visualData || item.visual || visualType || item.visual_type) {
         
            if (item.visual && item.tempVisual === undefined) {
                
                item.tempVisual = item.visual;
                setTemplateItemVisual(item.tempVisual);
                setSelectedViewOption(item.tempVisual.selectedViewOption);
            } else {
                const selectedViewOption = item.tempVisual?.selectedViewOption ?? item.visual_type;
                setSelectedViewOption(selectedViewOption);
            }
    
            const viewOption = item.tempVisual?.selectedViewOption ?? item.visual?.selectedViewOption ?? item.visual_type;
            switch (viewOption) {
                case 'Bar Chart':
                    const barVisual = processGraphView(item, layout, headerToMultiSelect, visualData, dataValue);
                    item.tempVisual.selectedViewOption = viewOption
                    setTemplateItemVisual(barVisual);
                    break;
                case 'Line Chart':
                    const graphVisual = processGraphView(item, layout, headerToMultiSelect, visualData, dataValue);
                    item.tempVisual.selectedViewOption = viewOption
                    setTemplateItemVisual(graphVisual);
                    break;
                case 'Pie Chart':
                    const pieVisual = processPieGraph(item, layout, headerToMultiSelect, visualData, dataValue);
                    item.tempVisual.selectedViewOption = viewOption
                    setTemplateItemVisual(pieVisual);
                    break;
                case 'Card':
                    const cardVisual = processCardView(item, layout, headerToMultiSelect, visualData, sqlresult, gridHeader);
                    item.tempVisual.selectedViewOption = viewOption
                    setTemplateItemVisual(cardVisual);
                    break;
                case 'Grid':
                    const gridVisual = processGridView(item, layout, headerToMultiSelect, visualData);
                    item.tempVisual.selectedViewOption = viewOption
                    setTemplateItemVisual(gridVisual);
                    break;
                default:
                    const defaultVisual = processGridView(item, layout, headerToMultiSelect, visualData);
                    item.tempVisual.selectedViewOption = 'Grid'
                    setTemplateItemVisual(defaultVisual);
                    break;
            }
        } else {
            // console.log(item);
            const defaultVisual = processGridView(item, layout, headerToMultiSelect, visualData, sqlresult, gridHeader);
            item.tempVisual.selectedViewOption = 'Grid'
            setSelectedViewOption('Grid');
            setTemplateItemVisual(defaultVisual);
        }
    };
    
    const processDefaultView = (sqlresult, visual_type) => 
    {
        setVisualType(capitalize(visual_type))
        setSelectedViewOption(capitalize(visual_type))
    }
    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    const handleSelectViewOption = (item) => {
        setSelectedViewOption(item)
    }
    const handleSaveVisual = (param) => {
        param.height = item.tempVisual?.height
        param.width = item.tempVisual?.width
        param.x = item.tempVisual?.x
        param.y = item.tempVisual?.y
        // item.visual = param
        item.tempVisual = param

        const newItem = { ...item };
        // console.log(newItem)
        // setTemplateItemVisual(param)
       
        handleCloseEditVisual()
        // registerDashboardTemplate(selectedDashboardTemplate)
        // handleSetItemIndexToEdit(-1)
        // toggleFullScreen()
        // handleNewUpdate()
    }
    const getDataValue = (dataValue) => {
        const res = []
        dataValue && dataValue.map((item, index) => {
            res.push({
                "label": item,
                "data": item,
                "color": defaultControl(index),
                "isOpen": false
            })
        })
        return res
    }
   
    const ItemContentView = () => {
        return (
            // !isError ? 

            <div className='dashboardTemplateItemContainer' style={{ display: 'block', maxHeight: 'none', height: '100%' }}>
                {loading ? <CustomSpinner /> :
                sqlresult && sqlresult.length > 0 ? 
                <>
                    <div className='templateVisualHeader'>
                        <div className='leftContent'>{item.name}</div>
                        <div className='rightContent'
                            onClick={() => onHandleEditVisual(index)}>
                            <span>Edit Visual</span>
                        </div>
                    </div>
                    <div className='dashboardSettingContainer' style={{ height: 'calc(100% - 40px)'}}>
                        {onEditVisual &&
                            <>
                                <h3 style={{marginBottom: 30}}>{name}</h3>
                                <div className='templateVisualContainer'>
                                    {viewOption.map((item, index) => (
                                        <div 
                                            key={index}
                                            className='templateVisualItem' 
                                            style={selectedViewOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                            onClick={() => handleSelectViewOption(item)}
                                        >{item}</div>
                                    ))}
                                </div>
                            </>
                        }
                        <div className='templateGraphContainer'
                            style={{ display: 'block', width: '100%', height: 'calc(100% - 45px)', padding: '10px 5px' }}>
                            {selectedViewOption === 'Grid' &&
                                <VisualGridView
                                    gridHeader={gridHeader}
                                    sqlresult={sqlresult}
                                    theme={theme}
                                    treeViewData={treeViewData}
                                    isWithChild={isWithChild}
                                    isShowGrid={isShowGrid}
                                    toggleAll={toggleAll}
                                    isShowGrap={isShowGrap}
                                    handleSaveVisual={handleSaveVisual}
                                    selectedViewOption={selectedViewOption}
                                />
                            }
                            {selectedViewOption === 'Card' &&
                                <VisualCardView
                                    gridHeader={gridHeader}
                                    sqlresult={sqlresult}
                                    theme={theme}
                                    treeViewData={treeViewData}
                                    isWithChild={isWithChild}
                                    isShowGrid={isShowGrid}
                                    toggleAll={toggleAll}
                                    isShowGrap={isShowGrap}
                                    handleSaveVisual={handleSaveVisual}
                                    templateItemVisual={templateItemVisual}
                                    headerToMultiSelect={headerToMultiSelect}
                                    selectedViewOption={selectedViewOption}
                                    visualType={visualType}
                                    layouts={layout}
                                    index={index}
                                    item={item}
                                />
                            }
                            {selectedViewOption === 'Bar Chart' &&
                                <VisualGraphView
                                    gridHeader={gridHeader}
                                    selectedViewOption={selectedViewOption}
                                    sqlresult={sqlresult}
                                    dataValue={dataValue}
                                    headerToMultiSelect={headerToMultiSelect}
                                    handleSaveVisual={handleSaveVisual}
                                    templateItemVisual={templateItemVisual}
                                    item={item}
                                    onEditVisual={onEditVisual}
                                    handleCloseEditVisual={handleCloseEditVisual}
                                />
                            }
                            {selectedViewOption === 'Line Chart' &&
                                <VisualGraphView
                                    gridHeader={gridHeader}
                                    selectedViewOption={selectedViewOption}
                                    sqlresult={sqlresult}
                                    dataValue={dataValue}
                                    headerToMultiSelect={headerToMultiSelect}
                                    handleSaveVisual={handleSaveVisual}
                                    templateItemVisual={templateItemVisual}
                                    item={item}
                                    onEditVisual={onEditVisual}
                                    handleCloseEditVisual={handleCloseEditVisual}
                                />
                            }
                            {selectedViewOption === 'Pie Chart' &&
                                <VisualPieChartView
                                    gridHeader={gridHeader}
                                    selectedViewOption={selectedViewOption}
                                    sqlresult={sqlresult}
                                    dataValue={dataValue}
                                    headerToMultiSelect={headerToMultiSelect}
                                    handleSaveVisual={handleSaveVisual}
                                    templateItemVisual={templateItemVisual}
                                    onEditVisual={onEditVisual}
                                    handleCloseEditVisual={handleCloseEditVisual}
                                />
                            }
            
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='templateVisualHeader'>
                        <div className='leftContent'>{item.name}</div>
                    </div>
                    <div className="dashboardItems" style={{
                        height: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally
                        justifyContent: 'center', // Center vertically
                        flexWrap: 'wrap'
                    }}>
                        <div className="" style={{color: '#9f9f9f', fontWeight: 'bold'}}>No data found</div>
                        <div className="" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                    </div>
                </>
                }
            </div>
            // :
            // <>
            //     <div className="dashboardItems" style={{
            //         height: '200px',
            //         display: 'flex',
            //         flexDirection: 'column',
            //         alignItems: 'center', 
            //         justifyContent: 'center', 
            //         flexWrap: 'wrap'
            //     }}>
            //         <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No data found</div>
            //         <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
            //     </div>
            // </>
        )
    }
    const ItemFullScreenView = () => {
        return (
            <div className='ItemFullScreenViewContainer'>
                <div  className='ItemFullScreenViewContent'>
                    <ItemContentView />
                </div>
            </div>
        )
    }
    return (
        isFullScreen ? 
        <ItemFullScreenView /> :
        <ItemContentView />
        // </Col>
    )
}