import React, { useCallback, useEffect, useState } from 'react'
import { evaThemeLighter, withHeaderFilter } from '../../services/EVAResponseService';

export default function TreeView({ theme, sqlresult, gridHeader, treeViewData, toggleAll, isFromPnL }) {

  const [subParentVisibility, setSubParentVisibility] = useState({});
  const [childVisibility, setChildVisibility] = useState({});
  const [dataHeader, setDataHeader] = useState([])
  const [treeData, setTreeData] = useState([])
  const [headerFilter, setHeaderFilter] = useState([])
  const [newSQL, setNewSQL] = useState([])
  const [activeHeaderIndex, setActiveHeaderIndex] = useState(0)

  const toggleVisibility = (label, setState) => {

    setState((prevVisibility) => ({
      ...prevVisibility,
      [label]: !prevVisibility[label]
    }));
  };

  const toggleSubParentVisibility = (label) => {
    toggleVisibility(label, setSubParentVisibility);
  };

  // const toggleChildVisibility = (label) => {
  //   toggleVisibility(label, setChildVisibility);
  // };

  function getIsAmount(cl) {
    const newcl = JSON.stringify(cl).replace(/,/g, "").replace(/\$/g, "");

    if (cl === "Total Amount")
      return "isTotalAmount"
    const newNumber = parseFloat(newcl);
    if (!Number.isNaN(newNumber)) {
      return "isAmount";
    }
    return ""
  }

  // const toggleAllVisibility = (expand) => {

  //   // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

  //   setSubParentVisibility((prevVisibility) => {
  //     const updatedVisibility = {};
  //     for (const key in prevVisibility) {
  //       updatedVisibility[key] = expand;
  //     }
  //     return updatedVisibility;
  //   });

  //   // setToggleAll(expand)
  // };

  useEffect(() => {
    // let dateFilter = [
    //   "All",
    //   "This FY Date Range",
    //   "Last FY Date Range",
    // ];

    // let allExist = true;

    // dateFilter.forEach(item => {
    //   if(item !== "All")
    //   if (!gridHeader.includes(item)) {
    //     allExist = false;
    //     return;
    //   }
    // });

    // if(allExist) setHeaderFilter(dateFilter)
    // console.log(treeViewData)
    setTreeData(treeViewData)
    setDataHeader(gridHeader)
    setNewSQL(sqlresult)
    
  },[treeViewData])

  const handleSelectHeaderFilter = (item, index) => {
    setActiveHeaderIndex(index)
    let dateFilter = [
      "All",
      "This FY Date Range",
      "Last FY Date Range",
    ];
    const res = withHeaderFilter(gridHeader, dateFilter, item, sqlresult)

    setTreeData(res.data)
    setDataHeader(res.header)
    setNewSQL(res.newSQL)
  }

  useEffect(() => {
    const initialState = treeViewData.reduce((acc, parent) => {
      acc[parent.label] = toggleAll;
      return acc;
    }, {});

    setSubParentVisibility(initialState)
  }, [gridHeader]);

  useEffect(() => {
    setSubParentVisibility((prevVisibility) => {
      const updatedVisibility = {};
      for (const key in prevVisibility) {
        updatedVisibility[key] = toggleAll;
      }
      return updatedVisibility;

    });
  }, [toggleAll])

  const backgroundColorStyleLighttd = evaThemeLighter(10, theme, '#333')
  const backgroundColorStyleLight = evaThemeLighter(90, theme)

  const SetGrandTotal = () => {
    const percentIndexes = dataHeader.reduce((indexes, item, index) => {
      if (item.includes('%')) {
          indexes.push(index);
      }
      return indexes;
    }, []);

    percentIndexes.forEach(index => {
      newSQL.forEach(child => {
        // console.log(child)
        const item = String(child[index - 1])
        if(!item.includes('%'))
        {
          let value = parseFloat(child[index]);
          if (!isNaN(value)) {
              // Divide by 100 and format to two decimal places, then append '%'
              const formattedValue = (value / 100).toFixed(2) + ' %';
              child[index] = formattedValue;
          }
        }
      });
    }); 
    
    const newRow = ['Grand Total']
    for (const column in newSQL[0]) {
      if (column > (dataHeader.length >= 4 ? 1 : 0)) {
        const columnValue = isNaN(newSQL[0][column]) ? newSQL[0][column].replace(/,/g, '') : newSQL[0][column];
        if (!isNaN(columnValue)) {
          const total = newSQL.reduce((acc, row) => {
            const value = typeof row[column] === 'string' ? parseFloat(row[column].replace(/,/g, '')) : row[column];
            return acc + (value || 0);
          }, 0);
          newRow.push(total.toFixed(2))
        } else newRow.push("-")
      }

    }

    return (
      <>
        <tr className='grandTotal' style={{ ...evaThemeLighter(120, theme), fontSize: '15px' }}>
          {newRow.map((item, index) => (
            <td
              colSpan={dataHeader.length >= 4 && index === 0 ? 2 : 1}
              style={{ textAlign: !isNaN(Number(item.replace(/,/g, ''))) ? 'right' : item.includes('%') ? 'right' : 'left' }}
              key={index}>
              {isNaN(Number(item.replace(/,/g, ''))) ? item : Number(item.replace(/,/g, '')).toLocaleString()}
            </td>
          ))
          }
        </tr >
      </>
    )
  }

  const styleAlignRight = (value) => {
    const alignment = typeof value === 'string' ? !isNaN(Number(value.replace(/,/g, ''))) ? 'right' : value.includes('%') ? 'right' : 'left' : 'right';
    return { textAlign: alignment }
  }

  const TreeViewData = ({parent, rowIndex}) => {
    const colCount = dataHeader.length
    const parentLabel = parent.label
    const children = parent.children
    const childrenCount = parent.children.length

    const newData = [];
    const newHeader = [` ▲ ${parentLabel}`];
    const newFooter = [`Total`];

    const percentIndexes = dataHeader.reduce((indexes, item, index) => {
      if (item.includes('%')) {
          indexes.push(index);
      }
      return indexes;
    }, []);

    percentIndexes.forEach(index => {
      children.forEach(child => {
        const item = String(child.clabel[index - 1]);
        if(!item.includes('%'))
        {
          const formattedValue = `${(child.clabel[index - 1] / 100).toFixed(2)}%`;
          child.clabel[index - 1] = formattedValue.replace(/-/g, '')
        }
      });
    });

    if (childrenCount > 1) {
      

      children.forEach((item) => {
          newData.push(item.clabel);
      });
      // console.log(newData)

      Object.keys(newData[0]).forEach((column) => {
        const columnValue = isNaN(newData[0][column]) ? newData[0][column].replace(/,/g, '') : newData[0][column];
        if (!isNaN(columnValue)) {
          const total = newData.reduce((acc, row) => {
            const value = typeof row[column] === 'string' ? parseFloat(row[column].replace(/,/g, '')) : row[column];
            return acc + (value || 0);
          }, 0);
          newHeader.push(total.toFixed(2));
          newFooter.push(total.toFixed(2));
        } else {
          newHeader.push("-");
          newFooter.push("-");
        }
      });
    }

    const renderHeader = () => {
      return (
        <>
        <tr key={rowIndex} className='parent' onClick={() => toggleSubParentVisibility(parentLabel)}>
          {!subParentVisibility[parentLabel] ? (
            <td colSpan={colCount} style={backgroundColorStyleLight}>
              {' ▼'} {parentLabel}
            </td>
          ) : (
            newHeader.map((item, index) => (
              <td className={`tdClass ${getIsAmount(item)}`} key={index} style={{ ...styleAlignRight(item), ...(rowIndex % 2 === 0 ? backgroundColorStyleLighttd : {}) }}>
                {isNaN(item) ? item : Number(item).toLocaleString()}
              </td>
            ))
          )}
        </tr>
        {renderChildren()}
        </>
      );
    };

    const renderChildren = () => {
      return (
        !subParentVisibility[parentLabel] && (
          <>
            {children.map((item, subparentIndex) => (
              <tr className='treeVierRow' key={subparentIndex} style={subparentIndex % 2 === 0 ? backgroundColorStyleLighttd : {}}>
                <td></td>
                {item.clabel && item.clabel.map((child, childIndex) => getRowData(child, childIndex))}
              </tr>
            ))}
            <tr style={evaThemeLighter(60, theme)}>
              {newFooter.map((item, index) => getRowData(item, index))}
            </tr>
          </>
        )
      );
    };
    
    const renderSingleChild = () => {
      return (
        <tr>
          <td>{parentLabel}</td>
          {children && children.map((item) => (
            item.clabel && item.clabel.map((child, childIndex) => getRowData(child, childIndex))
          ))}
        </tr>
      );
    };

    return (
      childrenCount > 1 ? renderHeader() : renderSingleChild()
    );
  }

  const getRowData = (child,childIndex) => {
    return (
      <td className={`tdClass ${getIsAmount(child)}`} key={childIndex} style={styleAlignRight(child)}>
        {isNaN(child) ? child : Number(child).toLocaleString()}
      </td>
    )
  }
  
  return (
    <>
      {/* {headerFilter && headerFilter.length > 0 && 
        <div className='treeviewHeaderFilter'>
          {headerFilter.map((item, index) => (
            <div 
              key={index}
              className='treeviewHeaderFilterItem'
              style={{ 
                background: activeHeaderIndex === index ? theme.PrimaryColor : theme.TextColor,
                border: `2px solid ${theme.PrimaryColor}`,
                color: activeHeaderIndex !== index ? theme.PrimaryColor : theme.TextColor
              }}
              onClick={() => handleSelectHeaderFilter(item, index)}>
              {item}
            </div>
          ))}
        </div>
      } */}

      <table className='treeView'>
        <thead style={evaThemeLighter(100, theme)} >
          <tr>
            {dataHeader !== undefined ? dataHeader.map((item, index) => (
              <th id={item} key={index} >{item}</th>
            )) : null}
          </tr>
        </thead>
        <tbody>
          {treeData && treeData.map((parent, index) => (
            <React.Fragment key={index}>
              <TreeViewData parent={parent} rowIndex={index}  />
            </React.Fragment>
          ))}
          
          {!isFromPnL && <SetGrandTotal />}
          
        </tbody>
      </table>
    </>
  );
}
