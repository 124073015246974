import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Modal, Spinner } from "reactstrap";

const MFAScreenGoogleAuthenticator = () => {
  const location = useLocation();
  const routerHistory = useHistory();
  const [authCode, setAuthCode] = useState("");
  const [modal, setModal] = useState(false);
  const [modalSecurityQuestion, setModalSecurityQuestion] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [accountingLogo, setAccountingLogo] = useState("");
  const [defaultScreen, setDefaultScreen] = useState(true);
  const [stepTwoBackUpEmail, setTwoBackUpEmail] = useState(false);
  const [stepTwoSecurityQuestions, setStepThreeSecurityQuestions] =
    useState(false);
  const [message, setMessage] = useState("");
  const answerSecQuestionOne = useRef(null);
  const answerSecQuestionTwo = useRef(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false)
  const [emailResetCooldown, setEmailResetCooldown] = useState(30)
  const [isAns1Valid, setIsAns1Valid] = useState(true)
  const [isAns2Valid, setIsAns2Valid] = useState(true)

  const [messageSecurityQuestion, setMessageSecurityQuestion] = useState("");

  const [authCodeBackupEmail, setAuthCodeBackupEmail] = useState("");
  const [modalBackupEmail, setModalBackupEmail] = useState(false);
  const [updatedData, setUpdateData] = useState("");
  const [messageBackupEmailConfirm, setMessageBackupEmailConfirm] =
    useState("");

  useEffect(() => {
    if (!location.state) {
      return routerHistory.push("/auth/login");
    }
    fetchFirmLogo();
  }, []);

  useEffect(() => {
    const checkUserDetail = async () => {
      try {
        const userData = await fetch("/api/auth/checkMFAMethod", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.value.email,
          }),
        });
        const data = await userData.json();
        if (data.success) {
          setUserDetails(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkUserDetail();

    const timer = setInterval(() => {
      setEmailResetCooldown((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(timer);
    }
  }, []);

  useEffect(() => {
    if (userDetails?.data?.enabled && userDetails?.data?.DeviceToken) {
      return routerHistory.push("/auth/multi-factor", {
        deviceToken: userDetails?.data?.DeviceToken,
        email: location.state.value.email,
        password: location.state.value.password,
      });
    }
  }, [userDetails?.data?.enabled, userDetails?.data?.DeviceToken]);

  const fetchFirmLogo = () => {
    const firmLogo = async () => {
      try {
        const data = await fetch("/api/accountingFirm/getLogo", {
          method: "GET",
        });
        const logo = await data.json();
        setAccountingLogo(logo);
      } catch (error) {
        console.log(error);
      }
    };
    firmLogo();
  };

  const sendMFABackEmailCode = async () => {
    // setLoading(true);
    try {
      const sendEmailBackEmailCode = await fetch(
        "/api/auth/sendVerifyEmailBackUp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.value.email,
            accountingFirmLogo: accountingLogo.logo,
          }),
        }
      );
      const data = await sendEmailBackEmailCode.json();
      // setLoading(false);
      if (data.success) {
        const userData = await fetch("/api/auth/checkMFAMethod", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.value.email,
          }),
        });
        const data = await userData.json();
        setUpdateData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const defaultScreenShow = () => {
    setDefaultScreen(true);
    setTwoBackUpEmail(false);
    setStepThreeSecurityQuestions(false);
  };

  const stepTwoBackUpEmailScreenShow = () => {
    setTwoBackUpEmail(true);
    sendMFABackEmailCode();
    setDefaultScreen(false);
    setStepThreeSecurityQuestions(false);
    setEmailResetCooldown(30)
  };

  const stepTwoSecurityQuestionsScreenShow = () => {
    setStepThreeSecurityQuestions(true);
    setDefaultScreen(false);
    setTwoBackUpEmail(false);
  };

  const mfaOnChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAuthCode(value);
  };

  const authCodeBackupEmailOnChangeHandler = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAuthCodeBackupEmail(value);
  };

  const securityQuestionVerifyOnClick = async () => {
    // setLoading(true);
    // console.log(userDetails)
    setBtnLoading(true)

    if (answerSecQuestionOne.current.value === "") {
      setBtnLoading(false)
      setIsAns1Valid(false)
      setMessageSecurityQuestion("Please type in your answers.");
      return setTimeout(() => {
        setIsAns1Valid(true)
        setMessageSecurityQuestion("")
      }, 2000);
    }
    if (answerSecQuestionTwo.current.value === "") {
      setBtnLoading(false)
      setIsAns2Valid(false)
      setMessageSecurityQuestion("Please type in your answers.");
      return setTimeout(() => {
        setIsAns2Valid(true)
        setMessageSecurityQuestion("")
      }, 2000);
    }

    try {
      const checkSecAns = await fetch(
        "/api/auth/checkSecurityQuestionAnswer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.value.email,
            q1: userDetails.data.securityQuestions[0].question,
            a1: answerSecQuestionOne.current.value.toLowerCase(),
            q2: userDetails.data.securityQuestions[1].question,
            a2: answerSecQuestionTwo.current.value.toLowerCase()
          }),
        }
      );
      const data = await checkSecAns.json();
      if (data.success) {
        const dataSend = await fetch(
          "/api/auth/mfaEnabledSecurityQuestionLogin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: location.state.value.email,
              password: location.state.value.password,
            }),
          }
        );
        const data = await dataSend.json();
        if (data.success) {
          setBtnLoading(false)
          setMessageSecurityQuestion("Security question validated.");
          return setTimeout(() => {
            routerHistory.push("/client/accountant/details");
          }, 2000);
        }
      }
      if (!data.success) {
        setBtnLoading(false)
        setMessageSecurityQuestion("Incorrect answers. Please try again.");
        return setTimeout(() => {
          setMessageSecurityQuestion("")
        }, 3000);
      }
      if (data.error) {
        setBtnLoading(false)
        setMessageSecurityQuestion(data.error)
        return setTimeout(() => {
          setMessageSecurityQuestion("")
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false)
      setMessageSecurityQuestion(error)
      return setTimeout(() => {
        setMessageSecurityQuestion("")
      }, 2000);
    }
  };

  const onSubmitHandler = () => {
    setBtnLoading(true)
    const validateMFA = async () => {
      const userLogin = await fetch("/api/auth/mfaEnabledLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: location.state.value.email,
          password: location.state.value.password,
          secretToken: authCode,
        }),
      });
      const data = await userLogin.json();
      if (!data.success) {
        setBtnLoading(false)
        setMessage("The multi-factor authentication code could not be verified. Please try again.");
        return setTimeout(() => {
          setMessage("")
        }, 3000);
        return setModal(true);
      }
      setBtnLoading(false)
      setMessage("Your login authentication was successful!");
      setTimeout(() => {
        return routerHistory.push("/client/accountant/details");
      }, 2000);
    };
    validateMFA();
  };

  const verifyEmailCodeOnGAuthClickHandler = async () => {
    // setLoading(true);
    setBtnLoading(true)
    try {
      const response = await fetch('/api/auth/verifyEmailCode', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          code: authCodeBackupEmail,
          email: location.state.value.email,
        }),
      });

      const emailValidation = await response.json();

      if (emailValidation.error) {
        setModal(true);
        // setLoading(false);
        setBtnLoading(false)
        return
      }

      await fetch(
        "/api/auth/mfaEnabledBackupEmailLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: location.state.value.email,
            password: location.state.value.password,
          }),
        }
      ).then(async (res) => {
        const data = await res.json();


        if (!data.success) {
          setMessageBackupEmailConfirm("Your login authentication was successful!");
          setBtnLoading(false)
          return setTimeout(() => {
            setMessageBackupEmailConfirm("")
          }, 2000);
        }
        // setLoading(false);
        setBtnLoading(false)

        setMessageBackupEmailConfirm("Your login authentication was successful!");

        return setTimeout(() => {
          routerHistory.push("/client/accountant/details");
        }, 2000);

      });

    } catch (error) {
      console.error('Error:', error.message);
    }
    setLoading(false);
  };

  const handleResendEmailCode = () => {
    // setBtnLoading(true)
    setEmailResetCooldown(30)
    sendMFABackEmailCode();
  }

  const closeModalHandler = () => {
    setModal(false);
  };

  const closeModalHandlerSecurityQuestions = () => {
    setModalSecurityQuestion(false);
  };

  const closeModalModalBackupEmail = () => {
    setModalBackupEmail(false);
  };

  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={modal}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid MFA Code
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            The multi-factor authentication code could not be verified. Enter
            the correct pin found on your Google Authenticator app and try
            again.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandler}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={modalSecurityQuestion}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid Security Question Answer
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            It appears that the answer you provided for the security question is
            incorrect. <br />
            Please double-check your answer and try again.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalHandlerSecurityQuestions}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={modalBackupEmail}
        size="lg"
        backdrop="static"
      >
        <div style={{ padding: "20px 20px 5px 20px" }}>
          <h1 style={{ marginBottom: "0px", fontSize: "30px" }}>
            Invalid Authentication Code
          </h1>
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            It appears that the code you provided is incorrect. Please check
            your secondary email to get the code.
          </p>
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
        </div>
        <div className="modal-footer" style={{ padding: "0px 20px 10px 20px" }}>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={closeModalModalBackupEmail}
          >
            Close
          </Button>
        </div>
      </Modal>
      {defaultScreen && (
        <div className="center-blk-container mfa-container-blk">
          <div></div>
          <h1>Authenticate Your Account</h1>
          <p>
            Your account is protected with Multi-factor authentication.
            <br />
            Confirm your account by entering the 6 digit pin on your Google
            Authenticator app.
          </p>
          <hr style={{ marginTop: 0, marginBottom: 0 }} />
          <div className="form-blk-mfa">
            <input
              type="text"
              placeholder="Enter your code here..."
              maxLength={6}
              value={authCode}
              onChange={mfaOnChangeHandler}
              disabled={btnLoading ? true : false}
            />
            {
              btnLoading ?
                <button disabled={btnLoading ? true : false} style={{ paddingLeft: "42px", paddingRight: "42px" }}>
                  <Spinner style={{ height: "1rem", width: "1rem" }} />
                </button>
                :
                <button onClick={btnLoading ? null : onSubmitHandler}>VERIFY</button>
            }
            {!message ? (
              ""
            ) : (
              <div
                className={
                  message === "Your login authentication was successful!" ?
                    "message-blk-validation-success" : "message-blk-validation-error"
                }
              >
                {message}
              </div>
            )}
          </div>
          {userDetails.success && userDetails.data?.backupEmail ? (
            <button
              className="buttonLinkClass"
              onClick={stepTwoBackUpEmailScreenShow}
            >
              Authenticate using backup email instead?
            </button>
          ) : (
            ""
          )}
          {userDetails.success && userDetails.data?.securityQuestions ? (
            <button
              className="buttonLinkClass"
              onClick={stepTwoSecurityQuestionsScreenShow}
            >
              {" "}
              Authenticate using security questions instead?
            </button>
          ) : (
            ""
          )}
        </div>
      )}
      {stepTwoBackUpEmail && (
        <div className="center-blk-container mfa-container-blk">
          <div></div>
          <h1>We have sent you an email</h1>
          <p>
            To authenticate your login, retrieve the 6 digit code that was sent
            to your back up email and copy it into the appropriate field.
          </p>

          <div className="form-blk-mfa">
            <input
              type="text"
              placeholder="Enter your code here..."
              maxLength={6}
              value={authCodeBackupEmail}
              onChange={authCodeBackupEmailOnChangeHandler}
              disabled={btnLoading ? true : false}
            />
            {
              btnLoading ?
                <button disabled={btnLoading ? true : false} style={{ paddingLeft: "42px", paddingRight: "42px" }}>
                  <Spinner style={{ height: "1rem", width: "1rem" }} />
                </button>
                :
                <button onClick={verifyEmailCodeOnGAuthClickHandler}>VERIFY</button>
            }

            <div className="text-center btn-link pt-1" >
              <span className={`text-small h5 ${emailResetCooldown > 0 ? "text-muted" : ""}`} style={{ cursor: `${emailResetCooldown > 0 ? "" : "pointer"}` }} onClick={() => emailResetCooldown > 0 || btnLoading ? null : handleResendEmailCode()} >
                Resend code {emailResetCooldown <= 0 ? "" : `in ${emailResetCooldown} seconds`}
              </span>
            </div>

            {loading ? (
              <Spinner style={{ display: "block", margin: "30px auto" }} />
            ) : (
              <div
                className={
                  messageBackupEmailConfirm === ""
                    ? ""
                    : messageBackupEmailConfirm ===
                      "Your login authentication was successful!"
                      ? "message-blk-validation-success"
                      : "message-blk-validation-error"
                }
              >
                {messageBackupEmailConfirm}
              </div>
            )}
          </div>
          <div>
            <button className="buttonLinkClass" onClick={btnLoading ? null : defaultScreenShow}>
              Use Google Authenticator instead?
            </button>
          </div>
        </div>
      )}
      {stepTwoSecurityQuestions &&
        userDetails.success &&
        userDetails.data?.securityQuestions && (
          <div
            className="center-blk-container mfa-container-blk form-security-question-answer"
            style={{ top: "38%" }}
          >
            <div></div>
            <h1>
              <b>Security Question</b>
            </h1>
            <p>
              To verify your login, you will need to provide an answer to your
              security question.
            </p>
            <hr style={{ marginTop: 0, marginBottom: 0 }} />
            <div className="form-blk-mfa" style={{ width: "700px" }}>
              <p style={{ textAlign: "left" }}>
                <b>Question 1:</b>{" "}
                {userDetails.data.securityQuestions[0].question}
              </p>
              <input
                type="text"
                placeholder="Answer..."
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  borderRadius: "5px",
                  borderColor: `${isAns1Valid ? "rgb(217, 217, 217)" : 'red'}`
                }}
                ref={answerSecQuestionOne}
                disabled={btnLoading ? true : false}
              />
              <p style={{ textAlign: "left" }}>
                <b>Question 2:</b>{" "}
                {userDetails.data.securityQuestions[1].question}
              </p>
              <input
                type="text"
                placeholder="Answer..."
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  borderColor: `${isAns2Valid ? "rgb(217, 217, 217)" : 'red'}`
                }}
                ref={answerSecQuestionTwo}
                disabled={btnLoading ? true : false}
              />
              <button
                style={{
                  borderRadius: "5px",
                  width: "100%",
                  marginTop: "10px",
                }}
                onClick={securityQuestionVerifyOnClick}
                disabled={btnLoading ? true : false}
              >
                {btnLoading ? <Spinner style={{ height: "1rem", width: "1rem" }} /> : `VERIFY YOUR ANSWER`}
              </button>

              {loading ? (
                <Spinner style={{ display: "block", margin: "30px auto" }} />
              ) : (
                <div
                  className={
                    messageSecurityQuestion === ""
                      ? ""
                      : messageSecurityQuestion ===
                        "Security question validated."
                        ? "message-blk-validation-success"
                        : "message-blk-validation-error"
                  }
                  style={{
                    width: "100%",
                  }}
                >
                  {messageSecurityQuestion}
                </div>
              )}
            </div>
            <div>
              <button className="buttonLinkClass" onClick={btnLoading ? null : defaultScreenShow}>
                Use Google Authenticator instead?
              </button>
            </div>
          </div>
        )}
      <div className="footer-blk">
        <p>
          &copy; 2023 - <span>Etani</span>
        </p>
      </div>
    </>
  );
};

export default MFAScreenGoogleAuthenticator;
