import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  Col,
} from "reactstrap";
import CustomHeader from "customComponents/customHeader";
import { MainContext } from "context/mainContext";
import EvaClientList from "components/EvaClientList/EvaClientList";
import PanelCard from "components/Cards/PanelCard";
import { useHistory } from "react-router";
import EVA from "eva-component/EVA";
import { checkEvaAccess } from "services/EVAService";
import CustomSpinner from "components/Misc/CustomSpinner";
import PanelCardEva from "components/Cards/PanelCardEva";
import EvaSettingModal from "./EvaSettingsModal";

const Eva = () => {
  const { userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
  const [evaIframeUrl, setEvaIframeUrl] = useState(null);
  const [noEvaAccess, setNoEvaAccess] = useState(false);
  const [hideHeader, setHideHeader] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [hasValidEVAIntegrations, setHasValidEVAIntegrations] = useState(false)
  const [showEvaSettingsModal, setShowEvaSettingModal] = useState(false)
  const [isEvaAdvisorOnly, setIsEvaAdvisorOnly] = useState(true)

  const handleSetEvaIframeUrl = (value) => {
    setEvaIframeUrl(value)
  }
  const handleSetNoEvaAccess = (value) => {
    setNoEvaAccess(value)
  }

  useEffect(() => {
    // console.log("fetching", userDetails)
    const fetchData = async () => {
      if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
        return history.push(`/`);
      }

      if (userDetails && userDetails.User && selectedClientID) {
        await checkEvaAccess(selectedClientID, userDetails, handleSetEvaIframeUrl, handleSetNoEvaAccess, checkIsEvaAdvisorOnly);
        // setIsLoading(false);
        getClientIntegrationIds(selectedClient.id)
      }
    };
    setIsLoading(true);
    fetchData();

    return () => {
      // Cleanup function if needed
    };
  }, [selectedClientID, userDetails]);


  const getClientIntegrationIds = async (cID) => {
    // console.log('getting integrations');
    // setIsLoading(true);
    try {
      const response = await fetch(`/api/clientService/getAllRecords/${cID}`);
      const data = await response.json();

      // console.log('got data', data);

      if (!data || data.error) {
        setIsLoading(false);
        return;
      }

      const integrationIds = data
        .filter(item => item.Service.id !== 3)
        .map(item => item.Service.id);

      // console.log(integrationIds)

      const evaValidIntegrationIds = [1, 24]
      const hasValidEVAIntegrations = checkValidIntegrations(evaValidIntegrationIds, integrationIds);

      // console.log("has valid", checkValidIntegrations(evaValidIntegrationIds, integrationIds))

      // console.log(hasValidEVAIntegrations ? "has eva" : "no eva");
      setHasValidEVAIntegrations(hasValidEVAIntegrations);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const checkValidIntegrations = (arr1, arr2) => {
    for (let val of arr1) {
      if (arr2.includes(val)) {
        return true;
      }
    }
    return false;
  }

  const submitEvaSettings = async () => {
    try {
      const details = await fetch("/api/eva/toggle", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          businessId: userDetails?.User?.AccountingFirm,
          clientId: selectedClientID,
          isAdvisorOnly: isEvaAdvisorOnly === true ? false : true
        }),
      });

      const data = await details.json();

      if (data.success) {
        console.log(data)
        setIsEvaAdvisorOnly(data.result[0].isAdvisorOnly);
        setShowEvaSettingModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const closeEvaSettings = () => {
    setShowEvaSettingModal(false)
  }

  const checkIsEvaAdvisorOnly = (value) => {
    setIsEvaAdvisorOnly(value)
  }

  const handleShowEvaSettings = async () => {
    setShowEvaSettingModal(true)
  }

  const handleRedirect = () => {
    return history.push(`/client/thirdpartyapplication`)
  }

  return (
    <>
      <Container className="mt-6" fluid>
        <div className="nav-wrapper">
          {/* <PanelCardEva
            headerName="EVA"
            showSettingButton={userDetails?.User?.UserAccess <= 4 && hasValidEVAIntegrations ? true : false}
            handleShowEvaSettings={handleShowEvaSettings}
            isEvaAdvisorOnly={isEvaAdvisorOnly}
          > */}
          <Card>
            {isLoading ? <CustomSpinner /> :
              selectedClient?.Details?.ShowEvaPage ?
                <>
                  {!selectedClientID &&
                    <Row className="text-center d-flex align-items-center justify-content-center">
                      Please choose a client first.
                    </Row>
                  }
                  {/* {noEvaAccess && selectedClientID &&
                    <Row className="text-center d-flex align-items-center justify-content-center">
                      {
                        userDetails?.User?.UserAccess >= 5 ? ""
                          :
                          "The chosen client does not have access to EVA."
                      }
                    </Row>
                  } */}
                  {
                    hasValidEVAIntegrations ?
                      selectedClientID && evaIframeUrl && !noEvaAccess ?
                        <EVA
                          token={evaIframeUrl}
                          showSettingButton={userDetails?.User?.UserAccess <= 4 && hasValidEVAIntegrations ? true : false}
                          handleShowEvaSettings={handleShowEvaSettings}
                          isEvaAdvisorOnly={isEvaAdvisorOnly}
                        />
                        :
                        <Row>
                          <Col className="text-center p-5">

                            <span>This client has no available eva indegrations data yet. Please wait until integration is complete.</span>
                          </Col>
                        </Row>
                      :
                      <Row>
                        <Col className="text-center p-5">
                          {
                            userDetails?.User?.UserAccess >= 5 ?
                              <span>EVA page is not yet enabled for this Client. Please speak to your advisor about turning this feature on.</span>
                              :
                              <span>There are no valid EVA integrations. Please click <strong style={{ color: "#5e72e4", cursor: "pointer" }} onClick={() => handleRedirect()}>here</strong> go to integrations page to add <strong>Xero Blue</strong>, <strong>XPM</strong> or <strong>Workflow Max</strong> to be able to use EVA.</span>
                          }
                        </Col>
                      </Row>
                  }
                </>
                :
                <Row>
                  <Col className="text-center p-5">
                    {
                      userDetails?.User?.UserAccess >= 5 ?
                        <span>EVA page is not yet enabled for this Client. Please speak to your advisor about turning this feature on.</span>
                        :
                        <span>EVA page is not yet enabled for this Client. Please click <a href="/client/lists" ><strong>here</strong></a> go to clients to enable EVA page.</span>
                    }
                  </Col>
                </Row>
            }
            {/* </PanelCardEva> */}
          </Card>
          <EvaSettingModal
            showEvaSettingsModal={showEvaSettingsModal}
            submitEvaSettings={submitEvaSettings}
            closeEvaSettings={closeEvaSettings}
            isEvaAdvisorOnly={isEvaAdvisorOnly}
          />
        </div>
      </Container>
    </>
  );
};

export default Eva;
