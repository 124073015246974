import React, { useEffect, useState, useRef } from "react";
import { Card, CardImg, CardBody, CardHeader, Container, Row, Col, Button, Modal, Input, InputGroup, InputGroupText, UncontrolledTooltip } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "utils/CheckoutForm";
import CustomSpinner from "components/Misc/CustomSpinner";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import CustomTooltip from "customComponents/customComponents";
import BlockUI from "components/Misc/BlockUI";

const PaymentSetup = ({ ...props }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [firmDetails, setFirmDetails] = useState();
  const routerHistory = useHistory();
  const [isError, setIsError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [openSharePayment, setOpenSharePayment] = useState(false);
  const [sharableLink, setSharableLink] = useState(null)
  const [copyLinkSuccess, setCopyLinkSuccess] = useState(false)
  const inputRef = useRef();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedBusinessToGoBack, setSelectedBusinessToGoBack] = useState([]);
  const [showBlockUI, setShowBlockUI] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(async () => {
    setShowBlockUI(true)
    fetch(
      `/api/accountingFirm/getRecord`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        // console.log(data)
        let firmDetails = data
        let url = window.location.hostname.split(".");
        // setSubDomain(url[0])

        if (url[0] === 'platform') {
          fetch("/api/trialUser/getAllBusinessesPerMBU")
            .then((res) => res.json())
            .then(data => {
              // console.log('trialUser/getAllBusinessesPerMBU', data)
              if (data.record && !data.error) {
                let businesses = data.record
                if (businesses.length > 1) {
                  let initialSelectedBusiness = businesses.filter((rec, i) => rec.Details?.NeedSetPayment != true)
                  // let indexOfBusiness = businesses.indexOf(initialSelectedBusiness[0])

                  // businesses.splice(indexOfBusiness, 1)
                  // businesses.unshift(initialSelectedBusiness[0])
                  // console.log(initialSelectedBusiness)
                  setSelectedBusinessToGoBack(initialSelectedBusiness)
                  if (initialSelectedBusiness.length >= 1) {
                    setShowBlockUI(false)
                  }
                  // console.log(indexOfBusiness)
                  // setBusinessList(businesses)
                  // setFilteredBusinessList(businesses)
                } else {
                  setShowBlockUI(false)
                }
              }
            })
            .catch(e => {
              setShowBlockUI(false)
              console.log(e)
            })
        }
        if (data && !data.error) {
          setFirmDetails(data)
          if (!data.Details.NeedSetPayment) {
            return routerHistory.push(
              "/client"
            );
          }
          fetch("/api/stripe/getConfig", {
            method: "POST",
            body: JSON.stringify({
              email: `{userDetails?.User?.Email}`,
            }),
          }).then(async (r) => {
            const { publishKey } = await r.json();
            // console.log(publishKey);

            setStripePromise(loadStripe(publishKey));
            if (publishKey) {
              fetch("/api/stripe/setUpIntent", {
                method: "POST",
                body: JSON.stringify({
                  name: `${firmDetails?.Name}`,
                  email: `${firmDetails?.Email}`,
                  description: `${firmDetails?.Name}`,
                  country: `${firmDetails?.CountryCode}`,
                }),
              }).then(async (result) => {
                let { clientSecret } = await result.json();
                // console.log(clientSecret);
                setClientSecret(clientSecret);
              });
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const stripeSuccess = (result) => {
    if (result?.setupIntent?.status === 'succeeded') {
      successAlert('Welcome to the Etani Platform - You are now a valued member of the Etani eco-system. Thank you for filling in your payment details. We look forward to our upcoming journey with you.')
    } else {
      warningAlert(result?.error?.message)
    }
  }

  const successAlert = (res) => {
    setSuccess(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => {
          // setTimeout(() => {
          routerHistory.push(
            "/"
          )
          setSuccess(null)
        }}
        // }, 1500)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        <p>
          {res}
        </p>
      </ReactBSAlert >
    );
  };

  const warningAlert = (res) => {
    setIsError(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setIsError(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {res}
      </ReactBSAlert>
    );
  };

  const handleLogout = async () => {
    try {
      const isLogout = await axios.get("/api/auth/forceLogout")
      // console.log(isLogout)
      if (isLogout.data.success) {
        return routerHistory.push(
          `/auth/login`
        );
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSharePayment = async () => {
    // console.log("CLICKED")
    setOpenSharePayment(true)
    try {
      const result = await axios.get(`/api/stripe/getSharablePaymentLink?bname=${firmDetails?.Code}`)
      // console.log(result.data)
      setSharableLink(result.data.link)
    } catch (error) {
      console.log(error)
    }
  }

  const showSharePayment = () => {
    setOpenSharePayment(!openSharePayment)
    setSharableLink(null)
    setCopyLinkSuccess(false)
  }

  const copyLink = () => {
    if (!inputRef.current) return;
    // Select the text inside the input element
    if (inputRef.current) {
      // inputRef.current.select();
      // document.execCommand('copy');

      // Create a temporary input element, select and copy from it, then remove it
      const tempInput = document.createElement("input");
      tempInput.value = inputRef.current.props.value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);

      setCopyLinkSuccess(true)
      setTimeout(() => {
        setCopyLinkSuccess(false)
      }, 1500)
    }
  }

  const handleGenerateLink = async () => {
    setSharableLink(null)
    try {
      const result = await axios.get(`/api/stripe/getSharablePaymentLink?bname=${firmDetails?.Code}`)
      // console.log(result.data)
      setSharableLink(result.data.link)
    } catch (error) {
      console.log(error)
    }
  }

  const sharePaymentModal = () => {
    return (
      <Modal
        className="modal-dialog-top"
        isOpen={openSharePayment}
        toggle={() => setOpenSharePayment(!openSharePayment)}
        size="lg"
        backdrop="static"
        centered
      >
        <div className="modal-header">
          <h6 className="modal-title">Share Payment Setup Link</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => showSharePayment()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <p className="font-weight-400 text-left">
              No access to a credit card or bank account? Simply share the link below with a designated member of your team, who can then proceed to provide the necessary payment information.
            </p>
            <p>
              After successful payment setup, you may refresh the page or click logout then relogin to start your Etani journey.
            </p>
            <p className="h5 font-weight-400 text-left">
              **Note: This link will expire within 24 hours. You can click the button below to generate new link.
            </p>
          </div>
          <div className="mb-1 justify-content-center text-center">
            {
              sharableLink ?
                <InputGroup>
                  <Input
                    disabled
                    value={sharableLink ? sharableLink : ""}
                    ref={inputRef}
                  />
                  <InputGroupText style={{ cursor: "pointer", backgroundColor: "#272A65", borderColor: "#272A65", color: "fff" }} onClick={copyLink} id="CopyLink1">
                    {
                      copyLinkSuccess ?
                        <i className="fas fa-check" style={{ color: "fff" }}></i>
                        : <i className="far fa-copy" style={{ color: "fff" }}></i>
                    }
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target={`CopyLink1`}
                      className="custom-tooltip"
                    >
                      {copyLinkSuccess ? 'Copied!' : 'Copy Link'}
                    </UncontrolledTooltip>
                  </InputGroupText>
                </InputGroup>
                : <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
            }
          </div>
          <div className="mb-3 justify-content-center text-center">
            <Button
              style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#272A65", }}
              className="mt-2"
              color="primary"
              type="button"
              onClick={handleGenerateLink}
            >
              Generate
            </Button>
          </div>
        </div>
      </Modal>
    )
  }

  const handleChangeBusiness = (bus) => {
    // console.log(bus)
    // let details = { AccountingFirm: bus.id }
    // getDetails(details)
    setShowBlockUI(true)
    // setSelectedBusiness(bus)
    fetch(`/api/trialUser/changeBusiness/${bus[0].id}`)
      .then((res) => res.json())
      .then(data => {
        // console.log('trialUser/changeBusiness', data)
        if (data.error) {
          setShowBlockUI(false)
          // errorAlert(data.error)
        }
        setTimeout(() => {
          if (data && !data.error) {
            // fetchAPIs()
            // getDetails()
            setShowBlockUI(false)
            return routerHistory.push("/");
          }
        }, 5000)
      })
      .catch(e => {
        setShowBlockUI(false)
        console.log(e)
      })
  }

  return (
    <>
      {isError}
      {success}
      {sharePaymentModal()}
      {showBlockUI ? <BlockUI /> : null}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "#fff",
          display: "grid",
        }}
      >
        {
          selectedBusinessToGoBack.length ?
            <div className="ml-2 mt-2">
              <span className="btn-link btn-inner--icon ml-1" style={{ cursor: 'pointer', color: '#32325d' }} onClick={() => handleChangeBusiness(selectedBusinessToGoBack)}>
                <i className="ni ni-bold-left mr-1" />Back
              </span>
            </div>
            : null
        }
        <Container className="align-items-center " style={{ display: "grid" }}>
          <Row className="justify-content-center">
            <Col
              lg="6"
              md="8"
              sm="12"
              xs="12"
              className={`mt-2 px-0 px-lg-3`}
            >
              <Card className=" bg-transparent shadow-none p-3 mb-0 py-3 px-lg-5 mx-2 ">
                <CardImg
                  alt="Login"
                  src={"/api/accountingFirm/getThemeLogo"}
                  style={{ width: "150px" }}
                  className=" justify-content-center text-center rounded mx-auto"
                  top
                />
                <CardHeader className="px-0 pb-0 bg-transparent">
                  <h1 className="h2 text-center text-normal">
                    Payment Setup
                  </h1>
                </CardHeader>
                <CardBody className="pt-2 p-0">
                  <p className="h5 font-weight-400 text-justify text-small">
                    Before diving deeper, we need your banking details. But here's the assurance: <strong>*You won't be charged until your trial is over!*</strong> And yes, you can cancel at any point before the trial concludes, even after entering your payment details.
                  </p>
                  <div className="pt-2">
                    {clientSecret && stripePromise ? (
                      <Elements stripe={stripePromise} options={{
                        appearance: {
                          theme: "stripe",
                        },
                        clientSecret,
                      }}>
                        <CheckoutForm
                          secret={clientSecret}
                          buttonstyle={{ width: "100%" }}
                          // userDetails={userDetails}
                          firmDetails={firmDetails}
                          stripeSuccess={stripeSuccess}
                          // userEmail={userDetails?.User?.Email}
                          description={firmDetails?.Name}
                          firstTimeSetup={true}
                        />
                      </Elements>
                    )
                      : <CustomSpinner />
                    }
                    <div className="mt-2">
                      <Button
                        style={{ backgroundColor: "#ffffff", borderColor: "#ffffff", color: "#272A65", width: "100%" }}
                        className="mt-2"
                        color="primary"
                        type="button"
                        onClick={handleLogout}
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="h5 font-weight-400 text-justify text-small">No credit card or bank account? Simply click below to share the payment setup link with a designated member of your team, who can then proceed to provide the necessary payment information.</p>
                    <p className="h5 font-weight-400 text-small"><strong className="link" style={{ cursor: "pointer" }} onClick={() => handleSharePayment()}>Click here.</strong></p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default PaymentSetup;