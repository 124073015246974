import { MainContext } from 'context/mainContext';
import { head } from 'lodash';
import React, { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { ChromePicker } from 'react-color'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { getRandomLightColor } from 'services/EVAService';
import { defaultControl } from 'services/EVAVisualService';

function VisualGraphView({ gridHeader, selectedViewOption, sqlresult, dataValue, headerToMultiSelect, handleSaveVisual, templateItemVisual, item, onEditVisual, handleCloseEditVisual, onEditName }) {
    const {
        bgColor,
        textColor
    } = useContext(MainContext);

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedFooter, setSelectedFooter] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([{ label: '', data: '', color: defaultControl(0), isOpen: false }]);
    const [chartDataSet, setChartDataSet] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState('#ffffff');
    const [color, setColor] = useState(bgColor);
    const [chartHeader, setChartHeader] = useState([])
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(null)
    const [isEditVisual, setIsEditVisual] = useState(false)
    const [visualSettings, setVisualSettings] = useState(null)


    let chartData = {
        labels: [],
        datasets: [],
    }
    useEffect(() => {
        if (selectedFooter) {
            const footer = getChartFooter(selectedFooter.id);
            chartData.labels = footer;
        }
    
        if (selectedFooter && selectedGroups) {
            getChartData(selectedGroups, selectedGraphHeaderOption);
        }
    
        const param = {
            selectedViewOption,
            selectedGroups,
            selectedGroup,
            chartHeader,
            selectedFooter,
            selectedGraphHeaderOption,
            headerToMultiSelect,
            chartData,
        };
        setVisualSettings(param);
    }, [selectedGroups, selectedFooter, selectedGraphHeaderOption]);
    
    useEffect(() => {
        if (!templateItemVisual) return;
    
        const {
            chartHeader,
            selectedFooter,
            selectedGroup,
            selectedGroups,
            selectedViewOption,
            headerToMultiSelect,
        } = templateItemVisual;
    
        if (selectedViewOption === undefined) return;
    
        if (["Bar Chart", "Line Chart"].includes(selectedViewOption)) {
            // console.log(templateItemVisual);
    
            if (selectedGroup !== "") {
                setSelectedGroup(selectedGroup);
                handleSelectGroupList(selectedGroup, headerToMultiSelect);
            }
    
            setSelectedGroups(selectedGroups);
            setSelectedFooter(selectedFooter);
            setSelectedGraphHeaderOption(selectedGraphHeaderOption);
        }
    }, [templateItemVisual]);
    

    const getChartFooter = (id) => {
        const label = []
        sqlresult.forEach(element => {
            if(selectedGraphHeaderOption)
            {
                if(!label.includes(element[id]) && element[chartHeader.fieldIndex] === selectedGraphHeaderOption)
                label.push(element[id])
            }else{
                if(!label.includes(element[id]))
                label.push(element[id])
            }
           
        });
        return label
    }
    const getChartData = (filter, withHeader) => 
    {
        filter.forEach((fil, index) => {
            if(fil.data !== '' && fil.label !== '')
            {
                const res = headerToMultiSelect && headerToMultiSelect.length > 0 && headerToMultiSelect.filter((e) => e.name === fil.data);
                if(res.length > 0)
                {
                    const id = res[0].id
                    if(chartData.labels.length > 0)
                    {
                        const item = {
                            id: index,
                            label: fil.label,
                            data: [],
                            backgroundColor: fil.color,
                            borderColor: fil.color,
                        }
                        let itemData = []
                        chartData.labels.forEach((item) => {
                            let totalAmount = 0
                            sqlresult.forEach((data) => {
                                if(item === data[selectedFooter.id])
                                {
                                    if (typeof data[id] === 'string') 
                                        if (data[id].includes('%')) 
                                            if(!isNaN(parseFloat(data[id].replace('%', '')).toFixed(2)))
                                                data[id] = parseFloat(data[id].replace('%', '')).toFixed(2)
                                    
                                    totalAmount += data[id]
                                }
                            })
                            itemData.push(totalAmount)
                        })
                    
                        item.data = itemData
                        chartData.datasets.push(item)
                    }
                }
                
            }
        })
        if(chartData.datasets.length > 0 && chartData.labels.length > 0)
        {
            // console.log(chartData)
            setChartDataSet(chartData)
        }
    }
    const handleSelectGroupChange = event => {
        if(event.target.value !== 'Select Group By')
        {
            setSelectedGroup(event.target.value);
            handleSelectGroupList(event.target.value, headerToMultiSelect)
        }else{
            setSelectedGraphHeaderOption(null)
            setSelectedGroup(null)
            setChartHeader({fieldIndex: 0, header : []})
        }
    };
    const handleSelectGroupList = (value, headerToMultiSelect) => {
        const res = headerToMultiSelect && headerToMultiSelect.length > 0 && headerToMultiSelect.filter((e) => e.name === value);
        if(res && res.length > 0)
        {
            const header = getChartHeader(res[0].id)
            setChartHeader({fieldIndex: res[0].id, header : header})
        }
    }
    const getChartHeader = (id) => {
        const label = []
        sqlresult.forEach(element => {
            if(!label.includes(element[id]))
            label.push(element[id])
        });
        return label
    }
    const handleSelectFooterChange = (value) => {
        const res = headerToMultiSelect.filter((e) => e.name === value);
        setSelectedFooter({ id: res[0].id, label: value });
    };
    const toggleColorPicker = (index, value) => {
        setIsOpen(!isOpen);
        setSelectedGroups(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index]['isOpen'] = value;
            return updatedGroups;
        });
    };
    const handleRemoveFormGroup = index => {
        setSelectedGroups(prevGroups => prevGroups.filter((group, i) => i !== index));
    };
    const handleSelectChange = (index, type, value) => {
        setSelectedGroups(prevGroups => {
          const updatedGroups = [...prevGroups];
          updatedGroups[index][type] = value;
          return updatedGroups;
        });
    };
    const handleChangeComplete = (newColor, index) => {
        setSelectedColor(newColor.hex);
        setSelectedGroups(prevGroups => {
            const updatedGroups = [...prevGroups];
            updatedGroups[index]['color'] = newColor.hex;
            return updatedGroups;
        });
    };
    const handleAddFormGroup = () => {
        setSelectedGroups(prevGroups => {
            const newLength = prevGroups.length;
            return [
                ...prevGroups,
                { label: '', data: '', color: defaultControl(newLength), isOpen: false }
            ];
        });
        
    };
    const handleSelectGraphHeader = (item) => {
        setSelectedGraphHeaderOption(item)
    }
    const onHandleSaveVisual = () => {
        handleSaveVisual(visualSettings)
    }
   
    
    return (
        <>
            
            {(onEditVisual || onEditName) &&
                <>
                    <FormGroup>
                        <Col sm='12' md='4'>
                            <Row>
                                <Label htmlFor="selectGroup">Select Group By (Optional)</Label>
                                <Input type="select" id="selectGroup" value={selectedGroup} onChange={handleSelectGroupChange}>
                                <option value={null}>Select Group By</option>
                                {gridHeader.length > 0 && gridHeader.map(group => (
                                    <option key={group} value={group}>
                                        {group}
                                    </option>
                                ))}
                                </Input>
                            </Row>
                        </Col>
                    </FormGroup>
                    {gridHeader && gridHeader.length > 0 &&
                        <FormGroup>
                            <Col sm='12' md='4'>
                                <Row>
                                    <Label htmlFor="selectGroup"><span style={{ color: 'red'}}>* </span>Select Primary Horizontal</Label>
                                    <Input 
                                        type="select" 
                                        id="selectGroup" 
                                        value={selectedFooter ? selectedFooter.label : ''}
                                        onChange={e => handleSelectFooterChange(e.target.value)}
                                    >
                                        <option value="">Select Primary Horizontal</option>
                                        {gridHeader.map((group) => (
                                            <option key={group} value={group}>
                                                {group}
                                            </option>
                                        ))}
                                    </Input>
                                </Row>
                            </Col>
                        </FormGroup>
                    }
                    {selectedGroups && selectedGroups.length > 0 && selectedGroups.map((group, index) => (
                        <FormGroup key={index}>
                            <Col sm='12'>
                                <Row>
                                    <Col sm='12' md='3'>
                                        <Row>
                                            <Label htmlFor={`selectGroupLabel${index}`}><span style={{ color: 'red'}}>* </span>Select Data Label</Label>
                                            <Input type="select" id={`selectGroupLabel${index}`} value={group.label} onChange={e => handleSelectChange(index, 'label', e.target.value)}>
                                            <option value="">Select Label</option>
                                            {gridHeader.length > 0 && gridHeader.map(group => (
                                                <option key={group} value={group}>
                                                    {group}
                                                </option>
                                            ))}
                                            </Input>
                                        </Row>
                                    </Col>
                                    <Col sm='12' md='3'>
                                        <Label htmlFor={`selectGroupData${index}`}><span style={{ color: 'red'}}>* </span>Select Primary Vertical</Label>
                                        <Input type="select" id={`selectGroupData${index}`} value={group.data} onChange={e => handleSelectChange(index, 'data', e.target.value)}>
                                            <option value="">Select Data</option>
                                            {gridHeader.length > 0 && gridHeader.map(group => (
                                                <option key={group} value={group}>
                                                {group}
                                                </option>
                                            ))}
                                        </Input>
                                        <div className='cardControllerContainer' style={{ width: 111, right: -120 }}>
                                            <div  style={{ 
                                                backgroundColor: group.color, 
                                                border: 'none',
                                                width: 30,
                                                borderRadius: 5,
                                                cursor: 'pointer'
                                                }} color="primary" onClick={e => toggleColorPicker(index, true)}></div>
                                            {group.isOpen &&
                                                <div style={{ position: "absolute", zIndex: "99999" }}>
                                                    <div
                                                        key={index}
                                                        id={index}
                                                        style={{
                                                            position: "fixed",
                                                            top: "0px",
                                                            right: "0px",
                                                            bottom: "0px",
                                                            left: "0px",
                                                        }}
                                                        onClick={e => toggleColorPicker(index, false)}
                                                    />
                                                    <ChromePicker color={group.color} onChangeComplete={(newColor) => handleChangeComplete(newColor, index)} />
                                                </div>
                                            }
                                            {index === selectedGroups.length - 1 && (
                                                <div 
                                                    className='addNewCardlData'
                                                    onClick={handleAddFormGroup}
                                                ><i className="fas fa-solid fa-plus"></i></div>
                                            )}
                                            {selectedGroups.length > 1 && (
                                                <div className='removeCardlData'  onClick={() => handleRemoveFormGroup(index)}><i className="fas fa-solid fa-trash"></i></div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                    ))}
                    <FormGroup>
                        <Col sm='12'>
                        <Row>
                            <div 
                                className='addNewVisualData' 
                                style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                                onClick={onHandleSaveVisual}
                            >Save View</div>
                            <div 
                                className='addNewVisualData' 
                                style={{ border: '1px solid #333', color: '#333', width: 100, marginTop: 10, marginRight: 0 }}
                                onClick={handleCloseEditVisual}
                            >Cancel</div>
                        </Row>
                        </Col>
                    </FormGroup>
                </>
            }
            

            <div className='templateVisualGraphHeaderContent'>
               
                {chartDataSet &&
                    <>
                        <div className='templateVisualGraphHeaderContainer'>
                            {chartHeader && chartHeader.header && chartHeader.header.map((item, index) => (
                            <div 
                                key={index}
                                className='templateVisualGraphHeaderItem' 
                                style={selectedGraphHeaderOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                onClick={() => handleSelectGraphHeader(item)}
                            >{item}</div>
                            ))}
                        </div>
                        {selectedViewOption === 'Line Chart' &&
                            <Line
                                datasetIdKey='id'
                                data={chartDataSet}
                            />}
                        {selectedViewOption === 'Bar Chart' &&
                            <Bar
                                datasetIdKey='id'
                                data={chartDataSet}
                        />}
                    </>
                }
            </div>
        </>   
    )
}

export default VisualGraphView