import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button } from "reactstrap";
import moment from "moment";
const IntegrationHistoryTable = ({ detailsType, data, isCurrent, ...props }) => {
  // console.log(data)
  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {/* <th scope="col">
              IntegrationID
            </th> */}
            <th scope="col">
              Name
            </th>
            <th scope="col">Integration Type</th>
            <th scope="col">Organisation ID</th>
            <th scope="col">Client</th>
            <th scope="col">{isCurrent ? "Price" : "Action"}</th>
            <th scope="col">Initiator</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {
            data?.length >= 1 ? data?.map((d, i) => (
              <tr key={i}>
                {/* <th scope="row">{d.Details.Integration.Details.id ?? "N/A"}</th> */}
                <td>{isCurrent ? (d.Details.name || d.Details.Name || d.Details.Organisation || d.Details.orgkey) ?? "N/A" : (d.Details.Integration.Details.name || d.Details.Integration.Details.Name || d.Details.Integration.Details.Organisation || d.Details.Integration.Details.orgkey)?? "N/A"}</td>
                <td>{isCurrent ? d.Service.Name : d.Details.Integration.Service.Name}</td>
                <td>{isCurrent ? d.id : d.Details.Integration.id}</td>
                <td>{isCurrent ? d.Client.Name : d.Details.Client.Name}</td>
                <td>{isCurrent ? `$${d.Service.Details?.Price ?? 10}` : d.Action}</td>
                <td>{isCurrent ? d.LastAddedBy.Email : d.Details.Initiator.id <= 4 ? `System` : `${d.Details.Initiator.Email}`}</td>
                <td>{moment(isCurrent ? d.createdAt : d.createdAt).format("DD-MM-YYYY LT")}</td>
              </tr>
            ))
              : null
          }
        </tbody>
      </Table>
      {
        data?.length < 1 ?
          <Row>
            <Col>
              <p className="text-center text-sm mt-3">No data found.</p>
            </Col>
          </Row>
          : null
      }
    </>
  )
}
export default IntegrationHistoryTable;