import { CustomButton } from 'components/Button/CustomButton'
import React, { useState, useContext, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { Modal } from 'reactstrap'
const EnablePBI = ({ showPBIModal, handleClosePBIModal, handleSavePBI, firmDetails, ...props }) => {
  return (
    <>
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showPBIModal}
        toggle={handleClosePBIModal}
      >
        <div className="modal-header">
          <h6 className="mb-0 modal-title">
            {`${firmDetails?.Details?.PbiEnabled ? "Disable" : "Enable"} Microsoft Power BI Etani Platform`}
          </h6>
        </div>
        <div className="modal-body py-0">
          {
            firmDetails?.Details?.PbiEnabled ?
              <>
                <p className="text-left">
                  Doing this will remove access to all Power BI features.
                </p>
                <p className="text-left">
                  Click confirm to continue.
                </p>
              </>
              :
              <>
                <p className="text-left">
                  Before showing Power BI Page to users, we need you to enable first Microsoft Power BI Etani Platform feature.
                </p>
                <p className="text-left">
                  This runs with Azure Data Warehouse and works other resources to simplify data handling. You need to run this platform to use Power BI and it’s advanced visualisation tools.
                </p>
                <p className="text-left">
                  <strong>Note:</strong> This implementation will activate the Power BI feature for your business, incurring an additional monthly fee of <em>$200 - $210</em>. Please click <a href='https://etani.ai/pricing-seat-fee-business' target="_blank" rel="noreferrer">here</a> for further information.
                </p>
                <p className="text-left">
                  Click confirm to continue.
                </p>
              </>
          }
        </div>
        <div className="modal-footer">
          <CustomButton
            type="button"
            onClick={() => handleSavePBI(firmDetails?.Details?.PbiEnabled ? "Disable" : "Enable")}
            outlineStyle={false}
            title={"Confirm"}
          />
          <CustomButton
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => handleClosePBIModal()}
            outlineStyle={true}
            title="Cancel"
          />
        </div>
      </Modal>
    </>
  )
}
export default EnablePBI;