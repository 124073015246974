import React, { useState } from "react";
import { FormGroup, Input, Col, Row, Modal } from "reactstrap";
import { CustomButton } from "components/Button/CustomButton";
import CustomThumbnailField from "components/CustomThumbnailField/CustomThumbnailField";
import PaymentDetails from "components/Payment/PaymentDetails";
import MFABlock from "components/MFABlock/MFABlock";

const AccountantDetailsForm = ({ data, selectedAccountant, userDetails, firmDetails, ...props }) => {
  const [openMultiFactorModal, setOpenMultiFactorModal] = useState(false);
  const [enableMultiFactor, setEnableMultiFactor] = useState(false);

  const handleEnableMultiFactor = () => {
    setOpenMultiFactorModal(!openMultiFactorModal);
    setEnableMultiFactor(!enableMultiFactor);
  };

  const closeMultiFactorModal = () => {
    // console.log(enableMultiFactor);
    setOpenMultiFactorModal(false);
    setEnableMultiFactor(false);
  };

  const renderCardIcon = (brand) => {

    let cardLogo = ''

    switch (brand) {
      case "amex":
        cardLogo = "amex"
        break;
      case "diners":
        cardLogo = "diners"
        break;
      case "discover":
        cardLogo = "discover"
        break;
      case "jcb":
        cardLogo = "jcb"
        break;
      case "mastercard":
        cardLogo = "mastercard"
        break;
      case "unionpay":
        cardLogo = "unionpay"
        break;
      case "visa":
        cardLogo = "visa"
        break;
      default:
        // code block
        cardLogo = "unknown"
    }


    return (
      <img
        style={{
          background: "#eee",
          width: "auto",
          height: "20px",
          objectFit: "contain",
          // borderRadius: "10px",
          // border: "2px solid #e2e2e2",
        }}
        alt="..."
        // className="rounded-circle"
        src={require(`assets/img/cards/${cardLogo}.png`).default}
      />
    )
  }

  return (
    <>
      <Modal
        className="modal-dialog-top"
        isOpen={enableMultiFactor}
        toggle={() => handleEnableMultiFactor()}
        size="lg"
        backdrop="static"
      >
        <div className="modal-header">
          <h6 className="modal-title">{"Setup Multi Factor Authentication"}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => closeMultiFactorModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label className="form-control-label" htmlFor="6digit">
              6-digit Code
            </label>
            <Input
              placeholder="6-digit Code"
              type="text"
              name="6-digit Code "
              // required={true}
              id="6digit"
              onChange={props.handleInputChange}
              className="getData"
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <CustomButton
            type="button"
            onClick={() => handleEnableMultiFactor()}
            outlineStyle={false}
            title="Save changes"
          />
          <CustomButton
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => closeMultiFactorModal()}
            outlineStyle={true}
            title="Close"
          />
        </div>
      </Modal>
      {
        props.showPaymentInfo ?
          <>
            <PaymentDetails userPaymentDetails={props.userPaymentDetails} handleShowPaymentInfo={props.handleShowPaymentInfo} />
          </>
          :
          <>
            {/* {userDetails?.User?.Details?.MFAEnabled ? <MFABlock userDetails={userDetails} /> : ''} */}
            <FormGroup>
              <label className="form-control-label" htmlFor="Email">
                Email <span className="text-warning">*</span>
              </label>
              <Input
                placeholder="Email"
                type="email"
                name="Email"
                required={true}
                id="Email"
                onChange={props.handleInputChange}
                defaultValue={data && data.Email ? data.Email : ""}
                className="getData"
                style={{
                  border: props.isEmailValid ? "1px solid #dee2e6" : "1px solid red",
                }}
                disabled={props.modalTitle === "Add Admin" ? false : true}
              />
              {props.isEmailValid ? (
                ""
              ) : (
                <small className="text-warning">Email is not valid.</small>
              )}
            </FormGroup>
            {props.userAccess <= 4 || props.isUpdate ? (
              firmDetails?.Details?.PbiEnabled ?
              <FormGroup>
                <label className="form-control-label" htmlFor="PowerBIEmail">
                  Power BI Email <span className="text-warning">*</span>
                </label>
                <Input
                  placeholder="PowerBI Email"
                  type="email"
                  name="PowerBIEmail"
                  required={true}
                  id="PowerBIEmail"
                  onChange={props.handleInputChange}
                  defaultValue={data && data.UserName ? data.UserName : ""}
                  className="getData"
                  style={{
                    border: props.isEmailValid
                      ? "1px solid #dee2e6"
                      : "1px solid red",
                  }}
                  disabled={props.modalTitle === "Add Admin" ? false : true}
                />
                {props.isEmailValid ? (
                  ""
                ) : (
                  <small className="text-warning">Email is not valid.</small>
                )}
              </FormGroup>
              : null
            ) : null}
            <FormGroup>
              <label className="form-control-label" htmlFor="FirstName">
                First Name <span className="text-warning">*</span>
              </label>
              <Input
                placeholder="First Name"
                type="text"
                name="FirstName"
                required={true}
                id="FirstName"
                onChange={props.handleInputChange}
                defaultValue={data && data.FirstName ? data.FirstName : ""}
                className="getData"
                style={{
                  border: props.isFirstNameValid
                    ? "1px solid #dee2e6"
                    : "1px solid red",
                }}
              />
              {props.isFirstNameValid ? (
                ""
              ) : (
                <small className="text-warning">This field is required.</small>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="LastName">
                Last Name <span className="text-warning">*</span>
              </label>
              <Input
                placeholder="Last Name"
                type="text"
                name="LastName"
                required={true}
                id="LastName"
                onChange={props.handleInputChange}
                defaultValue={data && data.LastName ? data.LastName : ""}
                className="getData"
                style={{
                  border: props.isLastNameValid
                    ? "1px solid #dee2e6"
                    : "1px solid red",
                }}
              />
              {props.isLastNameValid ? (
                ""
              ) : (
                <small className="text-warning">This field is required.</small>
              )}
            </FormGroup>
            {props.userAccess >= 5 ? null : (
              <FormGroup>
                <label className="form-control-label" htmlFor="Phone">
                  Phone Number
                  {/* <span className="text-warning">*</span> */}
                </label>
                <Input
                  placeholder="Phone Number"
                  type="text"
                  name="Phone"
                  required={true}
                  id="Phone"
                  onChange={props.handleInputChange}
                  defaultValue={data && data.Phone ? data.Phone : ""}
                  className="getData"
                // style={{
                //   border: props.isPhoneValid
                //     ? "1px solid #dee2e6"
                //     : "1px solid red",
                // }}
                />
                {/* {props.isPhoneValid ? (
                  ""
                ) : (
                  <small className="text-warning">This field is required.</small>
                )} */}
              </FormGroup>
            )}
            <Row>
              <Col>
                <CustomThumbnailField
                  id="Logo"
                  name="Logo"
                  help="Avatar"
                  onChange={props.handleInputChange}
                  value={
                    props.isLoading && !data.Avatar
                      ? require("assets/img/blank-avatar.png").default
                      : data.Avatar
                  }
                  label="Avatar"
                  alt="Logo"
                  lang="en"
                  record={data}
                  handleImgChange={props.handleImgChange}
                  withResetImage={true}
                />
              </Col>
            </Row>
            {props.userAccess >= 5 ? null : (
              <FormGroup>
                <label className="form-control-label" htmlFor="Sample">
                  Position <span className="text-warning">*</span>
                </label>
                <Input
                  placeholder="Position"
                  type="text"
                  name="Position"
                  required={true}
                  id="Position"
                  onChange={props.handleInputChange}
                  defaultValue={data && data.Position ? data.Position : ""}
                  className="getData"
                  style={{
                    border: props.isPositionValid
                      ? "1px solid #dee2e6"
                      : "1px solid red",
                  }}
                />
                {props.isPositionValid ? (
                  ""
                ) : (
                  <small className="text-warning">This field is required.</small>
                )}
              </FormGroup>
            )}
            {props.userAccess >= 5 ? null : (
              <FormGroup>
                <label className="form-control-label" htmlFor="Sample">
                  Description
                </label>
                <Input
                  placeholder="Description"
                  type="textarea"
                  name="Description"
                  required={true}
                  id="Description"
                  rows={4}
                  onChange={props.handleInputChange}
                  defaultValue={data && data.Description ? data.Description : ""}
                  className="getData"
                />
              </FormGroup>
            )}
            {props.onProfilePage ? (
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlInput1"
                >
                  Enable Multi Factor Authentication?
                </label>
                <div>
                  <Row>
                    <Col className="col-lg-1" xs="2">
                      <label className="custom-toggle custom-toggle-success mr-1">
                        <input
                          defaultChecked={enableMultiFactor}
                          type="checkbox"
                          onClick={handleEnableMultiFactor}
                          id="MultiAuth"
                          name="MultiAuth"
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Yes"
                        />
                      </label>
                    </Col>
                  </Row>
                </div>
              </FormGroup>
            ) : null}
          </>
      }
    </>
  );
};

export default AccountantDetailsForm;
