import { IconEdit } from "chat-components/ui/icon";
import { IconExpand } from "chat-components/ui/icon";
import { IconMinimize } from "chat-components/ui/icon";
import CustomSpinner from "components/Misc/CustomSpinner";
import { MainContext } from "context/mainContext";
import GraphView from "eva-component/response/GraphView";
import GridView from "eva-component/response/GridView";
import TreeView from "eva-component/response/TreeView";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { submitEvaQueryHandler } from "services/DashboardService";
import { decryptAndDecompress } from "services/DashboardService";
import { evaThemeLighter } from "services/EVAResponseService";
import { isWithChildHandler } from "services/EVAResponseService";
import { convertToTreeViewData } from "services/EVAResponseService";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { IconTrash } from "chat-components/ui/icon";
import { IconSave } from "chat-components/ui/icon";
import { reFormatEVADateRange } from "services/EVAResponseService";


const GroupDashboardDetails = ({detail, headerName, index, onHandleDeleteDashboardItem, onHandleSaveName}) =>{
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [isShowGrap, setIsShowGrap] = useState(false)
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [toggleAll, setToggleAll] = useState(false)
    const [sqlresult, setSqlResult] = useState(null)
    const [gridHeader, setGridHeader] = useState(null)
    const [EVAData, setEVAData] = useState(null)
    const [treeViewData, setTreeViewData] = useState(null)
    const [isWithChild, setIsWithChild] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [onEditName, setOnEditName] = useState(false)
    const [newDashboardItemName, setNewDashboardItemName] = useState('');
    const [isShowObserbation, setIsShowObservation] = useState(false)
    const [observation, setObservation] = useState(null)

    const { stext, uid, version, _id, name} = detail
    const inputRef = useRef(null);

    const theme = { PrimaryColor: bgColor, TextColor: textColor }
    // console.log(detail)
    useEffect(() => {
     
        const processData = async () => {
            try{

                const decryptedText = await decryptAndDecompress(stext);
                const res = await submitEvaQueryHandler(decryptedText, selectedClient, firmDetails, userDetails);
                if(!res) return
                
                const sqlresult = res.map(obj => Object.values(obj));
                const header = res.length > 0 ? Object.keys(res[0]) : [];
                const newRes = await reFormatEVADateRange(header, sqlresult)
                const treeViewData = convertToTreeViewData(sqlresult)
                const isWithChild = isWithChildHandler(treeViewData)

                setObservation(detail.observation)
                setEVAData(res)
                setSqlResult(sqlresult)
                setGridHeader(header)
                setTreeViewData(treeViewData)
                setIsWithChild(isWithChild)
                setLoading(false)
                setError(false)
            }catch (error)
            {
                console.log(error)
                setError(true)
                setLoading(false);
            }
        }
        setLoading(true);
        processData();
        
    }, [headerName])

    useEffect(() => {
        if (onEditName && inputRef.current) {
            inputRef.current.focus();
        }
    }, [onEditName, inputRef.current]);

    const showGridHandler = () => {
        setIsShowGrid(!isShowGrid)
        setIsShowGrap(false)
    }
    const showGrapHandler = () => {
        setIsShowGrap(!isShowGrap)
    }
    const toggleAllVisibility = () => {
        setToggleAll(!toggleAll)
    }
    const toggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
    }
    const ItemFullScreenView = () => {
        return (
            <div className='ItemFullScreenViewContainer'>
                <div  className='ItemFullScreenViewContent'>
                    <ItemContentView />
                </div>
            </div>
        )
    }

    const handleDeleteItem = () => {
        onHandleDeleteDashboardItem(detail)
    }
    const handleEditItem = () => {
        setNewDashboardItemName(name ? capitalize(name) : 'Item ' + (index + 1))
        setOnEditName(!onEditName)
    }
    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    const handleNewDashboardNameItemChange = (event) => {
        setNewDashboardItemName(event.target.value);
    };

    const handleSave = () => {
        detail.name = newDashboardItemName
        onHandleSaveName(detail)
        setOnEditName(!onEditName)
    };
    const ShowObserbationHandler = () => {
        setIsShowObservation(!isShowObserbation)
    }

    const ItemContentView = () => {
        return (
            <div className="dashboardItems" style={{ border: `1px solid ${bgColor}` }} key={index}>
                <div style={{...evaThemeLighter(80, theme),  display: 'flex', justifyContent: 'space-between',  padding: 10, fontWeight: 'bold' }}>
                    <div style={{ width: '100%', paddingRight: 10}}>
                        {!onEditName ?
                            name ? capitalize(name) : `Item ${index + 1}` :
                            <div className="editNameContent">
                                <input
                                    type="text"
                                    value={newDashboardItemName}
                                    onChange={handleNewDashboardNameItemChange}
                                    style={{ width: '100%', border: `1px solid ${bgColor}` }}
                                    ref={inputRef}
                                />
                            </div>
                        }
                    </div>
                    <div className="itemControls" style={{ color: `${textColor}`, cursor: 'pointer', marginTop: '-3px' }} >
                        <div className="itemControlsFullScreen" onClick={toggleFullScreen}>{isFullScreen ? <IconMinimize /> : <IconExpand />}</div>
                    </div>
                </div>
                {loading ? <CustomSpinner /> :
                isError && !loading ? 
                <>
                    <div className="dashboardItems" style={{
                        height: '200px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally
                        justifyContent: 'center', // Center vertically
                        flexWrap: 'wrap'
                    }}>
                        <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No data found</div>
                        <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                    </div>
                </>
                :
                <div className="dashboardItemBody">
                    <div className='evaText' style={{ marginBottom: '10px', fontSize: '14px'}}>
                        {isShowObserbation && <div>
                            <div className='evaLabel' style={{ fontSize : '15px', fontWeight: 'bold'}}>Observation:</div>
                            {observation}
                        </div>}
                    </div>
                    <div className='viewOptionBtnContainer'>
                        {observation && 
                            <div className='toggleObservation viewOptionBtn'
                            style={evaThemeLighter(100, theme)}
                            onClick={() => ShowObserbationHandler()}>
                                Observation {`${isShowObserbation ? ' ▲' : ' ▼'}`}
                            </div>
                        }
                        
                        {isWithChild && 
                            <div 
                                className='viewOptionBtn' 
                                style={{ 
                                    backgroundColor: theme.PrimaryColor, 
                                    color: theme.TextColor }}
                                onClick={() => showGridHandler()}>
                                {isShowGrid ? "Grid view" : "Tree view" }
                            </div>
                        }
                        <div 
                            className='viewOptionBtn' 
                            style={evaThemeLighter(100, theme)}
                            onClick={() => showGrapHandler()}
                            >
                            {isShowGrap ? isShowGrid ? "Back to Grid view" : "Back to Tree view" : "Convert to Graph view" }
                        </div>
                        {isWithChild && 
                            <div 
                                className='viewOptionBtn' 
                                style={{ 
                                    backgroundColor: theme.PrimaryColor, 
                                    color: theme.TextColor }}
                                onClick={() => toggleAllVisibility()}>
                                {toggleAll ? "Expand All" : "Collapse All"}
                            </div>
                        }
                    </div>
                    <div className='EVAResponseContainer'  style={{ display: 'unset' }}>
                        <div className='EVADataContent'>
                            {isShowGrap ? <GraphView 
                                sqlResult={sqlresult}
                                gridHeader={gridHeader}
                                theme={theme} 
                                treeViewData={treeViewData}
                                isWithChild={isWithChild}
                                isShowGrid={isShowGrid}
                            /> : 
                            isWithChild && !isShowGrid ? 
                                <TreeView
                                    theme={theme} 
                                    sqlresult={sqlresult} 
                                    gridHeader={gridHeader}
                                    treeViewData={treeViewData}
                                    toggleAll={toggleAll}
                                /> :
                                <GridView
                                    theme={theme} 
                                    sqlresult={sqlresult} 
                                    EVAData={sqlresult}
                                    gridHeader={gridHeader}
                                />
                            }
                        </div>
                    </div>
                </div>}
            </div>
            // </Col>
        )
    }

    return (
        isFullScreen ? 
        <ItemFullScreenView /> :
        <ItemContentView />
        // </Col>
    )
}
export default GroupDashboardDetails;